import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-wuxing-zuxuan-zx10',
  templateUrl: './ssc-wuxing-zuxuan-zx10.component.html',
  styleUrls: ['./ssc-wuxing-zuxuan-zx10.component.css']
})
export class SscWuxingZuxuanZx10Component extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false],
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
    ];
  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof SscWuxingZuxuanZx10Component
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];

    let pos_array = this.balls_str.split('|');
    for (let item of pos_array) {
      let arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    /* 判断规则：二重号不包含三重号，二重号数组长度最少为1位，否则至少为2位 */
    if (this.selected_ball_array[0].length >= 1 && this.selected_ball_array[1].length >= 1) {
      for (let i = 0; i < this.selected_ball_array[0].length; i++) {
        if (this.selected_ball_array[1].indexOf(this.selected_ball_array[0][i]) !== -1) {// 二重号中包含三重号
          if (this.selected_ball_array[1].length >= 2) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  /**
   * 计算注单数量
   *
   * @memberof SscWuxingZuxuanZx10Component
   */
  public calculate_bet_number() {
    let num: number = 0;
    let arr: Array<any> = [];

    for (let i = 0; i < this.selected_ball_array[0].length; i++) {
      // 初始化arr数组，使其与二重号数据一致
      arr = this.reset_erchonghao_data();

      let index = this.selected_ball_array[1].indexOf(this.selected_ball_array[0][i]);
      if (index !== -1) {// 二重号中包含三重号
        arr.splice(index, 1);
      }

      let len = arr.length;
      let n = 0;
      // 计算可形成注单的数量
      if (len >= 1) {
        n = this.fac(len) / (this.fac(1) * this.fac((len - 1)));
      }

      num += n;
    }

    this.bet_num = num;
  }

  /**
   * 恢复二重号数组数据
   *
   * @private
   * @returns {Array<any>}
   * @memberof SscWuxingZuxuanZx10Component
   */
  private reset_erchonghao_data(): Array<any> {
    let arr: Array<any> = [];
    this.selected_ball_array[1].forEach(val => {
      arr.push(val);
    });
    return arr;
  }

}
