import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-renxuan-rx3-zusan-fushi',
  templateUrl: './ssc-renxuan-rx3-zusan-fushi.component.html',
  styleUrls: ['./ssc-renxuan-rx3-zusan-fushi.component.css']
})
export class SscRenxuanRx3ZusanFushiComponent extends GameBase implements OnInit {

  public position_data: Array<any>;

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 位置选择 */
    this.position_data = [
      { label: '万位', value: '0', checked: false },
      { label: '千位', value: '1', checked: false },
      { label: '百位', value: '2', checked: true },
      { label: '十位', value: '3', checked: true },
      { label: '个位', value: '4', checked: true }
    ];
    this.betInfoService.set_bet_position_info_handler([0, 0, 1, 1, 1]);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof SscRenxuanRx3ZusanFushiComponent
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];

    let pos_array = this.balls_str.split('|');
    for (let item of pos_array) {
      let arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    for (let item of this.selected_ball_array) {
      if (item.length < 2) {
        return false;
      }
    }

    return true;
  }

  /**
   * 计算注单数量
   *
   * @memberof SscRenxuanRx3ZusanFushiComponent
   */
  public calculate_bet_number() {
    let num: number = 1;
    for (let item of this.selected_ball_array) {
      let len = item.length;
      num = this.fac(len) / (this.fac(2) * this.fac((len - 2))) * 2;
    }

    let position_num = this.position_data.filter(item => item.checked).length;
    let comb_num = this.fac(position_num) / (this.fac(3) * this.fac((position_num - 3)));

    this.bet_num = num * comb_num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscRenxuanRx3ZusanFushiComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let index = Number(bet_arr[i]);
      this.btn_status_array[0][index] = true;
    }

    this.update_bet_infor_handler();
  }

  /**
   * 位置选择
   *
   * @param {*} val
   * @memberof SscRenxuanRx3ZusanFushiComponent
   */
  public update_position_handler(val: any) {
    let arr = this.position_data.filter(item => item.checked);

    if (arr.length < 3) {
      let index = Number(val);
      setTimeout(() => {
        this.position_data[index].checked = true;
        this.update_bet_infor_handler();
      });
    } else {
      let p_arr = [0, 0, 0, 0, 0];
      this.position_data.forEach((item, index) => {
        if (item.checked) {
          p_arr[index] = 1;
        }
      });
      this.betInfoService.set_bet_position_info_handler(p_arr);

      this.update_bet_infor_handler();
    }
  }

  /**
   * 反选-位置选择
   *
   * @param {*} data
   * @memberof SscRenxuanRx3ZusanFushiComponent
   */
  public selected_position_render_handler(data: any) {
    data.forEach((item, index) => {
      if (item === 0) {
        this.position_data[index].checked = false;
      }
      if (item === 1) {
        this.position_data[index].checked = true;
      }
    });
  }
}
