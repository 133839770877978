import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscHezhi'
})
export class SscHezhiPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let lottery_arr = [];
    // 和值
    let num: number;

    switch (args) {
      case 'qiansan':
        value.forEach((element, index) => {
          if (index < 3) {
            lottery_arr.push(element);
          }
        });
        num = this.calculation_form_handler(lottery_arr);
        break;
      case 'zhongsan':
        value.forEach((element, index) => {
          if (index > 0 && index < 4) {
            lottery_arr.push(element);
          }
        });
        num = this.calculation_form_handler(lottery_arr);
        break;
      case 'housan':
        value.forEach((element, index) => {
          if (index > 1) {
            lottery_arr.push(element);
          }
        });
        num = this.calculation_form_handler(lottery_arr);
        break;
      case 'qianer':
        value.forEach((element, index) => {
          if (index < 2) {
            lottery_arr.push(element);
          }
        });
        num = this.calculation_form_handler(lottery_arr);
        break;
      case 'houer':
        value.forEach((element, index) => {
          if (index > 2) {
            lottery_arr.push(element);
          }
        });
        num = this.calculation_form_handler(lottery_arr);
        break;
      default: break;
    }

    return num;
  }

  private calculation_form_handler(lottery_arr: any): number {
    let total_num: number = 0;

    lottery_arr.forEach(element => {
      total_num += Number(element);
    });

    return total_num;
  }

}
