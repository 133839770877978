import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'k3Santonghao'
})
export class K3SantonghaoPipe implements PipeTransform {

  transform(value: any): any {
    let str: string;
    let arr = Array.from(new Set(value));

    if (arr.length === 1) {
      str = '三同号';
    } else {
      str = '--';
    }

    return str;
  }

}
