import { Component, OnInit } from '@angular/core';
import { GameConfig } from 'app/common/gameConfig';
import { Utils } from 'app/common/util';
import { ApiService } from 'app/providers/api/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-personal-prize',
  templateUrl: './personal-prize.component.html',
  styleUrls: ['./personal-prize.component.css']
})
export class PersonalPrizeComponent implements OnInit {

  // 奖金组
  public min_prize_group: number = 1800;
  public max_prize_group: number = 1960;
  public prize_group: number = 1960;
  // 两/一元模式
  public price_mode: string = '2';
  // 元/角/分模式
  public money_unit: string = '1';

  // 水平/垂直选项
  public nzTabPosition = 'top';
  // 时时彩玩法数组
  public ssc_data: Array<any> = [];
  public ssc_play_array: Array<any> = [];
  // 11选5玩法数组
  public x115_data: Array<any> = [];
  public x115_play_array: Array<any> = [];
  // K3玩法数组
  public k3_data: Array<any> = [];
  public k3_play_array: Array<any> = [];
  // PK10玩法数组
  public pk10_data: Array<any> = [];
  public pk10_play_array: Array<any> = [];
  // 低频玩法数组
  public dipin_data: Array<any> = [];
  public dipin_play_array: Array<any> = [];
  // 排列五玩法数组
  public plw_data: Array<any> = [];
  public plw_play_array: Array<any> = [];

  // 参数类型
  public type_param: number = 0;

  constructor(
    public api: ApiService,
    public activatedRoute: ActivatedRoute
  ) {
    this.api.get_request_base_config_info().subscribe(data => {
      if (data) {
        Utils.adt_val_11x5 = data['adt_val_11x5'];
        Utils.adt_val_dp = data['adt_val_dp'];
      }
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.type) {
        this.type_param = Number(queryParams.type);
      }
    });
  }

  ngOnInit() {
    this.api.get_request_ssc_game_config().subscribe(data => {
      if (data) {
        GameConfig.ssc_game_config = data['ssc_game_config'];
        this.init_ssc_play_data_handler();
      }
    });

    this.api.get_request_11x5_game_config().subscribe(data => {
      if (data) {
        GameConfig.x115_game_config = data['x115_game_config'];
        this.init_11x5_play_data_handler();
      }
    });

    this.api.get_request_k3_game_config().subscribe(data => {
      if (data) {
        GameConfig.k3_game_config = data['k3_game_config'];
        this.init_k3_play_data_handler();
      }
    });

    this.api.get_request_pk10_game_config().subscribe(data => {
      if (data) {
        GameConfig.pk10_game_config = data['pk10_game_config'];
        this.init_pk10_play_data_handler();
      }
    });

    this.api.get_request_f3d_game_config().subscribe(data => {
      if (data) {
        GameConfig.dipin_game_config = data['f3d_game_config'];
        this.init_dipin_play_data_handler();
      }
    });

    this.api.get_request_plw_game_config().subscribe(data => {
      if (data) {
        GameConfig.plw_game_config = data['plw_game_config'];
        this.init_plw_play_data_handler();
      }
    });

    this.api.get_request_lhc_game_config().subscribe(data => {
      if (data) {
        GameConfig.lhc_game_config = data['lhc_game_config'];
      }
    });
  }

  /**
   * 更新奖金组
   *
   * @memberof PersonalPrizeComponent
   */
  public update_prize_handler() {
    this.ssc_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 1);
    });

    this.x115_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 2);
    });

    this.k3_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 1);
    });

    this.pk10_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 1);
    });

    this.dipin_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 3);
    });

    this.plw_play_array.forEach(item => {
      item['prize'] = this.calculation_prize_handler(item['probability'], 3);
    });
  }

  /**
   * 计算奖金
   *
   * @param {*} p
   * @param {*} type
   * @returns
   * @memberof PersonalPrizeComponent
   */
  public calculation_prize_handler(p, type) {
    let prize: number;

    switch (type) {
      // ssc
      case 1: prize = Number(this.prize_group / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(p)); break;
      // 11x5
      case 2: prize = Number((this.prize_group - Utils.adt_val_11x5) / 1980 * Number(this.price_mode) * Number(this.money_unit) / Number(p)); break;
      // 低频
      case 3: prize = Number((this.prize_group - Utils.adt_val_dp) / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(p)); break;
      // 其他
      default: prize = Number(this.prize_group / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(p)); break;
    }

    return prize;
  }

  /**
   * 初始化时时彩玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_ssc_play_data_handler() {
    // 一级目录
    GameConfig.ssc_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number(this.prize_group / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.ssc_play_array.push(play);
          });

        });

      });

    });

    this.ssc_data = this.ssc_play_array;
  }

  /**
   * 初始化11选5玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_11x5_play_data_handler() {
    // 一级目录
    GameConfig.x115_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number((this.prize_group - Utils.adt_val_11x5) / 1980 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.x115_play_array.push(play);
          });

        });

      });

    });

    this.x115_data = this.x115_play_array;
  }

  /**
   * 初始化快3玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_k3_play_data_handler() {
    // 一级目录
    GameConfig.k3_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number(this.prize_group / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.k3_play_array.push(play);
          });

        });

      });

    });

    this.k3_data = this.k3_play_array;
  }

  /**
   * 初始化PK10玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_pk10_play_data_handler() {
    // 一级目录
    GameConfig.pk10_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number(this.prize_group / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.pk10_play_array.push(play);
          });

        });

      });

    });

    this.pk10_data = this.pk10_play_array;
  }

  /**
   * 初始化低频玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_dipin_play_data_handler() {
    // 一级目录
    GameConfig.dipin_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number((this.prize_group - Utils.adt_val_dp) / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.dipin_play_array.push(play);
          });

        });

      });

    });

    this.dipin_data = this.dipin_play_array;
  }

  /**
   * 初始化排列五玩法信息
   *
   * @memberof PersonalPrizeComponent
   */
  public init_plw_play_data_handler() {
    // 一级目录
    GameConfig.plw_game_config.forEach((v1) => {
      // 计算跨行数
      let span_num_type: number = 0;
      v1.children.forEach(g => {
        g.children.forEach(p => {
          span_num_type += p.aProbilities.level.length;
        });
      });

      // 二级目录
      v1.children.forEach((v2, g_index) => {
        // 计算跨行数
        let group_index: number = g_index;
        let span_num_group: number = 0;
        v2.children.forEach(element => {
          span_num_group += element.aProbilities.level.length;
        });

        // 三级目录
        v2.children.forEach((v3, p_index) => {

          // 四级目录
          v3.aProbilities.probability.sort(this.cmp).forEach((v4, index) => {
            let play: any = {};
            play['type_name'] = v1.name_cn;
            play['group_name'] = v2.name_cn;
            play['play_name'] = v3.name_cn;
            play['level'] = v3.aProbilities.probability.length === 1 ? '--' : `${index + 1}等奖`;
            play['probability'] = Number(v4);
            play['prize'] = Number((this.prize_group - Utils.adt_val_dp) / 2000 * Number(this.price_mode) * Number(this.money_unit) / Number(v4));
            // 玩法跨度
            if (index === 0) {
              play['span_num_play'] = v3.aProbilities.level.length;
            } else {
              play['span_num_play'] = 0;
            }
            // 玩法组跨度
            if (index === 0 && p_index === 0) {
              play['span_num_group'] = span_num_group;
            } else {
              play['span_num_group'] = 0;
            }
            // 玩法类型跨度
            if (index === 0 && p_index === 0 && group_index === 0) {
              play['span_num_type'] = span_num_type;
            } else {
              play['span_num_type'] = 0;
            }
            this.plw_play_array.push(play);
          });

        });

      });

    });

    this.plw_data = this.plw_play_array;
  }

  /**
   * 降序排列
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof PersonalPrizeComponent
   */
  public cmp(a, b) { return a - b; }

}
