import { Component, OnInit, Input } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-shengxiao-liuxiao',
  templateUrl: './lhc-shengxiao-liuxiao.component.html',
  styleUrls: ['./lhc-shengxiao-liuxiao.component.css']
})
export class LhcShengxiaoLiuxiaoComponent extends GameCreditBase implements OnInit {

  @Input()
  public current_zodiac: number;

  // balls
  public balls_array: Array<any> = [
    { name: '鼠', balls: [], is_select: false, p: 0 },
    { name: '牛', balls: [], is_select: false, p: 0 },
    { name: '虎', balls: [], is_select: false, p: 0 },
    { name: '兔', balls: [], is_select: false, p: 0 },
    { name: '龙', balls: [], is_select: false, p: 0 },
    { name: '蛇', balls: [], is_select: false, p: 0 },
    { name: '马', balls: [], is_select: false, p: 0 },
    { name: '羊', balls: [], is_select: false, p: 0 },
    { name: '猴', balls: [], is_select: false, p: 0 },
    { name: '鸡', balls: [], is_select: false, p: 0 },
    { name: '狗', balls: [], is_select: false, p: 0 },
    { name: '猪', balls: [], is_select: false, p: 0 }
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.init_zodiac_balls_handler();
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public reset_balls_panel_handler() {
    this.balls_array.forEach(item => {
      item.is_select = false;
    });
  }

  /**
   * 初始化生肖数组
   *
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public init_zodiac_balls_handler() {
    let index = 11 - (this.current_zodiac - 1) % 12;
    let cur_zodiac_ball = [1, 13, 25, 37, 49];

    cur_zodiac_ball.forEach(item => {
      let ball = Utils.lhc_balls_info[(item - 1)];
      this.balls_array[index]['balls'].push(ball);
    });

    for (let i = 1; i < 12; i++) {
      let n = ((12 + index - i) % 12);
      let b1 = cur_zodiac_ball[0] + i;
      let b2 = cur_zodiac_ball[1] + i;
      let b3 = cur_zodiac_ball[2] + i;
      let b4 = cur_zodiac_ball[3] + i;
      let b_arr = [b1, b2, b3, b4];
      b_arr.forEach(item => {
        let ball = Utils.lhc_balls_info[(item - 1)];
        this.balls_array[n]['balls'].push(ball);
      });
    }
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability['probability'];
    let index = 11 - (this.current_zodiac - 1) % 12;
    function findArr(arr) {
      if (arr[0] !== arr[1] && arr[0] !== arr[2]) {
        return arr[0];
      }
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr[i] !== arr[0]) {
          return arr[i];
        }
      }
    }
    let same = findArr(p_arr);
    p_arr.forEach((item, index) => {
      if (item === same) {
        p_arr.splice(index, 1);
      }
    });
    p_arr.splice(index, 0, same);

    this.balls_array.forEach((item, index) => {
      let prize = (this.prize_group / 2000) * (1 / p_arr[index]);

      item['p'] = prize;
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public render_bet_value_handler() {
    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.is_selected_info = this.balls_array.filter(item => (item.is_select));

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      let bet_info = new BetInfo();
      bet_info.way_id = Number(this.play_info['id']);

      let ball_str = [];
      this.is_selected_info.forEach((item: any) => {
        let z_str = [];
        item.balls.forEach((item: any) => {
          z_str.push(item.ball);
        });

        ball_str.push(z_str.join(','));
      });
      bet_info.ball = ball_str.join('|');

      bet_info.prize_group = Number(this.prize_group);

      let max_m = Math.floor(Utils.dp_max_prize / Number(this.balls_array[0]['p']));
      bet_info.multiple = this.bet_value > max_m ? max_m : this.bet_value;

      ball_arr.push(bet_info);

      if (this.is_selected_info.length >= 6) {
        let n = this.is_selected_info.length;
        let m = 6;
        let num = this.fac(n) / (this.fac(m) * this.fac((n - m)));
        bet_info.num = num;
        this.betInfoService.set_bet_num_handler(num);
      } else {
        this.betInfoService.set_bet_num_handler(0);
      }
      this.betInfoService.set_bet_info_handler(ball_arr);

    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

  /**
   * 阶乘计算公式
   *
   * @param {number} n
   * @returns
   * @memberof LhcShengxiaoLiuxiaoComponent
   */
  public fac(n: number) {
    return n > 1 ? n * this.fac(n - 1) : 1;
  }

}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
