import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trend-table-x115',
  templateUrl: './trend-table-x115.component.html',
  styleUrls: ['./trend-table-x115.component.css']
})
export class TrendTableX115Component implements OnInit {

  @ViewChild('WuXingTrendLine') wuxing_trend_line_ref: ElementRef;

  // 辅助功能
  public auxs: Array<any> = [true, true, false, true, false];
  public aux_array: Array<any> = [
    { val: '0', name: '辅助线', is_select: true },
    { val: '1', name: '遗漏', is_select: true },
    { val: '2', name: '遗漏条', is_select: false },
    { val: '3', name: '走势', is_select: true },
    { val: '4', name: '号温', is_select: false }
  ];
  // 期数
  public issue_num: string = '30';
  public issue_array: Array<any> = ['30', '50', '80', '100'];

  // 走势数据
  @Input()
  public trend_data: Array<any> = [];

  // 统计数据
  @Input()
  public analysis_data: Array<any> = [];

  @Output()
  public change_issue_num_emit: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.draw_wuxing_trend_line_handler();
  }

  /**
   * 选择辅助功能
   *
   * @param {*} e
   * @memberof TrendTableX115Component
   */
  public change_aux_handler(e: any) {
    this.auxs = [false, false, false, false, false];
    e.forEach(item => {
      let index = Number(item);
      this.auxs[index] = true;
    });
  }

  // 五星走势线
  public draw_wuxing_trend_line_handler() {
    // 设置canvas高度
    this.wuxing_trend_line_ref.nativeElement.width = 880;
    this.wuxing_trend_line_ref.nativeElement.height = this.trend_data.length * 30;

    const wuxing_cxt: CanvasRenderingContext2D = this.wuxing_trend_line_ref.nativeElement.getContext('2d');

    let w: number = 880;
    let h: number = this.trend_data.length * 30;
    wuxing_cxt.fillStyle = 'rgba(255, 255, 255, 0)';
    wuxing_cxt.clearRect(0, 0, w, Number(this.issue_num) * 30);
    wuxing_cxt.fillRect(0, 0, w, h);

    for (let i = 0; i < 5; i++) {
      wuxing_cxt.beginPath();

      for (let j = 0; j < this.trend_data.length - 1; j++) {
        let win_num_1 = Number(this.trend_data[j].lottery[i]);
        let win_num_2 = Number(this.trend_data[j + 1].lottery[i]);

        let cell_w = 16;
        let cell_h = 30;

        let x1: number = (cell_w * 11 * i) + (Number(win_num_1) - 1) * cell_w + cell_w / 2;
        let y1: number = (j * cell_h) + cell_h / 2;

        let x2: number = (cell_w * 11 * i) + (Number(win_num_2) - 1) * cell_w + cell_w / 2;
        let y2: number = ((j + 1) * cell_h) + cell_h / 2;

        wuxing_cxt.lineWidth = 1;
        wuxing_cxt.strokeStyle = '#FF5656';

        wuxing_cxt.moveTo(x1, y1);
        wuxing_cxt.lineTo(x2, y2);
        wuxing_cxt.stroke();
      }

      wuxing_cxt.closePath();
    }
  }

  /**
   * 切换显示期数
   *
   * @param {*} e
   * @memberof TrendTableX115Component
   */
  public change_issue_handler(e: any) {
    this.change_issue_num_emit.emit(Number(e));
  }

}
