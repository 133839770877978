import { Component, OnInit, Input } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-zongfen',
  templateUrl: './lhc-zongfen.component.html',
  styleUrls: ['./lhc-zongfen.component.css']
})
export class LhcZongfenComponent extends GameCreditBase implements OnInit {

  // balls
  public balls_array: Array<any> = [
    { id: 1, name: '大', desc: '总分大于等于175的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 2, name: '小', desc: '总分小于等于174的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 3, name: '单', desc: '总分是单数的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 4, name: '双', desc: '总分是双数的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 5, name: '大单', desc: '总分大于等于175且为单数的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 6, name: '大双', desc: '总分大于等于175且为双数的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 7, name: '小单', desc: '总分小于等于174且为单数的，即视为中奖。', is_select: false, p: 0, val: null },
    { id: 8, name: '小双', desc: '总分小于等于174且为双数的，即视为中奖。', is_select: false, p: 0, val: null },
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcZongfenComponent
   */
  public reset_balls_panel_handler() {
    this.balls_array.forEach(item => {
      item.is_select = false;
      item.val = null;
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcZongfenComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability['probability'];

    this.balls_array.forEach((item, index) => {
      let prize = (this.prize_group / 2000) * (1 / p_arr[index]);

      item['p'] = prize;
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcZongfenComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @memberof LhcZongfenComponent
   */
  public selecte_ball_handler(item: any) {
    if (!item.is_select) {
      item.is_select = true;
      item.val = this.bet_value;

      // balls内容整理
      this.update_bet_balls_info();
    }
  }

  /**
   * 失去焦点
   *
   * @param {*} item
   * @memberof LhcZongfenComponent
   */
  public change_val_handler(item: any) {
    if (item.is_select && !item.val) {
      item.val = this.bet_value;
    }

    let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
    item.val = item.val > max_m ? max_m : item.val;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcZongfenComponent
   */
  public render_bet_value_handler() {
    this.balls_array.forEach(item => {
      if (item.is_select) {
        item.val = this.bet_value;
      }
    });

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcZongfenComponent
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.is_selected_info = this.balls_array.filter(item => (item.is_select && item.val !== null));

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      this.is_selected_info.forEach(item => {
        let bet_info = new BetInfo();
        bet_info.way_id = Number(this.play_info['id']);
        bet_info.ball = item.id;
        bet_info.num = 1;
        bet_info.prize_group = Number(this.prize_group);

        let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
        item.val = item.val > max_m ? max_m : item.val;
        bet_info.multiple = Number(item.val);

        ball_arr.push(bet_info);
      });

      this.betInfoService.set_bet_info_handler(ball_arr);
      this.betInfoService.set_bet_num_handler(this.is_selected_info.length);
    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
