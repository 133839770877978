import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-public',
  templateUrl: './footer-public.component.html',
  styleUrls: ['./footer-public.component.css']
})
export class FooterPublicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
