import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-x115-trend',
  templateUrl: './x115-trend.component.html',
  styleUrls: ['./x115-trend.component.css']
})
export class X115TrendComponent implements OnInit {

  // 当前游戏ID
  @Input()
  public current_game_play_id: number;

   // Mini走势数据
   @Input()
   public mini_trend_data: Array<any>;
 
  constructor() { }

  ngOnInit() {
  }

}
