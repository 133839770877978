import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-daxiaodanshuang-qianer',
  templateUrl: './ssc-daxiaodanshuang-qianer.component.html',
  styleUrls: ['./ssc-daxiaodanshuang-qianer.component.css']
})
export class SscDaxiaodanshuangQianerComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false],
      [false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball('大'),
        new Ball('小'),
        new Ball('单'),
        new Ball('双')
      ],
      [
        new Ball('大'),
        new Ball('小'),
        new Ball('单'),
        new Ball('双')
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscDaxiaodanshuangQianerComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      for (let j = 0; j < bet_arr[i].length; j++) {
        let val = bet_arr[i][j];
        for (let k = 0; k < this.lottery_data[i].length; k++) {
          if (val === this.lottery_data[i][k].get_ball_num()) {
            this.btn_status_array[i][k] = true;
          }
        }
      }
    }

    this.update_bet_infor_handler();
  }

}
