import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-longhu-qiange',
  templateUrl: './ssc-longhu-qiange.component.html',
  styleUrls: ['./ssc-longhu-qiange.component.css']
})
export class SscLonghuQiangeComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball('龙'),
        new Ball('虎'),
        new Ball('和')
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球信息
   *
   * @memberof SscLonghuQiangeComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
    // 奖金计算
    this.calculation_play_prize_handle();
  }

  /**
   * 复写奖金计算方式
   *
   * @memberof SscLonghuQiangeComponent
   */
  public calculation_play_prize_handle() {
    if (this.prize_probability && this.prize_group && this.money_unit) {
      let probability_arr = this.prize_probability['probability'].sort();
      let prize_str = '';

      let arr = []; // 选中的规则号
      this.btn_status_array[0].forEach((item, index) => {
        if (item) {
          let r = index + '';
          arr.push(r);
        }
      });

      if (arr.length === 0) {
        arr = ['0', '2'];
      }

      let prize_arr = [];
      arr.forEach(item => {
        let p;
        if (item === '0' || item === '1') {
          p = probability_arr[1];
        }

        if (item === '2') {
          p = probability_arr[0];
        }

        let prize = this.prize_formula(p);
        prize_arr.push(prize);
      });

      prize_str = this.unique(prize_arr).sort(this.cmp).join(' - ');
      this.betInfoService.set_prize_info_handler(prize_str);
    }
  }

  /**
   * 计算注单数量
   *
   * @memberof SscLonghuQiangeComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array.length;

    this.bet_num = num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscLonghuQiangeComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      for (let j = 0; j < this.lottery_data[0].length; j++) {
        if (bet_arr[i] === this.lottery_data[0][j].get_ball_num()) {
          this.btn_status_array[0][j] = true;
        }
      }
    }

    this.update_bet_infor_handler();
  }

}
