import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-dipin-dingweidan-dingweidan',
  templateUrl: './dipin-dingweidan-dingweidan.component.html',
  styleUrls: ['./dipin-dingweidan-dingweidan.component.css']
})
export class DipinDingweidanDingweidanComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false]
    ];

    this.fast_option_array = [
      [false, false, false, false, false, false],
      [false, false, false, false, false, false],
      [false, false, false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];

  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   * 
   * @returns {boolean} 
   * @memberof DipinDingweidanDingweidanComponent
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];

    let pos_array = this.balls_str.split('|');
    for (let item of pos_array) {
      let arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    for (let item of this.selected_ball_array) {
      if (item.length !== 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * 计算注单数量
   * 
   * @memberof DipinDingweidanDingweidanComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    for (let item of this.selected_ball_array) {
      num += item.length;
    }

    this.bet_num = num;
  }

}
