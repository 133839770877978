import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trend-table-pk10',
  templateUrl: './trend-table-pk10.component.html',
  styleUrls: ['./trend-table-pk10.component.css']
})
export class TrendTablePk10Component implements OnInit {

  public trend_type: number = 0;
  // 走势tab
  public tabs: Array<any> = ['冠军走势图', '前五走势图', '后五走势图'];
  // 辅助功能
  public auxs: Array<any> = [true, true, false, true, false];
  public aux_array: Array<any> = [
    { val: '0', name: '辅助线', is_select: true },
    { val: '1', name: '遗漏', is_select: true },
    { val: '2', name: '遗漏条', is_select: false },
    { val: '3', name: '走势', is_select: true },
    { val: '4', name: '号温', is_select: false }
  ];
  // 期数
  public issue_num: string = '30';
  public issue_array: Array<any> = ['30', '50', '80', '100'];

  // 走势数据
  @Input()
  public trend_data: Array<any> = [];

  // 统计数据
  @Input()
  public analysis_data: Array<any> = [];

  @Output()
  public change_issue_num_emit: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * 切换走势
   *
   * @param {*} e
   * @memberof TrendTablePk10Component
   */
  public switch_tab_handler(e: any) {
    this.trend_type = e;
  }

  /**
   * 选择辅助功能
   *
   * @param {*} e
   * @memberof TrendTablePk10Component
   */
  public change_aux_handler(e: any) {
    this.auxs = [false, false, false, false, false];
    e.forEach(item => {
      let index = Number(item);
      this.auxs[index] = true;
    });
  }

  /**
   * 切换显示期数
   *
   * @param {*} e
   * @memberof TrendTablePk10Component
   */
  public change_issue_handler(e: any) {
    this.change_issue_num_emit.emit(Number(e));
  }

}
