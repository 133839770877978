import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-data-center-content',
  templateUrl: './data-center-content.component.html',
  styleUrls: ['./data-center-content.component.css']
})
export class DataCenterContentComponent implements OnInit {

  public theme: boolean = true;
  // 当前菜单
  public current_menu: string;
  public current_menu_cn: string;

  constructor(
    private router: Router
  ) {
    this.get_current_menu_by_url(location.pathname);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.get_current_menu_by_url(event['url']);
    });
  }

  /**
   * 通过url获取当前菜单
   * 
   * @param {string} url
   * @memberof DataCenterContentComponent
   */
  public get_current_menu_by_url(url: string) {
    this.current_menu = url.split('/')[3];

    switch (this.current_menu) {
      case 'bet-rd':
        this.current_menu_cn = '投注记录';
        break;
      case 'trace-rd':
        this.current_menu_cn = '追号记录';
        break;
      case 'transaction-rd':
        this.current_menu_cn = '账变记录';
        break;
      case 'transfer-rd':
        this.current_menu_cn = '转账记录';
        break;
      case 'prize':
        this.current_menu_cn = '奖金详情';
        break;
      case 'announcement':
        this.current_menu_cn = '平台公告';
        break;
      default: break;
    }
  }

}
