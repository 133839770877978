import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscSanxingTeshu'
})
export class SscSanxingTeshuPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let lottery_arr = [];
    // 特殊形态
    let form: string;

    switch (args) {
      case 'qiansan':
        value.forEach((element, index) => {
          if (index < 3) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      case 'zhongsan':
        value.forEach((element, index) => {
          if (index > 0 && index < 4) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      case 'housan':
        value.forEach((element, index) => {
          if (index > 1) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      default: break;
    }
    return form;
  }

  private calculation_form_handler(lottery_arr: any): string {
    let str: string;
    let arr = Array.from(new Set(lottery_arr));
    switch (arr.length) {
      case 1:
        str = '豹子';
        break;
      case 2:
        str = '对子';
        break;
      case 3:
        let arr_str = arr.join('');
        let straight_arr = [
          '012', '021', '102', '120', '201', '210',
          '123', '132', '213', '231', '312', '321',
          '234', '243', '324', '342', '423', '432',
          '345', '354', '435', '453', '534', '543',
          '456', '465', '546', '564', '645', '654',
          '567', '576', '657', '675', '756', '765',
          '678', '687', '768', '786', '867', '876',
          '789', '798', '879', '897', '978', '987'
        ];
        str = straight_arr.indexOf(arr_str) !== -1 ? '顺子' : '--';
        break;
      default: break;
    }
    return str;
  }

}
