import {Injectable} from '@angular/core';



@Injectable()
export class Store {
  // 休市图片地址
  public pageCloseImgUrl = '';

  constructor(
  ) {
  }


}
