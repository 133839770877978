import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Utils } from 'app/common/util';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Store } from 'app/providers/store';

@Component({
  selector: 'app-header-private',
  templateUrl: './header-private.component.html',
  styleUrls: ['./header-private.component.css']
})
export class HeaderPrivateComponent implements OnInit {

  // 当前路由
  public current_url: string;
  // 游戏菜单显示
  public game_menu_visible: boolean = false;
  // 游戏菜单
  public ssc_game_list: Array<any> = Utils.game_list[0];
  public x115_game_list: Array<any> = Utils.game_list[1];
  public k3_game_list: Array<any> = Utils.game_list[2];
  public pk10_game_list: Array<any> = Utils.game_list[3];
  public dp_game_list: Array<any> = Utils.game_list[4];
  public lhc_game_list: Array<any> = Utils.game_list[5];
  public pcdd_game_list: Array<any> = Utils.game_list[6];

  // 用户名称
  public username: string;
  // token
  public token: string;
  // 余额
  public balance: number;
  public balance_sub: Subscription;
  // 获取余额中
  public is_spin: boolean = false;

  // 路由监听流
  public router_subscription: any;

  public game_drawer: any = { 'background': '#140e0e', 'box-shadow': '0px 2px 10px #d7c283' };

  @ViewChild('winInfo') winInfo: TemplateRef<{}>;
  public win_list: Array<any> = [];
  public win_interval: any;

  constructor(
    public betInfoService: BetInfoService,
    private router: Router,
    private api: ApiService,
    private message: NzMessageService,
    private notification: NzNotificationService,
    private store: Store,
  ) {
    this.get_current_menu_by_url(location.pathname);
  }

  ngOnInit() {
    this.router_subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.get_current_menu_by_url(event['url']);
    });

    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
        this.ssc_game_list = Utils.game_list[0];
        this.x115_game_list = Utils.game_list[1];
        this.k3_game_list = Utils.game_list[2];
        this.pk10_game_list = Utils.game_list[3];
        this.dp_game_list = Utils.game_list[4];
        this.lhc_game_list = Utils.game_list[5];
        this.pcdd_game_list = Utils.game_list[6];

        Utils.game_type_array = data['game_type'];
      }
    });

    this.balance_sub = this.betInfoService.get_credit_update_balance_handler().subscribe(data => {
      if (data) {
        this.upadate_balance_handler();
      }
    });
  }

  ngOnDestroy() {
    this.router_subscription.unsubscribe();
    this.balance_sub.unsubscribe();

    clearInterval(this.win_interval);
  }

  ngAfterViewInit() {
    this.get_token_info();
  }

  /**
   * 获取token值
   *
   * @memberof HeaderPrivateComponent
   */
  public get_token_info() {
    this.token = localStorage.getItem('token');

    if (this.token) {
      this.upadate_balance_handler();
      this.show_win_info_handler();
    } else {
      setTimeout(() => {
        this.get_token_info();
      }, 200);
    }
  }

  /**
   * 识别路由
   *
   * @param {string} url
   * @memberof HeaderPrivateComponent
   */
  public get_current_menu_by_url(url: string) {
    this.current_url = url.split('/')[1];
  }

  /**
   * 获取用户登录信息
   *
   * @param {*} enter_token
   * @memberof HeaderPrivateComponent
   */
  // public get_user_login_infor_handler(enter_token: any) {
  //   this.api.get_login_info(enter_token).subscribe(data => {

  //     this.is_spin = false;

  //     if (data && data['isSuccess']) {
  //       let info = data['data'];
  //       this.token = info['token'];
  //       this.username = info['username'];
  //       this.balance = Number(info['balance']);

  //       // 储存信息
  //       localStorage.setItem('token', info['token']);

  //       // 开启中奖信息
  //       this.show_win_info_handler();
  //     } else {
  //       this.message.create('error', data['data']['error']);
  //     }
  //   });
  // }

  /**
   * 开启中奖信息
   *
   * @memberof HeaderPrivateComponent
   */
  public show_win_info_handler() {
    this.win_interval = setInterval(() => {
      this.api.get_recently_win_info(this.token).subscribe(data => {
        if (data && data['isSuccess']) {
          this.win_list = [];
          let info = data['data'];

          if (info.length > 0) {
            info.forEach((item: any) => {
              let win = {};
              win['game_id'] = Number(item['game_id']);
              win['issue'] = item['issue'];
              win['prize_value'] = Number(item['prize_value']);

              this.win_list.push(win);
            });

            this.notification.config({
              nzPlacement: 'bottomRight'
            });
            this.notification.template(this.winInfo);
          }
        } else {
          this.message.create('error', data['data']['error']);
        }
      });
    }, 15 * 1000);
  }

  /**
   * 获取用户余额信息
   *
   * @memberof HeaderPrivateComponent
   */
  public upadate_balance_handler() {
    this.is_spin = true;

    this.api.get_balance_info(this.token).subscribe(data => {
      this.is_spin = false;
      if (data && data['isSuccess']) {
        this.balance = Number(data['data']['available']);
        this.username = data['data']['username'];
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
 * 显示游戏菜单
 *
 * @memberof HeaderPrivateComponent
 */
  public show_game_menu_handler() {
    this.game_menu_visible = true;
  }

  /**
   * 隐藏游戏菜单
   *
   * @memberof HeaderPrivateComponent
   */
  public hide_game_menu_handler() {
    this.game_menu_visible = false;
  }

  /**
   * 登出操作
   *
   * @memberof HeaderPrivateComponent
   */
  public logout_handler() {
    this.router.navigate(['/homepage']);
  }

  public goto_game_page_handler(gameInfo: any, name: string) {
    let url;
    this.store.pageCloseImgUrl = gameInfo['close_img'];
    if (gameInfo.is_stop) {
      this.router.navigate(['page-close']);
    } else {
      switch (name) {
        case 'ssc':
          url = `/lottery/ssc/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case '11x5':
          url = `/lottery/11x5/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'k3':
          url = `/lottery/k3/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'pk10':
          url = `/lottery/pk10/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'dp':
          url = `/lottery/dp/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'plw':
          url = `/lottery/plw/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'lhc':
          url = `/lottery/lhc/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        case 'pcdd':
          url = `/lottery/pcdd/${gameInfo.id}`;
          this.router.navigate([url]);
          break;
        default: break;
      }
      // let url = `/lottery/ssc/${gameInfo.id}`;
    }
  }
}
