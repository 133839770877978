import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plw-trend',
  templateUrl: './plw-trend.component.html',
  styleUrls: ['./plw-trend.component.css']
})
export class PlwTrendComponent implements OnInit {

  // 当前游戏ID
  @Input()
  public current_game_play_id: number;

  // Mini走势数据
  @Input()
  public mini_trend_data: Array<any>;
  
  constructor() { }

  ngOnInit() {
  }

}
