import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'traceStatus'
})
export class TraceStatusPipe implements PipeTransform {

  transform(value: any): any {
    let trace_status: string;

    switch (value) {
      case 0: trace_status = '进行中'; break;
      case 1: trace_status = '已完成'; break;
      case 2: trace_status = '用户撤单'; break;
      case 3: trace_status = '管理员撤单'; break;
      case 4: trace_status = '系统撤单'; break;
      default: break;
    }

    return trace_status;
  }

}
