import { Component, OnInit } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lottery-pcdd',
  templateUrl: './lottery-pcdd.component.html',
  styleUrls: ['./lottery-pcdd.component.css']
})
export class LotteryPcddComponent implements OnInit {

  public token: string;
  // 游戏ID
  public game_id: number;
  // 游戏信息
  public game_info: any;
  // mini走势数据
  public mini_trend_info: Array<any> = [];
  // 奖期数组
  public game_issue_arr: Array<any> = [];
  // 游戏名称
  public game_name: string;
  // 游戏奖期
  public game_issue: string;

  // 注单信息
  public bet_info: any;
  public bet_info_sub: Subscription;
  // 注数监听流
  public bet_num: number;
  public bet_num_sub: Subscription;
  // 显示投注详情
  public open_modal: boolean;
  public open_modal_sub: Subscription;

  //  投注信息
  public submit_info: any;
  // 投注锁
  public is_lock: boolean = false;

  constructor(
    public betInfoService: BetInfoService,
    public routeInfo: ActivatedRoute,
    public api: ApiService,
    public message: NzMessageService,
    private modalService: NzModalService
  ) {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      this.token = localStorage.getItem('token');
    }

    this.routeInfo.params.subscribe((params: Params) => {
      this.betInfoService.set_clear_balls_handler('clear');
      this.betInfoService.set_bet_position_info_handler(null);
      this.game_id = Number(params['id']);

      // 获取游戏信息
      this.get_game_info_handler();
    });
  }

  ngOnInit() {
    // 监听注单信息
    this.bet_info_sub = this.betInfoService.get_bet_info_handler().subscribe(data => {
      if (data) {
        this.bet_info = data;
      }
    });

    // 订阅 注数监听
    this.bet_num_sub = this.betInfoService.get_bet_num_handler().subscribe(data => {
      this.bet_num = Number(data);
    });

    // 订阅 投注详情窗口
    this.open_modal_sub = this.betInfoService.get_confirm_bet_info().subscribe(data => {
      if (data) {
        this.open_modal = true;
        this.show_bet_info_handler();
      }
    });
  }

  ngOnDestroy() {
    this.betInfoService.set_cur_game_info_handler(null);
    // 取消 订阅注数
    this.betInfoService.set_bet_num_handler(0);
    this.bet_num_sub.unsubscribe();
    // 取消 注单信息
    this.bet_info_sub.unsubscribe();
    // 取消 窗口监听
    this.betInfoService.set_confirm_bet_info(null);
    this.open_modal_sub.unsubscribe();
    this.betInfoService.set_credit_bet_val_handler('1');
  }  

  /**
   * 获取游戏信息
   *
   * @memberof LotteryPcddComponent
   */
  public get_game_info_handler() {
    this.api.get_game_info(this.game_id).subscribe(data => {
      if (data && data['isSuccess']) {
        this.game_info = data['data'];
        let deadline: number = data['data']['current_prize_time'] - data['data']['current_time'];
        if (deadline < 1) {
          this.get_game_info_handler();
        } else {
          this.betInfoService.set_cur_game_info_handler(this.game_info);

          // 更新奖期数组
          this.game_issue_arr = data['data']['game_issue'];
          // 游戏名称
          this.game_name = data['data']['game_name'];
          // 游戏奖期
          this.game_issue = data['data']['current_issue'];
        }
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 取消投注
   *
   * @memberof LotteryPcddComponent
   */
  public cancel_handle() {
    this.open_modal = false;
    this.submit_info = null;
  }

  /**
   * 确认投注
   *
   * @memberof LotteryPcddComponent
   */
  public submit_bet_handler() {
    if (this.is_lock) {
      return;
    }
    this.is_lock = true;
    this.modalService.info({
      nzTitle: '温馨提示',
      nzContent: '投注中...',
      nzCancelText: null,
      nzOkText: null
    });

    let bet_info = this.create_bet_handler();

    setTimeout(() => {
      if (this.token) {
        this.api.get_submit_bet_info(bet_info, this.token).subscribe(data => {
          setTimeout(() => {
            this.is_lock = false;
            this.modalService.closeAll();
          }, 500);

          if (data && data['isSuccess']) {
            this.betInfoService.set_clear_credit_balls_handler('clear');
            this.betInfoService.set_bet_num_handler(0);
            this.open_modal = false;
            this.submit_info = null;

            this.betInfoService.set_credit_update_balance_handler(true);

            this.message.create('success', '投注成功，祝您中奖！');
          } else {
            this.message.create('error', data['data']['error']);
          }
        });
      }
    });
  }

  /**
   * 显示注单信息
   *
   * @memberof LotteryPcddComponent
   */
  public show_bet_info_handler() {
    if (this.bet_info && this.bet_info.length > 0) {
      let content_arr = [];
      this.submit_info = new SumbitInfo();

      this.bet_info.forEach(item => {
        // 大小
        if (item.way_id === 283) {
          let c = {};
          if (item['ball'] === '1') {
            c['content'] = '大';
          } else {
            c['content'] = '小';
          }

          c['play_id'] = item['way_id'];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          content_arr.push(c);
        }

        // 单双
        if (item.way_id === 284) {
          let c = {};
          if (item['ball'] === '1') {
            c['content'] = '单';
          } else {
            c['content'] = '双';
          }

          c['play_id'] = item['way_id'];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          content_arr.push(c);
        }

        // 两极
        if (item.way_id === 285) {
          let c = {};
          if (item['ball'] === '1') {
            c['content'] = '极大';
          } else {
            c['content'] = '极小';
          }

          c['play_id'] = item['way_id'];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          content_arr.push(c);
        }

        // 串关
        if (item.way_id === 286) {
          let c = {};
          switch (item['ball']) {
            case '11': c['content'] = '大单'; break;
            case '10': c['content'] = '大双'; break;
            case '01': c['content'] = '小单'; break;
            case '00': c['content'] = '小双'; break;
            default: break;
          }

          c['play_id'] = item['way_id'];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          content_arr.push(c);
        }

        // 和值
        if (item.way_id === 282) {
          let c = {};
          c['content'] = `和值·${item['ball']}`;

          c['play_id'] = item['way_id'];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          content_arr.push(c);
        }
      });

      this.submit_info.content = content_arr;

      this.bet_info.forEach(item => {
        this.submit_info.bet_num += item.num;
        this.submit_info.bet_value += item.num * item.multiple;
      });
    }
  }

  /**
   * 创建投注注单
   *
   * @returns
   * @memberof LotteryPcddComponent
   */
  public create_bet_handler() {
    let bet = new Bet();
    bet.game_id = this.game_id;
    bet.balls = Utils.encode_info_handler(JSON.stringify(this.bet_info), 1);
    bet.orders[this.game_issue] = 1;

    this.bet_info.forEach((item: any) => {
      bet.amount += item['num'] * item['multiple'];
    });

    return bet;
  }
}

class Bet {
  // 游戏ID
  public game_id: number;
  // balls信息
  public balls: any;
  // 奖期
  public orders: any = {};
  // 总金额
  public amount: number = 0;
  public is_encoded: number = 1;
  public bet_source: string = 'web';
  public isTrace: number = 0;
  public traceWinStop: number = 1;
  public traceStopValue: number = 1;
  constructor() { }
}

/**
 * 投注信息
 *
 * @class SumbitInfo
 */
class SumbitInfo {
  public content: Array<any> = [];
  public bet_num: number = 0;
  public bet_value: number = 0;

  constructor() {

  }
}
