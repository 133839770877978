import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'k3Ertonghao'
})
export class K3ErtonghaoPipe implements PipeTransform {

  transform(value: any): any {
    let str: string;
    let arr = Array.from(new Set(value));

    if (arr.length === 2) {
      str = '二同号';
    } else {
      str = '--';
    }

    return str;
  }

}
