import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-pk10-credit-mingci',
  templateUrl: './pk10-credit-mingci.component.html',
  styleUrls: ['./pk10-credit-mingci.component.css']
})
export class Pk10CreditMingciComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    // 第一
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第二
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第三
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第四
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第五
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第六
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第七
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第八
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第九
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ],
    // 第十
    [
      { name: '01', p: 0, val: null, is_select: false },
      { name: '02', p: 0, val: null, is_select: false },
      { name: '03', p: 0, val: null, is_select: false },
      { name: '04', p: 0, val: null, is_select: false },
      { name: '05', p: 0, val: null, is_select: false },
      { name: '06', p: 0, val: null, is_select: false },
      { name: '07', p: 0, val: null, is_select: false },
      { name: '08', p: 0, val: null, is_select: false },
      { name: '09', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false }
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof Pk10CreditMingciComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach(group => {
      group.forEach(item => {
        item.is_select = false;
        item.val = null;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof Pk10CreditMingciComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach(group => {
      group.forEach(item => {
        item.p = (this.prize_group / 2000) * (1 / p_arr[0]);
      });
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof Pk10CreditMingciComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} ball
   * @memberof Pk10CreditMingciComponent
   */
  public selecte_ball_handler(ball: any) {
    ball.is_select = true;
    ball.val = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof Pk10CreditMingciComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach(group => {
      group.forEach(item => {
        if (item.is_select) {
          item.val = this.bet_value;
        }
      });
    });
  }

}
