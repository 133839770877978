import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-wuxing-zuxuan-zx120',
  templateUrl: './ssc-wuxing-zuxuan-zx120.component.html',
  styleUrls: ['./ssc-wuxing-zuxuan-zx120.component.css']
})
export class SscWuxingZuxuanZx120Component extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof SscWuxingZuxuanZx120Component
   */
  public is_selected_ball_completed(): boolean {
    let pos_array, item, arr;
    this.selected_ball_array = [];

    pos_array = this.balls_str.split('|');
    for (item of pos_array) {
      arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    for (item of this.selected_ball_array) {
      if (item.length < 5) {
        return false;
      }
    }

    return true;
  }

  /**
   * 计算注单数量
   *
   * @memberof SscWuxingZuxuanZx120Component
   */
  public calculate_bet_number() {
    let item, len;
    let num: number = 1;

    for (item of this.selected_ball_array) {
      len = item.length;
      num = this.fac(len) / (this.fac(5) * this.fac((len - 5)));
    }

    this.bet_num = num;
  }

}
