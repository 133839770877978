import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Utils } from 'app/common/util';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public accept: string = 'application/vnd.zhanguo.v1+json';
  // 域名 （shengtiantest）
  private domain_arr = location.hostname.split('.');
  private detail_arr = this.domain_arr.slice(1, this.domain_arr.length - 1);
  // private domain_name = `https://api.${this.domain_arr[1]}.${this.domain_arr[(this.domain_arr.length - 1)]}`;
  private domain_name = `https://api.${this.detail_arr.join('.')}.${this.domain_arr[(this.domain_arr.length - 1)]}`;
  // private domain_name = `https://api.n1.shengtiantest.com/`;
  // 登录
  private api_url_login_info = `${this.domain_name}/users/token-login`;

  // 获取余额
  private api_url_balance_info = `${this.domain_name}/users/available`;
  // 游戏信息
  private api_url_game_info = `${this.domain_name}/games/info`;
  // 获取开奖号码
  private api_url_winnumber_info = `${this.domain_name}/games/win-number`;
  // 提交注单
  private api_url_submit_bet_info = `${this.domain_name}/games/bet`;
  // 投注记录
  private api_url_gamepage_recode_info = `${this.domain_name}/games/history`;
  // 游戏列表
  private api_url_lottery_info = `${this.domain_name}/games/lotteries`;
  // 中奖信息
  private api_url_win_info = `${this.domain_name}/games/recently-win`;

  // 投注记录 报表
  private api_url_bet_history_recode_info = `${this.domain_name}/users/bet-history`;
  // 获取注单详情
  private api_url_bet_detail_info = `${this.domain_name}/games/bet-detail`;
  // 撤销注单
  private api_url_bet_drop_info = `${this.domain_name}/games/drop`;
  // 追号记录 报表
  private api_url_trace_history_recode_info = `${this.domain_name}/users/trace-history`;
  // 追号详情
  private api_url_trace_detail_info = `${this.domain_name}/games/trace-detail`;
  // 停止追号
  private api_url_stop_trace_info = `${this.domain_name}/games/stop`;
  // 取消单期追号
  private api_url_cancel_single_trace_info = `${this.domain_name}/games/cancel`;
  // 平台公告
  private api_url_platform_announcement_info = `${this.domain_name}/cms/announcement`;
  // 平台公告已读状态
  private api_url_platform_announcement_read_info = `${this.domain_name}/cms/read_announcement`;
  // 走势图
  private api_url_charts_info = `${this.domain_name}/trends`;
  // 获取账变类型
  private api_url_transaction_type_info = `${this.domain_name}/reports/transaction-type`;
  // 用户账变
  private api_url_user_transaction_info = `${this.domain_name}/reports/transaction`;

  constructor(
    public http: HttpClient,
    public modalService: NzModalService
  ) {
    this.get_request_base_config_info().subscribe(data => {
      if (data) {
        Utils.is_public = data['lottery_mode'] === 'public' ? true : false;
        Utils.ssc_max_prize = data['ssc_max_prize'];
        Utils.ssctx_max_prize = data['ssctx_max_prize'];
        Utils.x115_max_prize = data['x115_max_prize'];
        Utils.k3_max_prize = data['k3_max_prize'];
        Utils.pk10_max_prize = data['pk10_max_prize'];
        Utils.dp_max_prize = data['dp_max_prize'];
        Utils.min_prize_group = data['min_bet_prize_group'];
        Utils.max_prize_group = data['max_bet_prize_group'];
      }
    });

    this.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
      }
    });
  }

  /**
   * 获取基础配置信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_base_config_info() {
    return this.http.get(`${this.domain_name}/assets/json/base.json`);
  }

  /**
   * 获取时时彩游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_ssc_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-ssc.json`);
  }

  /**
   * 获取11选5游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_11x5_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-11x5.json`);
  }

  /**
   * 获取快3游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_k3_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-k3.json`);
  }

  /**
   * 获取PK10游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_pk10_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-pk10.json`);
  }

  /**
   * 获取福彩3D游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_f3d_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-f3d.json`);
  }

  /**
   * 获取排列5游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_plw_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-plw.json`);
  }

  /**
   * 获取六合彩游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_lhc_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-lhc.json`);
  }

  /**
   * 获取PC蛋蛋游戏信息
   *
   * @returns
   * @memberof ApiService
   */
  get_request_pcdd_game_config() {
    return this.http.get(`${this.domain_name}/assets/json/play-pcdd.json`);
  }

  /**
   * 获取游戏列表信息
   *
   * @memberof ApiService
   */
  get_request_game_list_info() {
    return this.http.get(`${this.domain_name}/basic/gameList`);
  }

  /**
   * 获取省份城市信息
   *
   * @memberof ApiService
   */
  get_request_city_list_info() {
    return this.http.get(`${this.domain_name}/assets/json/cityList.json`);
  }

  /**
   * 登录操作
   *
   * @param {string} login_token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_login_info(login_token: string): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept });
    return this.postUrlReturn(this.api_url_login_info, { 'token': login_token }, { headers: headers });
  }

  /**
   * 获取余额
   *
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_balance_info(token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_balance_info, { headers: headers });
  }

  /**
   * 游戏列表
   *
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_game_lottery_list_info(): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept });
    return this.getUrlReturn(this.api_url_lottery_info, { headers: headers });
  }

  /**
   * 获取游戏信息
   *
   * @param {number} game_id
   * @param {number} [count=10]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_game_info(game_id: number, count: number = 10): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept });
    return this.postUrlReturn(this.api_url_game_info, { 'game_id': game_id, 'count': count }, { headers: headers });
  }

  /**
   * 获取开奖号码
   *
   * @param {number} game_id
   * @param {string} issue
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_winnumber_info(game_id: number, issue: string): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept });
    let url = `${this.api_url_winnumber_info}?game_id=${game_id}&issue=${issue}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取提交注单信息
   *
   * @param {*} bet
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_submit_bet_info(bet: any, token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let body = bet;
    return this.postUrlReturn(this.api_url_submit_bet_info, body, { headers: headers });
  }

  /**
   * 获取中奖信息
   *
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_recently_win_info(token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_win_info}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取游戏页面中的投注记录
   *
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_gamepage_recode_info(token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_gamepage_recode_info}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取投注记录
   *
   * @param {number} page
   * @param {string} token
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @param {string} [game_id]
   * @param {string} [way_id]
   * @param {string} [bet_code]
   * @param {string} [issue]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_bet_history_recode_info(page: number, token: string, start_time?: string, end_time?: string, user_name?: string, game_id?: string, way_id?: string, bet_code?: string, issue?: string, page_size: number = 10): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });

    let start_time_str: string = '';
    if (start_time !== undefined) {
      start_time_str = `&start_time=${start_time}`;
    }

    let end_time_str: string = '';
    if (end_time !== undefined) {
      end_time_str = `&end_time=${end_time}`;
    }

    let user_name_str: string = '';
    if (user_name !== undefined) {
      user_name_str = `&user_name=${user_name}`;
    }

    let game_id_str: string = '';
    if (game_id !== undefined) {
      game_id_str = `&game_id=${game_id}`;
    }

    let way_id_str: string = '';
    if (way_id !== undefined) {
      way_id_str = `&play_name=${way_id}`;
    }

    let bet_code_str: string = '';
    if (bet_code !== undefined && bet_code !== '') {
      bet_code_str = `&bet_code=${bet_code}`;
    }

    let issue_str: string = '';
    if (issue !== undefined && issue !== '') {
      issue_str = `&issue=${issue}`;
    }

    let page_size_str: string = `&page_size=${page_size}`;

    let url = `${this.api_url_bet_history_recode_info}?page=${page}${start_time_str}${end_time_str}${user_name_str}${game_id_str}${way_id_str}${bet_code_str}${issue_str}${page_size_str}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取注单详情
   *
   * @param {number} bet_id
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_bet_detail_info_by_id(bet_id: number, token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_bet_detail_info}?bet_id=${bet_id}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 撤销注单详情
   *
   * @param {number} bet_id
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  drop_bet_info_by_id(bet_id: number, token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_bet_drop_info}?bet_id=${bet_id}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取追号记录
   *
   * @param {number} page
   * @param {string} token
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @param {string} [game_id]
   * @param {string} [way_id]
   * @param {string} [bet_code]
   * @param {string} [issue]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_trace_history_recode_info(page: number, token: string, start_time?: string, end_time?: string, game_id?: string, way_id?: string, bet_code?: string, issue?: string, user_name?: string, page_size: number = 10): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });

    let start_time_str: string = '';
    if (start_time !== undefined) {
      start_time_str = `&start_time=${start_time}`;
    }

    let end_time_str: string = '';
    if (end_time !== undefined) {
      end_time_str = `&end_time=${end_time}`;
    }

    let game_id_str: string = '';
    if (game_id !== undefined) {
      game_id_str = `&game_id=${game_id}`;
    }

    let way_id_str: string = '';
    if (way_id !== undefined) {
      way_id_str = `&play_name=${way_id}`;
    }

    let bet_code_str: string = '';
    if (bet_code !== undefined && bet_code !== '') {
      bet_code_str = `&bet_code=${bet_code}`;
    }

    let issue_str: string = '';
    if (issue !== undefined && issue !== '') {
      issue_str = `&issue=${issue}`;
    }
    let user_name_str: String = '';
    if (user_name !== undefined && user_name !== '') {
      user_name_str = `&username=${user_name}`;
    }

    let page_size_str: string = `&page_size=${page_size}`;

    let url = `${this.api_url_trace_history_recode_info}?page=${page}${start_time_str}${end_time_str}${game_id_str}${way_id_str}${bet_code_str}${issue_str}${user_name_str}${page_size_str}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取追号注单详情
   *
   * @param {number} trace_id
   * @param {string} token
   * @param {number} [page]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_trace_detail_info_by_id(trace_id: number, token: string, page?: number, page_size: number = 5): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;

    let page_str = '';
    if (page) {
      page_str = `?page=${page}`;
    }

    let page_size_str: string = `&page_size=${page_size}`;

    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_trace_detail_info}/${trace_id}${page_str}${page_size_str}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 终止追号
   *
   * @param {*} info
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  stop_trace_by_id(info: any, token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let body = info;
    return this.postUrlReturn(this.api_url_stop_trace_info, body, { headers: headers });
  }

  /**
   * 取消单期追号
   *
   * @param {*} info
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  cancel_single_trace_by_id(info: any, token: string): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let body = info;
    return this.postUrlReturn(this.api_url_cancel_single_trace_info, body, { headers: headers });
  }

  /**
   * 平台公告
   *
   * @param {number} trace_id
   * @param {string} token
   * @param {number} [page]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_platform_announcement_info(token: string, page?: number): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let url = `${this.api_url_platform_announcement_info}?page=${page}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 公告已读状态
   *
   * @param {number} trace_id
   * @param {string} token
   * @param {number} [page]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_platform_announcement_read_info(token: string, id?: number): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });
    let id_str = '';
    if (id !== undefined) {
      id_str = `?id=${id}`;
    }
    let url = `${this.api_url_platform_announcement_read_info}${id_str}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 获取走势图数据
   *
   * @param {number} lottery_id
   * @param {number} count
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_charts_info(lottery_id: number, count: number): Observable<string[]> {
    let body = {
      'lottery_id': lottery_id,
      'count': count
    };
    let headers = new HttpHeaders({ 'Accept': this.accept });
    let url = `${this.api_url_charts_info}`;
    return this.postUrlReturn(url, body, { headers: headers });
  }

  /**
   * 获取账变类型
   *
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_transaction_type_info(): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept });
    let url = `${this.api_url_transaction_type_info}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * 用户账变
   *
   * @param {string} token
   * @param {number} page
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_user_transaction_info(page: number, token: string, start_time?: string, end_time?: string, user_name?: string, transaction_type?: string, game_id?: string, way_id?: string, bet_code?: string, issue?: string, page_size: number = 10): Observable<string[]> {
    let token_str: string = `Bearer ${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept, 'Authorization': token_str });

    let start_time_str: string = '';
    if (start_time !== undefined) {
      start_time_str = `&start_time=${start_time}`;
    }

    let end_time_str: string = '';
    if (end_time !== undefined) {
      end_time_str = `&end_time=${end_time}`;
    }

    let user_name_str: string = '';
    if (user_name !== undefined) {
      user_name_str = `&username=${user_name}`;
    }

    let transaction_type_str: string = '';
    if (transaction_type !== undefined) {
      transaction_type_str = `&transaction_type=${transaction_type}`;
    }

    let game_id_str: string = '';
    if (game_id !== undefined) {
      game_id_str = `&game_id=${game_id}`;
    }

    let way_id_str: string = '';
    if (way_id !== undefined) {
      way_id_str = `&play_name=${way_id}`;
    }

    let bet_code_str: string = '';
    if (bet_code !== undefined && bet_code !== '') {
      bet_code_str = `&bet_code=${bet_code}`;
    }

    let issue_str: string = '';
    if (issue !== undefined && issue !== '') {
      issue_str = `&issue=${issue}`;
    }

    let page_size_str: string = `&page_size=${page_size}`;

    let url = `${this.api_url_user_transaction_info}?page=${page}${start_time_str}${end_time_str}${user_name_str}${transaction_type_str}${game_id_str}${way_id_str}${bet_code_str}${issue_str}${page_size_str}`;
    return this.getUrlReturn(url, { headers: headers });
  }

  /**
   * http-post请求，获取数据
   *
   * @private
   * @param {string} url
   * @param {Object} [data]
   * @param {Object} [options]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  private postUrlReturn(url: string, data?: Object, options?: Object): Observable<string[]> {
    return this.http.post<string[]>(url, data, options).pipe(
      map(res => res),
      tap(() => { }),
      catchError(this.handleError('error', []))
    );
  }

  /**
   * http-get请求，获取数据
   *
   * @private
   * @param {string} url
   * @param {Object} [options]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  private getUrlReturn(url: string, options?: Object): Observable<string[]> {
    return this.http.get<string[]>(url, options).pipe(
      map(res => res),
      tap(() => { }),
      catchError(this.handleError('error', []))
    );
  }

  /**
   * 错误信息处理
   *
   * @private
   * @template T
   * @param {string} [operation='operation']
   * @param {T} [result]
   * @returns
   * @memberof ApiService
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.show_tips(error);

      return of(result as T);
    };
  }

  /**
   * 提示
   *
   * @private
   * @param {*} error
   * @memberof ApiService
   */
  private show_tips(error: any) {
    let error_code: number = Number(error['status']);

    switch (error_code) {
      case 401:
        this.modalService.info({
          nzTitle: '温馨提示',
          nzContent: '登录超时，请您重新登录！',
          nzCancelText: null,
        });
        break;
      case 429:
        this.modalService.info({
          nzTitle: '温馨提示',
          nzContent: '请勿频繁请求，过一会再来吧！！',
          nzCancelText: null,
        });
        break;
      case 500:
        this.modalService.info({
          nzTitle: '温馨提示',
          nzContent: '网络异常！',
          nzCancelText: null,
        });
        break;
      default:
        this.modalService.info({
          nzTitle: '温馨提示',
          nzContent: '网络异常，请稍后重试！',
          nzCancelText: null,
        });
        break;
    }
  }

}
