import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscSixingZuxuan'
})
export class SscSixingZuxuanPipe implements PipeTransform {

  transform(value: any): any {
    let lottery_arr = [];
    value.forEach((element, index) => {
      if (index > 0) {
        lottery_arr.push(element);
      }
    });
    // 组选形态
    let form: string;
    let max_repeat = 0;
    let arr = Array.from(new Set(lottery_arr));

    switch (arr.length) {
      case 4:
        form = '24';
        break;
      case 3:
        form = '12';
        break;
      case 2:
        for (let i = 0; i < arr.length; i++) {
          let n = 0;
          value.forEach(element => {
            if (element === arr[i]) {
              n += 1;
              if (max_repeat < n) {
                max_repeat = n;
              }
            }
          });
        }

        if (max_repeat === 2) {
          form = '6';
        }

        if (max_repeat === 3) {
          form = '4';
        }
        break;
      default: break;
    }

    return form;
  }

}
