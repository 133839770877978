import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-k3-trend',
  templateUrl: './k3-trend.component.html',
  styleUrls: ['./k3-trend.component.css']
})
export class K3TrendComponent implements OnInit {

  // 当前游戏ID
  @Input()
  public current_game_play_id: number;

  // Mini走势数据
  @Input()
  public mini_trend_data: Array<any>;

  constructor() { }

  ngOnInit() {
  }

}
