import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trend-table-pcdd',
  templateUrl: './trend-table-pcdd.component.html',
  styleUrls: ['./trend-table-pcdd.component.css']
})
export class TrendTablePcddComponent implements OnInit {

  // 期数
  public issue_num: string = '30';
  public issue_array: Array<any> = ['30', '50', '80', '100'];

  // 走势数据
  @Input()
  public trend_data: Array<any> = [];

  @Output()
  public change_issue_num_emit: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * 切换显示期数
   *
   * @param {*} e
   * @memberof TrendTablePcddComponent
   */
  public change_issue_handler(e: any) {
    this.change_issue_num_emit.emit(Number(e));
  }

}
