import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  public is_maintain: boolean = false;

  constructor(
    private api: ApiService,
    private message: NzMessageService,
    private router: Router
  ) { }

  ngOnInit() {
    let login_token = location.search.split('=')[1];
    // let login_token = 'b9484ed7eab2759b3fbf33969219a4O0';
    if (login_token) {
      localStorage.setItem('login_token', login_token);
      this.get_user_login_infor_handler(login_token);
    } else {
      this.message.create('error', '登录超时，请重新登录！');
    }
  }

  /**
   * 获取登录信息
   *
   * @param {*} login_token
   * @memberof LoginPageComponent
   */
  public get_user_login_infor_handler(login_token) {
    this.api.get_login_info(login_token).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        if (info['maintain']) {
          this.is_maintain = true;
        } else {
          this.is_maintain = false;
          // 储存信息
          localStorage.setItem('token', info['token']);
          setTimeout(() => {
            this.router.navigate(['/homepage']);
          }, 3 * 1000);
        }

      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

}
