import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pk10-guanya',
  templateUrl: './pk10-guanya.component.html',
  styleUrls: ['./pk10-guanya.component.css']
})
export class Pk10GuanyaComponent implements OnInit {

  @ViewChild('GuanYaTrendLine') guanya_trend_line_ref: ElementRef;

  @Input()
  public auxs: Array<any>;

  @Input()
  public issue_num: string;

  @Input()
  public trend_data: Array<any>;

  @Input()
  public analysis_data: Array<any> = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.draw_trend_line_handler();
  }

  /**
   * 走势线
   *
   * @memberof Pk10GuanyaComponent
   */
  public draw_trend_line_handler() {
    // 设置canvas高度
    this.guanya_trend_line_ref.nativeElement.width = 400;
    this.guanya_trend_line_ref.nativeElement.height = this.trend_data.length * 30;

    const guanya_cxt: CanvasRenderingContext2D = this.guanya_trend_line_ref.nativeElement.getContext('2d');

    let w: number = 400;
    let h: number = this.trend_data.length * 30;
    guanya_cxt.fillStyle = 'rgba(255, 255, 255, 0)';
    guanya_cxt.clearRect(0, 0, w, Number(this.issue_num) * 30);
    guanya_cxt.fillRect(0, 0, w, h);

    for (let i = 0; i < 2; i++) {
      guanya_cxt.beginPath();

      for (let j = 0; j < this.trend_data.length - 1; j++) {
        let win_num_1 = Number(this.trend_data[j].lottery[i]);
        let win_num_2 = Number(this.trend_data[j + 1].lottery[i]);

        let cell_w = 20;
        let cell_h = 30;

        let x1: number = (cell_w * 10 * i) + (Number(win_num_1) - 1) * cell_w + cell_w / 2;
        let y1: number = (j * cell_h) + cell_h / 2;

        let x2: number = (cell_w * 10 * i) + (Number(win_num_2) - 1) * cell_w + cell_w / 2;
        let y2: number = ((j + 1) * cell_h) + cell_h / 2;

        guanya_cxt.lineWidth = 1;
        guanya_cxt.strokeStyle = '#FF5656';

        guanya_cxt.moveTo(x1, y1);
        guanya_cxt.lineTo(x2, y2);
        guanya_cxt.stroke();
      }

      guanya_cxt.closePath();
    }
  }

}
