import { Component, OnInit } from '@angular/core';
import { Utils } from 'app/common/util';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-lottery-lobby',
  templateUrl: './lottery-lobby.component.html',
  styleUrls: ['./lottery-lobby.component.css']
})
export class LotteryLobbyComponent implements OnInit {

  public all_game_list: Array<any> = [];
  public ssc_game_list: Array<any> = [];
  public x115_game_list: Array<any> = [];
  public k3_game_list: Array<any> = [];
  public pk10_game_list: Array<any> = [];
  public dp_game_list: Array<any> = [];
  public lhc_game_list: Array<any> = [];
  public pcdd_game_list: Array<any> = [];

  public interval_handler: any;

  constructor(
    public api: ApiService,
    public message: NzMessageService
  ) {
    this.init_game_info_handler();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    clearInterval(this.interval_handler);
  }

  /**
   * 更新游戏信息
   *
   * @memberof LotteryLobbyComponent
   */
  public init_game_info_handler() {
    this.api.get_game_lottery_list_info().subscribe(data => {
      if (data && data['isSuccess']) {
        let list_info = data['data'];

        if (this.all_game_list.length !== 0) {
          list_info.forEach((info: any) => {
            info.forEach((item: any) => {
              this.all_game_list.forEach(game => {
                if (game.id === item.id) {
                  game.time = item.time;
                  game.time_str = Utils.seconds_formate_timer2(item.time);
                }
              });
            });
          });
        } else {
          this.all_game_list = [];
          this.ssc_game_list = [];
          this.x115_game_list = [];
          this.k3_game_list = [];
          this.pk10_game_list = [];
          this.dp_game_list = [];
          this.lhc_game_list = [];
          this.pcdd_game_list = [];

          list_info.forEach((info: any, index: number) => {
            info.forEach((item: any) => {
              let game_info = new GameInfo();
              game_info.group_id = index;
              game_info.id = item.id;
              game_info.name = item.name;
              game_info.last_issue = item.last_issue;
              game_info.current_issue = item.next_issue;
              game_info.lottery = item.lottery;
              game_info.time = item.time;
              game_info.time_str = Utils.seconds_formate_timer2(item.time);

              this.all_game_list.push(game_info);

              switch (index) {
                case 0:
                  this.ssc_game_list.push(game_info);
                  break;
                case 1:
                  this.x115_game_list.push(game_info);
                  break;
                case 2:
                  this.k3_game_list.push(game_info);
                  break;
                case 3:
                  this.pk10_game_list.push(game_info);
                  break;
                case 4:
                  this.dp_game_list.push(game_info);
                  break;
                case 5:
                  this.lhc_game_list.push(game_info);
                  break;
                case 6:
                  this.pcdd_game_list.push(game_info);
                  break;
                default: break;
              }
            });
          });
        }

        this.calculate_interval_time_handler(data['data']);
      } else {
        this.message.create('error', data['data']['error']);
      }
    });



  }

  /**
   * 计算间隔时间
   *
   * @param {*} data
   * @memberof LotteryLobbyComponent
   */
  public calculate_interval_time_handler(data: any) {
    let time_arr: any = [];
    let num: any;
    let interval_num: any;

    data.forEach(typaArr => {
      typaArr.forEach(gameInfo => {
        time_arr.push(gameInfo.time);
      });
    });

    num = Array.from(new Set(time_arr)).sort((a: any, b: any) => {
      return a - b;
    })[0];

    interval_num = num < 15 ? num : 15;
    this.interval_handler = setInterval(() => {
      this.all_game_list.forEach(item => {
        item.time -= 1;
        item.time_str = Utils.seconds_formate_timer2(item.time);
      });

      if (interval_num > 1) {
        interval_num -= 1;
      } else {
        clearInterval(this.interval_handler);
        this.init_game_info_handler();
      }
    }, 1000);
  }

}

class GameInfo {
  public group_id: number;
  public id: number;
  public name: string;
  public last_issue: string;
  public current_issue: string;
  public time: number;
  public time_str: string;
  public lottery: Array<any>;
  constructor() {

  }
}

