import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-renxuan-rx4-zuxuan-zx12',
  templateUrl: './ssc-renxuan-rx4-zuxuan-zx12.component.html',
  styleUrls: ['./ssc-renxuan-rx4-zuxuan-zx12.component.css']
})
export class SscRenxuanRx4ZuxuanZx12Component extends GameBase implements OnInit {

  public position_data: Array<any>;

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false],
      [false, false, false, false, false, false]
    ];

    /** 位置选择 */
    this.position_data = [
      { label: '万位', value: '0', checked: false },
      { label: '千位', value: '1', checked: true },
      { label: '百位', value: '2', checked: true },
      { label: '十位', value: '3', checked: true },
      { label: '个位', value: '4', checked: true }
    ];
    this.betInfoService.set_bet_position_info_handler([0, 1, 1, 1, 1]);

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
    ];
  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof SscRenxuanRx4ZuxuanZx12Component
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];

    let pos_array = this.balls_str.split('|');
    for (let item of pos_array) {
      let arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    /* 判断规则：单号不包含二重号，单号数组长度最少为2位，否则至少为3位 */
    if (this.selected_ball_array[0].length >= 1 && this.selected_ball_array[1].length >= 2) {
      for (let i = 0; i < this.selected_ball_array[0].length; i++) {
        if (this.selected_ball_array[1].indexOf(this.selected_ball_array[0][i]) !== -1) {// 单号中包含二重号
          if (this.selected_ball_array[1].length >= 3) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public calculate_bet_number() {
    let num: number = 0;
    let arr: Array<any> = [];

    for (let i = 0; i < this.selected_ball_array[0].length; i++) {
      // 初始化arr数组，使其与单号数据一致
      arr = this.reset_danhao_data();

      let index = this.selected_ball_array[1].indexOf(this.selected_ball_array[0][i]);
      if (index !== -1) {// 单号中包含二重号
        arr.splice(index, 1);
      }

      let len = arr.length;
      let n = 0;
      // 计算可形成注单的数量
      if (len >= 2) {
        n = this.fac(len) / (this.fac(2) * this.fac((len - 2)));
      }

      num += n;
    }

    let position_num = this.position_data.filter(item => item.checked).length;
    let comb_num = this.fac(position_num) / (this.fac(4) * this.fac((position_num - 4)));

    this.bet_num = num * comb_num;
  }

  /**
   * 恢复单号数组数据
   *
   * @private
   * @returns {Array<any>}
   * @memberof SscRenxuanRx4ZuxuanZx12Component
   */
  private reset_danhao_data(): Array<any> {
    let arr: Array<any> = [];
    this.selected_ball_array[1].forEach(val => {
      arr.push(val);
    });
    return arr;
  }

  /**
   * 位置选择
   *
   * @param {*} val
   * @memberof SscRenxuanRx4ZuxuanZx12Component
   */
  public update_position_handler(val: any) {
    let arr = this.position_data.filter(item => item.checked);

    if (arr.length < 4) {
      let index = Number(val);
      setTimeout(() => {
        this.position_data[index].checked = true;
        this.update_bet_infor_handler();
      });
    } else {
      let p_arr = [0, 0, 0, 0, 0];
      this.position_data.forEach((item, index) => {
        if (item.checked) {
          p_arr[index] = 1;
        }
      });
      this.betInfoService.set_bet_position_info_handler(p_arr);

      this.update_bet_infor_handler();
    }
  }

  /**
   * 反选-位置选择
   *
   * @param {*} data
   * @memberof SscRenxuanRx4ZuxuanZx12Component
   */
  public selected_position_render_handler(data: any) {
    data.forEach((item, index) => {
      if (item === 0) {
        this.position_data[index].checked = false;
      }
      if (item === 1) {
        this.position_data[index].checked = true;
      }
    });
  }

}
