import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pk10-trend',
  templateUrl: './pk10-trend.component.html',
  styleUrls: ['./pk10-trend.component.css']
})
export class Pk10TrendComponent implements OnInit {

  // 当前游戏ID
  @Input()
  public current_game_play_id: number;

  // Mini走势数据
  @Input()
  public mini_trend_data: Array<any>;

  constructor() { }

  ngOnInit() {
  }

}
