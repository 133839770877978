import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek } from 'date-fns';
import { Utils } from 'app/common/util';
import { GameConfig } from 'app/common/gameConfig';
import { ApiService } from 'app/providers/api/api.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-personal-transaction-record',
  templateUrl: './personal-transaction-record.component.html',
  styleUrls: ['./personal-transaction-record.component.css']
})
export class PersonalTransactionRecordComponent implements OnInit {

  // token
  public token: string;

  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  // 编号类型
  public code_type: string = 'transaction';
  public transaction_code: string;
  public bet_code: string;
  public trace_code: string;
  public lottery_code: string;
  public issue_code: string;
  // 用户名
  public user_name: string;
  // 账变名称
  public transaction_type_data: Array<any> = [];
  public transaction_type: string = 'all';
  // 游戏名称
  public game_name_data: Array<any> = [];
  public cur_game_name_val: string = 'all';
  // 玩法类型
  public play_type_array: Array<any> = [];
  public play_type_data: Array<any> = [];
  public cur_play_type_val: string = 'all';
  // 玩法名称
  public play_name_data: Array<any> = [];
  public cur_play_name_val: string = 'all';

  // 数据源
  public data_source_detail: Array<any> = [];
  public is_loading: boolean = false;

  // 数据数量
  public total_num: number;
  // 单页容量
  public page_size: number = 10;
  // 当前页码
  public curr_page: number = 1;

  public is_show: boolean = false;

  constructor(
    private api: ApiService,
    private modalService: NzModalService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.search_handler();
    }
  }

  ngOnInit() {
    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
        Utils.game_type_array = data['game_type'];
        // 初始化游戏名称
        this.init_game_name_data_handler();
      }
    });

    this.api.get_request_ssc_game_config().subscribe(data => {
      if (data) {
        GameConfig.ssc_game_config = data['ssc_game_config'];
        GameConfig.tx_game_config = data['tx_game_config'];
      }
    });

    this.api.get_request_11x5_game_config().subscribe(data => {
      if (data) {
        GameConfig.x115_game_config = data['x115_game_config'];
      }
    });

    this.api.get_request_k3_game_config().subscribe(data => {
      if (data) {
        GameConfig.k3_game_config = data['k3_game_config'];
      }
    });

    this.api.get_request_pk10_game_config().subscribe(data => {
      if (data) {
        GameConfig.pk10_game_config = data['pk10_game_config'];
      }
    });

    this.api.get_request_f3d_game_config().subscribe(data => {
      if (data) {
        GameConfig.dipin_game_config = data['f3d_game_config'];
      }
    });

    this.api.get_request_plw_game_config().subscribe(data => {
      if (data) {
        GameConfig.plw_game_config = data['plw_game_config'];
      }
    });

    this.api.get_request_lhc_game_config().subscribe(data => {
      if (data) {
        GameConfig.lhc_game_config = data['lhc_game_config'];
      }
    });
    this.api.get_transaction_type_info().subscribe(data => {
      if (data && data['isSuccess']) {
        this.transaction_type_data = data['data'];
      }
    });

  }

  /**
   * 初始化游戏名称
   *
   * @memberof PersonalTransactionRecordComponent
   */
  public init_game_name_data_handler() {
    Utils.game_list.forEach((item, index) => {
      let obj = {};
      switch (index) {
        case 0:
          obj['label'] = '时时彩';
          break;
        case 1:
          obj['label'] = '11选5';
          break;
        case 2:
          obj['label'] = '快3';
          break;
        case 3:
          obj['label'] = 'PK10';
          break;
        case 4:
          obj['label'] = '低频';
          break;
        case 5:
          obj['label'] = '六合彩';
          break;
        case 6:
          obj['label'] = 'PC蛋蛋';
          break;
        default: break;

      }
      obj['children'] = [];
      item.forEach(element => {
        let g = {};
        g['value'] = element['id'].toString();
        g['label'] = element['name'];
        obj['children'].push(g);
      });
      this.game_name_data.push(obj);
    });
  }

  /**
   * 切换游戏名称
   *
   * @param {*} e
   * @memberof PersonalTransactionRecordComponent
   */
  public change_game_name_handler(e: any) {
    this.cur_play_type_val = 'all';
    this.play_type_data = [];
    this.cur_play_name_val = 'all';
    this.play_name_data = [];
    let type;

    for (let i = 0; i < Utils.game_type_array.length; i++) {
      let id = Number(e);
      let index = Utils.game_type_array[i].indexOf(id);
      if (index !== -1) {
        type = i;
        this.update_play_type_data_handler(id, type);
        break;
      }
    }
  }

  /**
   * 更新玩法类型数据
   *
   * @param {number} id
   * @param {number} type
   * @memberof PersonalTransactionRecordComponent
   */
  public update_play_type_data_handler(id: number, type: number) {
    this.play_type_array = [];
    this.play_type_data = [];

    let arry;
    switch (type) {
      case 0:
        if (id === 64 || id === 100 || id === 101) {
          arry = GameConfig.tx_game_config;
        } else {
          arry = GameConfig.ssc_game_config;
        }
        break;
      case 1: arry = GameConfig.x115_game_config; break;
      case 2: arry = GameConfig.k3_game_config; break;
      case 3: arry = GameConfig.pk10_game_config; break;
      case 4: arry = GameConfig.dipin_game_config; break;
      case 5: arry = GameConfig.plw_game_config; break;
      default: arry = []; break;
    }

    arry.forEach(item => {
      this.play_type_array.push(item);

      let obj = {};
      obj['value'] = item['id'];
      obj['label'] = item['name_cn'];
      this.play_type_data.push(obj);
    });
  }

  /**
   * 切换玩法类型
   *
   * @param {*} e
   * @memberof PersonalTransactionRecordComponent
   */
  public change_play_type_handler(e: any) {
    this.cur_play_name_val = 'all';
    this.play_name_data = [];

    this.play_type_array.forEach(item => {
      let id = Number(e);
      if (item['id'] === id) {
        item['children'].forEach(element => {
          element['children'].forEach(play => {
            let p = {};
            p['value'] = play['id'].toString();
            p['label'] = play['name_cn'];
            this.play_name_data.push(p);
          });
        });
      }
    });
  }

  /**
   * 点击搜索
   *
   * @memberof PersonalTransactionRecordComponent
   */
  public search_handler() {
    this.is_loading = true;
    let start_time = Utils.formate_time(new Date(this.date_range[0]), 'yyyy-MM-dd hh:mm:ss');
    let end_time = Utils.formate_time(new Date(this.date_range[1]), 'yyyy-MM-dd hh:mm:ss');

    if (this.code_type === 'transaction') {
      this.issue_code = '';
    } else {
      this.bet_code = '';
    }

    this.api.get_user_transaction_info(
      this.curr_page,
      this.token,
      start_time,
      end_time,
      this.user_name,
      this.transaction_type === 'all' ? '0' : this.transaction_type,
      this.cur_game_name_val === 'all' ? '0' : this.cur_game_name_val,
      this.cur_play_name_val === 'all' ? '0' : this.cur_play_name_val,
      this.bet_code,
      this.issue_code
    ).subscribe(data => {
      this.is_loading = false;
      if (data && data['isSuccess']) {
        if (data['data']['total']) {
          this.total_num = data['data']['total'];
        }
        // 详情记录
        this.data_source_detail = [];
        data['data']['data'].forEach(item => {
          let detail_record = new DetailRecord();
          detail_record.transaction_num = item['code'];
          detail_record.transaction_time = item['time'];
          this.transaction_type_data.forEach((element) => {
            if (item['`transaction_type`'] === element['id']) {
              detail_record.transaction_type = element['name'];
            }
          });
          detail_record.game_name = item['game_name'];
          detail_record.play_id = item['play_name'];
          detail_record.change_val = Number(item['transaction_value']);
          detail_record.cur_val = Number(item['available_balance']);
          this.data_source_detail.push(detail_record);
        });
        if (this.data_source_detail.length > 0) {
          this.is_show = true;
        }
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

  /**
   * 根据页面获取信息
   *
   * @param {*} e
   * @memberof PersonalBetRecordComponent
   */
  public get_info_by_page(e: any) {
    this.curr_page = Number(e);
    this.search_handler();
  }

}

class DetailRecord {
  // 账变编号
  public transaction_num: string;
  // 账变时间
  public transaction_time: string;
  // 账变类型
  public transaction_type: string;
  // 游戏名称
  public game_name: number;
  // 玩法名称
  public play_id: string;
  // 变动金额
  public change_val: number;
  // 当前余额
  public cur_val: number;
  constructor() { }
}
