import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from 'app/providers/api/api.service';
import { Utils } from 'app/common/util';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-trend-page',
  templateUrl: './trend-page.component.html',
  styleUrls: ['./trend-page.component.css']
})
export class TrendPageComponent implements OnInit {

  // 游戏类别
  public game_type: number;
  // 游戏菜单
  public ssc_game_list: Array<any>;
  public x115_game_list: Array<any>;
  public k3_game_list: Array<any>;
  public pk10_game_list: Array<any>;
  public dp_game_list: Array<any>;
  public lhc_game_list: Array<any>;
  public pcdd_game_list: Array<any>;

  public game_index: number;
  // 游戏id
  public game_id: number;

  public analysis_data: Array<any> = [];
  public trend_data: Array<any> = [];

  constructor(
    public routeInfo: ActivatedRoute,
    public router: Router,
    public api: ApiService,
    public message: NzMessageService
  ) { }

  ngOnInit() {
    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
        this.ssc_game_list = Utils.game_list[0];
        this.x115_game_list = Utils.game_list[1];
        this.k3_game_list = Utils.game_list[2];
        this.pk10_game_list = Utils.game_list[3];
        this.dp_game_list = Utils.game_list[4];
        this.lhc_game_list = Utils.game_list[5];
        this.pcdd_game_list = Utils.game_list[6];

        Utils.game_type_array = data['game_type'];

        this.routeInfo.paramMap.subscribe((params: ParamMap) => {
          this.game_id = Number(params.get('id'));
          this.get_trend_data_handler();
        });

        for (let i = 0; i < Utils.game_type_array.length; i++) {
          let index = Utils.game_type_array[i].indexOf(this.game_id);
          if (index !== -1) {
            this.game_type = i;

            switch (this.game_type) {
              case 0:
                this.get_game_index_info_handler(this.ssc_game_list);
                break;
              case 1:
                this.get_game_index_info_handler(this.x115_game_list);
                break;
              case 2:
                this.get_game_index_info_handler(this.k3_game_list);
                break;
              case 3:
                this.get_game_index_info_handler(this.pk10_game_list);
                break;
              case 4:
                this.get_game_index_info_handler(this.dp_game_list);
                break;
              case 5:
                this.get_game_index_info_handler(this.lhc_game_list);
                break;
              case 6:
                this.get_game_index_info_handler(this.pcdd_game_list);
                break;
              default: break;
            }

            break;
          }
        }
      }
    });
  }

  /**
   * 获取走势数据
   *
   * @memberof TrendPageComponent
   */
  public get_trend_data_handler(num: number = 30) {
    this.trend_data = [];
    this.analysis_data = [];

    this.api.get_charts_info(this.game_id, num).subscribe((data) => {
      if (data && data['isSuccess']) {
        this.analysis_data = data['data']['analysis_data'];
        this.trend_data = data['data']['trend_data'];
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 更新数据
   *
   * @param {*} e
   * @memberof TrendPageComponent
   */
  public update_trend_data_handler(e: any) {
    this.get_trend_data_handler(e);
  }

  /**
   * 获取下标
   *
   * @param {*} arr
   * @memberof TrendPageComponent
   */
  public get_game_index_info_handler(arr: any) {
    if (!arr || arr.length === 0) {
      this.game_index = 0;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === this.game_id) {
        this.game_index = i;
        break;
      }
    }
  }

  /**
   * 切换标签
   *
   * @param {*} e
   * @memberof TrendPageComponent
   */
  public switch_tab_handler(e: any) {
    this.game_type = e;
    this.game_index = 0;

    switch (this.game_type) {
      case 0:
        this.get_game_id_info_handler(this.ssc_game_list);
        break;
      case 1:
        this.get_game_id_info_handler(this.x115_game_list);
        break;
      case 2:
        this.get_game_id_info_handler(this.k3_game_list);
        break;
      case 3:
        this.get_game_id_info_handler(this.pk10_game_list);
        break;
      case 4:
        this.get_game_id_info_handler(this.dp_game_list);
        break;
      case 5:
        this.get_game_id_info_handler(this.lhc_game_list);
        break;
      case 6:
        this.get_game_id_info_handler(this.pcdd_game_list);
        break;
      default: break;
    }
  }

  /**
   * 切换游戏
   *
   * @param {*} e
   * @memberof TrendPageComponent
   */
  public switch_game_trend_handler(e: any) {
    this.game_index = e;

    switch (this.game_type) {
      case 0:
        this.get_game_id_info_handler(this.ssc_game_list);
        break;
      case 1:
        this.get_game_id_info_handler(this.x115_game_list);
        break;
      case 2:
        this.get_game_id_info_handler(this.k3_game_list);
        break;
      case 3:
        this.get_game_id_info_handler(this.pk10_game_list);
        break;
      case 4:
        this.get_game_id_info_handler(this.dp_game_list);
        break;
      case 5:
        this.get_game_id_info_handler(this.lhc_game_list);
        break;
      case 6:
        this.get_game_id_info_handler(this.pcdd_game_list);
        break;
      default: break;
    }
  }

  /**
   * 获取游戏ID
   *
   * @param {*} arr
   * @memberof TrendPageComponent
   */
  public get_game_id_info_handler(arr: any) {
    this.game_id = arr[this.game_index].id;
    this.router.navigate(['/trend', { 'id': this.game_id }]);
  }

  /**
   * 前往投注页面
   *
   * @memberof TrendPageComponent
   */
  public goto_bet_page_handler() {
    switch (this.game_type) {
      case 0:
        this.router.navigate([`/lottery/ssc/${this.game_id}`]);
        break;
      case 1:
        this.router.navigate([`/lottery/11x5/${this.game_id}`]);
        break;
      case 2:
        this.router.navigate([`/lottery/k3/${this.game_id}`]);
        break;
      case 3:
        this.router.navigate([`/lottery/pk10/${this.game_id}`]);
        break;
      case 4:
        if (this.game_id !== 14) {
          this.router.navigate([`/lottery/dp/${this.game_id}`]);
        } else {
          this.router.navigate([`/lottery/plw/${this.game_id}`]);
        }
        break;
      case 5:
        this.router.navigate([`/lottery/lhc/${this.game_id}`]);
        break;
      case 6: break;
      default: break;
    }
  }

}
