import { BetInfoService } from '../providers/bet-info/bet-info.service';
import { Subscription } from 'rxjs';
import { Utils } from './util';

export class GameBase {
    // 游戏ID
    public game_id: number;
    public game_info_sub: Subscription;
    // 游戏玩法信息
    public play_info: String = '';
    public play_info_sub: Subscription;
    // 奖金组
    public prize_group: number;
    public prize_group_sub: Subscription;
    // 模式
    public money_unit: number;
    public money_unit_sub: Subscription;
    public money_mode_sub: Subscription;
    // 玩法面板数据
    public lottery_data: Array<any> = [];
    // 玩法面板选球状态
    public btn_status_array: Array<any>;
    // 快捷选号数组
    public fast_option_array: Array<Array<boolean>> = [];
    // 选球字符串
    public balls_str: String;
    // 选球数组集合
    public selected_ball_array: Array<Array<String>>;
    // 注单数量
    public bet_num: Number = 0;
    // 奖金概率
    public prize_probability: any;
    // 清空选球
    public clear_balls_sub: Subscription;
    // 反选注单
    public bets_sub: Subscription;
    // 位置信息
    public position_sub: Subscription;
    // 机选注单流
    public random_balls_sub: Subscription;

    constructor(
        public betInfoService: BetInfoService
    ) {
    }

    /**
     * 预定 监听
     *
     * @memberof GameBase
     */
    ngAfterContentInit() {
        // 游戏信息
        this.game_info_sub = this.betInfoService.get_cur_game_info_handler().subscribe(data => {
            if (data) {
                this.game_id = data.game_id;
                this.calculation_play_prize_handle();
            }
        });

        // 清空监听
        this.clear_balls_sub = this.betInfoService.get_clear_balls_handler().subscribe(data => {
            if (data) {
                this.reset_ball_plane_handler();
            }
        });

        // 反选注单
        this.bets_sub = this.betInfoService.get_bets_handler().subscribe(data => {
            if (data) {
                this.update_seleted_bets_handler(data);
            }
        });

        // 位置信息
        this.position_sub = this.betInfoService.get_bet_position_info_handle().subscribe(data => {
            if (data) {
                this.selected_position_render_handler(data);
            }
        });

        // 机选注单
        this.random_balls_sub = this.betInfoService.get_random_balls_handler().subscribe(data => {
        });

        // 玩法监听
        this.play_info_sub = this.betInfoService.get_play_info_handler().subscribe(data => {
            if (data) {
                this.play_info = data;
                this.prize_probability = data['aProbilities'];
            }
        });

        // 奖金组
        this.prize_group_sub = this.betInfoService.get_game_prize_group_info_handler().subscribe(data => {
            if (data) {
                this.prize_group = Number(data);
                if (this.prize_probability) {
                    this.calculation_play_prize_handle();
                }
            }
        });

        // 模式
        this.money_unit_sub = this.betInfoService.get_money_unit_info_handler().subscribe(data => {
            if (data) {
                this.money_unit = Number(data);
                this.calculation_play_prize_handle();
            }
        });

        // 两/一元模式
        this.money_mode_sub = this.betInfoService.get_money_mode_info_handler().subscribe(data => {
            this.calculation_play_prize_handle();
        });
    }

    /**
     * 销毁 监听
     *
     * @memberof GameBase
     */
    ngOnDestroy() {
        this.game_info_sub.unsubscribe();

        this.betInfoService.set_clear_balls_handler(null);
        this.clear_balls_sub.unsubscribe();

        this.betInfoService.set_bets_handler(null);
        this.bets_sub.unsubscribe();

        this.betInfoService.set_random_balls_handler(null);
        this.random_balls_sub.unsubscribe();

        this.betInfoService.set_bet_position_info_handler(null);
        this.position_sub.unsubscribe();

        this.play_info_sub.unsubscribe();
        this.prize_group_sub.unsubscribe();
        this.money_unit_sub.unsubscribe();
        this.money_mode_sub.unsubscribe();
    }

    /**
     * 更新任选位置
     *
     * @param {*} data
     * @memberof GameBase
     */
    public selected_position_render_handler(data: any) {
    }

    /**
     * 降序排列
     *
     * @param {*} a
     * @param {*} b
     * @returns
     * @memberof GameBase
     */
    public cmp(a, b) {
        return b - a;
    }

    /**
     * 去重方法
     *
     * @param {*} array
     * @returns
     * @memberof GameBase
     */
    public unique(array) {
        return Array.from(new Set(array));
    }

    /**
     * 阶乘计算公式
     *
     * @param {number} n
     * @returns
     * @memberof GameBase
     */
    public fac(n: number) {
        return n > 1 ? n * this.fac(n - 1) : 1;
    }

    /**
     * 匹配结果
     *
     * @param {number} sum
     * @param {number} nBegin
     * @param {number} nEnd
     * @returns {number}
     * @memberof GameBase
     */
    public mathResult(sum: number, nBegin: number, nEnd: number): number {
        let arr, x, y, z;

        arr = [];

        for (x = nBegin; x <= nEnd; x++) {
            for (y = nBegin; y <= nEnd; y++) {
                for (z = nBegin; z <= nEnd; z++) {
                    if (x + y + z === sum) {
                        arr.push([x, y, z]);
                    }
                }
            }
        }

        return arr.length;
    }

    /**
     * 奖金计算
     *
     * @memberof GameBase
     */
    public calculation_play_prize_handle() {
        let len;
        let rule_arr;
        let probability_arr;
        let prize_arr;
        let prize_str = '';
        let prize;

        if (this.prize_probability && this.prize_group) {
            len = this.prize_probability['level'].length;
            rule_arr = this.prize_probability['rule'];

            if (rule_arr[0] === '') {
                probability_arr = this.prize_probability['probability'].sort();
            } else {
                probability_arr = this.prize_probability['probability'];
            }

            prize_arr = [];
            for (let i = 0; i < len; i++) {
                prize = this.prize_formula(Number(probability_arr[i]));
                prize_arr.push(prize);
            }

            if (rule_arr[0] === '') {
                prize_str = prize_arr.join(' - ');
            }
        }

        setTimeout(() => {
            this.betInfoService.set_prize_info_handler(prize_str);
        });
    }

    /**
     * 计算奖金值
     *
     * @param {number} p
     * @returns {string}
     * @memberof GameBase
     */
    public prize_formula(p: number): string {
        let n;
        let prize;
        let str;

        if (this.prize_group !== undefined && this.game_id !== undefined) {
            Utils.game_type_array.forEach((item, index) => {
                if (item.indexOf(this.game_id) !== -1) {
                    n = index;
                }
            });

            if (n === 1) {
                prize = (this.prize_group - Utils.adt_val_11x5) / 1980 * Utils.price * this.money_unit * (1 / p);
            } else if (n === 4 || n === 5) {
                prize = (this.prize_group - Utils.adt_val_dp) / 2000 * Utils.price * this.money_unit * (1 / p);
            } else {
                prize = (this.prize_group) / 2000 * Utils.price * this.money_unit * (1 / p);
            }

            str = this.fmoney(prize, 4);
        }

        return str;
    }

    /**
     * 格式化金钱
     *
     * @param {*} s
     * @param {*} n
     * @returns
     * @memberof GameBase
     */
    public fmoney(s, n) {
        /*
         * 参数说明：
         * s：要格式化的数字
         * n：保留几位小数
         * */
        let l;
        let r;
        let t;

        n = n > 0 && n <= 20 ? n : 2;
        s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';

        l = s.split('.')[0].split('').reverse();
        r = s.split('.')[1];
        t = '';

        for (let i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '');
        }

        return t.split('').reverse().join('') + '.' + r;
    }

    /**
     * 选球操作
     *
     * @param {number} r
     * @param {number} c
     * @memberof GameBase
     */
    public click_btn_lottery_handler(r: number, c: number) {
        this.btn_status_array[r][c] = !this.btn_status_array[r][c];
        this.fast_option_array[r] = [false, false, false, false, false, false];
        /** 更新操作*/
        this.update_bet_infor_handler();
    }


    /**
     * 快捷菜单选择球
     *
     * @param {number} r
     * @param {number} n
     * @memberof GameBase
     */
    public select_fast_option_btn_handler(r: number, n: number) {
        this.fast_option_array[r] = [false, false, false, false, false, false];
        this.fast_option_array[r][n] = true;
        switch (n) {
            case 0:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    this.btn_status_array[r][i] = true;
                }
                break;
            case 1:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    if (i >= Math.floor(this.btn_status_array[r].length / 2)) {
                        this.btn_status_array[r][i] = true;
                    } else {
                        this.btn_status_array[r][i] = false;
                    }
                }
                break;
            case 2:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    if (i < Math.floor(this.btn_status_array[r].length / 2)) {
                        this.btn_status_array[r][i] = true;
                    } else {
                        this.btn_status_array[r][i] = false;
                    }
                }
                break;
            case 3:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    if (i % 2 === 1) {
                        this.btn_status_array[r][i] = true;
                    } else {
                        this.btn_status_array[r][i] = false;
                    }
                }
                break;
            case 4:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    if (i % 2 === 0) {
                        this.btn_status_array[r][i] = true;
                    } else {
                        this.btn_status_array[r][i] = false;
                    }
                }
                break;
            default:
                for (let i = 0; i < this.btn_status_array[r].length; i++) {
                    this.btn_status_array[r][i] = false;
                }
                break;
        }
        /** 更新操作*/
        this.update_bet_infor_handler();
    }

    /**
     * 反选效果
     *
     * @param {String} bets
     * @memberof GameBase
     */
    public update_seleted_bets_handler(bets: String) {

        let bet_arr;
        let index;

        bet_arr = bets.split('|');

        for (let i = 0; i < bet_arr.length; i++) {
            for (let j = 0; j < bet_arr[i].length; j++) {
                index = Number(bet_arr[i][j]);
                this.btn_status_array[i][index] = true;
            }
        }
        /** 更新操作*/
        this.update_bet_infor_handler();
    }

    /**
     * 判断是否完成选球，构成注单
     *
     * @returns {boolean}
     * @memberof GameBase
     */
    public is_selected_ball_completed(): boolean {
        let pos_array;
        let item;
        let arr;

        this.selected_ball_array = [];

        pos_array = this.balls_str.split('|');
        for (item of pos_array) {
            arr = item.split('');
            this.selected_ball_array.push(arr);
        }

        for (item of this.selected_ball_array) {
            if (item.length === 0) {
                return false;
            }
        }

        return true;
    }

    /**
     * 计算注单数量
     *
     * @memberof GameBase
     */
    public calculate_bet_number() {
        let num = 1;
        let item;

        for (item of this.selected_ball_array) {
            num *= item.length;
        }

        this.bet_num = num;
    }

    /**
     * 投注版面更新为初始状态
     *
     * @memberof GameBase
     */
    public reset_ball_plane_handler() {
        /** 恢复选球*/
        for (let i = 0; i < this.btn_status_array.length; i++) {
            for (let j = 0; j < this.btn_status_array[i].length; j++) {
                if (this.btn_status_array[i][j]) {
                    this.btn_status_array[i][j] = false;
                }
            }
        }
        /** 恢复快捷菜单*/
        for (let i = 0; i < this.fast_option_array.length; i++) {
            if (!this.fast_option_array[i]) {
                return;
            }
            for (let j = 0; j < this.fast_option_array[i].length; j++) {
                if (this.fast_option_array[i][j]) {
                    this.fast_option_array[i][j] = false;
                }
            }
        }
        /** 更新操作*/
        this.update_bet_infor_handler();
    }


    /**
     * 创建随机注单
     *
     * @memberof GameBase
     */
    public create_random_bet_handler() { }


    /**
     * 更新所选的注单信息
     *
     * @memberof GameBase
     */
    public update_bet_infor_handler() {
        let selected_ball_str: String = '';
        let no_ball: String = '';

        for (let i = 0; i < this.btn_status_array.length; i++) {
            if (i !== 0) {
                selected_ball_str = selected_ball_str + '|';
                no_ball = no_ball + '|';
            }
            for (let j = 0; j < this.btn_status_array[i].length; j++) {
                if (this.btn_status_array[i][j]) {
                    selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num();
                }
            }
        }

        selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str;
        this.balls_str = selected_ball_str;
        this.betInfoService.set_bet_info_handler(this.balls_str);

        // 判断是否选球完成，构成注单
        if (this.is_selected_ball_completed()) {
            this.calculate_bet_number();
        } else {
            this.bet_num = 0;
        }
        this.betInfoService.set_bet_num_handler(this.bet_num);
    }
}
