import { ApiService } from './../../../../providers/api/api.service';
import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-personal-transfer-record',
  templateUrl: './personal-transfer-record.component.html',
  styleUrls: ['./personal-transfer-record.component.css']
})
export class PersonalTransferRecordComponent implements OnInit {

  // token
  public token: string;
  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  // 用户名
  public user_name: string;
  // 转账类型
  public transfer_type: string = 'all';
  // 数据详情
  public data_source_detail: Array<any> = [];
  // 数据数量
  public total_num: number;
  // 单页容量
  public page_size: number = 10;
  // 当前页码
  public curr_page: number = 1;
  // 分页是否显示
  public is_show: boolean = false;
  // 加载是否完成
  public is_loading: boolean = false;
  constructor(
    private api: ApiService,
    private modalService: NzModalService,
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');

      this.search_handler();
    }
  }
  ngOnInit() {
  }

  /**
   *点击搜索
   *
   * @memberof PersonalTransferRecordComponent
   */
  public search_handler() {
    this.is_loading = true;
    let start_time = Utils.formate_time(new Date(this.date_range[0]), 'yyyy-MM-dd hh:mm:ss');
    let end_time = Utils.formate_time(new Date(this.date_range[1]), 'yyyy-MM-dd hh:mm:ss');
    this.api.get_user_transaction_info(
      this.curr_page,
      this.token,
      start_time,
      end_time,
      this.user_name,
      this.transfer_type === 'all' ? '1,2' : this.transfer_type
    ).subscribe(data => {
      this.is_loading = false;
      if (data && data['isSuccess']) {
        if (data['data']['total']) {
          this.total_num = data['data']['total'];
        }
        // 详情记录
        this.data_source_detail = [];
        data['data']['data'].forEach(item => {
          let detail_record = new DetailRecord();
          detail_record.apply_for_num = item['code'];
          detail_record.apply_for_time = item['time'];
          detail_record.apply_for_type = item['transaction_type'] === 1 ? '充值转入' : '提款转出';
          detail_record.apply_for_account = item['username'];
          detail_record.apply_for_val = Number(item['transaction_value']);
          detail_record.apply_for_balance = Number(item['available_balance']);
          this.data_source_detail.push(detail_record);
        });
        if (this.data_source_detail.length > 0) {
          this.is_show = true;
        }
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

  /**
   *根据页面获取信息
   *
   * @param {*} e
   * @memberof PersonalTransferRecordComponent
   */
  get_info_by_page(e: any) {
    this.curr_page = Number(e);
    this.search_handler();
  }
}

class DetailRecord {
  // 申请编号
  public apply_for_num: number;
  // 申请时间
  public apply_for_time: string;
  // 账变类型
  public apply_for_type: string;
  // 申请账户
  public apply_for_account: string;
  // 申请金额
  public apply_for_val: number;
  // 账户余额
  public apply_for_balance: number;
  constructor() { }
}
