import * as CryptoJS from 'crypto-js';

export class Utils {

    // 公彩 or 私彩
    static is_public: boolean;

    // 时时彩最高奖金
    static ssc_max_prize: number;
    static ssctx_max_prize: number;
    static ssctx_gameid_arr: Array<number> = [64, 87, 88, 100, 101];
    // 11选5最高奖金
    static x115_max_prize: number;
    // K3最高奖金
    static k3_max_prize: number;
    // PK10最高奖金
    static pk10_max_prize: number;
    // 低频最高奖金
    static dp_max_prize: number;
    // 基础奖金
    static base_prize: number = 2000;
    // 最低奖金组
    static min_prize_group: number;
    // 最高奖金组
    static max_prize_group: number;
    // 单价
    static price: number = 2;

    // 默认SSC玩法ID
    static default_play_id_ssc: number;
    // 默认11x5玩法ID
    static default_play_id_11x5: number;
    // 默认K3玩法ID
    static default_play_id_k3: number;
    // 默认PK10玩法ID
    static default_play_id_pk10: number;
    // 默认F3D玩法ID
    static default_play_id_f3d: number;
    // 默认P3/5玩法ID
    static default_play_id_p35: number;
    // 默认LHC玩法ID
    static default_play_id_lhc: number;

    // 11选5 奖金组调整值
    static adt_val_11x5: number;
    // 低频彩 奖金组调整值
    static adt_val_dp: number;

    // 游戏列表
    static game_list: Array<any> = [];

    // Chart
    static chart_size: Array<number> = [1000, 400];
    static chart_color: Array<any> = ['#FF0000', '#FF7F50', '#FFCC00', '#66FF00', '#00FFFF', '##007FFF', '#FF00FF', '#8B00FF', '#CC7722', '#C0C0C0'];

    /**
    * 游戏类型对应表
    * 
    * @static
    * @type {Array<any>}
    * @memberof Utils
    */
    static game_type_array: Array<any>;

    /**
     * 六合彩Balls
     *
     * @static
     * @type {Array<any>}
     * @memberof Utils
     */
    static lhc_balls_info: Array<any> = [
        { ball: '01', color: 'red', p: 0, is_select: false, val: null },
        { ball: '02', color: 'red', p: 0, is_select: false, val: null },
        { ball: '03', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '04', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '05', color: 'green', p: 0, is_select: false, val: null },
        { ball: '06', color: 'green', p: 0, is_select: false, val: null },
        { ball: '07', color: 'red', p: 0, is_select: false, val: null },
        { ball: '08', color: 'red', p: 0, is_select: false, val: null },
        { ball: '09', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '10', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '11', color: 'green', p: 0, is_select: false, val: null },
        { ball: '12', color: 'red', p: 0, is_select: false, val: null },
        { ball: '13', color: 'red', p: 0, is_select: false, val: null },
        { ball: '14', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '15', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '16', color: 'green', p: 0, is_select: false, val: null },
        { ball: '17', color: 'green', p: 0, is_select: false, val: null },
        { ball: '18', color: 'red', p: 0, is_select: false, val: null },
        { ball: '19', color: 'red', p: 0, is_select: false, val: null },
        { ball: '20', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '21', color: 'green', p: 0, is_select: false, val: null },
        { ball: '22', color: 'green', p: 0, is_select: false, val: null },
        { ball: '23', color: 'red', p: 0, is_select: false, val: null },
        { ball: '24', color: 'red', p: 0, is_select: false, val: null },
        { ball: '25', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '26', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '27', color: 'green', p: 0, is_select: false, val: null },
        { ball: '28', color: 'green', p: 0, is_select: false, val: null },
        { ball: '29', color: 'red', p: 0, is_select: false, val: null },
        { ball: '30', color: 'red', p: 0, is_select: false, val: null },
        { ball: '31', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '32', color: 'green', p: 0, is_select: false, val: null },
        { ball: '33', color: 'green', p: 0, is_select: false, val: null },
        { ball: '34', color: 'red', p: 0, is_select: false, val: null },
        { ball: '35', color: 'red', p: 0, is_select: false, val: null },
        { ball: '36', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '37', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '38', color: 'green', p: 0, is_select: false, val: null },
        { ball: '39', color: 'green', p: 0, is_select: false, val: null },
        { ball: '40', color: 'red', p: 0, is_select: false, val: null },
        { ball: '41', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '42', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '43', color: 'green', p: 0, is_select: false, val: null },
        { ball: '44', color: 'green', p: 0, is_select: false, val: null },
        { ball: '45', color: 'red', p: 0, is_select: false, val: null },
        { ball: '46', color: 'red', p: 0, is_select: false, val: null },
        { ball: '47', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '48', color: 'blue', p: 0, is_select: false, val: null },
        { ball: '49', color: 'green', p: 0, is_select: false, val: null },
    ];

    // 密钥
    // bet
    static key_bet_str: string = 'C194V1RBJG8MJPEL';
    static iv_bet_str: string = 'ARC49SBQE76B8QZT';
    // info
    static key_info_str: string = '90R1XU#DEDghEObz';
    static iv_info_str: string = '&$n$^VGfTT%VDdeX';

    /**
     * 加密操作
     *
     * @static
     * @param {*} info
     * @param {Number} type 1投注加密 0信息加密
     * @returns {string}
     * @memberof Utils
     */
    static encode_info_handler(info: any, type: number): string {
        let key;
        let iv;

        if (type) {
            key = CryptoJS.enc.Utf8.parse(Utils.key_bet_str);
            iv = CryptoJS.enc.Utf8.parse(Utils.iv_bet_str);
        } else {
            key = CryptoJS.enc.Utf8.parse(Utils.key_info_str);
            iv = CryptoJS.enc.Utf8.parse(Utils.iv_info_str);
        }

        let encrypt = CryptoJS.AES.encrypt(info, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        let str = encrypt.toString();

        return str;
    }

    /**
     * 格式化时间格式
     *
     * @static
     * @param {Date} data
     * @param {string} fmt
     * @returns {string}
     * @memberof Utils
     */
    static formate_time(data: Date, fmt: string): string {
        let o, k;
        o = {
            'M+': data.getMonth() + 1,                  /** 月份*/
            'd+': data.getDate(),                       /** 日 */
            'h+': data.getHours(),                      /** 小时 */
            'm+': data.getMinutes(),                    /** 分 */
            's+': data.getSeconds(),                    /** 秒 */
        };

        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (data.getFullYear() + '').substr(4 - RegExp.$1.length));
        }

        for (k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }

        return fmt;
    }

    /**
     * 秒数转换为倒计时
     *
     * @static
     * @param {number} seconds
     * @returns {string}
     * @memberof Utils
     */
    static seconds_formate_timer(seconds: number): string {
        let time_str = '';
        let h, m, s, h_str, m_str, s_str;

        h = Math.floor(seconds / 3600);
        m = Math.floor(seconds % 3600 / 60);
        s = seconds % 60;

        h_str = h < 10 ? '0' + h : '' + h;
        m_str = m < 10 ? '0' + m : '' + m;
        s_str = s < 10 ? '0' + s : '' + s;

        time_str = `${h_str}${m_str}${s_str}`;

        return time_str;
    }

    /**
     * 秒数转换为倒计时
     *
     * @static
     * @param {number} seconds
     * @returns {string}
     * @memberof Utils
     */
    static seconds_formate_timer2(seconds: number): string {
        if (seconds <= 0) {
            return `--:--:--`;
        }

        let time_str = '';
        let h, m, s, h_str, m_str, s_str;

        h = Math.floor(seconds / 3600);
        m = Math.floor(seconds % 3600 / 60);
        s = seconds % 60;

        h_str = h < 10 ? '0' + h : '' + h;
        m_str = m < 10 ? '0' + m : '' + m;
        s_str = s < 10 ? '0' + s : '' + s;

        time_str = `${h_str}:${m_str}:${s_str}`;

        return time_str;
    }

    /**
     * 格式化投注内容
     *
     * @static
     * @param {number} playId
     * @param {string} origin_content
     * @returns {string}
     * @memberof Utils
     */
    static format_bet_content(playId: number, origin_content: string): string {
        let content: string = origin_content;
        let row_arr;
        let row_str;

        // ssc-前三-直选-跨度 | ssc-中三-直选-跨度 | ssc-后三-直选-跨度 | ssc-二星-直选-后二跨度 | ssc-二星-直选-前二跨度
        if (playId === 60 || playId === 147 || playId === 62 || playId === 63 || playId === 61) {
            content = origin_content.replace(/\|/g, '');
        }

        // ssc-前三-其他-特殊号码 | ssc-中三-其他-特殊号码 | ssc-后三-其他-特殊号码
        if (playId === 48 || playId === 151 || playId === 57) {
            content = origin_content.replace(/\|/g, '').replace(/豹子/g, '0').replace(/顺子/g, '1').replace(/对子/g, '2');
        }

        // ssc-大小单双-后二 | ssc-大小单双-后三 | ssc-大小单双-前二 | ssc-大小单双-前三 | ssc-大小单双-中三 | F3D-大小单双-前二 | F3D-大小单双-后二 | PL5-大小单双-前二 | PL5-大小单双-后二
        if (playId === 58 || playId === 53 || playId === 19 || playId === 22 || playId === 157 || playId === 175 || playId === 176 || playId === 197 || playId === 198) {
            content = origin_content.replace(/小/g, '0').replace(/大/g, '1').replace(/双/g, '2').replace(/单/g, '3');
        }

        // ssc-龙虎
        if (playId >= 220 && playId <= 229) {
            content = origin_content.replace(/龙/g, '0').replace(/虎/g, '1').replace(/和/g, '2').replace(/\|/g, '');
        }

        // 11x5-趣味-定单双
        if (playId === 109) {
            content = origin_content.replace(/\|/g, ' ').replace(/5单0双/g, '5').replace(/4单1双/g, '4').replace(/3单2双/g, '3').replace(/2单3双/g, '2').replace(/1单4双/g, '1').replace(/0单5双/g, '0');
        }

        // 11x5-趣味-猜中位
        if (playId === 110) {
            content = origin_content.replace(/\|/g, ' ');
        }

        // k3-大小单双
        if (playId === 242) {
            content = origin_content.replace(/小/g, '0').replace(/大/g, '1').replace(/双/g, '2').replace(/单/g, '3');
        }

        // PK10
        if ((playId >= 1029 && playId <= 1038) || (playId >= 1001 && playId <= 1005) || (playId >= 1019 && playId <= 1025)) {
            row_arr = origin_content.split('|');
            row_str = '';
            row_arr.forEach(item => {
                let col_arr = item.split(' ');
                let col_str = '';
                col_arr.forEach(val => {
                    if (val !== '') {
                        let n = Number(val) - 1;
                        col_str += n + '';
                    }
                });
                row_str += col_str + '|';
            });

            content = row_str.substring(0, row_str.length - 1);
        }
        // PK10-趣味
        if (playId === 1049 || playId === 1050 || playId === 1051) {
            row_arr = origin_content.split('');
            row_str = row_arr.join('|');
            content = row_str.replace(/小/g, '0').replace(/大/g, '1').replace(/双/g, '2').replace(/单/g, '3');
        }

        if (playId === 1026 || playId === 1027) {
            content = origin_content.replace(/\s/g, '|');
        }

        if (playId === 1028) {
            content = origin_content.
                // row-1
                replace(/1龙2虎/g, '1').
                replace(/1龙3虎/g, '2').
                replace(/1龙4虎/g, '3').
                replace(/1龙5虎/g, '4').
                // row-2
                replace(/2龙1虎/g, '0').
                replace(/2龙3虎/g, '2').
                replace(/2龙4虎/g, '3').
                replace(/2龙5虎/g, '4').
                // row-3
                replace(/3龙1虎/g, '0').
                replace(/3龙2虎/g, '1').
                replace(/3龙4虎/g, '3').
                replace(/3龙5虎/g, '4').
                // row-4
                replace(/4龙1虎/g, '0').
                replace(/4龙2虎/g, '1').
                replace(/4龙3虎/g, '2').
                replace(/4龙5虎/g, '4').
                // row-5
                replace(/5龙1虎/g, '0').
                replace(/5龙2虎/g, '1').
                replace(/5龙3虎/g, '2').
                replace(/5龙4虎/g, '3').
                replace(/\s/g, '') + '|||||';
        }

        return content;
    }
}

