import { Component, OnInit } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ApiService } from 'app/providers/api/api.service';
import { GameConfig } from 'app/common/gameConfig';

@Component({
  selector: 'app-pk10-panel-credit',
  templateUrl: './pk10-panel-credit.component.html',
  styleUrls: ['./pk10-panel-credit.component.css']
})
export class Pk10PanelCreditComponent implements OnInit {

  // 默认类型下标
  public default_play_type_index: number = 0;
  //  玩法菜单
  public play_menu: Array<any>;
  // 当前玩法id
  public current_play_id: number;

  constructor(
    public betInfoService: BetInfoService,
    public api: ApiService
  ) {
    this.api.get_request_pk10_game_config().subscribe(data => {
      if (data) {
        GameConfig.pk10_game_credit_config = data['credit_game_config'];
        this.play_menu = data['credit_game_config'];
        this.current_play_id = data['credit_game_config'][0]['id'];

        this.betInfoService.set_play_info_handler(data['credit_game_config'][0]);
      }
    });
  }

  ngOnInit() {
  }

  /**
   * 切换玩法类型
   *
   * @param {*} e
   * @memberof Pk10PanelCreditComponent
   */
  public switch_play_menu_handler(e: any) {
    this.default_play_type_index = e;
    this.current_play_id = this.play_menu[e]['id'];
    this.betInfoService.set_play_info_handler(this.play_menu[e]);
  }

}
