import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscSanxingZuxuan'
})
export class SscSanxingZuxuanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let lottery_arr = [];
    // 组选形态
    let form: string;

    switch (args) {
      case 'qiansan':
        value.forEach((element, index) => {
          if (index < 3) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      case 'zhongsan':
        value.forEach((element, index) => {
          if (index > 0 && index < 4) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      case 'housan':
        value.forEach((element, index) => {
          if (index > 1) {
            lottery_arr.push(element);
          }
        });
        form = this.calculation_form_handler(lottery_arr);
        break;
      default: break;
    }
    return form;
  }

  private calculation_form_handler(lottery_arr: any): string {
    let str: string;
    let arr = Array.from(new Set(lottery_arr));
    switch (arr.length) {
      case 3: str = '组六'; break;
      case 2: str = '组三'; break;
      default: break;
    }

    return str;
  }

}
