import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lottery-bet-record',
  templateUrl: './lottery-bet-record.component.html',
  styleUrls: ['./lottery-bet-record.component.css']
})
export class LotteryBetRecordComponent implements OnInit {

  // token
  private token: string;
  // 加载数据
  public is_spining: boolean = false;
  // 投注记录
  public bet_record_data: Array<any> = [];
  // 追号记录
  public trace_record_data: Array<any> = [];
  // 获取投注记录
  public record_sub: Subscription;
  public open_record_sub: Subscription;
  // 窗口
  public isVisible_modal: boolean = false;

  constructor(
    private api: ApiService,
    private betInfoService: BetInfoService,
    private message: NzMessageService,
  ) {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      this.token = localStorage.getItem('token');
      // 获取游戏记录
      this.get_game_record_info_handler();
    }
  }

  ngOnInit() {
    this.record_sub = this.betInfoService.get_bet_record_info().subscribe(data => {
      if (data) {
        this.get_game_record_info_handler();
      }
    });

    this.open_record_sub = this.betInfoService.get_open_game_record_info().subscribe(data => {
      if (data) {
        this.isVisible_modal = true;
        this.get_game_record_info_handler();
      }
    });
  }

  ngOnDestroy() {
    this.betInfoService.set_bet_record_info(null);
    this.record_sub.unsubscribe();
    this.betInfoService.set_open_game_record_info(null);
    this.open_record_sub.unsubscribe();
  }

  /**
   * 获取游戏记录
   *
   * @memberof LotteryBetRecordComponent
   */
  public get_game_record_info_handler() {
    this.is_spining = true;
    this.api.get_gamepage_recode_info(this.token).subscribe(data => {
      this.is_spining = false;
      if (data && data['isSuccess']) {
        // 投注记录
        let bet_data = data['data'][0]['data'];
        this.bet_record_data = [];
        bet_data.forEach(item => {
          let bet_record = new BetRecord();
          bet_record.bet_id = item['bet_id'];
          bet_record.game_name = item['game_name'];
          bet_record.play_id = Number(item['way_id']);
          bet_record.game_issue = item['issue'];
          bet_record.lottery = item['prizeballs'];
          bet_record.bet_content = item['bet_content'];
          bet_record.bet_value = Number(item['bet_value']);
          bet_record.prize = Number(item['prize_value']);
          bet_record.prize_group = item['prize_group'];
          bet_record.bet_status = item['bet_status'];
          bet_record.possible_drop = Number(item['possible_drop']) === 1 ? true : false;

          this.bet_record_data.push(bet_record);
        });

        // 追号记录
        let trace_data = data['data'][1]['data'];
        this.trace_record_data = [];
        trace_data.forEach(item => {
          let trace_record = new TraceRecord();
          trace_record.game_name = item['game_name'];
          trace_record.play_id = Number(item['way_id']);
          trace_record.start_issue = item['start_issue'];
          trace_record.trace_schedule = item['trace_schedule'];
          trace_record.total_bet_val = Number(item['trace_value']);
          trace_record.win_prize = Number(item['prize_value']);
          trace_record.win_stop = Number(item['win_stop']) === 1 ? '是' : '否';
          trace_record.trace_status = Number(item['trace_status']);

          this.trace_record_data.push(trace_record);
        });
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 撤销注单
   *
   * @param {*} bet
   * @memberof LotteryBetRecordComponent
   */
  public delete_bet_handler(bet: any) {
    this.api.drop_bet_info_by_id(bet.bet_id, this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        bet.bet_status = 1;
        bet.possible_drop = 0;
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 关闭窗口
   *
   * @memberof LotteryBetRecordComponent
   */
  public dismiss_model_handler() {
    this.isVisible_modal = false;
  }

}

/**
 * 投注记录数据 
 *
 * @class BetRecord
 */
class BetRecord {
  // 注单id
  public bet_id: number;
  // 游戏名称
  public game_name: string;
  // 玩法id
  public play_id: number;
  // 游戏奖旗
  public game_issue: string;
  // 开奖号码
  public lottery: string;
  // 投注内容
  public bet_content: string;
  // 投注金额
  public bet_value: number;
  // 奖金
  public prize: number;
  // 奖金组
  public prize_group: string;
  // 注单状态
  public bet_status: number;
  // 是否可撤单
  public possible_drop: boolean;

  constructor() {

  }
}

/**
 * 追号记录数据
 *
 * @class TraceRecord
 */
class TraceRecord {
  // 游戏名称
  public game_name: string;
  // 玩法id
  public play_id: number;
  // 起始奖期
  public start_issue: string;
  // 追号进度
  public trace_schedule: string;
  // 总追号金额
  public total_bet_val: number;
  // 已中奖金额
  public win_prize: number;
  // 追中即停
  public win_stop: string;
  // 注单状态
  public trace_status: number;

  constructor() {

  }
}

