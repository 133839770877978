import { Component, OnInit } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { GameConfig } from 'app/common/gameConfig';
import { Utils } from 'app/common/util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lottery-lhc',
  templateUrl: './lottery-lhc.component.html',
  styleUrls: ['./lottery-lhc.component.css']
})
export class LotteryLhcComponent implements OnInit {

  public token: string;
  /** 游戏玩法菜单 */
  public play_menu: Array<any>;
  // 当前游戏cid
  public current_game_play_id: Number;
  // 当前游戏玩法信息
  public current_game_play_info: any;
  // 当前生肖
  public current_zodiac: number;
  // 玩法集合
  public game_play_array: Array<any> = [];
  // 游戏ID
  public game_id: number;
  // 游戏信息
  public game_info: any;
  // mini走势数据
  public mini_trend_info: Array<any> = [];
  // 奖期数组
  public game_issue_arr: Array<any> = [];
  // 游戏名称
  public game_name: string;
  // 游戏奖期
  public game_issue: string;

  // 注单信息
  public bet_info: any;
  public bet_info_sub: Subscription;
  // 注数监听流
  public bet_num: number;
  public bet_num_sub: Subscription;
  // 显示投注详情
  public open_modal: boolean;
  public open_modal_sub: Subscription;

  //  投注信息
  public submit_info: any;
  // 投注锁
  public is_lock: boolean = false;

  constructor(
    public betInfoService: BetInfoService,
    public routeInfo: ActivatedRoute,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService
  ) {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      this.token = localStorage.getItem('token');
    }

    this.api.get_request_lhc_game_config().subscribe(data => {
      if (data) {
        GameConfig.lhc_game_config = data['lhc_game_config'];
        this.current_zodiac = data['current_zodiac'];
        Utils.default_play_id_lhc = data['default_play_id_lhc'];
        this.current_game_play_id = Utils.default_play_id_lhc;
        this.betInfoService.set_credit_bet_val_handler('1');
        this.routeInfo.params.subscribe((params: Params) => {
          this.betInfoService.set_clear_balls_handler('clear');
          this.betInfoService.set_bet_position_info_handler(null);
          this.game_id = Number(params['id']);
          this.play_menu = GameConfig.lhc_game_config;

          // 获取游戏信息
          this.get_game_info_handler();

          // 初始化玩法数据
          this.init_game_play_array_handler();
        });
      }
    });
  }

  ngOnInit() {
    // 监听注单信息
    this.bet_info_sub = this.betInfoService.get_bet_info_handler().subscribe(data => {
      if (data) {
        this.bet_info = data;
      }
    });

    // 订阅 注数监听
    this.bet_num_sub = this.betInfoService.get_bet_num_handler().subscribe(data => {
      this.bet_num = Number(data);
    });

    // 订阅 投注详情窗口
    this.open_modal_sub = this.betInfoService.get_confirm_bet_info().subscribe(data => {
      if (data) {
        this.open_modal = true;
        this.show_bet_info_handler();
      }
    });
  }

  ngOnDestroy() {
    this.betInfoService.set_cur_game_info_handler(null);
    // 取消 订阅注数
    this.betInfoService.set_bet_num_handler(0);
    this.bet_num_sub.unsubscribe();
    // 取消 注单信息
    this.bet_info_sub.unsubscribe();
    // 取消 窗口监听
    this.betInfoService.set_confirm_bet_info(null);
    this.open_modal_sub.unsubscribe();
    this.betInfoService.set_credit_bet_val_handler('1');
  }

  /**
   * 初始化保存玩法信息
   *
   * @memberof LotteryLhcComponent
   */
  public init_game_play_array_handler() {
    // 一级目录
    this.play_menu.forEach((v1) => {
      // 二级目录
      v1.children.forEach((v2) => {
        // 三级目录
        v2.children.forEach((v3) => {
          v3['menu_id'] = v1.id;
          v3['play_name_cn'] = `${v1.name_cn}·${v3.name_cn}`;
          this.game_play_array.push(v3);

          if (v3.id === this.current_game_play_id) {
            this.current_game_play_info = v3;

            this.betInfoService.set_play_info_handler(v3);
          }
        });
      });
    });
  }

  /**
   * 更新切换玩法信息
   *
   * @param {*} e
   * @memberof LotteryLhcComponent
   */
  public update_current_play_info_handler(e: any) {
    this.current_game_play_info = e;
    this.current_game_play_id = e['id'];
    this.betInfoService.set_bet_num_handler(0);
  }

  /**
   * 获取游戏信息
   *
   * @memberof LotteryLhcComponent
   */
  public get_game_info_handler() {
    this.api.get_game_info(this.game_id).subscribe(data => {
      if (data && data['isSuccess']) {
        this.game_info = data['data'];
        let deadline: number = data['data']['current_prize_time'] - data['data']['current_time'];
        if (deadline < 1) {
          this.get_game_info_handler();
        } else {
          this.betInfoService.set_cur_game_info_handler(this.game_info);

          // 更新奖期数组
          this.game_issue_arr = data['data']['game_issue'];
          // 游戏名称
          this.game_name = data['data']['game_name'];
          // 游戏奖期
          this.game_issue = data['data']['current_issue'];
        }
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 取消投注
   *
   * @memberof LotteryLhcComponent
   */
  public cancel_handle() {
    this.open_modal = false;
    this.submit_info = null;
  }

  /**
   * 确认投注
   *
   * @memberof LotteryLhcComponent
   */
  public submit_bet_handler() {
    if (this.is_lock) {
      return;
    }
    this.is_lock = true;
    this.modalService.info({
      nzTitle: '温馨提示',
      nzContent: '投注中...',
      nzCancelText: null,
      nzOkText: null
    });

    let bet_info = this.create_bet_handler();

    setTimeout(() => {
      if (this.token) {
        this.api.get_submit_bet_info(bet_info, this.token).subscribe(data => {
          setTimeout(() => {
            this.is_lock = false;
            this.modalService.closeAll();
          }, 500);

          if (data && data['isSuccess']) {
            this.betInfoService.set_clear_credit_balls_handler('clear');
            this.betInfoService.set_bet_num_handler(0);
            this.open_modal = false;
            this.submit_info = null;

            this.betInfoService.set_credit_update_balance_handler(true);

            this.message.create('success', '投注成功，祝您中奖！');
          } else {
            this.message.create('error', data['data']['error']);
          }
        });
      }
    });
  }

  /**
   * 显示注单信息
   *
   * @memberof LotteryLhcComponent
   */
  public show_bet_info_handler() {
    if (this.bet_info && this.bet_info.length > 0) {
      this.submit_info = new SumbitInfo();

      this.submit_info.play_id = this.bet_info[0].way_id;

      // 特码 & 正特
      if (this.submit_info.play_id >= 290 && this.submit_info.play_id <= 296) {
        this.bet_info.forEach(item => {
          let c = {};
          c['content'] = Utils.lhc_balls_info[(Number(item['ball']) - 1)];
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      // 半波
      if (this.submit_info.play_id === 297) {
        this.bet_info.forEach(item => {
          let c = {};
          switch (Number(item['ball'])) {
            case 1: c['content'] = '红大'; break;
            case 3: c['content'] = '红小'; break;
            case 5: c['content'] = '红单'; break;
            case 7: c['content'] = '红双'; break;
            case 9: c['content'] = '红合单'; break;
            case 11: c['content'] = '红合双'; break;

            case 13: c['content'] = '绿大'; break;
            case 15: c['content'] = '绿小'; break;
            case 17: c['content'] = '绿单'; break;
            case 2: c['content'] = '绿双'; break;
            case 4: c['content'] = '绿合单'; break;
            case 6: c['content'] = '绿合双'; break;

            case 8: c['content'] = '蓝大'; break;
            case 10: c['content'] = '蓝小'; break;
            case 12: c['content'] = '蓝单'; break;
            case 14: c['content'] = '蓝双'; break;
            case 16: c['content'] = '蓝合单'; break;
            case 18: c['content'] = '蓝合双'; break;
            default: break;
          }

          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      // 生肖
      if (this.submit_info.play_id >= 298 && this.submit_info.play_id <= 299) {
        this.bet_info.forEach(item => {
          let c = {};
          let n = Number(item['ball'].split(',')[0]);
          switch (n) {
            case 12: c['content'] = '蛇'; break;
            case 11: c['content'] = '马'; break;
            case 10: c['content'] = '羊'; break;
            case 9: c['content'] = '猴'; break;
            case 8: c['content'] = '鸡'; break;
            case 7: c['content'] = '狗'; break;
            case 6: c['content'] = '猪'; break;
            case 5: c['content'] = '鼠'; break;
            case 4: c['content'] = '牛'; break;
            case 3: c['content'] = '虎'; break;
            case 2: c['content'] = '兔'; break;
            case 1: c['content'] = '龙'; break;
            default: break;
          }

          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      if (this.submit_info.play_id === 300) {
        this.bet_info.forEach(item => {
          let c = {};
          let content_arr = [];
          let content_str = '';
          let z_arr = item['ball'].split('|');
          z_arr.forEach(z => {
            content_arr.push(Number(z.split(',')[0]));
          });
          content_str = content_arr.join(',');

          c['content'] = content_str.
            replace(/12/g, '蛇').
            replace(/11/g, '马').
            replace(/10/g, '羊').
            replace(/9/g, '猴').
            replace(/8/g, '鸡').
            replace(/7/g, '狗').
            replace(/6/g, '猪').
            replace(/5/g, '鼠').
            replace(/4/g, '牛').
            replace(/3/g, '虎').
            replace(/2/g, '兔').
            replace(/1/g, '龙');
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      // 尾数
      if (this.submit_info.play_id === 301) {
        this.bet_info.forEach(item => {
          let c = {};
          c['content'] = `${Number(item['ball'])}尾`;
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      // 总分
      if (this.submit_info.play_id === 302) {
        this.bet_info.forEach(item => {
          let c = {};
          switch (Number(item['ball'])) {
            case 1: c['content'] = '大'; break;
            case 2: c['content'] = '小'; break;
            case 3: c['content'] = '单'; break;
            case 4: c['content'] = '双'; break;
            case 5: c['content'] = '大单'; break;
            case 6: c['content'] = '大双'; break;
            case 7: c['content'] = '小单'; break;
            case 8: c['content'] = '小双'; break;
            default: break;
          }

          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      // 不中
      if (this.submit_info.play_id >= 303 && this.submit_info.play_id <= 309) {
        this.bet_info.forEach(item => {
          let c = {};
          let ball_arr = [];
          item['ball'].split('|').forEach(item => {
            ball_arr.push(Utils.lhc_balls_info[(Number(item) - 1)]);
          });
          c['content'] = ball_arr;
          c['num'] = Number(item['num']);
          c['val'] = Number(item['multiple']);
          this.submit_info.content.push(c);
        });
      }

      this.bet_info.forEach(item => {
        this.submit_info.bet_num += item.num;
        this.submit_info.bet_value += item.num * item.multiple;
      });
    }
  }

  /**
   * 创建投注注单
   *
   * @memberof LotteryLhcComponent
   */
  public create_bet_handler() {
    let bet = new Bet();
    bet.game_id = this.game_id;
    bet.balls = Utils.encode_info_handler(JSON.stringify(this.bet_info), 1);
    bet.orders[this.game_issue] = 1;

    this.bet_info.forEach((item: any) => {
      bet.amount += item['num'] * item['multiple'];
    });

    return bet;
  }
}

class Bet {
  // 游戏ID
  public game_id: number;
  // balls信息
  public balls: any;
  // 奖期
  public orders: any = {};
  // 总金额
  public amount: number = 0;
  public is_encoded: number = 1;
  public bet_source: string = 'web';
  public isTrace: number = 0;
  public traceWinStop: number = 1;
  public traceStopValue: number = 1;
  constructor() { }
}

/**
 * 投注信息
 *
 * @class SumbitInfo
 */
class SumbitInfo {
  public play_id: number;
  public content: Array<any> = [];
  public bet_num: number = 0;
  public bet_value: number = 0;

  constructor() {

  }
}
