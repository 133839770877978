import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'k3Daxiaodanshuang'
})
export class K3DaxiaodanshuangPipe implements PipeTransform {

  transform(value: any): any {

    let form: string;
    // 大小形态
    let dx_form: string;
    // 单双形态
    let ds_form: string;

    // 和值
    let num: number = 0;

    value.forEach(item => {
      num += Number(item);
    });

    if (num >= 3 && num <= 10) {
      dx_form = '小';
    }

    if (num >= 11 && num <= 18) {
      dx_form = '大';
    }

    if (num % 2 === 0) {
      ds_form = '双';
    } else {
      ds_form = '单';
    }

    form = `${dx_form}${ds_form}`;

    return form;
  }

}
