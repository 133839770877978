import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betStatus'
})
export class BetStatusPipe implements PipeTransform {

  transform(value: any): any {
    let status_str: string;

    switch (value) {
      case 0: status_str = '待开奖'; break;
      case 1: status_str = '已撤单'; break;
      case 2: status_str = '未中奖'; break;
      case 3: status_str = '已中奖'; break;
      case 4: status_str = '已派奖'; break;
      case 5: status_str = '系统撤单'; break;
      default: break;
    }

    return status_str;
  }

}
