import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-header',
  templateUrl: './lhc-header.component.html',
  styleUrls: ['./lhc-header.component.css']
})
export class LhcHeaderComponent implements OnInit {

  // 游戏菜单显示
  public game_menu_visible: boolean = false;
  // 游戏菜单
  public ssc_game_list: Array<any>;
  public x115_game_list: Array<any>;
  public k3_game_list: Array<any>;
  public pk10_game_list: Array<any>;
  public dp_game_list: Array<any>;
  public lhc_game_list: Array<any>;
  public pcdd_game_list: Array<any>;

  // 游戏信息
  public game_info: any;
  public game_info_sub: Subscription;

  // 游戏ID
  public game_id: number;
  public logo_url: string = `https://static.012023.vip/game-gold.png`;
  // 游戏名称
  public game_name: string;
  // 当前奖期
  public current_issue: string;
  // 最近开奖奖期
  public lottery_issue: string;
  public last_issue: string;
  // 最近开奖号码
  public last_lottery: Array<string> = [];
  // 开奖信息计时器
  public lottery_timer: any;
  // 时间
  public h1: string = '-';
  public h2: string = '-';
  public m1: string = '-';
  public m2: string = '-';
  public s1: string = '-';
  public s2: string = '-';
  public game_timer: any;

  @Output()
  public emit_deadline: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public betInfoService: BetInfoService,
    public api: ApiService,
    private message: NzMessageService,
    public router: Router
  ) { }

  ngOnInit() {
    this.game_info_sub = this.betInfoService.get_cur_game_info_handler().subscribe(data => {
      if (data) {
        if (this.game_timer) {
          clearInterval(this.game_timer);
        }
        // 获取游戏信息
        this.init_game_header_info_handler(data);
      }

      this.api.get_request_game_list_info().subscribe(data => {
        if (data) {
          Utils.game_list = data['game_list'];
          this.ssc_game_list = Utils.game_list[0];
          this.x115_game_list = Utils.game_list[1];
          this.k3_game_list = Utils.game_list[2];
          this.pk10_game_list = Utils.game_list[3];
          this.dp_game_list = Utils.game_list[4];
          this.lhc_game_list = Utils.game_list[5];
          this.pcdd_game_list = Utils.game_list[6];
        }
      });
    });
  }

  ngOnDestroy() {
    clearInterval(this.game_timer);

    this.game_info_sub.unsubscribe();
  }

  /**
   * 初始化 游戏信息
   *
   * @param {*} info
   * @memberof LhcHeaderComponent
   */
  public init_game_header_info_handler(info: any) {
    // 游戏ID
    this.game_id = info['game_id'];
    this.logo_url = `https://static.012023.vip/game-${this.game_id}.png`;
    // 游戏名称
    this.game_name = info['game_name'];
    // 当前奖期
    this.current_issue = info['current_issue'];

    // 开奖信息
    if (info['last_lottery'].length > 0) {
      this.last_issue = info['last_issue'];
      this.lottery_issue = info['last_issue'];
      info['last_lottery'].forEach((element, index) => {
        this.last_lottery[index] = element;
      });
    } else {
      this.last_issue = info['last_issue'];
      this.lottery_issue = info['history_lottery'][0]['number'];
      info['history_lottery'][0]['lottery'].forEach((element, index) => {
        this.last_lottery[index] = element;
      });

      clearTimeout(this.lottery_timer);
      this.lottery_timer = setTimeout(() => {
        this.get_win_number_handler();
      }, 20 * 1000);
    }

    // 倒计时
    let curr_issue_deadline: number = info['current_prize_time'] - info['current_time'];
    this.updata_left_time(curr_issue_deadline);

    this.game_timer = setInterval(() => {
      curr_issue_deadline -= 1;
      if (curr_issue_deadline >= 0) {
        this.updata_left_time(curr_issue_deadline);
        this.betInfoService.set_count_down_info_handler(curr_issue_deadline);
      } else {
        clearInterval(this.game_timer);
        this.emit_deadline.emit(true);
      }
    }, 1000);
  }

  /**
   * 更新倒计时
   *
   * @private
   * @param {number} num
   * @memberof LhcHeaderComponent
   */
  private updata_left_time(num: number) {
    let deadline: string = Utils.seconds_formate_timer(num);
    let arr = deadline.split('');
    this.h1 = arr[0];
    this.h2 = arr[1];
    this.m1 = arr[2];
    this.m2 = arr[3];
    this.s1 = arr[4];
    this.s2 = arr[5];
  }

  /**
   * 获取开奖号码
   *
   * @private
   * @memberof LhcHeaderComponent
   */
  private get_win_number_handler() {
    this.api.get_winnumber_info(this.game_id, this.last_issue).subscribe(data => {
      if (data && data['isSuccess']) {
        let win_info = data['data'];
        if (win_info['lottery'] === '') {
          this.lottery_timer = setTimeout(() => {
            this.get_win_number_handler();
          }, 20 * 1000);
        } else {
          this.lottery_issue = this.last_issue;
          win_info['lottery'].split('').forEach((element, index) => {
            this.last_lottery[index] = element;
          });
        }
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 显示游戏菜单
   *
   * @memberof LhcHeaderComponent
   */
  public show_game_menu_handler() {
    this.game_menu_visible = true;
  }

  /**
   * 隐藏游戏菜单
   *
   * @memberof LhcHeaderComponent
   */
  public hide_game_menu_handler() {
    this.game_menu_visible = false;
  }

  /**
   * 前往开奖走势页面
   *
   * @memberof LhcHeaderComponent
   */
  public goto_lottery_trend_handler() {
    this.router.navigate(['/trend', { 'id': this.game_id }]);
  }

  /**
  * 打开游戏记录
  *
  * @memberof LhcHeaderComponent
  */
  public open_game_record_handler() {
    this.betInfoService.set_open_game_record_info(true);
  }


}
