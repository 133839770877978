import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscLonghuhe'
})
export class SscLonghuhePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // 龙虎和形态
    let form: string;

    let b1;
    let b2;

    switch (args) {
      case 'wanqian':
        b1 = value[0];
        b2 = value[1];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'wanbai':
        b1 = value[0];
        b2 = value[2];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'wanshi':
        b1 = value[0];
        b2 = value[3];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'wange':
        b1 = value[0];
        b2 = value[4];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'qianbai':
        b1 = value[1];
        b2 = value[2];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'qianshi':
        b1 = value[1];
        b2 = value[3];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'qiange':
        b1 = value[1];
        b2 = value[4];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'baishi':
        b1 = value[2];
        b2 = value[3];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'baige':
        b1 = value[2];
        b2 = value[4];
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'shige':
        b1 = value[3];
        b2 = value[4];
        form = this.calculation_form_handler(b1, b2);
        break;
      default: break;
    }
    return form;
  }

  private calculation_form_handler(b1, b2): string {
    let str: string;
    if (b1 > b2) {
      str = '龙';
    }

    if (b1 === b2) {
      str = '和';
    }

    if (b1 < b2) {
      str = '虎';
    }
    return str;
  }

}
