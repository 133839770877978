import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyMode'
})
export class MoneyModePipe implements PipeTransform {

  transform(value: any): any {
    let unit: string;

    switch (value) {
      case 1:
        unit = '模式·元';
        break;
      case 0.1:
        unit = '模式·角';
        break;
      case 0.01:
        unit = '模式·分';
        break;
      case 0.001:
        unit = '模式·厘';
        break;
      default: break;
    }

    return unit;
  }

}
