import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'app/providers/api/api.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-message-announcement',
  templateUrl: './message-announcement.component.html',
  styleUrls: ['./message-announcement.component.css']
})
export class MessageAnnouncementComponent implements OnInit {
  // 公告数据
  public announcement_data: Array<any> = [];

  // 公告详情
  public current_announcement: any = {};

  // token
  public token: string;

  // 加载状态
  public is_loading: boolean = false;



  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.get_platform_announcement_handler();

    }
  }

  /**
   *获取数据
   *
   * @memberof MessageAnnouncementComponent
   */
  public get_platform_announcement_handler() {
    this.is_loading = true;
    this.api.get_platform_announcement_info(this.token).subscribe(data => {
      this.is_loading = false;
      if (data && data['isSuccess']) {
        this.announcement_data = data['data']['data'];
        this.announcement_data.forEach((item) => {
          item['content'] = this.sanitizer.bypassSecurityTrustHtml(item['content']);
        });
        this.current_announcement = data['data']['data'][0];

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

  /**
   *点击切换公告内容
   *
   * @param {*} data
   * @memberof MessageAnnouncementComponent
   */
  public get_detail_handler(data: any) {
    this.current_announcement = data;
    
    this.get_platform_announcement_read(data.id);
  }

  /**
 *已阅读状态
 *
 * @memberof MessageLetterComponent
 */
  public get_platform_announcement_read(id: number) {
    this.api.get_platform_announcement_read_info(this.token, id).subscribe(data => {
      
    });
  }
}

