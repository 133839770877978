import { Subscription } from 'rxjs';
import { BetInfoService } from '../providers/bet-info/bet-info.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Utils } from './util';

export class GameDanShiBase {
    // 输入内容
    public input_content: string = '';
    public clear_content_sub: Subscription;
    // 注单切分
    public filtration: RegExp = /[；|;]+|[\n\r]+|[,|，]+/g;
    public checkFont: RegExp = /[\u4E00-\u9FA5A-Za-z]+/g;
    public regStr: RegExp = /[^0-9]+/g;
    public regStr2: RegExp = /[^0-9|]+/g;
    // 格式化后的注单数组
    public bet_arr: Array<any> = [];

    // 文件拖入
    public is_dragenter: Boolean = false;

    // 游戏ID
    public game_id: number;
    public game_info_sub: Subscription;
    // 游戏玩法信息
    public play_info: String = '';
    public play_info_sub: Subscription;
    // 奖金概率
    public prize_probability: any;
    // 奖金组
    public prize_group: number;
    public prize_group_sub: Subscription;
    // 模式
    public money_unit: number;
    public money_unit_sub: Subscription;
    public money_mode_sub: Subscription;

    constructor(
        public betInfoService: BetInfoService,
        public msgService: NzMessageService
    ) {

    }

    ngAfterContentInit() {
        // 游戏信息
        this.game_info_sub = this.betInfoService.get_cur_game_info_handler().subscribe(data => {
            if (data) {
                this.game_id = data.game_id;
                this.calculation_play_prize_handle();
            }
        });

        // 玩法监听
        this.play_info_sub = this.betInfoService.get_play_info_handler().subscribe(data => {
            if (data) {
                this.play_info = data;
                this.prize_probability = data['aProbilities'];
            }
        });

        // 奖金组
        this.prize_group_sub = this.betInfoService.get_game_prize_group_info_handler().subscribe(data => {
            if (data) {
                this.prize_group = Number(data);
                if (this.prize_probability) {
                    this.calculation_play_prize_handle();
                }
            }
        });

        // 模式
        this.money_unit_sub = this.betInfoService.get_money_unit_info_handler().subscribe(data => {
            if (data) {
                this.money_unit = Number(data);
                this.calculation_play_prize_handle();
            }
        });

        // 两/一元模式
        this.money_mode_sub = this.betInfoService.get_money_mode_info_handler().subscribe(data => {
            this.calculation_play_prize_handle();
        });

        // 加入购彩栏，清空内容
        this.clear_content_sub = this.betInfoService.get_clear_balls_handler().subscribe(data => {
            if (data && data === 'clear') {
                this.clear_input_content_handler();
            }
        });
    }

    ngOnDestroy() {
        this.game_info_sub.unsubscribe();

        this.betInfoService.set_clear_balls_handler(null);
        this.clear_content_sub.unsubscribe();

        this.play_info_sub.unsubscribe();
        this.prize_group_sub.unsubscribe();
        this.money_unit_sub.unsubscribe();
        this.money_mode_sub.unsubscribe();
    }

    /**
     * 阶乘计算公式
     *
     * @param {number} n
     * @returns
     * @memberof GameDanShiBase
     */
    public fac(n: number) {
        return n > 1 ? n * this.fac(n - 1) : 1;
    }

    /**
     * 奖金计算
     *
     * @memberof GameDanShiBase
     */
    public calculation_play_prize_handle() {
        let len;
        let rule_arr;
        let probability_arr;
        let prize_arr;
        let prize_str = '';
        let prize;

        if (this.prize_probability && this.prize_group) {
            len = this.prize_probability['level'].length;
            rule_arr = this.prize_probability['rule'];

            if (rule_arr[0] === '') {
                probability_arr = this.prize_probability['probability'].sort();
            } else {
                probability_arr = this.prize_probability['probability'];
            }

            prize_arr = [];
            for (let i = 0; i < len; i++) {
                prize = this.prize_formula(Number(probability_arr[i]));
                prize_arr.push(prize);
            }

            if (rule_arr[0] === '') {
                prize_str = prize_arr.join(' - ');
            }
        }

        setTimeout(() => {
            this.betInfoService.set_prize_info_handler(prize_str);
        });
    }

    /**
     * 计算奖金值
     *
     * @param {number} p
     * @returns {string}
     * @memberof GameDanShiBase
     */
    public prize_formula(p: number): string {
        let n;
        let prize;
        let str;

        if (this.prize_group !== undefined) {
            Utils.game_type_array.forEach((item, index) => {
                if (item.indexOf(this.game_id) !== -1) {
                    n = index;
                }
            });

            if (n === 1) {
                prize = (this.prize_group - Utils.adt_val_11x5) / 1980 * Utils.price * this.money_unit * (1 / p);
            } else if (n === 4 || n === 5) {
                prize = (this.prize_group - Utils.adt_val_dp) / 2000 * Utils.price * this.money_unit * (1 / p);
            } else {
                prize = (this.prize_group) / 2000 * Utils.price * this.money_unit * (1 / p);
            }

            str = this.fmoney(prize, 4);
        }

        return str;
    }

    /**
     * 格式化金钱
     *
     * @param {*} s
     * @param {*} n
     * @returns
     * @memberof GameDanShiBase
     */
    public fmoney(s, n) {
        /*
         * 参数说明：
         * s：要格式化的数字
         * n：保留几位小数
         * */
        let l;
        let r;
        let t;

        n = n > 0 && n <= 20 ? n : 2;
        s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';

        l = s.split('.')[0].split('').reverse();
        r = s.split('.')[1];
        t = '';

        for (let i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '');
        }

        return t.split('').reverse().join('') + '.' + r;
    }

    /**
     * 整理注单
     *
     * @param {string} bets_str
     * @returns {Array<any>}
     * @memberof GameDanShiBase
     */
    public sort_out_bet_info_handler(bets_str: string): Array<any> {
        let arr;
        let bets: string = bets_str
            .replace(this.filtration, ' ')
            .replace(this.regStr, ' ')
            .trim();

        arr = bets.split(' ');
        return arr;
    }

    /**
     * 清理错误 & 去重
     *
     * @memberof GameDanShiBase
     */
    public fix_error_repeat_bet_info_handler() {

    }

    /**
     * 清空文本操作
     *
     * @memberof GameDanShiBase
     */
    public clear_input_content_handler() {
        this.input_content = '';
        this.bet_arr = [];

        this.msgService.success('清空文本完毕！');

        // 注单信息
        this.betInfoService.set_bet_num_handler(0);
        this.betInfoService.set_bet_info_handler(null);
    }

    /**
     * 上传文件
     *
     * @param {*} e
     * @memberof GameDanShiBase
     */
    public upload_file_handle(e: any) {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            let index = file['name'].lastIndexOf('.');
            let ext = file['name'].substr(index + 1);
            if (ext !== 'txt') {
                this.msgService.warning('上传文件格式有误');
            } else {
                this.read_file_content_handler(file);
            }
        }
    }

    /**
     * 拖入文件
     *
     * @param {*} e
     * @memberof GameDanShiBase
     */
    public drop_handler(e: any) {
        e.preventDefault();
        this.is_dragenter = false;

        if (e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            let index = file['name'].lastIndexOf('.');
            let ext = file['name'].substr(index + 1);
            if (ext !== 'txt') {
                this.msgService.warning('上传文件格式有误');
            } else {
                this.read_file_content_handler(file);
            }
        }
    }

    /**
     * 读取上传 | 拖入文件的内容
     *
     * @param {*} file
     * @memberof GameDanShiBase
     */
    public read_file_content_handler(file) {
        let obj = this;

        let reader = new FileReader();
        reader.readAsText(file, 'utf-8');
        reader.onload = () => {
            obj.input_content = reader.result.toString();
            obj.fix_error_repeat_bet_info_handler();
        };
    }

    /**
     * 文件拖入
     *
     * @param {*} e
     * @memberof GameDanShiBase
     */
    public dragenter_handler(e: any) {
        e.preventDefault();
        this.is_dragenter = true;
    }

    /**
     * 文件拖出
     *
     * @param {*} e
     * @memberof GameDanShiBase
     */
    public dragleave_handler(e: any) {
        e.preventDefault();
        this.is_dragenter = false;
    }
}
