import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-erxing-zuxuan-houer-hezhi',
  templateUrl: './ssc-erxing-zuxuan-houer-hezhi.component.html',
  styleUrls: ['./ssc-erxing-zuxuan-houer-hezhi.component.css']
})
export class SscErxingZuxuanHouerHezhiComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [
        false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false
      ]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9),
        new Ball(10),
        new Ball(11),
        new Ball(12),
        new Ball(13),
        new Ball(14),
        new Ball(15),
        new Ball(16),
        new Ball(17)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球信息
   *
   * @memberof SscErxingZuxuanHouerHezhiComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 计算注单数量
   *
   * @memberof SscErxingZuxuanHouerHezhiComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    let balls_arr = this.balls_str.split('|');

    balls_arr.forEach(val => {
      num += this.mathResult(Number(val), 0, 9);
    });

    this.bet_num = num;
  }

  /**
   * 匹配结果
   *
   * @param {number} sum
   * @param {number} nBegin
   * @param {number} nEnd
   * @returns {number}
   * @memberof SscErxingZuxuanHouerHezhiComponent
   */
  public mathResult(sum: number, nBegin: number, nEnd: number): number {
    let arr = [];
    let _arr = [];
    let x, y;
    let has = {};
    let key = '';

    for (x = nBegin; x <= nEnd; x++) {
      for (y = nBegin; y <= nEnd; y++) {
        if (x + y === sum) {
          _arr = [x, y];
          key = _arr.sort(this.fn).join(',');
          if (!has[key] && x !== y) {
            arr.push([x, y]);
            has[key] = true;
          }
        }
      }
    }

    return arr.length;
  }

  private fn(a, b) {
    return a - b;
  }

  /**
   * 快捷菜单
   *
   * @param {number} r
   * @param {number} n
   * @memberof SscErxingZuxuanHouerHezhiComponent
   */
  public select_fast_option_btn_handler(r: number, n: number) {
    this.fast_option_array[r] = [false, false, false, false, false, false];
    this.fast_option_array[r][n] = true;
    switch (n) {
      case 0:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = true;
        }
        break;
      case 1:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i >= Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 2:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i < Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 3:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 0) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 4:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 1) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      default:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = false;
        }
        break;
    }
    this.update_bet_infor_handler();
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscErxingZuxuanHouerHezhiComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let index = Number(bet_arr[i]) - 1;
      this.btn_status_array[0][index] = true;
    }

    this.update_bet_infor_handler();
  }

}
