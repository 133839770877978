import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-buzhong-bz9',
  templateUrl: './lhc-buzhong-bz9.component.html',
  styleUrls: ['./lhc-buzhong-bz9.component.css']
})
export class LhcBuzhongBz9Component extends GameCreditBase implements OnInit {

  // balls
  public balls_array: Array<any>;

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcBuzhongBz9Component
   */
  public reset_balls_panel_handler() {
    Utils.lhc_balls_info.forEach((item) => {
      item['is_select'] = false;
      item['val'] = null;
    });
    this.balls_array = Utils.lhc_balls_info;
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcBuzhongBz9Component
   */
  public fill_probilities_info_handler() {
    let p = Number(this.prize_probability['probability']);
    let prize = (this.prize_group / 2000) * (1 / p);

    this.balls_array.forEach(item => {
      item['p'] = prize;
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcBuzhongBz9Component
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcBuzhongBz9Component
   */
  public render_bet_value_handler() {
    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcBuzhongBz9Component
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.is_selected_info = this.balls_array.filter(item => (item.is_select));

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      let bet_info = new BetInfo();
      bet_info.way_id = Number(this.play_info['id']);

      let ball_str = [];
      this.is_selected_info.forEach((item: any) => {
        ball_str.push(item.ball);
      });
      bet_info.ball = ball_str.join('|');

      bet_info.num = ball_str.length;
      bet_info.prize_group = Number(this.prize_group);
      let max_m = Math.floor(Utils.dp_max_prize / Number(this.balls_array[0]['p']));
      bet_info.multiple = this.bet_value > max_m ? max_m : this.bet_value;

      ball_arr.push(bet_info);

      if (this.is_selected_info.length >= 9) {
        let n = this.is_selected_info.length;
        let m = 9;
        let num = this.fac(n) / (this.fac(m) * this.fac((n - m)));
        bet_info.num = num;
        this.betInfoService.set_bet_num_handler(num);
      } else {
        this.betInfoService.set_bet_num_handler(0);
      }
      this.betInfoService.set_bet_info_handler(ball_arr);

    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

  /**
   * 阶乘计算公式
   *
   * @param {number} n
   * @returns
   * @memberof LhcBuzhongBz9Component
   */
  public fac(n: number) {
    return n > 1 ? n * this.fac(n - 1) : 1;
  }

}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
