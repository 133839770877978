import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-dipin-panel',
  templateUrl: './dipin-panel.component.html',
  styleUrls: ['./dipin-panel.component.css']
})
export class DipinPanelComponent implements OnInit {

  // 反选状态
  public is_selected: boolean = false;
  // 默认类型下标
  public default_play_type_index: number;
  // 默认玩法类型ID
  public play_type_id: number;
  // 当前玩法信息
  public current_play_info: any;
  // 玩法菜单数据
  @Input()
  public play_menu: Array<any>;
  // 当前玩法ID
  @Input()
  public current_game_play_id: number;
  // 玩法集合
  @Input()
  public game_play_array: Array<any>;
  // 发送当前玩法
  @Output()
  public emit_game_play_id: EventEmitter<any> = new EventEmitter<any>();

  // 奖金信息
  public prize_info: string;
  public prize_info_sub: Subscription;
  // 奖金组
  public prize_group: number;
  public prize_group_sub: Subscription;

  // 反选玩法流
  public selected_play_sub: Subscription;

  public bar_style: any = { color: '#fff' };

  constructor(
    public betInfoService: BetInfoService
  ) {
    setTimeout(() => {
      this.prize_group = Utils.max_prize_group;
      this.betInfoService.set_game_prize_group_info_handler(this.prize_group);
    });
  }

  ngOnInit() {
    this.init_menu_handler();

    setTimeout(() => {
      // 监听奖金组
      this.prize_group_sub = this.betInfoService.get_game_prize_group_info_handler().subscribe(data => {
        if (data) {
          this.prize_group = Number(data);
        }
      });

      // 监听奖金信息
      this.prize_info_sub = this.betInfoService.get_prize_info_handler().subscribe(data => {
        if (data) {
          this.prize_info = data;
        }
      });

      // 反选监听
      this.selected_play_sub = this.betInfoService.get_selected_play_handler().subscribe(data => {
        if (data) {
          this.is_selected = true;
          this.current_game_play_id = data['way_id'];

          for (let i = 0; i < this.game_play_array.length; i++) {
            let play = this.game_play_array[i];
            if (play.id === this.current_game_play_id) {
              this.play_type_id = play['menu_id'];
              this.current_play_info = play;
            }
          }

          for (let i = 0; i < this.play_menu.length; i++) {
            if (this.play_menu[i]['id'] === this.play_type_id) {
              this.switch_selected_play_menu_handler(i, data['ball']);
            }
          }
        }
      });

    });
  }

  ngOnDestroy() {
    this.betInfoService.set_game_prize_group_info_handler(null);
    this.prize_group_sub.unsubscribe();

    this.betInfoService.set_prize_info_handler(null);
    this.prize_info_sub.unsubscribe();

    this.betInfoService.set_selected_play_handler(null);
    this.selected_play_sub.unsubscribe();
  }

  /**
   * 指向默认菜单
   *
   * @memberof DipinPanelComponent
   */
  public init_menu_handler() {
    for (let i = 0; i < this.game_play_array.length; i++) {
      let play = this.game_play_array[i];
      if (play.id === this.current_game_play_id) {
        this.play_type_id = play['menu_id'];
        this.current_play_info = play;
      }
    }

    for (let i = 0; i < this.play_menu.length; i++) {
      if (this.play_menu[i]['id'] === this.play_type_id) {
        this.default_play_type_index = i;
      }
    }
  }

  /**
   * 切换玩法类型
   *
   * @param {*} e
   * @memberof DipinPanelComponent
   */
  public switch_play_menu_handler(e: any) {
    this.default_play_type_index = e;
    this.current_game_play_id = Number(this.play_menu[e]['children'][0]['children'][0]['id']);
    this.switch_game_play_handler(this.play_menu[e]['children'][0]['children'][0]);
  }

  /**
   * 购彩篮注单切换
   *
   * @param {*} e
   * @param {*} ball
   * @memberof DipinPanelComponent
   */
  public switch_selected_play_menu_handler(e: any, ball: any) {
    this.default_play_type_index = e;

    let play;
    for (let i = 0; i < this.game_play_array.length; i++) {
      let p = this.game_play_array[i];
      if (p.id === this.current_game_play_id) {
        play = p;
      }
    }
    this.betInfoService.set_play_info_handler(play);
    this.emit_game_play_id.emit(play);

    setTimeout(() => {
      this.betInfoService.set_bets_handler(ball);
    });
  }

  /**
   * 切换玩法操作
   *
   * @param {*} play
   * @memberof DipinPanelComponent
   */
  public switch_game_play_handler(play: any) {
    this.betInfoService.set_play_info_handler(play);
    this.current_play_info = play;
    this.current_game_play_id = play.id;
    this.emit_game_play_id.emit(play);
  }

}
