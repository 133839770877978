import { Component, OnInit, Input } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-zhengte6',
  templateUrl: './lhc-zhengte6.component.html',
  styleUrls: ['./lhc-zhengte6.component.css']
})
export class LhcZhengte6Component extends GameCreditBase implements OnInit {

  @Input()
  public current_zodiac: number;

  // balls
  public balls_array: Array<any>;

  // 快捷菜单
  public selected_balls: Array<any> = [];
  public selected_menus: Array<any> = [];
  public fast_menu: Array<any> = [
    [
      { name: '单码', is_select: false, balls: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49] },
      { name: '双码', is_select: false, balls: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48] },
      { name: '大码', is_select: false, balls: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49] },
      { name: '小码', is_select: false, balls: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] },
      { name: '小单', is_select: false, balls: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23] },
      { name: '小双', is_select: false, balls: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24] },
      { name: '大单', is_select: false, balls: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49] },
      { name: '大双', is_select: false, balls: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48] },
      { name: '合单', is_select: false, balls: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49] },
      { name: '合双', is_select: false, balls: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48] },
      { name: '合大', is_select: false, balls: [7, 8, 9, 16, 17, 18, 19, 25, 26, 27, 28, 29, 34, 35, 36, 37, 38, 39, 43, 44, 45, 46, 47, 48, 49] },
      { name: '合小', is_select: false, balls: [1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 20, 21, 22, 23, 24, 30, 31, 32, 33, 40, 41, 42] }
    ],
    [
      { name: '鼠', is_select: false, balls: [] },
      { name: '牛', is_select: false, balls: [] },
      { name: '虎', is_select: false, balls: [] },
      { name: '兔', is_select: false, balls: [] },
      { name: '龙', is_select: false, balls: [] },
      { name: '蛇', is_select: false, balls: [] },
      { name: '马', is_select: false, balls: [] },
      { name: '羊', is_select: false, balls: [] },
      { name: '猴', is_select: false, balls: [] },
      { name: '鸡', is_select: false, balls: [] },
      { name: '狗', is_select: false, balls: [] },
      { name: '猪', is_select: false, balls: [] }
    ],
    [
      { name: '0头', is_select: false, balls: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { name: '1头', is_select: false, balls: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19] },
      { name: '2头', is_select: false, balls: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29] },
      { name: '3头', is_select: false, balls: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39] },
      { name: '4头', is_select: false, balls: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49] },
      { name: '0尾', is_select: false, balls: [10, 20, 30, 40] },
      { name: '1尾', is_select: false, balls: [1, 11, 21, 31, 41] },
      { name: '2尾', is_select: false, balls: [2, 12, 22, 32, 42] },
      { name: '3尾', is_select: false, balls: [3, 13, 23, 33, 43] },
      { name: '4尾', is_select: false, balls: [4, 14, 24, 34, 44] },
      { name: '5尾', is_select: false, balls: [5, 15, 25, 35, 45] },
      { name: '6尾', is_select: false, balls: [6, 16, 26, 36, 46] },
      { name: '7尾', is_select: false, balls: [7, 17, 27, 37, 47] },
      { name: '8尾', is_select: false, balls: [8, 18, 28, 38, 48] },
      { name: '9尾', is_select: false, balls: [9, 19, 29, 39, 49] }
    ],
    [
      { name: '红', is_select: false, balls: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46] },
      { name: '红单', is_select: false, balls: [1, 7, 13, 19, 23, 29, 35, 45] },
      { name: '红双', is_select: false, balls: [2, 8, 12, 18, 24, 30, 34, 40, 46] },
      { name: '红大', is_select: false, balls: [29, 30, 34, 35, 40, 45, 46] },
      { name: '红小', is_select: false, balls: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24] },
      { name: '蓝', is_select: false, balls: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48] },
      { name: '蓝单', is_select: false, balls: [3, 9, 15, 25, 31, 37, 41, 47] },
      { name: '蓝双', is_select: false, balls: [4, 10, 14, 20, 26, 36, 42, 48] },
      { name: '蓝大', is_select: false, balls: [25, 26, 31, 36, 37, 41, 42, 47, 48] },
      { name: '蓝小', is_select: false, balls: [3, 4, 9, 10, 14, 15, 20] },
      { name: '绿', is_select: false, balls: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49] },
      { name: '绿单', is_select: false, balls: [5, 11, 17, 21, 27, 33, 39, 43] },
      { name: '绿双', is_select: false, balls: [6, 16, 22, 28, 32, 38, 44] },
      { name: '绿大', is_select: false, balls: [27, 28, 32, 33, 38, 39, 43, 44] },
      { name: '绿小', is_select: false, balls: [5, 6, 11, 16, 17, 21, 22] }
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.init_zodiac_balls_handler();
    this.reset_balls_panel_handler();
  }

  /**
   * 初始化生肖数组
   *
   * @memberof LhcZhengte6Component
   */
  public init_zodiac_balls_handler() {
    let index = 11 - (this.current_zodiac - 2) % 12;
    this.fast_menu[1][index]['balls'] = [1, 13, 25, 37, 49];
    for (let i = 1; i < 12; i++) {
      let n = ((12 + index - i) % 12);
      let b1 = this.fast_menu[1][index]['balls'][0] + i;
      let b2 = this.fast_menu[1][index]['balls'][1] + i;
      let b3 = this.fast_menu[1][index]['balls'][2] + i;
      let b4 = this.fast_menu[1][index]['balls'][3] + i;
      this.fast_menu[1][n]['balls'] = [b1, b2, b3, b4];
    }
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcZhengte6Component
   */
  public reset_balls_panel_handler() {
    Utils.lhc_balls_info.forEach((item) => {
      item['is_select'] = false;
      item['val'] = null;
    });
    this.balls_array = Utils.lhc_balls_info;
    this.reset_lhc_fast_menu_handler();
  }

  /**
   * 重置快捷菜单
   *
   * @memberof LhcZhengte6Component
   */
  public reset_lhc_fast_menu_handler() {
    this.selected_balls = [];
    this.selected_menus = [];

    this.fast_menu.forEach(group => {
      group.forEach(menu => {
        menu.is_select = false;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcZhengte6Component
   */
  public fill_probilities_info_handler() {
    let p = Number(this.prize_probability['probability']);
    let prize = (this.prize_group / 2000) * (1 / p);

    this.balls_array.forEach(item => {
      item['p'] = prize;
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcZhengte6Component
   */
  public switch_ball_status_handler(ball: any) {
    let ball_num = Number(ball['ball']);
    let item = this.balls_array[(ball_num - 1)];
    item.is_select = !item.is_select;
    if (!item.is_select) {
      item.val = null;
    } else {
      item.val = this.bet_value;
    }

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @memberof LhcZhengte6Component
   */
  public selecte_ball_handler(item: any) {
    if (!item.is_select) {
      item.is_select = true;
      item.val = this.bet_value;

      // balls内容整理
      this.update_bet_balls_info();
    }
  }

  /**
   * 失去焦点
   *
   * @param {*} item
   * @memberof LhcZhengte6Component
   */
  public change_val_handler(item: any) {
    if (item.is_select && !item.val) {
      item.val = this.bet_value;
    }

    let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
    item.val = item.val > max_m ? max_m : item.val;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 选择快捷菜单
   *
   * @param {number} x
   * @param {number} y
   * @memberof LhcZhengte6Component
   */
  public switch_fast_menu_cell_handler(x: number, y: number) {
    let menu = this.fast_menu[x][y];
    let menu_position_str = [x, y].join(',');

    if (!menu['is_select']) {
      menu['is_select'] = true;
      this.selected_menus.push(menu_position_str);

      this.selected_balls = [];
      this.selected_menus.forEach(item => {
        let position = item.split(',');
        let x = Number(position[0]);
        let y = Number(position[1]);
        let menu_balls = this.fast_menu[x][y]['balls'];
        this.selected_balls = Array.from(new Set(this.selected_balls.concat(menu_balls))).sort(this.sort_by_num);
      });

      // 渲染选择球
      this.balls_array.forEach((item) => {
        this.selected_balls.forEach(num => {
          if (Number(item['ball']) === num) {
            item['is_select'] = true;
            item['val'] = this.bet_value;
          }
        });
      });

      // balls内容整理
      this.update_bet_balls_info();
    } else {
      menu['is_select'] = false;
      let index = this.selected_menus.indexOf(menu_position_str);
      this.selected_menus.splice(index, 1);

      // 渲染选择球
      this.balls_array.forEach((item) => {
        this.fast_menu[x][y]['balls'].forEach(num => {
          if (Number(item['ball']) === num) {
            item['is_select'] = false;
            item['val'] = null;
          }
        });
      });

      // balls内容整理
      this.update_bet_balls_info();
    }

  }

  /**
   * 从小到大排序
   *
   * @private
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof LhcZhengte6Component
   */
  private sort_by_num(a, b) {
    return Number(a) - Number(b);
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcZhengte6Component
   */
  public render_bet_value_handler() {
    this.balls_array.forEach((item) => {
      if (item.is_select) {
        item.val = this.bet_value;
      }
    });

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcZhengte6Component
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.is_selected_info = this.balls_array.filter(item => (item.is_select && item.val !== null));

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      this.is_selected_info.forEach(item => {
        let bet_info = new BetInfo();
        bet_info.way_id = Number(this.play_info['id']);
        bet_info.ball = item.ball;
        bet_info.num = 1;
        bet_info.prize_group = Number(this.prize_group);

        let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
        item.val = item.val > max_m ? max_m : item.val;
        bet_info.multiple = Number(item.val);

        ball_arr.push(bet_info);
      });

      this.betInfoService.set_bet_info_handler(ball_arr);
      this.betInfoService.set_bet_num_handler(this.is_selected_info.length);
    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
