export class Ball {
    /** 球号 */
    private ball_num: any;

    constructor(ball_num) {
        this.ball_num = ball_num;
    }

    public get_ball_num() {
        return this.ball_num;
    }
}
