import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-renxuan-rx2-zuxuan-hezhi',
  templateUrl: './ssc-renxuan-rx2-zuxuan-hezhi.component.html',
  styleUrls: ['./ssc-renxuan-rx2-zuxuan-hezhi.component.css']
})
export class SscRenxuanRx2ZuxuanHezhiComponent extends GameBase implements OnInit {

  public position_data: Array<any>;

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 位置选择 */
    this.position_data = [
      { label: '万位', value: '0', checked: false },
      { label: '千位', value: '1', checked: false },
      { label: '百位', value: '2', checked: false },
      { label: '十位', value: '3', checked: true },
      { label: '个位', value: '4', checked: true }
    ];
    this.betInfoService.set_bet_position_info_handler([0, 0, 0, 1, 1]);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [
        false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false
      ]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9),
        new Ball(10),
        new Ball(11),
        new Ball(12),
        new Ball(13),
        new Ball(14),
        new Ball(15),
        new Ball(16),
        new Ball(17)
      ]
    ];

  }

  ngOnInit() {
  }

  /**
   * 快捷菜单选择球
   *
   * @param {number} r
   * @param {number} n
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public select_fast_option_btn_handler(r: number, n: number) {
    this.fast_option_array[r] = [false, false, false, false, false, false];
    this.fast_option_array[r][n] = true;
    switch (n) {
      case 0:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = true;
        }
        break;
      case 1:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i >= Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 2:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i < Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 3:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 0) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      case 4:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 1) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }
        }
        break;
      default:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = false;
        }
        break;
    }
    this.update_bet_infor_handler();
  }

  /**
   * 更新选球信息
   *
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 计算注单数量
   *
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    let balls_arr = this.balls_str.split('|');

    balls_arr.forEach(val => {
      num += this.mathResult(Number(val), 0, 9);
    });

    let position_num = this.position_data.filter(item => item.checked).length;
    let comb_num = this.fac(position_num) / (this.fac(2) * this.fac((position_num - 2)));

    this.bet_num = num * comb_num;
  }

  /**
   * 匹配结果
   *
   * @param {number} sum
   * @param {number} nBegin
   * @param {number} nEnd
   * @returns {number}
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public mathResult(sum: number, nBegin: number, nEnd: number): number {
    let arr = [];
    let x, y;
    let checkArray = [];

    for (x = nBegin; x <= nEnd; x++) {
      for (y = nBegin; y <= nEnd; y++) {
        if (x + y === sum && this.arrIndexOf(x, [x, y]) !== 2) {
          let postArray = [x, y].sort(function (a, b) {
            return a - b;
          });

          if (this.checkResult(postArray.join(''), checkArray)) {
            checkArray.push(postArray);
            arr.push([x, y]);
          }
        }
      }
    }

    return arr.length;
  }

  // 检查数组存在某数
  private arrIndexOf(value, arr) {
    let r = 0;
    for (let s = 0; s < arr.length; s++) {
      if (arr[s] === value) {
        r += 1;
      }
    }
    return r || -1;
  }

  // 检测结果重复
  private checkResult(data, array) {
    // 检查重复
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].join('') === data) {
        return false;
      }
    }
    return true;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let index = Number(bet_arr[i]) - 1;
      this.btn_status_array[0][index] = true;
    }

    this.update_bet_infor_handler();
  }

  /**
   * 位置选择
   *
   * @param {*} val
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public update_position_handler(val: any) {
    let arr = this.position_data.filter(item => item.checked);

    if (arr.length < 2) {
      let index = Number(val);
      setTimeout(() => {
        this.position_data[index].checked = true;
        this.update_bet_infor_handler();
      });
    } else {
      let p_arr = [0, 0, 0, 0, 0];
      this.position_data.forEach((item, index) => {
        if (item.checked) {
          p_arr[index] = 1;
        }
      });
      this.betInfoService.set_bet_position_info_handler(p_arr);

      this.update_bet_infor_handler();
    }
  }

  /**
   *  反选-位置选择
   *
   * @param {*} data
   * @memberof SscRenxuanRx2ZuxuanHezhiComponent
   */
  public selected_position_render_handler(data: any) {
    data.forEach((item, index) => {
      if (item === 0) {
        this.position_data[index].checked = false;
      }
      if (item === 1) {
        this.position_data[index].checked = true;
      }
    });
  }

}
