import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lottery-chips',
  templateUrl: './lottery-chips.component.html',
  styleUrls: ['./lottery-chips.component.css']
})
export class LotteryChipsComponent implements OnInit {

  @ViewChild('totalChip') totalChip: ElementRef;

  public token: string;
  // 编辑
  public is_edit: boolean = false;
  // 筹码
  public chips_array = [1, 2, 5, 10, 20, 50, 100, 500];
  // 金额
  public total_chip_str: string = '1';
  public total_chip: number = 1;
  // 余额
  public balance: number = 0;
  public balance_sub: Subscription;
  public bet_prize_group: number;
  public max_prize_group: number;
  public min_prize_group: number;
  // 注数
  public bet_num: number = 0;
  public bet_num_sub: Subscription;

  constructor(
    public betInfoService: BetInfoService,
    public api: ApiService,
    private message: NzMessageService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.update_balance_handler();
    }

    if (localStorage.getItem('chip1')) {
      this.chips_array[0] = Number(localStorage.getItem('chip1'));
    }

    if (localStorage.getItem('chip2')) {
      this.chips_array[1] = Number(localStorage.getItem('chip2'));
    }

    if (localStorage.getItem('chip3')) {
      this.chips_array[2] = Number(localStorage.getItem('chip3'));
    }

    if (localStorage.getItem('chip4')) {
      this.chips_array[3] = Number(localStorage.getItem('chip4'));
    }

    if (localStorage.getItem('chip5')) {
      this.chips_array[4] = Number(localStorage.getItem('chip5'));
    }

    if (localStorage.getItem('chip6')) {
      this.chips_array[5] = Number(localStorage.getItem('chip6'));
    }
  }

  ngOnInit() {
    this.api.get_request_base_config_info().subscribe(data => {
      if (data) {
        this.max_prize_group = data['max_bet_prize_group'];
        this.min_prize_group = data['min_bet_prize_group'];
        this.bet_prize_group = this.max_prize_group;
        this.betInfoService.set_credit_prize_group_handler(this.bet_prize_group);
      }
    });

    this.bet_num_sub = this.betInfoService.get_bet_num_handler().subscribe(data => {
      if (data) {
        this.bet_num = Number(data);
      } else {
        this.bet_num = 0;
      }
    });

    this.balance_sub = this.betInfoService.get_credit_update_balance_handler().subscribe(data => {
      if (data) {
        this.update_balance_handler();
      }
    });
  }

  ngOnDestroy() {
    // 取消 订阅注数
    this.betInfoService.set_bet_num_handler(0);
    this.bet_num_sub.unsubscribe();
    // 取消 订阅刷新余额
    this.betInfoService.set_credit_update_balance_handler(null);
    this.balance_sub.unsubscribe();
  }

  /**
   * 切换编辑金额开关状态
   *
   * @memberof LotteryChipsComponent
   */
  public switch_edit_status_handler() {
    this.is_edit = !this.is_edit;
  }

  /**
   * 保存筹码值
   *
   * @param {*} e
   * @param {number} n
   * @memberof LotteryChipsComponent
   */
  public save_new_chips_handler(e: any, n: number) {
    let name = `chip${(n + 1)}`;
    localStorage.setItem(name, e);
  }

  /**
   * 重置筹码
   *
   * @memberof LotteryChipsComponent
   */
  public reset_chips_handler() {
    this.chips_array = [1, 2, 5, 10, 20, 50, 100, 500];

    this.chips_array.forEach((item, index) => {
      let name = `chip${(index + 1)}`;
      localStorage.setItem(name, item.toString());
    });
  }

  /**
   * 增加筹码
   *
   * @param {*} val
   * @memberof LotteryChipsComponent
   */
  public add_chip_handler(val: any) {
    this.total_chip = this.total_chip + val;
    this.total_chip_str = this.total_chip.toString();

    setTimeout(() => {
      this.betInfoService.set_credit_bet_val_handler(this.total_chip);
    });
  }

  /**
   * 修改投注金额
   *
   * @memberof LotteryChipsComponent
   */
  public change_total_chip_handler(value: string) {
    if (value === '') {
      this.total_chip = 1;
      this.betInfoService.set_credit_bet_val_handler('1');
    }

    const reg = /^[1-9][0-9]*$/;
    if (!reg.test(value)) {
      if (value === '0') {
        this.totalChip.nativeElement.value = '';
      } else {
        let val_str = value.replace(/[^0-9]/ig, '');
        this.totalChip.nativeElement.value = val_str;
      }
    } else {
      this.totalChip.nativeElement.value = this.total_chip_str;
      this.total_chip = Number(this.total_chip_str);
      this.betInfoService.set_credit_bet_val_handler(this.total_chip);
    }
  }

  /**
   * 清除选号操作
   *
   * @memberof LotteryChipsComponent
   */
  public clear_all_selected_balls_handler() {
    this.betInfoService.set_bet_info_handler(null);
    this.betInfoService.set_bet_num_handler(0);
    this.betInfoService.set_clear_credit_balls_handler('clear');
  }

  /**
   * 更新余额
   *
   * @memberof LotteryChipsComponent
   */
  public update_balance_handler() {
    this.api.get_balance_info(this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        this.balance = Number(data['data']['available']);
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 确认投注
   *
   * @memberof LotteryChipsComponent
   */
  public submit_bet_handler() {
    this.betInfoService.set_confirm_bet_info(true);
  }

}
