import { Component } from '@angular/core';
import { Utils } from './common/util';
import { ApiService } from './providers/api/api.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public is_public: boolean = false;
  public full_screen: boolean = true;

  constructor(
    public api: ApiService,
    public router: Router
  ) {
    this.api.get_request_base_config_info().subscribe(data => {
      if (data) {
        Utils.is_public = data['lottery_mode'] === 'public' ? true : false;
        this.is_public = Utils.is_public;
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.get_current_menu_by_url(event['url']);
    });
  }

  /**
   * 通过url获取当前菜单
   *
   * @param {string} url
   * @memberof AppComponent
   */
  public get_current_menu_by_url(url: string) {
    let current_url = url.split('/')[1];

    if (!current_url || current_url.indexOf('login-page') !== -1) {
      this.full_screen = true;
    } else {
      this.full_screen = false;
    }
  }

}
