import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-ssc-credit-longhudou',
  templateUrl: './ssc-credit-longhudou.component.html',
  styleUrls: ['./ssc-credit-longhudou.component.css']
})
export class SscCreditLonghudouComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    [
      { name: `万千 第一球vs第二球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `万百 第一球vs第三球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `万十 第一球vs第四球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `万个 第一球vs第五球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
    ],
    [
      { name: `千百 第二球vs第三球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `千十 第二球vs第四球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `千个 第二球vs第五球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
    ],
    [
      { name: `百十 第三球vs第四球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
      { name: `百个 第三球vs第五球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
    ],
    [
      { name: `十个 第四球vs第五球`, p_arr: [0, 0, 0], val_arr: [null, null, null], is_select_arr: [false, false, false] },
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof SscCreditLonghudouComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(group => {
        for (let i = 0; i <= 2; i++) {
          group.val_arr[i] = null;
          group.is_select_arr[i] = false;
        }
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof SscCreditLonghudouComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach(item => {
      item.forEach(group => {
        group.p_arr[0] = (this.prize_group / 2000) * (1 / p_arr[0]);
        group.p_arr[1] = (this.prize_group / 2000) * (1 / p_arr[1]);
        group.p_arr[2] = (this.prize_group / 2000) * (1 / p_arr[0]);
      });
    });
  }

  /**
   * 切换球的状态
   *
   * @param {any} item
   * @param {number} index
   * @memberof SscCreditLonghudouComponent
   */
  public switch_ball_status_handler(item: any, index: number) {
    item.is_select_arr[index] = !item.is_select_arr[index];
    if (!item.is_select_arr[index]) {
      item.val_arr[index] = null;
    } else {
      item.val_arr[index] = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @param {number} index
   * @memberof SscCreditLonghudouComponent
   */
  public selecte_ball_handler(item: any, index: number) {
    item.is_select_arr[index] = true;
    item.val_arr[index] = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof SscCreditLonghudouComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(group => {
        group.is_select_arr.forEach((s, n) => {
          if (s) {
            group.val_arr[n] = this.bet_value;
          }
        });
      });
    });
  }

}
