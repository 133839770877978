import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playName'
})
export class PlayNamePipe implements PipeTransform {

  transform(value: number): any {
    let play_name: string;

    switch (value) {
      // ===============时时彩===============
      // 五星
      case 68: play_name = '五星·直选复式'; break;
      case 7: play_name = '五星·直选单式'; break;
      case 15: play_name = '五星·直选组合'; break;
      case 32: play_name = '五星·组选120'; break;
      case 31: play_name = '五星·组选60'; break;
      case 30: play_name = '五星·组选30'; break;
      case 29: play_name = '五星·组选20'; break;
      case 28: play_name = '五星·组选10'; break;
      case 27: play_name = '五星·组选5'; break;
      // 四星
      case 67: play_name = '四星·直选复式'; break;
      case 6: play_name = '四星·直选单式'; break;
      case 79: play_name = '四星·直选组合'; break;
      case 26: play_name = '四星·组选24'; break;
      case 25: play_name = '四星·组选12'; break;
      case 24: play_name = '四星·组选6'; break;
      case 23: play_name = '四星·组选4'; break;
      // 前三
      case 65: play_name = '前三·直选复式'; break;
      case 1: play_name = '前三·直选单式'; break;
      case 71: play_name = '前三·直选和值'; break;
      case 60: play_name = '前三·直选跨度'; break;
      case 14: play_name = '前三·直选组合'; break;
      case 16: play_name = '前三·组三'; break;
      case 2: play_name = '前三·组三单式'; break;
      case 17: play_name = '前三·组六'; break;
      case 3: play_name = '前三·组六单式'; break;
      case 13: play_name = '前三·混合组选'; break;
      case 75: play_name = '前三·组选和值'; break;
      case 64: play_name = '前三·包胆'; break;
      case 33: play_name = '前三·和值尾数'; break;
      case 48: play_name = '前三·特殊号码'; break;
      // 中三
      case 142: play_name = '中三·直选复式'; break;
      case 143: play_name = '中三·直选单式'; break;
      case 145: play_name = '中三·直选和值'; break;
      case 147: play_name = '中三·直选跨度'; break;
      case 148: play_name = '中三·直选组合'; break;
      case 150: play_name = '中三·组三'; break;
      case 144: play_name = '中三·组三单式'; break;
      case 152: play_name = '中三·组六'; break;
      case 146: play_name = '中三·组六单式'; break;
      case 154: play_name = '中三·混合组选'; break;
      case 160: play_name = '中三·组选和值'; break;
      case 153: play_name = '中三·包胆'; break;
      case 149: play_name = '中三·和值尾数'; break;
      case 151: play_name = '中三·特殊号码'; break;
      // 后三
      case 69: play_name = '后三·直选复式'; break;
      case 8: play_name = '后三·直选单式'; break;
      case 73: play_name = '后三·直选和值'; break;
      case 62: play_name = '后三·直选跨度'; break;
      case 82: play_name = '后三·直选组合'; break;
      case 49: play_name = '后三·组三'; break;
      case 9: play_name = '后三·组三单式'; break;
      case 50: play_name = '后三·组六'; break;
      case 10: play_name = '后三·组六单式'; break;
      case 81: play_name = '后三·混合组选'; break;
      case 80: play_name = '后三·组选和值'; break;
      case 83: play_name = '后三·包胆'; break;
      case 54: play_name = '后三·和值尾数'; break;
      case 57: play_name = '后三·特殊号码'; break;
      // 二星
      case 70: play_name = '二星·直选·后二复式'; break;
      case 11: play_name = '二星·直选·后二单式'; break;
      case 74: play_name = '二星·直选·后二和值'; break;
      case 63: play_name = '二星·直选·后二跨度'; break;
      case 66: play_name = '二星·直选·前二复式'; break;
      case 4: play_name = '二星·直选·前二单式'; break;
      case 72: play_name = '二星·直选·前二和值'; break;
      case 61: play_name = '二星·直选·前二跨度'; break;
      case 59: play_name = '二星·组选·后二复式'; break;
      case 12: play_name = '二星·组选·后二单式'; break;
      case 77: play_name = '二星·组选·后二和值'; break;
      case 85: play_name = '二星·组选·后二包胆'; break;
      case 20: play_name = '二星·组选·前二复式'; break;
      case 5: play_name = '二星·组选·前二单式'; break;
      case 76: play_name = '二星·组选·前二和值'; break;
      case 84: play_name = '二星·组选·前二包胆'; break;
      // 定位胆
      case 78: play_name = '定位胆·定位胆'; break;
      // 不定位
      case 51: play_name = '不定位·后三一码'; break;
      case 52: play_name = '不定位·后三二码'; break;
      case 18: play_name = '不定位·前三一码'; break;
      case 21: play_name = '不定位·前三二码'; break;
      case 155: play_name = '不定位·中三一码'; break;
      case 156: play_name = '不定位·中三二码'; break;
      case 34: play_name = '不定位·四星一码'; break;
      case 35: play_name = '不定位·四星二码'; break;
      case 36: play_name = '不定位·五星二码'; break;
      case 37: play_name = '不定位·五星三码'; break;
      // 大小单双
      case 58: play_name = '大小单双·后二'; break;
      case 53: play_name = '大小单双·后三'; break;
      case 19: play_name = '大小单双·前二'; break;
      case 22: play_name = '大小单双·前三'; break;
      case 157: play_name = '大小单双·中三'; break;
      // 任选
      case 199: play_name = '任选二·直选复式'; break;
      case 200: play_name = '任选二·直选单式'; break;
      case 201: play_name = '任选二·直选和值'; break;
      case 217: play_name = '任选二·组选复式'; break;
      case 218: play_name = '任选二·组选单式'; break;
      case 219: play_name = '任选二·组选和值'; break;

      case 202: play_name = '任选三·直选复式'; break;
      case 203: play_name = '任选三·直选单式'; break;
      case 204: play_name = '任选三·直选和值'; break;
      case 205: play_name = '任选三·组三复式'; break;
      case 206: play_name = '任选三·组三单式'; break;
      case 207: play_name = '任选三·组六复式'; break;
      case 208: play_name = '任选三·组六单式'; break;
      case 209: play_name = '任选三·混合组选'; break;
      case 210: play_name = '任选三·混合和值'; break;

      case 211: play_name = '任选四·直选复式'; break;
      case 212: play_name = '任选四·直选单式'; break;
      case 213: play_name = '任选四·组选24'; break;
      case 214: play_name = '任选四·组选12'; break;
      case 215: play_name = '任选四·组选6'; break;
      case 216: play_name = '任选四·组选4'; break;
      // 趣味
      case 38: play_name = '趣味·五码·三星'; break;
      case 39: play_name = '趣味·四码·三星'; break;
      case 55: play_name = '趣味·后三·二星'; break;
      case 40: play_name = '趣味·前三·二星'; break;
      case 158: play_name = '趣味·中三·二星'; break;

      case 41: play_name = '区间·五码·三星'; break;
      case 42: play_name = '区间·四码·三星'; break;
      case 56: play_name = '区间·后三·二星'; break;
      case 43: play_name = '区间·前三·二星'; break;
      case 159: play_name = '区间·中三·二星'; break;

      case 44: play_name = '趣味·一帆风顺'; break;
      case 45: play_name = '趣味·好事成双'; break;
      case 46: play_name = '趣味·三星报喜'; break;
      case 47: play_name = '趣味·四季发财'; break;
      // 龙虎
      case 220: play_name = '龙虎·万千'; break;
      case 221: play_name = '龙虎·万百'; break;
      case 222: play_name = '龙虎·万十'; break;
      case 223: play_name = '龙虎·万个'; break;
      case 224: play_name = '龙虎·千百'; break;
      case 225: play_name = '龙虎·千十'; break;
      case 226: play_name = '龙虎·千个'; break;
      case 227: play_name = '龙虎·百十'; break;
      case 228: play_name = '龙虎·百个'; break;
      case 229: play_name = '龙虎·十个'; break;

      // ===============11选5===============
      // 三码
      case 112: play_name = '前三·直选复式'; break;
      case 95: play_name = '前三·直选单式'; break;
      case 108: play_name = '前三·组选复式'; break;
      case 97: play_name = '前三·组选单式'; break;
      case 121: play_name = '前三·组选胆拖'; break;
      // 二码
      case 111: play_name = '前二·直选复式'; break;
      case 94: play_name = '前二·直选单式'; break;
      case 107: play_name = '前二·组选复式'; break;
      case 96: play_name = '前二·组选单式'; break;
      case 120: play_name = '前二·组选胆拖'; break;
      // 不定位
      case 122: play_name = '前三·不定位'; break;
      // 趣味型
      case 109: play_name = '定单双'; break;
      case 110: play_name = '猜中位'; break;
      // 定位胆
      case 106: play_name = '定位胆'; break;
      // 任选复式
      case 98: play_name = '任选复式·一中一'; break;
      case 99: play_name = '任选复式·二中二'; break;
      case 100: play_name = '任选复式·三中三'; break;
      case 101: play_name = '任选复式·四中四'; break;
      case 102: play_name = '任选复式·五中五'; break;
      case 103: play_name = '任选复式·六中五'; break;
      case 104: play_name = '任选复式·七中五'; break;
      case 105: play_name = '任选复式·八中五'; break;
      // 任选单式
      case 86: play_name = '任选单式·一中一'; break;
      case 87: play_name = '任选单式·二中二'; break;
      case 88: play_name = '任选单式·三中三'; break;
      case 89: play_name = '任选单式·四中四'; break;
      case 90: play_name = '任选单式·五中五'; break;
      case 91: play_name = '任选单式·六中五'; break;
      case 92: play_name = '任选单式·七中五'; break;
      case 93: play_name = '任选单式·八中五'; break;
      // 任选胆拖
      case 113: play_name = '任选胆拖·二中二'; break;
      case 114: play_name = '任选胆拖·三中三'; break;
      case 115: play_name = '任选胆拖·四中四'; break;
      case 116: play_name = '任选胆拖·五中五'; break;
      case 117: play_name = '任选胆拖·六中五'; break;
      case 118: play_name = '任选胆拖·七中五'; break;
      case 119: play_name = '任选胆拖·八中五'; break;

      // ===============快3===============
      case 242: play_name = '大小单双'; break;
      case 236: play_name = '和值'; break;
      case 232: play_name = '三不同号'; break;
      case 230: play_name = '三同号'; break;
      case 234: play_name = '三连号'; break;
      case 233: play_name = '二不同号'; break;
      case 231: play_name = '二同号'; break;
      case 235: play_name = '单挑一骰'; break;

      // ===============PK10===============
      // 第一名
      case 1029: play_name = '第一名·复式'; break;
      case 1039: play_name = '第一名·单式'; break;
      // 第二名
      case 1030: play_name = '第二名·复式'; break;
      case 1040: play_name = '第二名·单式'; break;
      case 1001: play_name = '猜前二·复式'; break;
      case 1010: play_name = '猜前二·单式'; break;
      // 第三名
      case 1031: play_name = '第三名·复式'; break;
      case 1041: play_name = '第三名·单式'; break;
      case 1002: play_name = '猜前三·复式'; break;
      case 1011: play_name = '猜前三·单式'; break;
      // 第四名
      case 1032: play_name = '第四名·复式'; break;
      case 1042: play_name = '第四名·单式'; break;
      case 1003: play_name = '猜前四·复式'; break;
      case 1012: play_name = '猜前四·单式'; break;
      // 第五名
      case 1033: play_name = '第五名·复式'; break;
      case 1043: play_name = '第五名·单式'; break;
      case 1004: play_name = '猜前五·复式'; break;
      case 1013: play_name = '猜前五·单式'; break;
      // 第六名
      case 1034: play_name = '第六名·复式'; break;
      case 1044: play_name = '第六名·单式'; break;
      case 1005: play_name = '猜前六·复式'; break;
      case 1014: play_name = '猜前六·单式'; break;
      // 第七名
      case 1035: play_name = '第七名·复式'; break;
      case 1045: play_name = '第七名·单式'; break;
      // 第八名
      case 1036: play_name = '第八名·复式'; break;
      case 1046: play_name = '第八名·单式'; break;
      // 第九名
      case 1037: play_name = '第九名·复式'; break;
      case 1047: play_name = '第九名·单式'; break;
      // 第十名
      case 1038: play_name = '第十名·复式'; break;
      case 1048: play_name = '第十名·单式'; break;
      // 定位胆
      case 1025: play_name = '定位胆'; break;
      // 不定位
      case 1019: play_name = '前三·不定位'; break;
      case 1020: play_name = '后三·不定位'; break;
      case 1021: play_name = '前四·不定位'; break;
      case 1022: play_name = '后四·不定位'; break;
      case 1023: play_name = '前五·不定位'; break;
      case 1024: play_name = '后五·不定位'; break;
      // 趣味玩法
      case 1049: play_name = '大小单双·冠军'; break;
      case 1050: play_name = '大小单双·亚军'; break;
      case 1051: play_name = '大小单双·季军'; break;
      case 1026: play_name = '和值·冠亚'; break;
      case 1027: play_name = '和值·冠亚季'; break;
      case 1028: play_name = '龙虎斗'; break;

      // ===============低频===============
      // 三星
      case 136: play_name = '三星·复式'; break;
      case 123: play_name = '三星·单式'; break;
      case 139: play_name = '三星·直选和值'; break;
      case 131: play_name = '三星·组三'; break;
      case 124: play_name = '三星·组三单式'; break;
      case 132: play_name = '三星·组六'; break;
      case 125: play_name = '三星·组六单式'; break;
      case 130: play_name = '三星·混合组选'; break;
      case 140: play_name = '三星·组选和值'; break;
      // 二星
      case 137: play_name = '前二·直选·复式'; break;
      case 126: play_name = '前二·直选·单式'; break;
      case 138: play_name = '后二·直选·复式'; break;
      case 128: play_name = '后二·直选·单式'; break;
      case 135: play_name = '后二·组选·复式'; break;
      case 129: play_name = '后二·组选·单式'; break;
      case 134: play_name = '前二·组选·复式'; break;
      case 127: play_name = '前二·组选·单式'; break;
      // 定位胆
      case 141: play_name = '定位胆'; break;
      // 不定位
      case 133: play_name = '不定位·一码'; break;
      case 162: play_name = '不定位·二码'; break;
      // 大小单双
      case 175: play_name = '大小单双·前二'; break;
      case 176: play_name = '大小单双·后二'; break;

      // ===============排列五===============
      // 三星
      case 189: play_name = '排三·直选复式'; break;
      case 177: play_name = '排三·直选单式'; break;
      case 192: play_name = '排三·直选和值'; break;
      case 184: play_name = '排三·组三复式'; break;
      case 178: play_name = '排三·组三单式'; break;
      case 185: play_name = '排三·组六复式'; break;
      case 179: play_name = '排三·组六单式'; break;
      case 195: play_name = '排三·混合组选'; break;
      case 193: play_name = '排三·组选和值'; break;
      // 二星
      case 190: play_name = '前二·排五直选·复式'; break;
      case 180: play_name = '前二·排五直选·单式'; break;
      case 191: play_name = '后二·排五直选·复式'; break;
      case 192: play_name = '后二·排五直选·单式'; break;
      case 187: play_name = '前二·排五组选·复式'; break;
      case 181: play_name = '前二·排五组选·单式'; break;
      case 188: play_name = '后二·排五组选·复式'; break;
      case 183: play_name = '后二·排五组选·单式'; break;
      // 定位胆
      case 194: play_name = '定位胆'; break;
      // 不定位
      case 186: play_name = '不定位·一码'; break;
      case 196: play_name = '不定位·二码'; break;
      // 大小单双
      case 197: play_name = '大小单双·前二'; break;
      case 198: play_name = '大小单双·后二'; break;

      // ===============六合彩===============
      case 290: play_name = '特码·特码'; break;
      case 291: play_name = '正特·正码1'; break;
      case 292: play_name = '正特·正码2'; break;
      case 293: play_name = '正特·正码3'; break;
      case 294: play_name = '正特·正码4'; break;
      case 295: play_name = '正特·正码5'; break;
      case 296: play_name = '正特·正码6'; break;
      case 297: play_name = '半波·半波'; break;
      case 298: play_name = '生肖·特肖'; break;
      case 299: play_name = '生肖·一肖'; break;
      case 300: play_name = '生肖·六肖'; break;
      case 301: play_name = '尾数·尾数'; break;
      case 302: play_name = '总分·总分'; break;
      case 303: play_name = '不中·五不中'; break;
      case 305: play_name = '不中·六不中'; break;
      case 306: play_name = '不中·七不中'; break;
      case 307: play_name = '不中·八不中'; break;
      case 308: play_name = '不中·九不中'; break;
      case 309: play_name = '不中·十不中'; break;

      // ===============PC蛋蛋===============
      case 282: play_name = '和值玩法'; break;
      case 283: play_name = '大小玩法'; break;
      case 284: play_name = '单双玩法'; break;
      case 285: play_name = '两极玩法'; break;
      case 286: play_name = '串关玩法'; break;

      default: break;
    }

    return play_name;
  }

}
