import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LotteryPageComponent } from './lottery-page/lottery-page.component';
import { LotteryLobbyComponent } from './lottery-page/lottery-lobby/lottery-lobby.component';
import { LotterySscComponent } from './lottery-page/lottery-ssc/lottery-ssc.component';
import { LotteryX115Component } from './lottery-page/lottery-x115/lottery-x115.component';
import { LotteryK3Component } from './lottery-page/lottery-k3/lottery-k3.component';
import { LotteryPk10Component } from './lottery-page/lottery-pk10/lottery-pk10.component';
import { LotteryLhcComponent } from './lottery-page/lottery-lhc/lottery-lhc.component';
import { LotteryDipinComponent } from './lottery-page/lottery-dipin/lottery-dipin.component';
import { DataCenterPageComponent } from './data-center-page/data-center-page.component';
import { DataCenterContentComponent } from './data-center-page/data-center-content/data-center-content.component';
import { PersonalPrizeComponent } from './data-center-page/data-center-content/personal/personal-prize/personal-prize.component';
import { PersonalBetRecordComponent } from './data-center-page/data-center-content/personal/personal-bet-record/personal-bet-record.component';
import { PersonalTraceRecordComponent } from './data-center-page/data-center-content/personal/personal-trace-record/personal-trace-record.component';
import { PersonalTransactionRecordComponent } from './data-center-page/data-center-content/personal/personal-transaction-record/personal-transaction-record.component';
import { PersonalTransferRecordComponent } from './data-center-page/data-center-content/personal/personal-transfer-record/personal-transfer-record.component';
import { MessageAnnouncementComponent } from './data-center-page/data-center-content/message/message-announcement/message-announcement.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LotteryPlwComponent } from './lottery-page/lottery-plw/lottery-plw.component';
import { TrendPageComponent } from './trend-page/trend-page.component';
import { PermissionGuard } from './guard/permission.guard';
import { AnnouncementPageComponent } from './announcement-page/announcement-page.component';
import { LotteryPcddComponent } from './lottery-page/lottery-pcdd/lottery-pcdd.component';
import { Page404Component } from './page404/page404.component';
import { PageCloseComponent } from './page-close/page-close.component';

const routes: Routes = [
  { path: '', redirectTo: 'login-page', pathMatch: 'full' },
  // 首页
  { path: 'homepage', component: HomepageComponent },
  // 彩票大厅
  {
    path: 'lottery', component: LotteryPageComponent, children: [
      { path: '', redirectTo: 'lobby', pathMatch: 'full' },
      { path: 'lobby', component: LotteryLobbyComponent },
      { path: 'ssc/:id', component: LotterySscComponent, canActivate: [PermissionGuard] },
      { path: '11x5/:id', component: LotteryX115Component, canActivate: [PermissionGuard] },
      { path: 'k3/:id', component: LotteryK3Component, canActivate: [PermissionGuard] },
      { path: 'pk10/:id', component: LotteryPk10Component, canActivate: [PermissionGuard] },
      { path: 'lhc/:id', component: LotteryLhcComponent, canActivate: [PermissionGuard] },
      { path: 'dp/:id', component: LotteryDipinComponent, canActivate: [PermissionGuard] },
      { path: 'plw/:id', component: LotteryPlwComponent, canActivate: [PermissionGuard] },
      { path: 'pcdd/:id', component: LotteryPcddComponent, canActivate: [PermissionGuard] }
    ]
  },
  // 走势图表
  {
    path: 'trend', component: TrendPageComponent
  },
  // 平台公告
  {
    path: 'announcement', component: AnnouncementPageComponent
  },
  // 数据中心
  {
    path: 'data-center', component: DataCenterPageComponent, children: [
      {
        path: 'content', component: DataCenterContentComponent, children: [
          /** 个人中心*/
          { path: 'prize', component: PersonalPrizeComponent }, // 奖金组设置
          { path: 'bet-rd', component: PersonalBetRecordComponent }, // 投注记录
          { path: 'trace-rd', component: PersonalTraceRecordComponent }, // 追号记录
          { path: 'transaction-rd', component: PersonalTransactionRecordComponent }, // 账变记录
          { path: 'transfer-rd', component: PersonalTransferRecordComponent }, // 转账记录
          /** 消息中心*/
          // { path: 'announcement', component: MessageAnnouncementComponent }, // 公告
        ]
      }
    ]
  },
  // 登陆页面
  { path: 'login-page', component: LoginPageComponent },
  // 休市页面
  {path: 'page-close', component: PageCloseComponent},
  // 404
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
