import { Subscription } from 'rxjs';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

export class GameCreditBase {
    // 游戏ID
    public game_id: number;
    public game_info_sub: Subscription;
    // 游戏玩法信息
    public play_info: String = '';
    public play_info_sub: Subscription;
    // 奖金组
    public prize_group: number;
    public prize_group_sub: Subscription;
    // 奖金概率
    public prize_probability: any;
    // 投注金额
    public bet_value: number;
    public bet_value_sub: Subscription;
    // 情空选球
    public clear_balls_sub: Subscription;
    // 被选中的信息
    public is_selected_info: Array<any> = [];

    constructor(
        public betInfoService: BetInfoService
    ) {
    }

    ngAfterContentInit() {
        // 游戏信息
        this.game_info_sub = this.betInfoService.get_cur_game_info_handler().subscribe(data => {
            if (data) {
                this.game_id = data.game_id;
            }
        });

        // 玩法监听
        this.play_info_sub = this.betInfoService.get_play_info_handler().subscribe(data => {
            if (data) {
                this.play_info = data;
                this.prize_probability = data['aProbilities'];
            }
        });

        // 奖金组
        this.prize_group_sub = this.betInfoService.get_credit_prize_group_handler().subscribe(data => {
            if (data) {
                this.prize_group = Number(data);
                if (this.prize_probability) {
                    this.fill_probilities_info_handler();
                }
            }
        });

        // 投注金额
        this.bet_value_sub = this.betInfoService.get_credit_bet_val_handler().subscribe(data => {
            if (data) {
                this.bet_value = Number(data);
            } else {
                this.bet_value = 1;
            }
            this.render_bet_value_handler();
        });

        // 情空选球
        this.clear_balls_sub = this.betInfoService.get_clear_credit_balls_handler().subscribe(data => {
            if (data) {
                this.reset_balls_panel_handler();
            }
        });
    }

    /**
     * 销毁 监听
     *
     * @memberof GameCreditBase
     */
    ngOnDestroy() {
        this.betInfoService.set_cur_game_info_handler(null);
        this.game_info_sub.unsubscribe();

        this.play_info_sub.unsubscribe();
        this.prize_group_sub.unsubscribe();
        this.bet_value_sub.unsubscribe();

        this.betInfoService.set_clear_credit_balls_handler(null);
        this.clear_balls_sub.unsubscribe();
    }

    /**
     * 重制号码状态
     *
     * @memberof GameCreditBase
     */
    public reset_balls_panel_handler() {

    }

    /**
     * 填充赔率信息
     *
     * @memberof GameBase
     */
    public fill_probilities_info_handler() {

    }

    /**
     * 渲染投注金额
     *
     * @memberof GameCreditBase
     */
    public render_bet_value_handler() {

    }
}
