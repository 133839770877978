import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'k3Sanlianhao'
})
export class K3SanlianhaoPipe implements PipeTransform {

  transform(value: any): any {
    let str: string;
    let val = value.join('').toString();

    if (val === '123' || val === '234' || val === '345' || val === '456') {
      str = '三连号';
    } else {
      str = '--';
    }

    return str;
  }

}
