import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-pk10-quwei-caihezhi-guanyaji',
  templateUrl: './pk10-quwei-caihezhi-guanyaji.component.html',
  styleUrls: ['./pk10-quwei-caihezhi-guanyaji.component.css']
})
export class Pk10QuweiCaihezhiGuanyajiComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [
        false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false,
        false, false
      ]
    ];

    this.lottery_data = [
      [
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9),
        new Ball(10),
        new Ball(11),
        new Ball(12),
        new Ball(13),
        new Ball(14),
        new Ball(15),
        new Ball(16),
        new Ball(17),
        new Ball(18),
        new Ball(19),
        new Ball(20),
        new Ball(21),
        new Ball(22),
        new Ball(23),
        new Ball(24),
        new Ball(25),
        new Ball(26),
        new Ball(27)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球值
   *
   * @memberof Pk10QuweiCaihezhiGuanyajiComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      if (i !== 0) {
        selected_ball_str = selected_ball_str + '|';
        no_ball = no_ball + '|';
      }
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + ' ';
        }
      }
      selected_ball_str = selected_ball_str.trim();
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str;
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
    // 奖金计算
    this.calculation_play_prize_handle();
  }

  /**
   * 复写奖金计算方式
   *
   * @memberof Pk10QuweiCaihezhiGuanyajiComponent
   */
  public calculation_play_prize_handle() {
    if (this.prize_probability && this.prize_group && this.money_unit) {
      let probability_arr = this.prize_probability['probability'];
      let prize_str = '';

      let arr = []; // 选中的规则号
      this.btn_status_array[0].forEach((item, index) => {
        if (item) {
          let r = (index + 6) + '';
          arr.push(r);
        }
      });

      if (arr.length === 0) {
        arr = ['6', '15'];
      }

      let prize_arr = [];
      arr.forEach(item => {
        let p;
        if (item === '6' || item === '7' || item === '26' || item === '27') {
          p = probability_arr[0];
        }

        if (item === '8' || item === '25') {
          p = probability_arr[1];
        }

        if (item === '9' || item === '24') {
          p = probability_arr[2];
        }

        if (item === '10' || item === '23') {
          p = probability_arr[3];
        }

        if (item === '11' || item === '22') {
          p = probability_arr[4];
        }

        if (item === '12' || item === '21') {
          p = probability_arr[5];
        }

        if (item === '13' || item === '20') {
          p = probability_arr[6];
        }

        if (item === '14' || item === '19') {
          p = probability_arr[7];
        }

        if (item === '15' || item === '16' || item === '17' || item === '18') {
          p = probability_arr[8];
        }

        let prize = this.prize_formula(p);
        prize_arr.push(prize);
      });

      prize_str = this.unique(prize_arr).sort(this.cmp).join(' - ');
      this.betInfoService.set_prize_info_handler(prize_str);
    }
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof Pk10QuweiCaihezhiGuanyajiComponent
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];
    let pos_array = [];

    if (this.balls_str !== '') {
      pos_array = this.balls_str.split('|');
    }

    for (let item of pos_array) {
      let arr = item.split(' ');
      if (arr)
        this.selected_ball_array.push(arr);
    }

    if (this.selected_ball_array.length !== 0) {
      if (this.selected_ball_array[0].length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * 计算注单数量
   *
   * @memberof Pk10QuweiCaihezhiGuanyajiComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array[0].length;

    this.bet_num = num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof Pk10QuweiCaihezhiGuanyajiComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split(' ');

    for (let i = 0; i < bet_arr.length; i++) {
      for (let j = 0; j < this.lottery_data[0].length; j++) {
        if (bet_arr[i] === this.lottery_data[0][j].get_ball_num()) {
          this.btn_status_array[0][j] = true;
        }
      }
    }

    this.update_bet_infor_handler();
  }

}
