import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-erxing-zuxuan-qianer-baodan',
  templateUrl: './ssc-erxing-zuxuan-qianer-baodan.component.html',
  styleUrls: ['./ssc-erxing-zuxuan-qianer-baodan.component.css']
})
export class SscErxingZuxuanQianerBaodanComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 选择球号动作
   *
   * @param {number} r
   * @param {number} c
   * @memberof SscErxingZuxuanQianerBaodanComponent
   */
  public click_btn_lottery_handler(r: number, c: number) {
    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          this.btn_status_array[i][j] = false;
        }
      }
    }

    this.btn_status_array[r][c] = true;
    this.update_bet_infor_handler();
  }

  /**
   * 计算注单数量
   *
   * @memberof SscErxingZuxuanQianerBaodanComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    let balls_arr = this.balls_str.split('|');

    balls_arr.forEach(val => {
      num += this.mathResult(Number(val), 0, 9);
    });

    this.bet_num = num;
  }

  /**
   * 匹配结果
   *
   * @param {*} sum
   * @param {*} nBegin
   * @param {*} nEnd
   * @returns {number}
   * @memberof SscErxingZuxuanQianerBaodanComponent
   */
  public mathResult(sum, nBegin, nEnd): number {
    let x, y = 0;
    let arr = [];
    let checkArray = [];

    for (x = nBegin; x <= nEnd; x++) {
      for (y = nBegin; y <= nEnd; y++) {
        if ((x === sum && y !== sum) || (y === sum && x !== sum)) {
          let postArray = [x, y].sort(function (a, b) {
            return a - b;
          });
          if (this.checkResult(postArray.join(''), checkArray)) {
            checkArray.push(postArray);
            arr.push([x, y]);
          }
        }
      }
    }

    return arr.length;
  }

  // 检测结果重复
  private checkResult(data, array) {
    // 检查重复
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].join('') === data) {
        return false;
      }
    }
    return true;
  }

}
