import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-ssc-housan',
  templateUrl: './ssc-housan.component.html',
  styleUrls: ['./ssc-housan.component.css']
})
export class SscHousanComponent implements OnInit {

  @ViewChild('HouSanTrendLine') qiansan_trend_line_ref: ElementRef;

  @Input()
  public auxs: Array<any>;

  @Input()
  public issue_num: string;

  @Input()
  public trend_data: Array<any>;

  @Input()
  public analysis_data: Array<any>;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.draw_housan_trend_line_handler();
  }

  // 后三走势线
  public draw_housan_trend_line_handler() {
    // 设置canvas高度
    this.qiansan_trend_line_ref.nativeElement.width = 540;
    this.qiansan_trend_line_ref.nativeElement.height = this.trend_data.length * 30;

    const qiansan_cxt: CanvasRenderingContext2D = this.qiansan_trend_line_ref.nativeElement.getContext('2d');

    let w: number = 540;
    let h: number = this.trend_data.length * 30;
    qiansan_cxt.fillStyle = 'rgba(255, 255, 255, 0)';
    qiansan_cxt.clearRect(0, 0, w, Number(this.issue_num) * 30);
    qiansan_cxt.fillRect(0, 0, w, h);

    for (let i = 2; i < 5; i++) {
      qiansan_cxt.beginPath();

      for (let j = 0; j < this.trend_data.length - 1; j++) {
        let win_num_1 = Number(this.trend_data[j].lottery[i]);
        let win_num_2 = Number(this.trend_data[j + 1].lottery[i]);

        let cell_w = 18;
        let cell_h = 30;

        let x1: number = (cell_w * 10 * (i - 2)) + Number(win_num_1) * cell_w + cell_w / 2;
        let y1: number = (j * cell_h) + cell_h / 2;

        let x2: number = (cell_w * 10 * (i - 2)) + Number(win_num_2) * cell_w + cell_w / 2;
        let y2: number = ((j + 1) * cell_h) + cell_h / 2;

        qiansan_cxt.lineWidth = 1;
        qiansan_cxt.strokeStyle = '#FF5656';

        qiansan_cxt.moveTo(x1, y1);
        qiansan_cxt.lineTo(x2, y2);
        qiansan_cxt.stroke();
      }

      qiansan_cxt.closePath();
    }
  }

}
