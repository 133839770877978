import { Component, OnInit } from '@angular/core';
import { GameDanShiBase } from 'app/common/gameDanShiBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-ssc-renxuan-rx3-zuxuan-hunhe',
  templateUrl: './ssc-renxuan-rx3-zuxuan-hunhe.component.html',
  styleUrls: ['./ssc-renxuan-rx3-zuxuan-hunhe.component.css']
})
export class SscRenxuanRx3ZuxuanHunheComponent extends GameDanShiBase implements OnInit {

  // 输入内容
  public input_content: string = '';

  public tips: string = `说明：
  1、支持常见的各种单式格式，间隔符如： 换行符 回车 逗号 分号等！
  2、上传文件后缀必须是.txt格式,最大支持10万注！
  3、文件较大时会导致上传时间较长，请耐心等待！
  
  格式范例：123 112 341 091`;

  public position_data: Array<any>;

  constructor(
    public betInfoService: BetInfoService,
    public msgService: NzMessageService
  ) {
    super(betInfoService, msgService);

    /** 位置选择 */
    this.position_data = [
      { label: '万位', value: '0', checked: false },
      { label: '千位', value: '1', checked: false },
      { label: '百位', value: '2', checked: true },
      { label: '十位', value: '3', checked: true },
      { label: '个位', value: '4', checked: true }
    ];
    setTimeout(() => {
      this.betInfoService.set_bet_position_info_handler([0, 0, 1, 1, 1]);
    });
  }

  ngOnInit() {
  }

  /**
   * 清理错误 & 去重
   *
   * @returns
   * @memberof SscRenxuanRx3ZuxuanHunheComponent
   */
  public fix_error_repeat_bet_info_handler() {
    if (this.input_content.length === 0) {
      this.betInfoService.set_bet_num_handler(0);
      this.betInfoService.set_bet_info_handler(null);
      return;
    }

    let arr = this.sort_out_bet_info_handler(this.input_content).filter((item) => {
      let is_effect = item.length === 3 && Array.from(new Set(item)).length > 1;
      return is_effect;
    });

    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('').sort().join('');
    }
    this.bet_arr = Array.from(new Set(arr));

    this.input_content = this.bet_arr.join(' ');
    this.msgService.success('内容整理完毕！');

    let position_num = this.position_data.filter(item => item.checked).length;
    let comb_num = this.fac(position_num) / (this.fac(3) * this.fac((position_num - 3)));
    let bet_num = this.bet_arr.length * comb_num;

    // 注单信息
    this.betInfoService.set_bet_num_handler(bet_num);
    this.betInfoService.set_bet_info_handler(this.bet_arr.join('|'));
  }

  /**
   * 位置选择
   *
   * @param {*} val
   * @memberof SscRenxuanRx3ZuxuanHunheComponent
   */
  public update_position_handler(val: any) {
    let arr = this.position_data.filter(item => item.checked);

    if (arr.length < 3) {
      let index = Number(val);
      setTimeout(() => {
        this.position_data[index].checked = true;
        this.fix_error_repeat_bet_info_handler();
      });
    } else {
      let p_arr = [0, 0, 0, 0, 0];
      this.position_data.forEach((item, index) => {
        if (item.checked) {
          p_arr[index] = 1;
        }
      });
      this.betInfoService.set_bet_position_info_handler(p_arr);

      this.fix_error_repeat_bet_info_handler();
    }
  }

  /**
   * 反选-位置选择
   *
   * @param {*} data
   * @memberof SscRenxuanRx3ZuxuanHunheComponent
   */
  public selected_position_render_handler(data: any) {
    data.forEach((item, index) => {
      if (item === 0) {
        this.position_data[index].checked = false;
      }
      if (item === 1) {
        this.position_data[index].checked = true;
      }
    });
  }

}
