import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-k3-credit-zhenghe',
  templateUrl: './k3-credit-zhenghe.component.html',
  styleUrls: ['./k3-credit-zhenghe.component.css']
})
export class K3CreditZhengheComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    // 和值
    [
      { name: '3', p: 0, val: null, is_select: false },
      { name: '4', p: 0, val: null, is_select: false },
      { name: '5', p: 0, val: null, is_select: false },
      { name: '6', p: 0, val: null, is_select: false },
      { name: '7', p: 0, val: null, is_select: false },
      { name: '8', p: 0, val: null, is_select: false },
      { name: '9', p: 0, val: null, is_select: false },
      { name: '10', p: 0, val: null, is_select: false },
      { name: '11', p: 0, val: null, is_select: false },
      { name: '12', p: 0, val: null, is_select: false },
      { name: '13', p: 0, val: null, is_select: false },
      { name: '14', p: 0, val: null, is_select: false },
      { name: '15', p: 0, val: null, is_select: false },
      { name: '16', p: 0, val: null, is_select: false },
      { name: '17', p: 0, val: null, is_select: false },
      { name: '18', p: 0, val: null, is_select: false }
    ],
    // 和值大小单双
    [
      { name: '大', p: 0, val: null, is_select: false },
      { name: '小', p: 0, val: null, is_select: false },
      { name: '单', p: 0, val: null, is_select: false },
      { name: '双', p: 0, val: null, is_select: false }
    ],
    // 两连
    [
      { name: [1, 2], p: 0, val: null, is_select: false },
      { name: [1, 3], p: 0, val: null, is_select: false },
      { name: [1, 4], p: 0, val: null, is_select: false },
      { name: [1, 5], p: 0, val: null, is_select: false },
      { name: [1, 6], p: 0, val: null, is_select: false },
      { name: [2, 3], p: 0, val: null, is_select: false },
      { name: [2, 4], p: 0, val: null, is_select: false },
      { name: [2, 5], p: 0, val: null, is_select: false },
      { name: [2, 6], p: 0, val: null, is_select: false },
      { name: [3, 4], p: 0, val: null, is_select: false },
      { name: [3, 5], p: 0, val: null, is_select: false },
      { name: [3, 6], p: 0, val: null, is_select: false },
      { name: [4, 5], p: 0, val: null, is_select: false },
      { name: [4, 6], p: 0, val: null, is_select: false },
      { name: [5, 6], p: 0, val: null, is_select: false }
    ],
    // 独胆、豹子、对子
    [
      [
        { name: '1', p: 0, val: null, is_select: false },
        { name: '2', p: 0, val: null, is_select: false },
        { name: '3', p: 0, val: null, is_select: false },
        { name: '4', p: 0, val: null, is_select: false },
        { name: '5', p: 0, val: null, is_select: false },
        { name: '6', p: 0, val: null, is_select: false }
      ],
      [
        { name: '1', p: 0, val: null, is_select: false },
        { name: '2', p: 0, val: null, is_select: false },
        { name: '3', p: 0, val: null, is_select: false },
        { name: '4', p: 0, val: null, is_select: false },
        { name: '5', p: 0, val: null, is_select: false },
        { name: '6', p: 0, val: null, is_select: false },
        { name: '任意豹子', p: 0, val: null, is_select: false }
      ],
      [
        { name: '1', p: 0, val: null, is_select: false },
        { name: '2', p: 0, val: null, is_select: false },
        { name: '3', p: 0, val: null, is_select: false },
        { name: '4', p: 0, val: null, is_select: false },
        { name: '5', p: 0, val: null, is_select: false },
        { name: '6', p: 0, val: null, is_select: false }
      ]
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof K3CreditZhengheComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach((group, index) => {
      if (index !== 3) {
        group.forEach(item => {
          item.is_select = false;
          item.val = null;
        });
      } else {
        group.forEach(g => {
          g.forEach(item => {
            item.is_select = false;
            item.val = null;
          });
        });
      }
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof K3CreditZhengheComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;
    // 和值
    this.panel_arr[0].forEach((item, index) => {
      item.p = (this.prize_group / 2000) * (1 / p_arr[0][index]);
    });
    // 和值大小单双
    this.panel_arr[1].forEach((item, index) => {
      item.p = (this.prize_group / 2000) * (1 / p_arr[1][0]);
    });
    // 两连
    this.panel_arr[2].forEach((item, index) => {
      item.p = (this.prize_group / 2000) * (1 / p_arr[2][0]);
    });
    // 其他
    this.panel_arr[3].forEach((group, index) => {
      // 独胆
      if (index === 0) {
        group.forEach(item => {
          item.p = (this.prize_group / 2000) * (1 / p_arr[3][0]);
        });
      }
      // 豹子
      if (index === 1) {
        group.forEach((item, n) => {
          if (n !== 6) {
            item.p = (this.prize_group / 2000) * (1 / p_arr[3][1]);
          } else {
            item.p = (this.prize_group / 2000) * (1 / p_arr[3][2]);
          }
        });
      }
      // 对子
      if (index === 0) {
        group.forEach(item => {
          item.p = (this.prize_group / 2000) * (1 / p_arr[3][3]);
        });
      }
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof K3CreditZhengheComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} ball
   * @memberof K3CreditZhengheComponent
   */
  public selecte_ball_handler(ball: any) {
    ball.is_select = true;
    ball.val = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof K3CreditZhengheComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach((group, index) => {
      if (index !== 3) {
        group.forEach(item => {
          if (item.is_select) {
            item.val = this.bet_value;
          }
        });
      } else {
        group.forEach(g => {
          g.forEach(item => {
            if (item.is_select) {
              item.val = this.bet_value;
            }
          });
        });
      }
    });
  }

}
