import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betContent'
})
export class BetContentPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let playId = args;

    if (playId === 60 || playId === 147 || playId === 62 || playId === 63 || playId === 61) {
      value = value.split('').join('|');
    }

    if (playId === 48 || playId === 151 || playId === 57) {
      value = value.split('').join('|').replace(/0/g, '豹子').replace(/1/g, '顺子').replace(/2/g, '对子');
    }

    if (playId === 58 || playId === 53 || playId === 19 || playId === 22 || playId === 157 || playId === 175 || playId === 176 || playId === 197 || playId === 198) {
      value = value.replace(/0/g, '小').replace(/1/g, '大').replace(/2/g, '双').replace(/3/g, '单');
    }

    if (playId >= 220 && playId <= 229) {
      value = value.split('').join('|').replace(/0/g, '龙').replace(/1/g, '虎').replace(/2/g, '和');
    }

    if (playId === 109) {
      value = value.split('').join('|').replace(/5/g, '五单零双').replace(/4/g, '四单一双').replace(/3/g, '三单二双').replace(/2/g, '二单三双').replace(/1/g, '一单四双').replace(/0/g, '零单五双');
    }

    if (playId === 110) {
      value = value.split(' ').join('|');
    }

    if (playId === 242) {
      value = value.replace(/0/g, '小').replace(/1/g, '大').replace(/2/g, '双').replace(/3/g, '单');
    }

    if ((playId >= 1029 && playId <= 1038) || (playId >= 1001 && playId <= 1005) || (playId >= 1019 && playId <= 1025)) {
      let new_arr: any = [];

      let arr = value.split('');
      arr.forEach((item, index) => {
        new_arr[index] = Number(item) + 1;
      });

      value = new_arr.join('|');
    }

    if (playId === 1049 || playId === 1050 || playId === 1051) {
      value = value.replace(/0/g, '小').replace(/1/g, '大').replace(/2/g, '双').replace(/3/g, '单');
    }

    if (playId === 1028) {
      value = value.
        // row-1
        replace(/1-2/g, '1龙2虎').
        replace(/1-3/g, '1龙3虎').
        replace(/1-4/g, '1龙4虎').
        replace(/1-5/g, '1龙5虎').
        // row-2
        replace(/2-1/g, '2龙1虎').
        replace(/2-3/g, '2龙3虎').
        replace(/2-4/g, '2龙4虎').
        replace(/2-5/g, '2龙5虎').
        // row-3
        replace(/3-1/g, '3龙1虎').
        replace(/3-2/g, '3龙2虎').
        replace(/3-4/g, '3龙4虎').
        replace(/3-5/g, '3龙5虎4').
        // row-4
        replace(/4-1/g, '4龙1虎').
        replace(/4-2/g, '4龙2虎').
        replace(/4-3/g, '4龙3虎').
        replace(/4-5/g, '4龙5虎').
        // row-5
        replace(/5-1/g, '5龙1虎').
        replace(/5-2/g, '5龙2虎').
        replace(/5-3/g, '5龙3虎').
        replace(/5-4/g, '5龙4虎');
    }

    if (playId === 297) {
      switch (Number(value)) {
        case 1: value = '红大'; break;
        case 3: value = '红小'; break;
        case 5: value = '红单'; break;
        case 7: value = '红双'; break;
        case 9: value = '红合单'; break;
        case 11: value = '红合双'; break;

        case 13: value = '绿大'; break;
        case 15: value = '绿小'; break;
        case 17: value = '绿单'; break;
        case 2: value = '绿双'; break;
        case 4: value = '绿合单'; break;
        case 6: value = '绿合双'; break;

        case 8: value = '蓝大'; break;
        case 10: value = '蓝小'; break;
        case 12: value = '蓝单'; break;
        case 14: value = '蓝双'; break;
        case 16: value = '蓝合单'; break;
        case 18: value = '蓝合双'; break;
        default: break;
      }
    }

    if (playId >= 298 && playId <= 299) {
      let c = Number(value.split(',')[0]);
      switch (c) {

        case 12: value = '蛇'; break;
        case 11: value = '马'; break;
        case 10: value = '羊'; break;
        case 9: value = '猴'; break;
        case 8: value = '鸡'; break;
        case 7: value = '狗'; break;
        case 6: value = '猪'; break;
        case 5: value = '鼠'; break;
        case 4: value = '牛'; break;
        case 3: value = '虎'; break;
        case 2: value = '兔'; break;
        case 1: value = '龙'; break;

        default: break;
      }
    }

    if (playId === 300) {
      let content_arr = [];
      let z_arr = value.split('|');
      z_arr.forEach(z => {
        content_arr.push(Number(z.split(',')[0]));
      });

      value = content_arr.join(',').

      replace(/12/g, '蛇').
      replace(/11/g, '马').
      replace(/10/g, '羊').
      replace(/9/g, '猴').
      replace(/8/g, '鸡').
      replace(/7/g, '狗').
      replace(/6/g, '猪').
      replace(/5/g, '鼠').
      replace(/4/g, '牛').
      replace(/3/g, '虎').
      replace(/2/g, '兔').
      replace(/1/g, '龙');
    }

    if (playId === 301) {
      value = `${Number(value)}尾`;
    }

    if (playId === 302) {
      switch (Number(value)) {
        case 1: value = '大'; break;
        case 2: value = '小'; break;
        case 3: value = '单'; break;
        case 4: value = '双'; break;
        case 5: value = '大单'; break;
        case 6: value = '大双'; break;
        case 7: value = '小单'; break;
        case 8: value = '小双'; break;
        default: break;
      }
    }

    if (playId === 283) {
      switch (Number(value)) {
        case 1: value = '大'; break;
        case 0: value = '小'; break;
      }
    }

    if (playId === 284) {
      switch (Number(value)) {
        case 1: value = '单'; break;
        case 0: value = '双'; break;
      }
    }

    if (playId === 285) {
      switch (Number(value)) {
        case 1: value = '极大'; break;
        case 0: value = '极小'; break;
      }
    }

    if (playId === 286) {
      switch (Number(value)) {
        case 11: value = '大单'; break;
        case 10: value = '大双'; break;
        case 0o1: value = '小单'; break;
        case 0o0: value = '小双'; break;
      }
    }

    return value;
  }

}
