import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/common/util';

@Pipe({
  name: 'gameName'
})
export class GameNamePipe implements PipeTransform {

  public game_list = [];
  constructor() {
    if (Utils.game_list.length > 0) {
      let l = Utils.game_list.length;
      for (let i = 0; i < l; i++) {
        Utils.game_list[i].forEach((data, i) => {
          this.game_list.push(data);
        });
      }
    }
  }

  transform(value: number): any {
    let game_name: string;

    if (value) {
      this.game_list.forEach((data, index) => {
        if (value === data.id) {
          game_name = data.name;
        }
      });
    }
    // switch (value) {
       
      // case value === this.id: game_name = this.name;
      // case 1: game_name = '重庆时时彩'; break;
      // case 3: game_name = '黑龙江时时彩'; break;
      // case 6: game_name = '新疆时时彩'; break;
      // case 64: game_name = '腾讯分分彩'; break;
      // case 88: game_name = '腾讯时时彩'; break;
      // case 87: game_name = '腾讯5分彩'; break;
      // case 65: game_name = '胜天分分彩'; break;
      // case 66: game_name = '韩国2分彩'; break;
      // case 67: game_name = '菲律宾5分彩'; break;
      // case 102: game_name = '微博5分彩'; break;
      // case 100: game_name = '奇趣腾讯分分彩'; break;
      // case 101: game_name = '多彩腾讯分分彩'; break;

      // case 2: game_name = '山东11选5'; break;
      // case 8: game_name = '江西11选5'; break;
      // case 9: game_name = '广东11选5'; break;
      // case 39: game_name = '上海11选5'; break;
      // case 44: game_name = '山西11选5'; break;
      // case 45: game_name = '安徽11选5'; break;
      // case 10: game_name = '湖北11选5'; break;
      // case 11: game_name = '江苏11选5'; break;
      // case 12: game_name = '陕西11选5'; break;
      // case 74: game_name = '胜天11选5'; break;
      // case 73: game_name = '极速11选5'; break;

      // case 16: game_name = '江苏快3'; break;
      // case 17: game_name = '安徽快3'; break;
      // case 38: game_name = '湖北快3'; break;
      // case 46: game_name = '河南快3'; break;
      // case 63: game_name = '甘肃快3'; break;
      // case 68: game_name = '龙的快3'; break;
      // case 69: game_name = '极速快3'; break;
      // case 70: game_name = '幸运快3'; break;
      // case 71: game_name = '胜天快3'; break;
      // case 18: game_name = '江西快3'; break;
      // case 19: game_name = '内蒙古快3'; break;

      // case 53: game_name = '北京PK10'; break;
      // case 72: game_name = '极速赛车'; break;
      // case 54: game_name = '幸运飞艇'; break;
      // case 86: game_name = '极速飞艇'; break;

      // case 13: game_name = '福彩3D'; break;
      // case 14: game_name = '排列3/5'; break;
      // case 42: game_name = '上海时时乐'; break;

      // case 61: game_name = '香港六合彩'; break;

      // case 57: game_name = '胜天PC蛋蛋'; break;
      // case 58: game_name = '北京PC蛋蛋'; break;
      // case 59: game_name = '台湾PC蛋蛋'; break;
      // case 60: game_name = '极速PC蛋蛋'; break;

    //   default: break;
    // }

    return game_name;
  }

}
