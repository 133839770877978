import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek } from 'date-fns';
import { Utils } from 'app/common/util';
import { GameConfig } from 'app/common/gameConfig';
import { ApiService } from 'app/providers/api/api.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-personal-trace-record',
  templateUrl: './personal-trace-record.component.html',
  styleUrls: ['./personal-trace-record.component.css']
})
export class PersonalTraceRecordComponent implements OnInit {

  // token
  public token: string;
  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  // 编号类型
  public code_type: string = 'trace';
  public trace_code: string;
  public issue_code: string;
  // 用户名
  public user_name: string;
  // 游戏名称
  public game_name_data: Array<any> = [];
  public cur_game_name_val: string = 'all';
  // 玩法类型
  public play_type_array: Array<any> = [];
  public play_type_data: Array<any> = [];
  public cur_play_type_val: string = 'all';
  // 玩法名称
  public play_name_data: Array<any> = [];
  public cur_play_name_val: string = 'all';

  // 数据源
  public data_source_detail: Array<any> = [];
  public is_loading: boolean = false;
  // 数据数量
  public total_num: number;
  // 单页容量
  public page_size: number = 10;
  // 当前页码
  public curr_page: number = 1;

  // 是否显示详情窗口
  public detail_visible: boolean = false;
  // 是否显示注单详情
  public bet_detail_visible: boolean = false;

  //  追号详情列表
  public trace_detail_list: Array<any> = [];
  // 详情页码
  public trace_curr_page: number = 1;
  // 详情页码数据数量
  public trace_total_num: number;
  // 详情页码单页容量
  public trace_page_size: number = 5;
  // 是否显示分页
  public is_show: boolean = false;
  // 是否显示注单分页
  public is_bet_show: boolean = false;

  // 当前追号id
  public cur_trace_id: number;
  // 当前投注id
  public cur_bet_id: number;

  // 追号详情
  public trace_details: any;
  public bet_details: any;

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private msgService: NzMessageService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');

      this.search_handler();
    }
  }

  ngOnInit() {
    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
        Utils.game_type_array = data['game_type'];
        // 初始化游戏名称
        this.init_game_name_data_handler();
      }
    });

    this.api.get_request_ssc_game_config().subscribe(data => {
      if (data) {
        GameConfig.ssc_game_config = data['ssc_game_config'];
        GameConfig.tx_game_config = data['tx_game_config'];
      }
    });

    this.api.get_request_11x5_game_config().subscribe(data => {
      if (data) {
        GameConfig.x115_game_config = data['x115_game_config'];
      }
    });

    this.api.get_request_k3_game_config().subscribe(data => {
      if (data) {
        GameConfig.k3_game_config = data['k3_game_config'];
      }
    });

    this.api.get_request_pk10_game_config().subscribe(data => {
      if (data) {
        GameConfig.pk10_game_config = data['pk10_game_config'];
      }
    });

    this.api.get_request_f3d_game_config().subscribe(data => {
      if (data) {
        GameConfig.dipin_game_config = data['f3d_game_config'];
      }
    });

    this.api.get_request_plw_game_config().subscribe(data => {
      if (data) {
        GameConfig.plw_game_config = data['plw_game_config'];
      }
    });

    this.api.get_request_lhc_game_config().subscribe(data => {
      if (data) {
        GameConfig.lhc_game_config = data['lhc_game_config'];
      }
    });
  }

  /**
   * 初始化游戏名称
   *
   * @memberof PersonalTraceRecordComponent
   */
  public init_game_name_data_handler() {
    Utils.game_list.forEach((item, index) => {
      let obj: object = {};
      switch (index) {
        case 0:
          obj['label'] = '时时彩';
          break;
        case 1:
          obj['label'] = '11选5';
          break;
        case 2:
          obj['label'] = '快3';
          break;
        case 3:
          obj['label'] = 'PK10';
          break;
        case 4:
          obj['label'] = '低频';
          break;
        case 5:
          obj['label'] = '六合彩';
          break;
        case 6:
          obj['label'] = 'PC蛋蛋';
          break;
        default: break;
      }
      obj['children'] = [];
      item.forEach(element => {
        let g = {};
        g['value'] = element['id'].toString();
        g['label'] = element['name'];
        obj['children'].push(g);
      });
      this.game_name_data.push(obj);
    });
  }

  /**
   * 切换游戏名称
   *
   * @param {*} e
   * @memberof PersonalTraceRecordComponent
   */
  public change_game_name_handler(e: any) {
    this.cur_play_type_val = 'all';
    this.play_type_data = [];
    this.cur_play_name_val = 'all';
    this.play_name_data = [];
    let type;

    for (let i = 0; i < Utils.game_type_array.length; i++) {
      let id = Number(e);
      let index = Utils.game_type_array[i].indexOf(id);
      if (index !== -1) {
        type = i;
        this.update_play_type_data_handler(id, type);
        break;
      }
    }
  }

  /**
   * 更新玩法类型数据
   *
   * @param {number} id
   * @param {number} type
   * @memberof PersonalTraceRecordComponent
   */
  public update_play_type_data_handler(id: number, type: number) {
    this.play_type_array = [];
    this.play_type_data = [];

    let arry;
    switch (type) {
      case 0:
        if (id === 64 || id === 100 || id === 101) {
          arry = GameConfig.tx_game_config;
        } else {
          arry = GameConfig.ssc_game_config;
        }
        break;
      case 1: arry = GameConfig.x115_game_config; break;
      case 2: arry = GameConfig.k3_game_config; break;
      case 3: arry = GameConfig.pk10_game_config; break;
      case 4:
        if (id !== 14) {
          arry = GameConfig.dipin_game_config;
        } else {
          arry = GameConfig.plw_game_config;
        }
        break;
      case 5: arry = GameConfig.lhc_game_config; break;
      case 6: arry = GameConfig.pcdd_game_config; break;
      default: arry = []; break;
    }

    arry.forEach(item => {
      this.play_type_array.push(item);

      let obj = {};
      obj['value'] = item['id'];
      obj['label'] = item['name_cn'];
      this.play_type_data.push(obj);
    });
  }

  /**
   * 切换玩法类型
   *
   * @param {*} e
   * @memberof PersonalTraceRecordComponent
   */
  public change_play_type_handler(e: any) {
    this.cur_play_name_val = 'all';
    this.play_name_data = [];

    this.play_type_array.forEach(item => {
      let id = Number(e);
      if (item['id'] === id) {
        item['children'].forEach(element => {
          element['children'].forEach(play => {
            let p = {};
            p['value'] = play['id'].toString();
            p['label'] = play['name_cn'];
            this.play_name_data.push(p);
          });
        });
      }
    });
  }

  /**
   * 搜索操作
   *
   * @memberof PersonalTraceRecordComponent
   */
  public search_handler() {
    this.is_loading = true;
    let start_time = Utils.formate_time(new Date(this.date_range[0]), 'yyyy-MM-dd hh:mm:ss');
    let end_time = Utils.formate_time(new Date(this.date_range[1]), 'yyyy-MM-dd hh:mm:ss');

    if (this.code_type === 'bet') {
      this.issue_code = '';
    } else {
      this.trace_code = '';
    }
    this.api.get_trace_history_recode_info(
      this.curr_page,
      this.token,
      start_time,
      end_time,
      this.cur_game_name_val === 'all' ? '0' : this.cur_game_name_val,
      this.cur_play_name_val === 'all' ? '0' : this.cur_play_name_val,
      this.trace_code,
      this.issue_code,
      this.user_name,
    ).subscribe(data => {
      this.is_loading = false;
      if (data && data['isSuccess']) {
        if (data && data['isSuccess']) {
          this.total_num = data['data']['total'];
          this.curr_page = data['data']['current_page'];
          // 详情记录
          this.data_source_detail = [];
          data['data']['data'].forEach(item => {
            let tr = new TraceRecord();
            tr.trace_id = item['trace_id'];
            tr.game_name = item['game_name'];
            tr.play_name = item['play'];
            tr.start_issue = item['start_issue'];
            tr.issue_schedule = item['trace_schedule'];
            tr.trace_total_val = Number(item['trace_value']);
            tr.win_prize_val = item['prize_value'];
            tr.win_stop = item['win_stop'] === 1 ? '是' : '否';
            tr.trace_status = item['status'];
            this.data_source_detail.push(tr);
          });
          if (this.data_source_detail.length > 0) {
            this.is_show = true;
          }
        } else {
          // 错误提示
          let error = data['data']['error'];
          this.modalService.error({
            nzTitle: '温馨提示',
            nzContent: error,
            nzCancelText: null,
          });
        }
      }
    });
  }

  /**
   * 根据页面获取信息
   *
   * @param {*} e
   * @memberof PersonalTraceRecordComponent
   */
  public get_info_by_page(e: any) {
    this.curr_page = Number(e);
    this.search_handler();
  }

  /**
   * 详情页内表获取信息
   *
   * @param {*} e
   * @memberof PersonalTraceRecordComponent
   */
  public get_trace_detail_by_page(e: any) {
    this.trace_curr_page = Number(e);
    this.get_trace_detail_info(this.cur_trace_id);
  }

  /**
   * 点击打开详情窗口
   *
   * @param {number} id
   * @memberof PersonalTraceRecordComponent
   */
  public get_trace_detail_info(id: number) {
    this.cur_trace_id = id;
    this.api.get_trace_detail_info_by_id(id, this.token, this.trace_curr_page).subscribe((data) => {
      if (data && data['isSuccess']) {

        let info = data['data'];
        let td = new TraceDetail();
        td.trace_id = info['trace_id'];
        td.lottery = info['lottery'];
        td.game_name = info['game_name'];
        td.trace_code = info['trace_code'];
        td.play = info['play'];
        td.trace_time = info['trace_time'];
        td.start_issue = info['start_issue'];
        td.trace_num = Number(info['trace_num']);
        td.trace_value = Number(info['trace_value']);
        td.complete_value = Number(info['complete_value']);
        td.cancel_value = Number(info['cancel_value']);
        td.prize_value = Number(info['prize_value']);
        td.trace_status = info['trace_status'];
        td.game_mode = Number(info['game_mode']);
        td.prize_group = info['prize_group'];
        td.win_stop = info['win_stop'] === 1 ? '是' : '否';
        td.trace_content = info['trace_content'];
        td.is_stop = (Number(info['is_stop']) === 1);

        this.trace_details = td;

        let list_info = data['data']['list']['datas'];
        this.trace_detail_list = [];
        this.trace_total_num = data['data']['list']['total'];
        if (list_info && list_info.length > 0) {
          list_info.forEach(item => {
            let bit = new BetInTrace();
            bit.bet_id = item['bet_id'];
            bit.issue = item['issue'];
            bit.content = item['content'];
            bit.multiple = Number(item['multiple']);
            bit.bet_value = Number(item['bet_value']);
            bit.trace_status = item['trace_status'];
            bit.bet_status = item['bet_status'];

            this.trace_detail_list.push(bit);
          });
        }

        this.detail_visible = true;
        if (this.trace_detail_list.length > 0) {
          this.is_bet_show = true;
        }
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

  /**
   * 终止追号
   *
   * @memberof PersonalTraceRecordComponent
   */
  public stop_trace_by_id_handler(trace_info: any) {
    let info = { trace_id: Number(trace_info['trace_id']) };
    this.api.stop_trace_by_id(info, this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        trace_info.is_stop = 0;
        this.data_source_detail.filter(item => (item['trace_id'] === trace_info['trace_id']))[0].is_stop = 0;
        this.data_source_detail.filter(item => (item['trace_id'] === trace_info['trace_id']))[0].trace_status = 2;

        this.trace_detail_list.forEach(item => {
          if (item.trace_status === 0) {
            item.trace_status = 2;
          }
        });

        this.modalService.success({
          nzTitle: '温馨提示',
          nzContent: '成功终止追号！',
          nzCancelText: null,
        });
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

  /**
   * 获取注单详情记录
   *
   * @param {number} id
   * @memberof PersonalTraceRecordComponent
   */
  public get_bet_detail_info(id: number) {
    this.cur_bet_id = id;
    this.api.get_bet_detail_info_by_id(id, this.token).subscribe((data) => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        let bd = new BetDetail();
        bd.id = Number(info['bet_id']);
        bd.lottery = info['lottery'];
        bd.game_name = info['game_name'];
        bd.bet_code = info['bet_code'];
        bd.time = info['time'];
        bd.play = info['play'];
        bd.issue = info['issue'];
        bd.game_mode = Number(info['game_mode']);
        bd.multiple = Number(info['multiple']);
        bd.bet_value = Number(info['bet_value']);
        bd.bet_status = info['bet_status'];
        bd.prize_value = Number(info['prize_value']);
        bd.bet_content = info['bet_content'];
        bd.is_cancel = info['is_cancel'];

        this.bet_details = bd;
        this.bet_detail_visible = true;
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }


  /**
   * 关闭追号详细信息窗口
   *
   * @memberof PersonalBetRecordComponent
   */
  public close_detail_canvas_handler() {
    this.detail_visible = false;
  }

  /**
   * 关闭注单详情窗口
   *
   * @memberof PersonalTraceRecordComponent
   */
  public close_bet_detail_canvas_handler() {
    this.bet_detail_visible = false;
  }

  /**
   * 点击撤单操作
   *
   * @memberof PersonalTraceRecordComponent
   */
  public confirm_cancel_handle() {
    this.modalService.warning({
      nzTitle: '温馨提示',
      nzContent: '您确定撤单吗？',
      nzOnOk: () => {
        this.drop_bet_handler();
      }
    });
  }

  /**
   * 撤单操作
   *
   * @memberof PersonalTraceRecordComponent
   */
  public drop_bet_handler() {
    this.api.drop_bet_info_by_id(this.bet_details.id, this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        this.bet_details.bet_status = 1;
        this.bet_details.is_cancel = 0;
        this.msgService.success(data['data']['msg']);
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error,
          nzCancelText: null,
        });
      }
    });
  }

}

class TraceRecord {
  // ID
  public trace_id: number;
  // 游戏名称
  public game_name: string;
  // 玩法名称
  public play_name: string;
  // 起始奖期
  public start_issue: string;
  // 追号进度
  public issue_schedule: string;
  // 追号总金额
  public trace_total_val: number;
  // 已中奖金额
  public win_prize_val: number;
  // 追中即停
  public win_stop: string;
  // 追号状态
  public trace_status: number;

  constructor() {
  }
}

class TraceDetail {
  // ID
  public trace_id: number;
  // 开奖号码
  public lottery: any;
  // 游戏名称
  public game_name: any;
  // 追号编号
  public trace_code: string;
  // 游戏玩法
  public play: any;
  // 追号时间
  public trace_time: string;
  // 开始期号
  public start_issue: string;
  // 追号期数
  public trace_num: number;
  // 追号金额
  public trace_value: number;
  // 完成金额
  public complete_value: number;
  // 取消金额
  public cancel_value: number;
  // 中奖金额
  public prize_value: number;
  // 追号状态
  public trace_status: any;
  // 游戏模式
  public game_mode: any;
  // 追号奖金组
  public prize_group: any;
  // 中奖后终止任务
  public win_stop: string;
  // 投注内容
  public trace_content: any;
  // 是否可终止
  public is_stop: boolean;

  constructor() { }
}

class BetInTrace {
  // ID
  public bet_id: any;
  // 奖期
  public issue: string;
  // 追号内容
  public content: string;
  // 追号倍数
  public multiple: number;
  // 投注金额
  public bet_value: number;
  // 追号状态
  public trace_status: number;
  // 投注状态
  public bet_status: any;

  constructor() { }
}

class BetDetail {
  // ID
  public id: number;
  // 开奖号码
  public lottery: any;
  // 游戏名称
  public game_name: any;
  // 注单编号
  public bet_code: string;
  // 投注时间
  public time: string;
  // 玩法名称
  public play: any;
  // 游戏奖期
  public issue: string;
  // 游戏模式
  public game_mode: any;
  // 投注倍数
  public multiple: number;
  // 投注金额
  public bet_value: number;
  // 注单状态
  public bet_status: any;
  // 奖金金额
  public prize_value: number;
  // 投注内容
  public bet_content: string;
  // 是否可以撤单
  public is_cancel: any;

  constructor() { }
}


