import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-ssc-credit-sihaoqiu',
  templateUrl: './ssc-credit-sihaoqiu.component.html',
  styleUrls: ['./ssc-credit-sihaoqiu.component.css']
})
export class SscCreditSihaoqiuComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    [
      { name: '0', p: 0, val: null, is_select: false },
      { name: '1', p: 0, val: null, is_select: false },
      { name: '2', p: 0, val: null, is_select: false },
      { name: '3', p: 0, val: null, is_select: false },
      { name: '4', p: 0, val: null, is_select: false },
      { name: '5', p: 0, val: null, is_select: false },
      { name: '6', p: 0, val: null, is_select: false },
      { name: '7', p: 0, val: null, is_select: false },
      { name: '8', p: 0, val: null, is_select: false },
      { name: '9', p: 0, val: null, is_select: false }
    ],
    [
      { name: '大', p: 0, val: null, is_select: false },
      { name: '小', p: 0, val: null, is_select: false },
      { name: '单', p: 0, val: null, is_select: false },
      { name: '双', p: 0, val: null, is_select: false }
    ],
    [
      { name: '总大', p: 0, val: null, is_select: false },
      { name: '总小', p: 0, val: null, is_select: false },
      { name: '总单', p: 0, val: null, is_select: false },
      { name: '总双', p: 0, val: null, is_select: false }
    ],
  ];
  // 路数类型
  public chart_type: string = '0';
  // 路数数据
  public chart_original_data: Array<any> = [
    { ball: 0, oe: '双', bs: '小', t_oe: '双', t_bs: '大' },
    { ball: 0, oe: '单', bs: '大', t_oe: '双', t_bs: '大' },
    { ball: 0, oe: '双', bs: '小', t_oe: '单', t_bs: '大' },
    { ball: 0, oe: '双', bs: '小', t_oe: '双', t_bs: '大' },
    { ball: 0, oe: '双', bs: '小', t_oe: '双', t_bs: '大' },
    { ball: 0, oe: '单', bs: '小', t_oe: '单', t_bs: '大' },
    { ball: 0, oe: '双', bs: '小', t_oe: '双', t_bs: '小' },
    { ball: 0, oe: '双', bs: '小', t_oe: '单', t_bs: '大' },
    { ball: 0, oe: '单', bs: '小', t_oe: '单', t_bs: '大' },
    { ball: 1, oe: '单', bs: '小', t_oe: '单', t_bs: '小' },
    { ball: 2, oe: '双', bs: '大', t_oe: '双', t_bs: '小' },
  ];

  public chart_data_1: any = [[], [], [], [], [], []];
  public chart_data_2: any = [[], [], [], [], [], []];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
    this.render_chart_data_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof SscCreditSihaoqiuComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(ball => {
        ball['is_select'] = false;
        ball['val'] = null;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof SscCreditSihaoqiuComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach((item, index) => {
      if (index === 0) {
        item.forEach((ball) => {
          ball['p'] = (this.prize_group / 2000) * (1 / p_arr[0]);
        });
      } else {
        item.forEach((ball) => {
          ball['p'] = (this.prize_group / 2000) * (1 / p_arr[1]);
        });
      }
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof SscCreditSihaoqiuComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} ball
   * @memberof SscCreditSihaoqiuComponent
   */
  public selecte_ball_handler(ball: any) {
    ball.is_select = true;
    ball.val = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach((item) => {
      item.forEach(ball => {
        if (ball.is_select) {
          ball.val = this.bet_value;
        }
      });
    });
  }

  /**
   * 渲染图表
   *
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_chart_data_handler() {
    let type_index = Number(this.chart_type);
    this.chart_data_1 = [[], [], [], [], [], []];
    this.chart_data_2 = [[], [], [], [], [], []];

    switch (type_index) {
      case 0:
        let data_ball = this.chart_original_data.map(item => {
          return { val: item.ball };
        });
        this.render_ball_content_1_handler(data_ball);
        this.render_ball_content_2_handler(data_ball);
        break;
      case 1:
        let data_oe = this.chart_original_data.map(item => {
          return { val: item.oe };
        });
        this.render_oe_content_1_handler(data_oe);
        this.render_oe_content_2_handler(data_oe);
        break;
      case 2:
        let data_bs = this.chart_original_data.map(item => {
          return { val: item.bs };
        });
        this.render_bs_content_1_handler(data_bs);
        this.render_bs_content_2_handler(data_bs);
        break;
      case 3:
        let data_t_oe = this.chart_original_data.map(item => {
          return { val: item.t_oe };
        });
        this.render_oe_content_1_handler(data_t_oe);
        this.render_oe_content_2_handler(data_t_oe);
        break;
      case 4:
        let data_t_bs = this.chart_original_data.map(item => {
          return { val: item.t_bs };
        });
        this.render_bs_content_1_handler(data_t_bs);
        this.render_bs_content_2_handler(data_t_bs);
        break;
      default: break;
    }
  }

  /**
   * 渲染球号图表1
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_ball_content_1_handler(data: any) {
    let r: any;
    let c: any;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (i === 0) {
        r = 0;
        c = 0;
        let info = {
          val: item.val,
          clr: Number(item.val % 2) ? 'blue' : 'red'
        };
        this.chart_data_1[r][c] = info;
      } else {
        let pre_info = this.chart_data_1[r][c];

        if (pre_info.val === item.val) {
          if (r !== 5) {
            r = r + 1;
            c = c;
          } else {
            r = 5;
            c = c + 1;
          }
        } else {
          r = 0;
          c = c + 1;
        }
        let info = {
          val: item.val,
          clr: Number(item.val % 2) ? 'blue' : 'red'
        };

        this.chart_data_1[r][c] = info;
      }
    }
  }

  /**
   * 渲染球号图表2
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_ball_content_2_handler(data: any) {
    let r: any = 0;
    let c: any = 0;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let info = {
        val: item.val,
        clr: Number(item.val % 2) ? 'blue' : 'red'
      };

      if (i === 0) {
        r = 0;
        c = 0;
      } else {
        if (r === 5) {
          r = 0;
          c += 1;
        } else {
          r += 1;
          c = c;
        }
      }

      this.chart_data_2[r][c] = info;
    }
  }

  /**
   * 渲染奇偶图表1
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_oe_content_1_handler(data: any) {
    let r: any;
    let c: any;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (i === 0) {
        r = 0;
        c = 0;
        let info = {
          val: item.val,
          clr: item.val === '双' ? 'blue' : 'red'
        };
        this.chart_data_1[r][c] = info;
      } else {
        let pre_info = this.chart_data_1[r][c];

        if (pre_info.val === item.val) {
          if (r !== 5) {
            r = r + 1;
            c = c;
          } else {
            r = 5;
            c = c + 1;
          }
        } else {
          r = 0;
          c = c + 1;
        }
        let info = {
          val: item.val,
          clr: item.val === '双' ? 'blue' : 'red'
        };

        this.chart_data_1[r][c] = info;
      }
    }
  }

  /**
   * 渲染奇偶图表2
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_oe_content_2_handler(data: any) {
    let r: any = 0;
    let c: any = 0;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let info = {
        val: item.val,
        clr: item.val === '双' ? 'blue' : 'red'
      };

      if (i === 0) {
        r = 0;
        c = 0;
      } else {
        if (r === 5) {
          r = 0;
          c += 1;
        } else {
          r += 1;
          c = c;
        }
      }

      this.chart_data_2[r][c] = info;
    }
  }

  /**
   * 渲染大小图表1
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_bs_content_1_handler(data: any) {
    let r: any;
    let c: any;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (i === 0) {
        r = 0;
        c = 0;
        let info = {
          val: item.val,
          clr: item.val === '小' ? 'blue' : 'red'
        };
        this.chart_data_1[r][c] = info;
      } else {
        let pre_info = this.chart_data_1[r][c];

        if (pre_info.val === item.val) {
          if (r !== 5) {
            r = r + 1;
            c = c;
          } else {
            r = 5;
            c = c + 1;
          }
        } else {
          r = 0;
          c = c + 1;
        }
        let info = {
          val: item.val,
          clr: item.val === '小' ? 'blue' : 'red'
        };

        this.chart_data_1[r][c] = info;
      }
    }
  }

  /**
   * 渲染大小图表2
   *
   * @param {*} data
   * @memberof SscCreditSihaoqiuComponent
   */
  public render_bs_content_2_handler(data: any) {
    let r: any = 0;
    let c: any = 0;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let info = {
        val: item.val,
        clr: item.val === '小' ? 'blue' : 'red'
      };

      if (i === 0) {
        r = 0;
        c = 0;
      } else {
        if (r === 5) {
          r = 0;
          c += 1;
        } else {
          r += 1;
          c = c;
        }
      }

      this.chart_data_2[r][c] = info;
    }
  }

}
