import { Component, OnInit } from '@angular/core';
import { GameConfig } from 'app/common/gameConfig';
import { Utils } from 'app/common/util';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-lottery-k3',
  templateUrl: './lottery-k3.component.html',
  styleUrls: ['./lottery-k3.component.css']
})
export class LotteryK3Component implements OnInit {

  /** 游戏玩法菜单 */
  public play_menu: Array<any>;
  // 当前游戏玩法id
  public current_game_play_id: Number;
  // 当前游戏玩法信息
  public current_game_play_info: any;
  // 玩法集合
  public game_play_array: Array<any> = [];
  // 游戏ID
  public game_id: number;
  // 游戏信息
  public game_info: any;
  // mini走势数据
  public mini_trend_info: Array<any> = [];
  // 奖期数组
  public game_issue_arr: Array<any> = [];
  // 游戏名称
  public game_name: string;
  // 游戏奖期
  public game_issue: string;
  // 玩法模式
  public game_play_way: string = 'official';
  // 提示
  public tip_visible: boolean = false;

  constructor(
    public betInfoService: BetInfoService,
    public routeInfo: ActivatedRoute,
    public api: ApiService,
    private message: NzMessageService,
  ) {
    this.routeInfo.params.subscribe((params: Params) => {
      this.game_id = Number(params['id']);
      this.betInfoService.set_clear_basket_list_handler('clear');
      // 获取游戏信息
      this.get_game_info_handler();
    });

    this.api.get_request_k3_game_config().subscribe(data => {
      if (data) {
        GameConfig.k3_game_config = data['k3_game_config'];
        Utils.default_play_id_k3 = data['default_play_id_k3'];
        this.play_menu = GameConfig.k3_game_config;
        this.current_game_play_id = Utils.default_play_id_k3;

        // 获取游戏信息
        this.get_game_info_handler();

        // 初始化玩法数据
        this.init_game_play_array_handler();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.betInfoService.set_cur_game_info_handler(null);
  }

  /**
   * 初始化保存玩法信息
   *
   * @memberof LotteryK3Component
   */
  public init_game_play_array_handler() {
    // 一级目录
    this.play_menu.forEach((v1) => {
      // 二级目录
      v1.children.forEach((v2) => {
        // 三级目录
        v2.children.forEach((v3) => {
          v3['menu_id'] = v1.id;
          v3['play_name_cn'] = `${v1.name_cn}·${v3.name_cn}`;
          this.game_play_array.push(v3);

          if (v3.id === this.current_game_play_id) {
            this.current_game_play_info = v3;

            this.betInfoService.set_play_info_handler(v3);
          }
        });
      });
    });
  }

  /**
   * 更新切换玩法信息
   *
   * @param {*} e
   * @memberof LotteryK3Component
   */
  public update_current_play_info_handler(e: any) {
    this.current_game_play_info = e;
    this.current_game_play_id = e['id'];
    this.betInfoService.set_bet_num_handler(0);
  }

  /**
   * 获取游戏信息
   *
   * @memberof LotteryK3Component
   */
  public get_game_info_handler() {
    this.api.get_game_info(this.game_id).subscribe(data => {
      if (data && data['isSuccess']) {
        this.game_info = data['data'];
        let deadline: number = data['data']['current_prize_time'] - data['data']['current_time'];
        if (deadline < 1) {
          this.get_game_info_handler();
        } else {
          this.betInfoService.set_cur_game_info_handler(this.game_info);

          // 更新mini走势数据
          this.mini_trend_info = [];
          let len = data['data']['history_lottery'].length >= 10 ? 10 : data['data']['history_lottery'].length;
          for (let i = 0; i < len; i++) {
            let obj = {};
            let lottery_info = data['data']['history_lottery'];
            obj['issue'] = lottery_info[i]['number'];
            obj['lottery'] = [];
            lottery_info[i]['lottery'].forEach((element, index) => {
              obj['lottery'][index] = element;
            });
            this.mini_trend_info.push(obj);
          }

          // 更新奖期数组
          this.game_issue_arr = data['data']['game_issue'];
          // 游戏名称
          this.game_name = data['data']['game_name'];
          // 游戏奖期
          this.game_issue = data['data']['current_issue'];

          this.tip_visible = true;
          setTimeout(() => {
            this.tip_visible = false;
          }, 2000);
        }
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 更新mini走势图数据
   *
   * @param {*} event
   * @memberof LotteryK3Component
   */
  public update_mini_trend_data_handler(event: any) {
    this.mini_trend_info = [];
    let len = event['data']['history_lottery'].length >= 10 ? 10 : event['data']['history_lottery'].length;
    for (let i = 0; i < len; i++) {
      let obj = {};
      let lottery_info = event['data']['history_lottery'];
      obj['issue'] = lottery_info[i]['number'];
      obj['lottery'] = [];
      lottery_info[i]['lottery'].forEach((element, index) => {
        obj['lottery'][index] = element;
      });
      this.mini_trend_info.push(obj);
    }
  }

  /**
   * 玩法切换 官方/信用
   *
   * @param {*} event
   * @memberof LotteryK3Component
   */
  public switch_game_play_way_handler(event: any) {
    if (event) {
      this.game_play_way = 'credit';
    } else {
      this.game_play_way = 'official';
    }
  }

}
