import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-pk10-quwei-longhudou-longhudou',
  templateUrl: './pk10-quwei-longhudou-longhudou.component.html',
  styleUrls: ['./pk10-quwei-longhudou-longhudou.component.css']
})
export class Pk10QuweiLonghudouLonghudouComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball(''),
        new Ball('1龙2虎'),
        new Ball('1龙3虎'),
        new Ball('1龙4虎'),
        new Ball('1龙5虎')
      ],
      [
        new Ball('2龙1虎'),
        new Ball(''),
        new Ball('2龙3虎'),
        new Ball('2龙4虎'),
        new Ball('2龙5虎')
      ],
      [
        new Ball('3龙1虎'),
        new Ball('3龙2虎'),
        new Ball(''),
        new Ball('3龙4虎'),
        new Ball('3龙5虎')
      ],
      [
        new Ball('4龙1虎'),
        new Ball('4龙2虎'),
        new Ball('4龙3虎'),
        new Ball(''),
        new Ball('4龙5虎')
      ],
      [
        new Ball('5龙1虎'),
        new Ball('5龙2虎'),
        new Ball('5龙3虎'),
        new Ball('5龙4虎'),
        new Ball('')
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球值
   *
   * @memberof Pk10QuweiLonghudouLonghudouComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      if (i !== 0) {
        selected_ball_str = selected_ball_str + '|';
        no_ball = no_ball + '|';
      }
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + ' ';
        }
      }
      selected_ball_str = selected_ball_str.trim();
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str;
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof Pk10QuweiLonghudouLonghudouComponent
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];
    let pos_array = [];

    if (this.balls_str !== '') {
      pos_array = this.balls_str.split('|');
    }

    for (let item of pos_array) {
      let arr = item.split(' ');
      if (arr[0] !== '')
        this.selected_ball_array.push(arr);
    }

    if (this.selected_ball_array.length !== 0) {
      for (let item of this.selected_ball_array) {
        if (item.length > 0) {
          return true;
        }
      }

      return false;
    } else {
      return false;
    }
  }

  /**
   * 计算注单数量
   *
   * @memberof Pk10QuweiLonghudouLonghudouComponent
   */
  public calculate_bet_number() {
    let num: number = 0;

    this.selected_ball_array.forEach((item) => {
      num += item.length;
    });

    this.bet_num = num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof Pk10QuweiLonghudouLonghudouComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let arr = bet_arr[i].split(' ');
      for (let j = 0; j < arr.length; j++) {
        let val = arr[j];
        for (let k = 0; k < this.lottery_data[i].length; k++) {
          if (val === this.lottery_data[i][k].get_ball_num()) {
            this.btn_status_array[i][k] = true;
          }
        }
      }
    }

    this.update_bet_infor_handler();
  }

}
