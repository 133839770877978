import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'k3Hezhi'
})
export class K3HezhiPipe implements PipeTransform {
  
  transform(value: any): any {
    // 和值
    let num: number = 0;

    value.forEach(item => {
      num += Number(item);
    });

    return num;
  }

}
