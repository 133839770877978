import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-ssc-sixing',
  templateUrl: './ssc-sixing.component.html',
  styleUrls: ['./ssc-sixing.component.css']
})
export class SscSixingComponent implements OnInit {

  @ViewChild('SiXingTrendLine') sixing_trend_line_ref: ElementRef;

  @Input()
  public auxs: Array<any>;

  @Input()
  public issue_num: string;

  @Input()
  public trend_data: Array<any>;

  @Input()
  public analysis_data: Array<any>;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.draw_sixing_trend_line_handler();
  }

  // 四星走势线
  public draw_sixing_trend_line_handler() {
    // 设置canvas高度
    this.sixing_trend_line_ref.nativeElement.width = 900;
    this.sixing_trend_line_ref.nativeElement.height = this.trend_data.length * 30;

    const sixing_cxt: CanvasRenderingContext2D = this.sixing_trend_line_ref.nativeElement.getContext('2d');

    let w: number = 900;
    let h: number = this.trend_data.length * 30;
    sixing_cxt.fillStyle = 'rgba(255, 255, 255, 0)';
    sixing_cxt.clearRect(0, 0, w, Number(this.issue_num) * 30);
    sixing_cxt.fillRect(0, 0, w, h);

    for (let i = 1; i < 5; i++) {
      sixing_cxt.beginPath();

      for (let j = 0; j < this.trend_data.length - 1; j++) {
        let win_num_1 = Number(this.trend_data[j].lottery[i]);
        let win_num_2 = Number(this.trend_data[j + 1].lottery[i]);

        let cell_w = 22;
        let cell_h = 30;

        let x1: number = (cell_w * 10 * (i - 1)) + Number(win_num_1) * cell_w + cell_w / 2;
        let y1: number = (j * cell_h) + cell_h / 2;

        let x2: number = (cell_w * 10 * (i - 1)) + Number(win_num_2) * cell_w + cell_w / 2;
        let y2: number = ((j + 1) * cell_h) + cell_h / 2;

        sixing_cxt.lineWidth = 1;
        sixing_cxt.strokeStyle = '#FF5656';

        sixing_cxt.moveTo(x1, y1);
        sixing_cxt.lineTo(x2, y2);
        sixing_cxt.stroke();
      }

      sixing_cxt.closePath();
    }
  }

}
