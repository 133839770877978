import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-plw-paisan-zhixuan-hezhi',
  templateUrl: './plw-paisan-zhixuan-hezhi.component.html',
  styleUrls: ['./plw-paisan-zhixuan-hezhi.component.css']
})
export class PlwPaisanZhixuanHezhiComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [
        false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false
      ]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9),
        new Ball(10),
        new Ball(11),
        new Ball(12),
        new Ball(13),
        new Ball(14),
        new Ball(15),
        new Ball(16),
        new Ball(17),
        new Ball(18),
        new Ball(19),
        new Ball(20),
        new Ball(21),
        new Ball(22),
        new Ball(23),
        new Ball(24),
        new Ball(25),
        new Ball(26),
        new Ball(27)
      ]
    ];
  }

  ngOnInit() {
  }

}
