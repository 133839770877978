import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-qiansan-zhixuan-kuadu',
  templateUrl: './ssc-qiansan-zhixuan-kuadu.component.html',
  styleUrls: ['./ssc-qiansan-zhixuan-kuadu.component.css']
})
export class SscQiansanZhixuanKuaduComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球信息
   *
   * @memberof SscQiansanZhixuanKuaduComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 计算注单数量
   *
   * @memberof SscQiansanZhixuanKuaduComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    let balls_arr = this.balls_str.split('|');

    balls_arr.forEach(val => {
      num += this.mathResult(Number(val));
    });

    this.bet_num = num;
  }

  /**
   * 匹配结果 跨度
   *
   * @param {number} num
   * @returns {number}
   * @memberof SscQiansanZhixuanKuaduComponent
   */
  public mathResult(num: number): number {
    let i, j, k;
    let result = [];

    for (i = 0; i < 10; i++) {
      for (j = 0; j < 10; j++) {
        for (k = 0; k < 10; k++) {
          let numList = [i, j, k];
          let minNums = Math.min.apply(Math, numList);
          let maxNums = Math.max.apply(Math, numList);
          if (maxNums - minNums === num) {
            result.push(numList);
          }
        }
      }
    }

    return result.length;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof SscQiansanZhixuanKuaduComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let index = Number(bet_arr[i]);
      this.btn_status_array[0][index] = true;
    }

    this.update_bet_infor_handler();
  }

}
