import { Component, OnInit, Input } from '@angular/core';
import { Utils } from 'app/common/util';
import { Subscription } from 'rxjs';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { ApiService } from 'app/providers/api/api.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lottery-bet-info',
  templateUrl: './lottery-bet-info.component.html',
  styleUrls: ['./lottery-bet-info.component.css']
})
export class LotteryBetInfoComponent implements OnInit {

  // 单式玩法ID
  public danshi_arr = [
    7, 6, 1, 2, 3, 13, 143, 144, 146, 154, 8, 9, 10, 81, 11, 4, 12, 5, // ssc
    95, 97, 94, 96, 86, 87, 88, 89, 90, 91, 92, 93, // 11x5
    1039, 1040, 1010, 1041, 1011, 1042, 1012, 1043, 1013, 1044, 1014, 1045, 1046, 1047, 1048, // pk10
    123, 124, 125, 130, 126, 128, 129, 127, // f3d
    177, 178, 179, 195, 180, 182, 181, 183 // plw
  ];

  // ==============================选号信息==============================
  // 游戏ID
  @Input()
  public game_id: number;
  // 玩法ID
  @Input()
  public play_id: number;
  @Input()
  // 奖期数组
  public game_issue_arr: Array<any>;
  // 游戏名称
  @Input()
  public game_name: string;
  // 游戏奖期
  @Input()
  public game_issue: string;

  // token
  public token: string;
  // 最低奖金组
  public min_prize_group: number;
  // 最高奖金组
  public max_prize_group: number;
  // 奖金组
  public current_prize_group: number;
  // 奖金组格式化
  // public formatter_percent = value => `${value} - ${((this.max_prize_group - this.current_prize_group) / Utils.base_prize * 100).toFixed(2)}%`;
  // public parser_percent = value => value = this.current_prize_group;
  // 两元/一元模式
  public money_mode_array: Array<any>;
  public money_mode: string;
  // 模式
  public money_unit_array: Array<any>;
  public money_unit: string;
  // 倍数
  public multiple: number = 1;
  // 最大倍数
  public max_multiple: number;
  // 最大奖金
  public bet_prize: number;
  // 注数
  public bet_num: number = 0;

  // 余额
  public balance: number = 0;
  // 时间
  public h1: string = '-';
  public h2: string = '-';
  public m1: string = '-';
  public m2: string = '-';
  public s1: string = '-';
  public s2: string = '-';
  // 倒计时监听流
  public count_down_sub: Subscription;
  // 注数监听流
  public bet_num_sub: Subscription;
  // 模式监听流
  public multiple_sub: Subscription;
  // 两/一元模式
  public money_mode_sub: Subscription;
  // 奖金监听流
  public prize_info_sub: Subscription;
  // 注单信息
  public bet_info: string;
  public bet_info_sub: Subscription;
  // 位置信息（任选）
  public bet_position_info: any;
  public bet_position_info_sub: Subscription;

  // 总价格
  public total_price: number;

  // ==============================购彩篮信息==============================
  // 已存在注单编号（反选编辑）
  public exist_bet_no: any = null;
  // 购彩篮
  public bet_basket_info: Array<any> = [];
  public bet_data_array: Array<any> = [];
  public bet_basket_sub: Subscription;
  // 注单
  public fast_bet: BetInfo = new BetInfo();
  // 购彩栏注单数量
  public bet_basket_total_bet_num: number = 0;
  // 购彩栏注单金额
  public bet_basket_total_bet_val: number = 0;

  // ==============================追号信息==============================
  // 起始倍数限制
  public start_multiple_limit: number = 1;
  // 追号期数限制
  public trace_issue_limit: number;
  // 利润率追号
  public profit_trace_percent: number = 100;
  public profit_trace_start: number = 1;
  public profit_trace_num: number = 10;
  formatterPercent = value => `${value} %`;
  parserPercent = value => value.replace(' %', '');

  // 同倍追号
  public same_trace_start: number = 1;
  public same_trace_num: number = 10;

  // 翻倍追号
  public more_trace_start: number = 1;
  public more_trace_interval: number = 2;
  public more_trace_multiple: number = 2;
  public more_trace_num: number = 10;

  // 利率追号
  public profit_trace_data: Array<any> = [];
  // 同倍追号
  public same_trace_data: Array<any> = [];
  // 翻倍追号
  public more_trace_data: Array<any> = [];

  // ==============================追号窗口==============================
  // 追号类型
  public trace_type: string;
  public trace_title: string;
  // 追号窗口
  public isVisible_trace_modal: boolean = false;
  // 起始追号奖期
  public start_trace_issue: string;
  // 结束追号奖期
  public end_trace_issue: string;
  // 总结期数
  public total_issue_num: number;
  // 总计金额
  public total_trace_val: number;
  // 中奖即停
  public win_stop: boolean = true;

  // 游戏奖金
  public current_game_prize: number;

  // 投注锁
  public is_lock: boolean = false;

  // 单注奖金
  public prize_info: number;

  constructor(
    private betInfoService: BetInfoService,
    private api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private router: Router
  ) {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      this.token = localStorage.getItem('token');
      // 获取账户余额
      this.update_balance_info_handler();
    }

    this.api.get_request_base_config_info().subscribe(data => {
      if (data) {
        Utils.min_prize_group = data['min_bet_prize_group'];
        Utils.max_prize_group = data['max_bet_prize_group'];

        this.min_prize_group = Utils.min_prize_group;
        this.max_prize_group = Utils.max_prize_group;

        this.money_mode_array = data['price'];
        this.money_mode = this.money_mode_array[0]['value'].toString();
        this.money_unit_array = data['price_mode'];
        this.money_unit = this.money_unit ? this.money_unit : this.money_unit_array[0]['value'].toString();

        Utils.adt_val_11x5 = data['adt_val_11x5'];
        Utils.adt_val_dp = data['adt_val_dp'];
      }
    });

    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_type_array = data['game_type'];
        this.init_reward_percent_handler();
      }
    });
  }

  ngOnInit() {
    // 倒计时监听
    this.count_down_sub = this.betInfoService.get_count_down_info_handler().subscribe(data => {
      this.update_left_time(Number(data));
    });

    // 订阅 注数监听
    this.bet_num_sub = this.betInfoService.get_bet_num_handler().subscribe(data => {
      this.bet_num = data;
      this.calculation_total_price();
    });

    // 倍数
    this.multiple_sub = this.betInfoService.get_money_unit_info_handler().subscribe(data => {
      this.money_unit = data.toString();
    });

    // 两/一元模式
    this.money_mode_sub = this.betInfoService.get_money_mode_info_handler().subscribe(data => {
      this.calculation_total_price();
    });

    // 最大倍数
    this.prize_info_sub = this.betInfoService.get_prize_info_handler().subscribe(data => {
      if (data) {
        console.log('prize_info', data)
        setTimeout(() => {
          this.prize_info = Number(data.replace(',', '').replace(' ', '').split('-')[0]);
        });

        let max_prize = Number(data.replace(',', '').replace(' ', '').split('-')[0]);
        if (!isNaN(max_prize)) {
          this.max_multiple = Math.floor(this.current_game_prize / max_prize);
          this.bet_prize = max_prize;
          if (this.multiple > this.max_multiple) {
            this.multiple = this.max_multiple;
          }
        }
      }
    });

    // 监听注单信息
    this.bet_info_sub = this.betInfoService.get_bet_info_handler().subscribe(data => {
      if (data) {
        this.bet_info = data;
      }
    });

    // 监听位置信息
    this.bet_position_info_sub = this.betInfoService.get_bet_position_info_handle().subscribe(data => {
      if (data) {
        this.bet_position_info = data;
      } else {
        this.bet_position_info = null;
      }
    });

    // 清空购彩篮
    this.bet_basket_sub = this.betInfoService.get_clear_basket_list_handle().subscribe(data => {
      if (data) {
        this.bet_basket_info = [];
        this.bet_data_array = [];
      }
    });
  }

  ngOnDestroy() {
    // 取消 倒计时监听
    this.betInfoService.set_count_down_info_handler(0);
    this.count_down_sub.unsubscribe();
    // 取消 订阅注数
    this.betInfoService.set_bet_num_handler(0);
    this.bet_num_sub.unsubscribe();
    // 取消 模式监听
    this.multiple_sub.unsubscribe();
    // 取消 两一元监听
    this.money_mode_sub.unsubscribe();
    // 取消 奖金监听
    this.prize_info_sub.unsubscribe();
    // 取消 注单信息监听
    this.bet_info_sub.unsubscribe();
    // 取消 位置信息监听
    this.betInfoService.set_bet_position_info_handler(null);
    this.bet_position_info_sub.unsubscribe();
    // 取消 清空购彩篮监听
    this.betInfoService.set_clear_basket_list_handler(null);
    this.bet_basket_sub.unsubscribe();
  }

  /**
   * 初始化返点
   *
   * @memberof LotteryBetInfoComponent
   */
  public init_reward_percent_handler() {
    let n: any;
    Utils.game_type_array.forEach((item, index) => {
      if (item.indexOf(this.game_id) !== -1) {
        n = index;
      }
    });

    if (n === 1) {
      this.current_prize_group = Utils.max_prize_group - Utils.adt_val_11x5;
      // this.formatter_percent = value => `${value} - ${((this.max_prize_group - this.current_prize_group) / (Utils.base_prize - Utils.adt_val_11x5) * 100).toFixed(2)}%`;
    } else if (n === 4 || n === 5) {
      this.current_prize_group = Utils.max_prize_group - Utils.adt_val_dp;
      // this.formatter_percent = value => `${value} - ${((this.max_prize_group - this.current_prize_group) / (Utils.base_prize - Utils.adt_val_dp) * 100).toFixed(2)}%`;
    } else {
      this.current_prize_group = Utils.max_prize_group;
      // this.formatter_percent = value => `${value} - ${((this.max_prize_group - this.current_prize_group) / Utils.base_prize * 100).toFixed(2)}%`;
    }

    switch (n) {
      case 0:
        if (Utils.ssctx_gameid_arr.indexOf(this.game_id) !== -1) {
          this.current_game_prize = Utils.ssctx_max_prize;
        } else {
          this.current_game_prize = Utils.ssc_max_prize;
        }
        break;
      case 1:
        this.current_game_prize = Utils.x115_max_prize;
        break;
      case 2:
        this.current_game_prize = Utils.k3_max_prize;
        break;
      case 3:
        this.current_game_prize = Utils.pk10_max_prize;
        break;
      case 4:
        this.current_game_prize = Utils.dp_max_prize;
        break;
      case 5:
        this.current_game_prize = Utils.dp_max_prize;
        break;
      default: break;
    }
  }

  /**
   * 获取账户余额
   *
   * @memberof LotteryBetInfoComponent
   */
  public update_balance_info_handler() {
    this.api.get_balance_info(this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        this.balance = Number(data['data']['available']);
      } else {
        this.message.create('error', data['data']['error']);
      }
    });
  }

  /**
   * 倒计时
   *
   * @param {number} num
   * @memberof LotteryBetInfoComponent
   */
  public update_left_time(num: number) {
    let deadline: string = Utils.seconds_formate_timer(num);
    let arr = deadline.split('');
    this.h1 = arr[0];
    this.h2 = arr[1];
    this.m1 = arr[2];
    this.m2 = arr[3];
    this.s1 = arr[4];
    this.s2 = arr[5];
  }

  /**
   * 修改两/一元模式
   *
   * @memberof LotteryBetInfoComponent
   */
  public change_money_mode_handler() {
    Utils.price = Number(this.money_mode);
    this.betInfoService.set_money_mode_info_handler(Utils.price);
  }

  /**
   * 修改模式
   *
   * @memberof LotteryBetInfoComponent
   */
  public change_money_unit_handler() {
    this.betInfoService.set_money_unit_info_handler(Number(this.money_unit));
    this.calculation_total_price();
  }

  /**
   * 修改倍数
   *
   * @memberof LotteryBetInfoComponent
   */
  public change_multiple_handler() {
    if (this.multiple < 1 || !this.multiple) {
      setTimeout(() => {
        this.multiple = 1;
        this.calculation_total_price();
      });
    } else {
      this.calculation_total_price();
    }
  }

  /**
   * 计算价格
   *
   * @memberof LotteryBetInfoComponent
   */
  public calculation_total_price() {
    this.total_price = this.bet_num * this.multiple * Number(this.money_unit) * Utils.price;
  }

  /**
   * 直接投注操作
   *
   * @memberof LotteryBetInfoComponent
   */
  public fast_bet_handler() {
    this.create_bet_handler(true);
  }

  /**
   * 确认投注操作
   *
   * @memberof LotteryBetInfoComponent
   */
  public submit_bet_handler() {
    this.submit_bet_info_handler(this.bet_basket_info, false);
  }

  /**
   * 添加注单到构彩篮
   *
   * @memberof LotteryBetInfoComponent
   */
  public add_bet_into_basket_handler() {
    // 最大追号期数设置
    this.trace_issue_limit = this.game_issue_arr.length;
    this.profit_trace_num = this.trace_issue_limit >= 10 ? this.profit_trace_num : this.trace_issue_limit;
    this.same_trace_num = this.trace_issue_limit >= 10 ? this.same_trace_num : this.trace_issue_limit;
    this.more_trace_num = this.trace_issue_limit >= 10 ? this.more_trace_num : this.trace_issue_limit;

    if (this.bet_num !== 0) {
      this.create_bet_handler(false);
      this.betInfoService.set_clear_balls_handler('clear');
    }
  }

  /**
   * 创建注单
   *
   * @param {boolean} isFast
   * @memberof LotteryBetInfoComponent
   */
  public create_bet_handler(isFast: boolean) {
    // 是否存在注单
    let is_same: boolean = false;
    let same_id: number = 0;

    // 注单信息
    let bet = new Bet();
    // 玩法ID
    bet.way_id = this.play_id;
    // 注单
    bet.ball = Utils.format_bet_content(this.play_id, this.bet_info);
    // 注数
    bet.num = this.bet_num;
    // 模式
    bet.moneyunit = Number(this.money_unit);
    // 倍数
    bet.multiple = this.multiple;
    // 最大倍数
    bet.max_multiple = this.max_multiple;
    // 最大奖金
    bet.bet_prize = this.bet_prize;
    // 奖金组
    bet.prize_group = Utils.max_prize_group;
    // 位置
    bet.position = this.bet_position_info;

    if (!isFast) {
      // 加入购彩栏操作
      for (let i = 0; i < this.bet_basket_info.length; i++) {
        let b = this.bet_basket_info[i];
        if (b.way_id === bet.way_id && b.ball === bet.ball && b.moneyunit === bet.moneyunit) {
          if (this.exist_bet_no) {
            b.multiple = bet.multiple;
          } else {
            bet.multiple += b.multiple;
          }
          is_same = true;
          same_id = b.no_id;
        }
      }

      if (is_same) {
        this.bet_basket_info.splice((same_id - 1), 1, bet);
        is_same = false;
        same_id = -1;
        this.exist_bet_no = null;
      } else {
        if (this.exist_bet_no) {
          this.bet_basket_info.splice((this.exist_bet_no - 1), 1, bet);
          this.exist_bet_no = null;
        } else {
          this.bet_basket_info.splice(0, 0, bet);
        }
      }

      // 统计购彩栏数据
      this.update_bet_basket_info_handler();
    } else {
      // 直接投注
      let arr = [];
      arr.push(bet);
      this.submit_bet_info_handler(arr, isFast);
    }

    // 购彩篮数据
    this.bet_data_array = [];
    this.bet_basket_info.forEach(item => {
      this.bet_data_array.push(item);
    });

    // 设置追号起始最大限制
    let arr = [];
    this.bet_basket_info.forEach(item => {
      arr.push(item.max_multiple);
    });

    if (arr.length > 0) {
      this.start_multiple_limit = arr.sort()[0];
    }
  }

  /**
   * 提交注单信息
   *
   * @param {*} bet_info
   * @param {boolean} type
   * @memberof LotteryBetInfoComponent
   */
  public submit_bet_info_handler(info: any, type: boolean) {
    if (this.is_lock) {
      return;
    }
    this.is_lock = true;
    this.modalService.info({
      nzTitle: '温馨提示',
      nzContent: '投注中...',
      nzCancelText: null,
      nzOkText: null
    });

    let bet_info = new BetInfo();
    bet_info.game_id = this.game_id;
    bet_info.balls = Utils.encode_info_handler(JSON.stringify(info), 1);
    // 直接投注
    if (type) {
      bet_info.amount = Number(info[0]['num']) * Number(info[0]['multiple']) * Number(info[0]['moneyunit']) * Number(info[0]['onePrice']);
    } else {
      // 确认投注
      bet_info.amount = 0;
      info.forEach(item => {
        bet_info.amount += Number(item['num']) * Number(item['multiple']) * Number(item['moneyunit']) * Number(item['onePrice']);
      });
    }
    bet_info.orders = {};
    bet_info.orders[this.game_issue_arr[0]['number']] = 1;

    if (this.token) {
      this.api.get_submit_bet_info(bet_info, this.token).subscribe(data => {
        setTimeout(() => {
          this.is_lock = false;
          this.modalService.closeAll();
        }, 500);

        if (data && data['isSuccess']) {
          if (!type) {
            this.bet_basket_info = [];
            this.bet_data_array = [];
          }
          this.betInfoService.set_clear_balls_handler('clear');

          // 更新购彩篮统计
          this.update_bet_basket_info_handler();
          // 更新账户余额
          this.update_balance_info_handler();
          this.betInfoService.set_credit_update_balance_handler(true);
          // 更新投注记录
          this.betInfoService.set_bet_record_info(1);

          this.message.create('success', '投注成功，祝您中奖！');
        } else {
          this.message.create('error', data['data']['error']);
        }
      });
    }
  }

  /**
   * 确认提交追号信息
   *
   * @memberof LotteryBetInfoComponent
   */
  public submit_trace_info_hanler() {
    if (this.is_lock) {
      return;
    }
    this.is_lock = true;
    this.modalService.info({
      nzTitle: '温馨提示',
      nzContent: '追号投注中...',
      nzCancelText: null,
      nzOkText: null
    });

    let trace_info: any;
    switch (this.trace_type) {
      case 'profit': trace_info = this.profit_trace_data; break;
      case 'same': trace_info = this.same_trace_data; break;
      case 'more': trace_info = this.more_trace_data; break;
      default: break;
    }

    let bet_info = new BetInfo();
    bet_info.game_id = this.game_id;
    bet_info.balls = Utils.encode_info_handler(JSON.stringify(this.bet_basket_info), 1);
    bet_info.amount = this.total_trace_val;
    bet_info.orders = {};
    bet_info.traceWinStop = this.win_stop ? 1 : 0;
    bet_info.isTrace = 1;
    trace_info.forEach(item => {
      bet_info.orders[item['issue']] = item['multiple'];
    });

    if (this.token) {
      this.api.get_submit_bet_info(bet_info, this.token).subscribe(data => {
        setTimeout(() => {
          this.is_lock = false;
          this.modalService.closeAll();
        }, 500);

        if (data && data['isSuccess']) {
          // 清空操作
          this.profit_trace_data = [];
          this.same_trace_data = [];
          this.more_trace_data = [];

          this.bet_basket_info = [];
          this.bet_data_array = [];

          this.dismiss_model_handler();

          // 更新购彩篮统计
          this.update_bet_basket_info_handler();
          // 更新账户余额
          this.update_balance_info_handler();
          this.betInfoService.set_credit_update_balance_handler(true);
          // 更新投注记录
          this.betInfoService.set_bet_record_info(1);

          this.message.create('success', '投注成功，祝您中奖！');
        } else {
          this.message.create('error', data['data']['error']);
        }
      });
    }
  }

  /**
   * 撤销购彩篮内的注单
   *
   * @param {number} id
   * @memberof LotteryBetInfoComponent
   */
  public delete_bet_in_basket_handler(id: number) {
    let data = this.bet_basket_info.filter(d => d.no_id !== id);
    this.bet_basket_info = data;
    this.bet_data_array = data;

    this.update_bet_basket_info_handler();
  }

  /**
   * 统计购彩栏数据
   *
   * @memberof LotteryBetInfoComponent
   */
  public update_bet_basket_info_handler() {
    this.bet_basket_total_bet_num = 0;
    this.bet_basket_total_bet_val = 0;
    this.bet_basket_info.forEach((element, index) => {
      element.no_id = index + 1;
      this.bet_basket_total_bet_num += element.num;
      this.bet_basket_total_bet_val += element.num * element.multiple * element.moneyunit * element.onePrice;
    });
  }

  /**
   * 更新已选择的注单信息
   *
   * @param {Bet} bet
   * @memberof LotteryBetInfoComponent
   */
  public update_selected_bet_info_handler(bet: Bet) {
    // 单式不进行反选
    if (this.danshi_arr.indexOf(bet.way_id) !== -1) {
      return;
    }

    this.betInfoService.set_clear_balls_handler('clear');
    this.exist_bet_no = bet.no_id;
    this.betInfoService.set_selected_play_handler(bet);
  }

  /**
   * 创建追号注单
   *
   * @param {string} type
   * @memberof LotteryBetInfoComponent
   */
  public create_trace_bet_handler(type: string) {
    this.isVisible_trace_modal = true;

    this.trace_type = type;
    switch (type) {
      case 'profit':
        // 单一玩法
        let way_id_arr = this.bet_data_array.map((item) => {
          return item.way_id;
        });
        let len1 = Array.from(new Set(way_id_arr)).length;
        // 单一模式
        let moneyunit_arr = this.bet_data_array.map((item) => {
          return item.moneyunit;
        });
        let len2 = Array.from(new Set(moneyunit_arr)).length;

        if (len1 > 1 || len2 > 1) {
          this.isVisible_trace_modal = false;
          this.message.create('info', `利润率追号不支持混投，请确保您的投注都为同一玩法类型，且元角模式一致。`);
        } else {
          this.trace_title = '利润率追号';
          this.show_profit_modeal_handler();
        }
        break;
      case 'same':
        this.trace_title = '同倍追号';
        this.show_same_modal_handler();
        break;
      case 'more':
        this.trace_title = '翻倍追号';
        this.show_more_modal_handler();
        break;
      default: break;
    }

    // 统计购彩栏数据
    this.update_bet_basket_info_handler();
  }

  /**
   * 利润率追号
   *
   * @memberof LotteryBetInfoComponent
   */
  public show_profit_modeal_handler() {
    this.profit_trace_data = [];

    for (let i = 0; i < this.profit_trace_num; i++) {
      let row_data = new ProfitRowData();
      row_data.id = i + 1;
      row_data.issue = this.game_issue_arr[i]['number'];
      row_data.multiple = this.profit_trace_start;
      row_data.bet_val = 0;
      this.bet_basket_info.forEach(item => {
        item.multiple = 1;
        row_data.bet_val += item.num * item.multiple * item.moneyunit * item.onePrice;
      });
      row_data.value = row_data.bet_val * row_data.multiple;
      row_data.prize = this.bet_prize * row_data.multiple;
      row_data.profit = (row_data.prize - row_data.value * row_data.id);

      let p = row_data.profit / (row_data.value * row_data.id) * 100;

      if (p < this.profit_trace_percent && i === 0) {
        this.message.create('info', '您设置的参数无法达到盈利，请重新设置');
        this.isVisible_trace_modal = false;
        break;
      }

      // 低于利率加倍
      while (p < this.profit_trace_percent) {
        // 计算当前成本值
        let v = 0;
        this.profit_trace_data.forEach(item => {
          v += item.value;
        });

        // 限制最大倍数
        let max_multiple = this.max_multiple;
        if (row_data.multiple >= max_multiple) {
          row_data.multiple = max_multiple;
          row_data.value = row_data.bet_val * row_data.multiple;
          row_data.prize = this.bet_prize * row_data.multiple;
          row_data.profit = (row_data.prize - row_data.value - v);
          p = row_data.profit / (row_data.value + v) * 100;
          break;
        }

        row_data.multiple += this.profit_trace_data[(i - 1)].multiple + 1;
        row_data.value = row_data.bet_val * row_data.multiple;
        row_data.prize = this.bet_prize * row_data.multiple;
        row_data.profit = (row_data.prize - row_data.value - v);
        p = row_data.profit / (row_data.value + v) * 100;

        if (p > this.profit_trace_percent) {
          break;
        }
      }
      row_data.percentage = p;
      this.profit_trace_data.push(row_data);
    }

    if (this.isVisible_trace_modal) {
      // 追号信息
      this.start_trace_issue = this.profit_trace_data[0]['issue'];
      this.end_trace_issue = this.profit_trace_data[this.profit_trace_data.length - 1]['issue'];
      this.total_issue_num = this.profit_trace_data.length;
      this.total_trace_val = 0;
      this.profit_trace_data.forEach(item => {
        this.total_trace_val = Number((this.total_trace_val + Number(item['value'])).toFixed(2));
      });
    }
  }

  /**
   * 同倍追号
   *
   * @memberof LotteryBetInfoComponent
   */
  public show_same_modal_handler() {
    this.same_trace_data = [];
    for (let i = 0; i < this.same_trace_num; i++) {
      let row_data = new SameRowData();
      row_data.id = i + 1;
      row_data.issue = this.game_issue_arr[i]['number'];
      row_data.multiple = this.same_trace_start;
      row_data.time = this.game_issue_arr[i]['time'];
      row_data.bet_val = 0;
      this.bet_basket_info.forEach(item => {
        item.multiple = 1;
        row_data.bet_val += item.num * item.multiple * item.moneyunit * item.onePrice;
      });
      row_data.value = row_data.bet_val * row_data.multiple;
      this.same_trace_data.push(row_data);
    }

    // 追号信息
    this.start_trace_issue = this.same_trace_data[0]['issue'];
    this.end_trace_issue = this.same_trace_data[this.same_trace_data.length - 1]['issue'];
    this.total_issue_num = this.same_trace_data.length;
    this.total_trace_val = 0;
    this.same_trace_data.forEach(item => {
      this.total_trace_val = Number((this.total_trace_val + Number(item['value'])).toFixed(2));
    });
  }

  /**
   * 翻倍追号
   *
   * @memberof LotteryBetInfoComponent
   */
  public show_more_modal_handler() {
    this.more_trace_data = [];

    for (let i = 0; i < this.more_trace_num; i++) {
      let row_data = new MoreRowData();
      row_data.id = i + 1;
      row_data.issue = this.game_issue_arr[i]['number'];
      row_data.multiple = this.more_trace_start;
      row_data.time = this.game_issue_arr[i]['time'];
      let n = Math.floor(i / this.more_trace_interval);
      if (n > 0) {
        row_data.multiple = this.more_trace_start * Math.pow(this.more_trace_multiple, n);
        if (row_data.multiple > this.start_multiple_limit) {
          row_data.multiple = this.start_multiple_limit;
        }
      }
      row_data.bet_val = 0;
      this.bet_basket_info.forEach(item => {
        item.multiple = 1;
        row_data.bet_val += item.num * item.multiple * item.moneyunit * item.onePrice;
      });
      row_data.value = row_data.bet_val * row_data.multiple;
      this.more_trace_data.push(row_data);
    }

    // 追号信息
    this.start_trace_issue = this.more_trace_data[0]['issue'];
    this.end_trace_issue = this.more_trace_data[this.more_trace_data.length - 1]['issue'];
    this.total_issue_num = this.more_trace_data.length;
    this.total_trace_val = 0;
    this.more_trace_data.forEach(item => {
      this.total_trace_val = Number((this.total_trace_val + Number(item['value'])).toFixed(2));
    });
  }

  /**
   * 关闭追号窗口
   *
   * @memberof LotteryBetInfoComponent
   */
  public dismiss_model_handler() {
    this.isVisible_trace_modal = false;
  }

  /**
   * 切换中奖即停按钮
   *
   * @memberof LotteryBetInfoComponent
   */
  public switch_win_stop_btn_handler() {
    this.win_stop = !this.win_stop;
  }

  /**
   * 前往奖金详情页面
   *
   * @memberof LotteryBetInfoComponent
   */
  public goto_prize_detail_page_handle() {
    let type_num: any;
    Utils.game_type_array.forEach((item, index) => {
      if (item.indexOf(this.game_id) !== -1) {
        if (this.game_id !== 14) {
          type_num = index;
        } else {
          type_num = 5;
        }
      }
    });

    this.router.navigate(['/data-center/content/prize'], { queryParams: { 'type': type_num } });
  }

}

class Bet {
  // ID
  public no_id: number = 0;
  // 玩法ID
  public way_id: number;
  // 注单
  public ball: string;
  // 倍数
  public multiple: number;
  // 注单数量
  public num: number;
  // 模式
  public moneyunit: number;
  // 单价
  public onePrice: number = Utils.price;
  // 奖金组
  public prize_group: number;
  // 最大倍数
  public max_multiple: number;
  // 奖金
  public bet_prize: number;
  // 位置
  public position: any;

  constructor() {

  }
}

class BetInfo {
  // 游戏ID
  public game_id: number;
  // 投注内容
  public balls: any;
  // 加密
  public is_encoded: number = 1;
  // 总金额
  public amount: number = 0;
  // 追号 0 , 1
  public isTrace: number = 0;
  // 追号 {期号 : 倍数 , 期号 : 倍数}
  public orders: any = {};
  public traceStopValue: number = 1;
  // 追中即停 0 , 1
  public traceWinStop: number = 1;
  // 投注来源
  public bet_source: string = 'web';

  constructor() {

  }
}

/**
 * 利率追号行数据
 *
 * @class ProfitRowData
 */
class ProfitRowData {
  public id: number;
  public issue: string;
  public multiple: number;
  public bet_val: number;
  public value: number;
  public prize: number;
  public profit: number;
  public percentage: number;
  constructor() {

  }
}

/**
 * 同倍追号行数据
 *
 * @class SameRowData
 */
class SameRowData {
  public id: number;
  public issue: string;
  public multiple: number;
  public bet_val: number;
  public value: number;
  public time: string;
  constructor() {

  }
}

/**
 * 翻倍追号行数据
 *
 * @class MoreData
 */
class MoreRowData {
  public id: number;
  public issue: string;
  public multiple: number;
  public bet_val: number;
  public value: number;
  public time: string;
  constructor() {

  }
}


