import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-pk10-credit-longhudou',
  templateUrl: './pk10-credit-longhudou.component.html',
  styleUrls: ['./pk10-credit-longhudou.component.css']
})
export class Pk10CreditLonghudouComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    [
      { name: `第一名vs第十名`, p_arr: [0, 0], val_arr: [null, null], is_select_arr: [false, false] },
      { name: `第二名vs第九名`, p_arr: [0, 0], val_arr: [null, null], is_select_arr: [false, false] },
      { name: `第三名vs第八名`, p_arr: [0, 0], val_arr: [null, null], is_select_arr: [false, false] }
    ],
    [
      { name: `第四名vs第七名`, p_arr: [0, 0], val_arr: [null, null], is_select_arr: [false, false] },
      { name: `第五名vs第六名`, p_arr: [0, 0], val_arr: [null, null], is_select_arr: [false, false] }
    ]
  ];

  // 路数类型
  public chart_type: string = '0';
  // 路数数据
  public chart_original_data: Array<any> = [
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '虎', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '虎', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '虎', bs_5: '龙' },
    { bs_1: '虎', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '虎', bs_3: '虎', bs_4: '龙', bs_5: '虎' },
    { bs_1: '龙', bs_2: '龙', bs_3: '虎', bs_4: '龙', bs_5: '虎' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' },
    { bs_1: '龙', bs_2: '龙', bs_3: '龙', bs_4: '龙', bs_5: '龙' }
  ];

  public chart_data_1: any = [[], [], [], [], [], []];
  public chart_data_2: any = [[], [], [], [], [], []];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
    this.render_chart_data_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof Pk10CreditLonghudouComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(group => {
        for (let i = 0; i <= 1; i++) {
          group.val_arr[i] = null;
          group.is_select_arr[i] = false;
        }
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof Pk10CreditLonghudouComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach(item => {
      item.forEach(group => {
        group.p_arr[0] = (this.prize_group / 2000) * (1 / p_arr[0]);
        group.p_arr[1] = (this.prize_group / 2000) * (1 / p_arr[0]);
      });
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} item
   * @param {number} index
   * @memberof Pk10CreditLonghudouComponent
   */
  public switch_ball_status_handler(item: any, index: number) {
    item.is_select_arr[index] = !item.is_select_arr[index];
    if (!item.is_select_arr[index]) {
      item.val_arr[index] = null;
    } else {
      item.val_arr[index] = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @param {number} index
   * @memberof Pk10CreditLonghudouComponent
   */
  public selecte_ball_handler(item: any, index: number) {
    item.is_select_arr[index] = true;
    item.val_arr[index] = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof Pk10CreditLonghudouComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(group => {
        group.is_select_arr.forEach((s, n) => {
          if (s) {
            group.val_arr[n] = this.bet_value;
          }
        });
      });
    });
  }

  /**
   * 渲染图表
   *
   * @memberof Pk10CreditLonghudouComponent
   */
  public render_chart_data_handler() {
    let type_index = Number(this.chart_type);
    this.chart_data_1 = [[], [], [], [], [], []];
    this.chart_data_2 = [[], [], [], [], [], []];

    let data_bs: any = [];

    switch (type_index) {
      case 0:
        data_bs = this.chart_original_data.map(item => {
          return { val: item.bs_1 };
        });
        break;
      case 1:
        data_bs = this.chart_original_data.map(item => {
          return { val: item.bs_2 };
        });
        break;
      case 2:
        data_bs = this.chart_original_data.map(item => {
          return { val: item.bs_3 };
        });
        break;
      case 3:
        data_bs = this.chart_original_data.map(item => {
          return { val: item.bs_4 };
        });
        break;
      case 4:
        data_bs = this.chart_original_data.map(item => {
          return { val: item.bs_5 };
        });
        break;
      default: break;
    }

    this.render_bs_content_1_handler(data_bs);
    this.render_bs_content_2_handler(data_bs);
  }

  /**
   * 渲染大小图表1
   *
   * @param {*} data
   * @memberof Pk10CreditLonghudouComponent
   */
  public render_bs_content_1_handler(data: any) {
    let r: any;
    let c: any;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (i === 0) {
        r = 0;
        c = 0;
        let info = {
          val: item.val,
          clr: item.val === '小' ? 'blue' : 'red'
        };
        this.chart_data_1[r][c] = info;
      } else {
        let pre_info = this.chart_data_1[r][c];

        if (pre_info.val === item.val) {
          if (r !== 5) {
            r = r + 1;
            c = c;
          } else {
            r = 5;
            c = c + 1;
          }
        } else {
          r = 0;
          c = c + 1;
        }
        let info = {
          val: item.val,
          clr: item.val === '虎' ? 'blue' : 'red'
        };

        this.chart_data_1[r][c] = info;
      }
    }
  }

  /**
   * 渲染大小图表2
   *
   * @param {*} data
   * @memberof Pk10CreditLonghudouComponent
   */
  public render_bs_content_2_handler(data: any) {
    let r: any = 0;
    let c: any = 0;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let info = {
        val: item.val,
        clr: item.val === '虎' ? 'blue' : 'red'
      };

      if (i === 0) {
        r = 0;
        c = 0;
      } else {
        if (r === 5) {
          r = 0;
          c += 1;
        } else {
          r += 1;
          c = c;
        }
      }

      this.chart_data_2[r][c] = info;
    }
  }

}
