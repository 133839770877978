import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { Utils } from 'app/common/util';
import { ApiService } from 'app/providers/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private message: NzMessageService,
    private api: ApiService,
    private router: Router

  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (Utils.game_type_array) {
      let game_type_index;
      let game_id = Number(route.params.id);

      for (let i = 0; i < Utils.game_type_array.length; i++) {
        let game_type_arr = Utils.game_type_array[i];
        if (game_type_arr.indexOf(game_id) !== -1) {
          game_type_index = i;
          break;
        }
      }

      switch (game_type_index) {
        case 0:
          for (let i = 0; i < Utils.game_list[0].length; i++) {
            if (Utils.game_list[0][i].id === game_id) {
              if (Utils.game_list[0][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[0][i].is_stop;
            }
          }
          break;
        case 1:
          for (let i = 0; i < Utils.game_list[1].length; i++) {
            if (Utils.game_list[1][i].id === game_id) {
              if (Utils.game_list[1][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[1][i].is_stop;
            }
          }
          break;
        case 2:
          for (let i = 0; i < Utils.game_list[2].length; i++) {
            if (Utils.game_list[2][i].id === game_id) {
              if (Utils.game_list[2][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[2][i].is_stop;
            }
          }
          break;
        case 3:
          for (let i = 0; i < Utils.game_list[3].length; i++) {
            if (Utils.game_list[3][i].id === game_id) {
              if (Utils.game_list[3][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[3][i].is_stop;
            }
          }
          break;
        case 4:
          for (let i = 0; i < Utils.game_list[4].length; i++) {
            if (Utils.game_list[4][i].id === game_id) {
              if (Utils.game_list[4][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[4][i].is_stop;
            }
          }
          break;
        case 5:
          for (let i = 0; i < Utils.game_list[5].length; i++) {
            if (Utils.game_list[5][i].id === game_id) {
              if (Utils.game_list[5][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[5][i].is_stop;
            }
          }
          break;
        case 6:
          for (let i = 0; i < Utils.game_list[6].length; i++) {
            if (Utils.game_list[6][i].id === game_id) {
              if (Utils.game_list[6][i].is_stop) {
                this.router.navigate(['/page-close']);
              }
              return !Utils.game_list[6][i].is_stop;
            }
          }
          break;
        default: break;
      }
    } else {
      this.api.get_request_game_list_info().subscribe(data => {
        if (data) {
          Utils.game_type_array = data['game_type'];

          let game_type_index;
          let game_id = Number(route.params.id);

          for (let i = 0; i < Utils.game_type_array.length; i++) {
            let game_type_arr = Utils.game_type_array[i];
            if (game_type_arr.indexOf(game_id) !== -1) {
              game_type_index = i;
              break;
            }
          }

          switch (game_type_index) {
            case 0:
              for (let i = 0; i < Utils.game_list[0].length; i++) {
                if (Utils.game_list[0][i].id === game_id) {
                  if (Utils.game_list[0][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/ssc/${game_id}`]);
                  }
                  return !Utils.game_list[0][i].is_stop;
                }
              }
              break;
            case 1:
              for (let i = 0; i < Utils.game_list[1].length; i++) {
                if (Utils.game_list[1][i].id === game_id) {
                  if (Utils.game_list[1][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/11x5/${game_id}`]);
                  }
                  return !Utils.game_list[1][i].is_stop;
                }
              }
              break;
            case 2:
              for (let i = 0; i < Utils.game_list[2].length; i++) {
                if (Utils.game_list[2][i].id === game_id) {
                  if (Utils.game_list[2][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/k3/${game_id}`]);
                  }
                  return !Utils.game_list[2][i].is_stop;
                }
              }
              break;
            case 3:
              for (let i = 0; i < Utils.game_list[3].length; i++) {
                if (Utils.game_list[3][i].id === game_id) {
                  if (Utils.game_list[3][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/pk10/${game_id}`]);
                  }
                  return !Utils.game_list[3][i].is_stop;
                }
              }
              break;
            case 4:
              for (let i = 0; i < Utils.game_list[4].length; i++) {
                if (Utils.game_list[4][i].id === game_id) {
                  if (Utils.game_list[4][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  }
                  return !Utils.game_list[4][i].is_stop;
                }
              }
              break;
            case 5:
              for (let i = 0; i < Utils.game_list[5].length; i++) {
                if (Utils.game_list[5][i].id === game_id) {
                  if (Utils.game_list[5][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/lhc/${game_id}`]);
                  }
                  return !Utils.game_list[5][i].is_stop;
                }
              }
              break;
            case 6:
              for (let i = 0; i < Utils.game_list[6].length; i++) {
                if (Utils.game_list[6][i].id === game_id) {
                  if (Utils.game_list[6][i].is_stop) {
                    this.router.navigate(['/page-close']);
                  } else {
                    this.router.navigate([`/lottery/pcdd/${game_id}`]);
                  }
                  return !Utils.game_list[6][i].is_stop;
                }
              }
              break;
            default: break;
          }
        }
      });
    }
  }
}
