import { Component, OnInit, Input } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-shengxiao-yixiao',
  templateUrl: './lhc-shengxiao-yixiao.component.html',
  styleUrls: ['./lhc-shengxiao-yixiao.component.css']
})
export class LhcShengxiaoYixiaoComponent extends GameCreditBase implements OnInit {

  @Input()
  public current_zodiac: number;

  // balls
  public balls_array: Array<any> = [
    { name: '鼠', balls: [], is_select: false, p: 0, val: null },
    { name: '牛', balls: [], is_select: false, p: 0, val: null },
    { name: '虎', balls: [], is_select: false, p: 0, val: null },
    { name: '兔', balls: [], is_select: false, p: 0, val: null },
    { name: '龙', balls: [], is_select: false, p: 0, val: null },
    { name: '蛇', balls: [], is_select: false, p: 0, val: null },
    { name: '马', balls: [], is_select: false, p: 0, val: null },
    { name: '羊', balls: [], is_select: false, p: 0, val: null },
    { name: '猴', balls: [], is_select: false, p: 0, val: null },
    { name: '鸡', balls: [], is_select: false, p: 0, val: null },
    { name: '狗', balls: [], is_select: false, p: 0, val: null },
    { name: '猪', balls: [], is_select: false, p: 0, val: null }
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.init_zodiac_balls_handler();
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public reset_balls_panel_handler() {
    this.balls_array.forEach(item => {
      item.is_select = false;
      item.val = null;
    });
  }

  /**
   * 初始化生肖数组
   *
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public init_zodiac_balls_handler() {
    let index = 11 - (this.current_zodiac - 1) % 12;
    let cur_zodiac_ball = [1, 13, 25, 37, 49];

    cur_zodiac_ball.forEach(item => {
      let ball = Utils.lhc_balls_info[(item - 1)];
      this.balls_array[index]['balls'].push(ball);
    });

    for (let i = 1; i < 12; i++) {
      let n = ((12 + index - i) % 12);
      let b1 = cur_zodiac_ball[0] + i;
      let b2 = cur_zodiac_ball[1] + i;
      let b3 = cur_zodiac_ball[2] + i;
      let b4 = cur_zodiac_ball[3] + i;
      let b_arr = [b1, b2, b3, b4];
      b_arr.forEach(item => {
        let ball = Utils.lhc_balls_info[(item - 1)];
        this.balls_array[n]['balls'].push(ball);
      });
    }
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability['probability'];
    let index = 11 - (this.current_zodiac - 1) % 12;
    function findArr(arr) {
      if (arr[0] !== arr[1] && arr[0] !== arr[2]) {
        return arr[0];
      }
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr[i] !== arr[0]) {
          return arr[i];
        }
      }
    }
    let same = findArr(p_arr);
    p_arr.forEach((item, index) => {
      if (item === same) {
        p_arr.splice(index, 1);
      }
    });
    p_arr.splice(index, 0, same);

    this.balls_array.forEach((item, index) => {
      let prize = (this.prize_group / 2000) * (1 / p_arr[index]);

      item['p'] = prize;
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public selecte_ball_handler(item: any) {
    if (!item.is_select) {
      item.is_select = true;
      item.val = this.bet_value;

      // balls内容整理
      this.update_bet_balls_info();
    }
  }

  /**
   * 失去焦点
   *
   * @param {*} item
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public change_val_handler(item: any) {
    if (item.is_select && !item.val) {
      item.val = this.bet_value;
    }

    let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
    item.val = item.val > max_m ? max_m : item.val;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public render_bet_value_handler() {
    this.balls_array.forEach(item => {
      if (item.is_select) {
        item.val = this.bet_value;
      }
    });

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcShengxiaoYixiaoComponent
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.is_selected_info = this.balls_array.filter(item => (item.is_select && item.val !== null));

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      this.is_selected_info.forEach(item => {
        let bet_info = new BetInfo();
        bet_info.way_id = Number(this.play_info['id']);

        let ball_str = [];
        item.balls.forEach((item: any) => {
          ball_str.push(item.ball);
        });
        bet_info.ball = ball_str.join(',');

        bet_info.num = 1;
        bet_info.prize_group = Number(this.prize_group);

        let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
        item.val = item.val > max_m ? max_m : item.val;
        bet_info.multiple = Number(item.val);

        ball_arr.push(bet_info);
      });

      this.betInfoService.set_bet_info_handler(ball_arr);
      this.betInfoService.set_bet_num_handler(this.is_selected_info.length);
    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
