import { Component, OnInit } from '@angular/core';
import { GameDanShiBase } from 'app/common/gameDanShiBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-ssc-housan-zuxuan-hunhe',
  templateUrl: './ssc-housan-zuxuan-hunhe.component.html',
  styleUrls: ['./ssc-housan-zuxuan-hunhe.component.css']
})
export class SscHousanZuxuanHunheComponent extends GameDanShiBase implements OnInit {

  // 输入内容
  public input_content: string = '';

  public tips: string = `说明：
  1、支持常见的各种单式格式，间隔符如： 换行符 回车 逗号 分号等！
  2、上传文件后缀必须是.txt格式,最大支持10万注！
  3、文件较大时会导致上传时间较长，请耐心等待！
  
  格式范例：123 112 341 091`;

  constructor(
    public betInfoService: BetInfoService,
    public msgService: NzMessageService
  ) {
    super(betInfoService, msgService);
  }

  ngOnInit() {
  }

  /**
   * 清理错误 & 去重
   *
   * @returns
   * @memberof SscHousanZuxuanHunheComponent
   */
  public fix_error_repeat_bet_info_handler() {
    if (this.input_content.length === 0) {
      this.betInfoService.set_bet_num_handler(0);
      this.betInfoService.set_bet_info_handler(null);
      return;
    }

    let arr = this.sort_out_bet_info_handler(this.input_content).filter((item) => {
      let is_effect = item.length === 3 && Array.from(new Set(item)).length > 1;
      return is_effect;
    });

    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('').sort().join('');
    }
    this.bet_arr = Array.from(new Set(arr));

    this.input_content = this.bet_arr.join(' ');
    this.msgService.success('内容整理完毕！');

    // 注单信息
    this.betInfoService.set_bet_num_handler(this.bet_arr.length);
    this.betInfoService.set_bet_info_handler(this.bet_arr.join('|'));
  }

}
