import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { AppRoutingModule } from './app-routing.module';
import { HomepageComponent } from './homepage/homepage.component';
import { LotteryPageComponent } from './lottery-page/lottery-page.component';
import { LotteryLobbyComponent } from './lottery-page/lottery-lobby/lottery-lobby.component';
import { LotterySscComponent } from './lottery-page/lottery-ssc/lottery-ssc.component';
import { LotteryX115Component } from './lottery-page/lottery-x115/lottery-x115.component';
import { LotteryPk10Component } from './lottery-page/lottery-pk10/lottery-pk10.component';
import { LotteryK3Component } from './lottery-page/lottery-k3/lottery-k3.component';
import { LotteryDipinComponent } from './lottery-page/lottery-dipin/lottery-dipin.component';
import { DataCenterPageComponent } from './data-center-page/data-center-page.component';
import { DataCenterContentComponent } from './data-center-page/data-center-content/data-center-content.component';
import { PersonalPrizeComponent } from './data-center-page/data-center-content/personal/personal-prize/personal-prize.component';
import { PersonalBetRecordComponent } from './data-center-page/data-center-content/personal/personal-bet-record/personal-bet-record.component';
import { PersonalTraceRecordComponent } from './data-center-page/data-center-content/personal/personal-trace-record/personal-trace-record.component';
import { PersonalTransactionRecordComponent } from './data-center-page/data-center-content/personal/personal-transaction-record/personal-transaction-record.component';
import { PersonalTransferRecordComponent } from './data-center-page/data-center-content/personal/personal-transfer-record/personal-transfer-record.component';
import { MessageAnnouncementComponent } from './data-center-page/data-center-content/message/message-announcement/message-announcement.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LotteryLhcComponent } from './lottery-page/lottery-lhc/lottery-lhc.component';
import { SscHeaderComponent } from './lottery-page/lottery-ssc/ssc-header/ssc-header.component';
import { SscPanelComponent } from './lottery-page/lottery-ssc/ssc-panel/ssc-panel.component';
import { SscTrendComponent } from './lottery-page/lottery-ssc/ssc-trend/ssc-trend.component';
import { LotteryBetInfoComponent } from './lottery-page/lottery-bet-info/lottery-bet-info.component';
import { LotteryBetRecordComponent } from './lottery-page/lottery-bet-record/lottery-bet-record.component';
import { X115HeaderComponent } from './lottery-page/lottery-x115/x115-header/x115-header.component';
import { X115PanelComponent } from './lottery-page/lottery-x115/x115-panel/x115-panel.component';
import { X115TrendComponent } from './lottery-page/lottery-x115/x115-trend/x115-trend.component';
import { K3HeaderComponent } from './lottery-page/lottery-k3/k3-header/k3-header.component';
import { K3PanelComponent } from './lottery-page/lottery-k3/k3-panel/k3-panel.component';
import { K3TrendComponent } from './lottery-page/lottery-k3/k3-trend/k3-trend.component';
import { Pk10HeaderComponent } from './lottery-page/lottery-pk10/pk10-header/pk10-header.component';
import { Pk10PanelComponent } from './lottery-page/lottery-pk10/pk10-panel/pk10-panel.component';
import { Pk10TrendComponent } from './lottery-page/lottery-pk10/pk10-trend/pk10-trend.component';
import { DipinHeaderComponent } from './lottery-page/lottery-dipin/dipin-header/dipin-header.component';
import { DipinPanelComponent } from './lottery-page/lottery-dipin/dipin-panel/dipin-panel.component';
import { DipinTrendComponent } from './lottery-page/lottery-dipin/dipin-trend/dipin-trend.component';
import { LotteryPlwComponent } from './lottery-page/lottery-plw/lottery-plw.component';
import { PlwHeaderComponent } from './lottery-page/lottery-plw/plw-header/plw-header.component';
import { PlwPanelComponent } from './lottery-page/lottery-plw/plw-panel/plw-panel.component';
import { PlwTrendComponent } from './lottery-page/lottery-plw/plw-trend/plw-trend.component';
import { SscWuxingZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zhixuan/ssc-wuxing-zhixuan-fushi/ssc-wuxing-zhixuan-fushi.component';
import { SscWuxingZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zhixuan/ssc-wuxing-zhixuan-danshi/ssc-wuxing-zhixuan-danshi.component';
import { SscWuxingZhixuanZuheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zhixuan/ssc-wuxing-zhixuan-zuhe/ssc-wuxing-zhixuan-zuhe.component';
import { SscWuxingZuxuanZx120Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx120/ssc-wuxing-zuxuan-zx120.component';
import { SscWuxingZuxuanZx60Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx60/ssc-wuxing-zuxuan-zx60.component';
import { SscWuxingZuxuanZx30Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx30/ssc-wuxing-zuxuan-zx30.component';
import { SscWuxingZuxuanZx20Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx20/ssc-wuxing-zuxuan-zx20.component';
import { SscWuxingZuxuanZx10Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx10/ssc-wuxing-zuxuan-zx10.component';
import { SscWuxingZuxuanZx5Component } from './lottery-page/lottery-ssc/ssc-play/ssc-wuxing/ssc-wuxing-zuxuan/ssc-wuxing-zuxuan-zx5/ssc-wuxing-zuxuan-zx5.component';
import { SscSixingZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zhixuan/ssc-sixing-zhixuan-fushi/ssc-sixing-zhixuan-fushi.component';
import { SscSixingZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zhixuan/ssc-sixing-zhixuan-danshi/ssc-sixing-zhixuan-danshi.component';
import { SscSixingZhixuanZuheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zhixuan/ssc-sixing-zhixuan-zuhe/ssc-sixing-zhixuan-zuhe.component';
import { SscSixingZuxuanZx24Component } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zuxuan/ssc-sixing-zuxuan-zx24/ssc-sixing-zuxuan-zx24.component';
import { SscSixingZuxuanZx12Component } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zuxuan/ssc-sixing-zuxuan-zx12/ssc-sixing-zuxuan-zx12.component';
import { SscSixingZuxuanZx6Component } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zuxuan/ssc-sixing-zuxuan-zx6/ssc-sixing-zuxuan-zx6.component';
import { SscSixingZuxuanZx4Component } from './lottery-page/lottery-ssc/ssc-play/ssc-sixing/ssc-sixing-zuxuan/ssc-sixing-zuxuan-zx4/ssc-sixing-zuxuan-zx4.component';
import { SscQiansanZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zhixuan/ssc-qiansan-zhixuan-fushi/ssc-qiansan-zhixuan-fushi.component';
import { SscQiansanZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zhixuan/ssc-qiansan-zhixuan-danshi/ssc-qiansan-zhixuan-danshi.component';
import { SscQiansanZhixuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zhixuan/ssc-qiansan-zhixuan-hezhi/ssc-qiansan-zhixuan-hezhi.component';
import { SscQiansanZhixuanKuaduComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zhixuan/ssc-qiansan-zhixuan-kuadu/ssc-qiansan-zhixuan-kuadu.component';
import { SscQiansanZhixuanZuheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zhixuan/ssc-qiansan-zhixuan-zuhe/ssc-qiansan-zhixuan-zuhe.component';
import { SscQiansanZuxuanZusanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-zusan/ssc-qiansan-zuxuan-zusan.component';
import { SscQiansanZuxuanZuliuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-zuliu/ssc-qiansan-zuxuan-zuliu.component';
import { SscQiansanZuxuanZusanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-zusan-danshi/ssc-qiansan-zuxuan-zusan-danshi.component';
import { SscQiansanZuxuanZuliuDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-zuliu-danshi/ssc-qiansan-zuxuan-zuliu-danshi.component';
import { SscQiansanZuxuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-hezhi/ssc-qiansan-zuxuan-hezhi.component';
import { SscQiansanZuxuanHunheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-hunhe/ssc-qiansan-zuxuan-hunhe.component';
import { SscQiansanZuxuanBaodanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-zuxuan/ssc-qiansan-zuxuan-baodan/ssc-qiansan-zuxuan-baodan.component';
import { SscQiansanQitaHezhiWeishuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-qita/ssc-qiansan-qita-hezhi-weishu/ssc-qiansan-qita-hezhi-weishu.component';
import { SscQiansanQitaTeshuHaomaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-qiansan/ssc-qiansan-qita/ssc-qiansan-qita-teshu-haoma/ssc-qiansan-qita-teshu-haoma.component';
import { SscZhongsanZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zhixuan/ssc-zhongsan-zhixuan-fushi/ssc-zhongsan-zhixuan-fushi.component';
import { SscZhongsanZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zhixuan/ssc-zhongsan-zhixuan-danshi/ssc-zhongsan-zhixuan-danshi.component';
import { SscZhongsanZhixuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zhixuan/ssc-zhongsan-zhixuan-hezhi/ssc-zhongsan-zhixuan-hezhi.component';
import { SscZhongsanZhixuanKuaduComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zhixuan/ssc-zhongsan-zhixuan-kuadu/ssc-zhongsan-zhixuan-kuadu.component';
import { SscZhongsanZhixuanZuheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zhixuan/ssc-zhongsan-zhixuan-zuhe/ssc-zhongsan-zhixuan-zuhe.component';
import { SscZhongsanZuxuanZusanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-zusan/ssc-zhongsan-zuxuan-zusan.component';
import { SscZhongsanZuxuanZusanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-zusan-danshi/ssc-zhongsan-zuxuan-zusan-danshi.component';
import { SscZhongsanZuxuanZuliuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-zuliu/ssc-zhongsan-zuxuan-zuliu.component';
import { SscZhongsanZuxuanZuliuDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-zuliu-danshi/ssc-zhongsan-zuxuan-zuliu-danshi.component';
import { SscZhongsanZuxuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-hezhi/ssc-zhongsan-zuxuan-hezhi.component';
import { SscZhongsanZuxuanHunheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-hunhe/ssc-zhongsan-zuxuan-hunhe.component';
import { SscZhongsanZuxuanBaodanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-zuxuan/ssc-zhongsan-zuxuan-baodan/ssc-zhongsan-zuxuan-baodan.component';
import { SscZhongsanQitaHezhiWeishuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-qita/ssc-zhongsan-qita-hezhi-weishu/ssc-zhongsan-qita-hezhi-weishu.component';
import { SscZhongsanQitaTeshuHaomaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-zhongsan/ssc-zhongsan-qita/ssc-zhongsan-qita-teshu-haoma/ssc-zhongsan-qita-teshu-haoma.component';
import { SscHousanZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zhixuan/ssc-housan-zhixuan-fushi/ssc-housan-zhixuan-fushi.component';
import { SscHousanZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zhixuan/ssc-housan-zhixuan-danshi/ssc-housan-zhixuan-danshi.component';
import { SscHousanZhixuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zhixuan/ssc-housan-zhixuan-hezhi/ssc-housan-zhixuan-hezhi.component';
import { SscHousanZhixuanKuaduComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zhixuan/ssc-housan-zhixuan-kuadu/ssc-housan-zhixuan-kuadu.component';
import { SscHousanZhixuanZuheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zhixuan/ssc-housan-zhixuan-zuhe/ssc-housan-zhixuan-zuhe.component';
import { SscHousanZuxuanZusanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-zusan/ssc-housan-zuxuan-zusan.component';
import { SscHousanZuxuanZusanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-zusan-danshi/ssc-housan-zuxuan-zusan-danshi.component';
import { SscHousanZuxuanZuliuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-zuliu/ssc-housan-zuxuan-zuliu.component';
import { SscHousanZuxuanZuliuDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-zuliu-danshi/ssc-housan-zuxuan-zuliu-danshi.component';
import { SscHousanZuxuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-hezhi/ssc-housan-zuxuan-hezhi.component';
import { SscHousanZuxuanHunheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-hunhe/ssc-housan-zuxuan-hunhe.component';
import { SscHousanZuxuanBaodanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-zuxuan/ssc-housan-zuxuan-baodan/ssc-housan-zuxuan-baodan.component';
import { SscHousanQitaHezhiWeishuComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-qita/ssc-housan-qita-hezhi-weishu/ssc-housan-qita-hezhi-weishu.component';
import { SscHousanQitaTeshuHaomaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-housan/ssc-housan-qita/ssc-housan-qita-teshu-haoma/ssc-housan-qita-teshu-haoma.component';
import { SscErxingZhixuanHouerFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-houer-fushi/ssc-erxing-zhixuan-houer-fushi.component';
import { SscErxingZhixuanHouerDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-houer-danshi/ssc-erxing-zhixuan-houer-danshi.component';
import { SscErxingZhixuanHouerHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-houer-hezhi/ssc-erxing-zhixuan-houer-hezhi.component';
import { SscErxingZhixuanHouerKuaduComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-houer-kuadu/ssc-erxing-zhixuan-houer-kuadu.component';
import { SscErxingZhixuanQianerFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-qianer-fushi/ssc-erxing-zhixuan-qianer-fushi.component';
import { SscErxingZhixuanQianerDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-qianer-danshi/ssc-erxing-zhixuan-qianer-danshi.component';
import { SscErxingZhixuanQianerHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-qianer-hezhi/ssc-erxing-zhixuan-qianer-hezhi.component';
import { SscErxingZhixuanQianerKuaduComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zhixuan/ssc-erxing-zhixuan-qianer-kuadu/ssc-erxing-zhixuan-qianer-kuadu.component';
import { SscErxingZuxuanHouerFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-houer-fushi/ssc-erxing-zuxuan-houer-fushi.component';
import { SscErxingZuxuanHouerDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-houer-danshi/ssc-erxing-zuxuan-houer-danshi.component';
import { SscErxingZuxuanHouerHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-houer-hezhi/ssc-erxing-zuxuan-houer-hezhi.component';
import { SscErxingZuxuanHouerBaodanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-houer-baodan/ssc-erxing-zuxuan-houer-baodan.component';
import { SscErxingZuxuanQianerFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-qianer-fushi/ssc-erxing-zuxuan-qianer-fushi.component';
import { SscErxingZuxuanQianerDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-qianer-danshi/ssc-erxing-zuxuan-qianer-danshi.component';
import { SscErxingZuxuanQianerHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-qianer-hezhi/ssc-erxing-zuxuan-qianer-hezhi.component';
import { SscErxingZuxuanQianerBaodanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-erxing/ssc-erxing-zuxuan/ssc-erxing-zuxuan-qianer-baodan/ssc-erxing-zuxuan-qianer-baodan.component';
import { SscDingweidanDingweidanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-dingweidan/ssc-dingweidan-dingweidan/ssc-dingweidan-dingweidan.component';
import { SscBudingweiSanxingHousanYimaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-housan-yima/ssc-budingwei-sanxing-housan-yima.component';
import { SscBudingweiSanxingHousanErmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-housan-erma/ssc-budingwei-sanxing-housan-erma.component';
import { SscBudingweiSanxingQiansanYimaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-qiansan-yima/ssc-budingwei-sanxing-qiansan-yima.component';
import { SscBudingweiSanxingQiansanErmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-qiansan-erma/ssc-budingwei-sanxing-qiansan-erma.component';
import { SscBudingweiSanxingZhongsanYimaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-zhongsan-yima/ssc-budingwei-sanxing-zhongsan-yima.component';
import { SscBudingweiSanxingZhongsanErmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sanxing/ssc-budingwei-sanxing-zhongsan-erma/ssc-budingwei-sanxing-zhongsan-erma.component';
import { SscBudingweiSixingYimaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sixing/ssc-budingwei-sixing-yima/ssc-budingwei-sixing-yima.component';
import { SscBudingweiSixingErmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-sixing/ssc-budingwei-sixing-erma/ssc-budingwei-sixing-erma.component';
import { SscBudingweiWuxingErmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-wuxing/ssc-budingwei-wuxing-erma/ssc-budingwei-wuxing-erma.component';
import { SscBudingweiWuxingSanmaComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-budingwei/ssc-budingwei-wuxing/ssc-budingwei-wuxing-sanma/ssc-budingwei-wuxing-sanma.component';
import { SscDaxiaodanshuangHouerComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-daxiaodanshuang/ssc-daxiaodanshuang-houer/ssc-daxiaodanshuang-houer.component';
import { SscDaxiaodanshuangHousanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-daxiaodanshuang/ssc-daxiaodanshuang-housan/ssc-daxiaodanshuang-housan.component';
import { SscDaxiaodanshuangQianerComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-daxiaodanshuang/ssc-daxiaodanshuang-qianer/ssc-daxiaodanshuang-qianer.component';
import { SscDaxiaodanshuangQiansanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-daxiaodanshuang/ssc-daxiaodanshuang-qiansan/ssc-daxiaodanshuang-qiansan.component';
import { SscDaxiaodanshuangZhongsanComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-daxiaodanshuang/ssc-daxiaodanshuang-zhongsan/ssc-daxiaodanshuang-zhongsan.component';
import { SscQuweiTeshuTs1Component } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-teshu/ssc-quwei-teshu-ts1/ssc-quwei-teshu-ts1.component';
import { SscQuweiTeshuTs2Component } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-teshu/ssc-quwei-teshu-ts2/ssc-quwei-teshu-ts2.component';
import { SscQuweiTeshuTs3Component } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-teshu/ssc-quwei-teshu-ts3/ssc-quwei-teshu-ts3.component';
import { SscQuweiTeshuTs4Component } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-teshu/ssc-quwei-teshu-ts4/ssc-quwei-teshu-ts4.component';
import { SscLonghuWanqianComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-wanqian/ssc-longhu-wanqian.component';
import { SscLonghuWanbaiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-wanbai/ssc-longhu-wanbai.component';
import { SscLonghuWanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-wanshi/ssc-longhu-wanshi.component';
import { SscLonghuWangeComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-wange/ssc-longhu-wange.component';
import { SscLonghuQianbaiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-qianbai/ssc-longhu-qianbai.component';
import { SscLonghuQianshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-qianshi/ssc-longhu-qianshi.component';
import { SscLonghuQiangeComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-qiange/ssc-longhu-qiange.component';
import { SscLonghuBaishiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-baishi/ssc-longhu-baishi.component';
import { SscLonghuBaigeComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-baige/ssc-longhu-baige.component';
import { SscLonghuShigeComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-longhu/ssc-longhu-shige/ssc-longhu-shige.component';
import { X115SanmaZhixuanFushiComponent } from './lottery-page/lottery-x115/x115-play/x115-sanma/x115-sanma-zhixuan/x115-sanma-zhixuan-fushi/x115-sanma-zhixuan-fushi.component';
import { X115SanmaZhixuanDanshiComponent } from './lottery-page/lottery-x115/x115-play/x115-sanma/x115-sanma-zhixuan/x115-sanma-zhixuan-danshi/x115-sanma-zhixuan-danshi.component';
import { X115SanmaZuxuanFushiComponent } from './lottery-page/lottery-x115/x115-play/x115-sanma/x115-sanma-zuxuan/x115-sanma-zuxuan-fushi/x115-sanma-zuxuan-fushi.component';
import { X115SanmaZuxuanDanshiComponent } from './lottery-page/lottery-x115/x115-play/x115-sanma/x115-sanma-zuxuan/x115-sanma-zuxuan-danshi/x115-sanma-zuxuan-danshi.component';
import { X115SanmaZuxuanDantuoComponent } from './lottery-page/lottery-x115/x115-play/x115-sanma/x115-sanma-zuxuan/x115-sanma-zuxuan-dantuo/x115-sanma-zuxuan-dantuo.component';
import { X115ErmaZhixuanFushiComponent } from './lottery-page/lottery-x115/x115-play/x115-erma/x115-erma-zhixuan/x115-erma-zhixuan-fushi/x115-erma-zhixuan-fushi.component';
import { X115ErmaZhixuanDanshiComponent } from './lottery-page/lottery-x115/x115-play/x115-erma/x115-erma-zhixuan/x115-erma-zhixuan-danshi/x115-erma-zhixuan-danshi.component';
import { X115ErmaZuxuanFushiComponent } from './lottery-page/lottery-x115/x115-play/x115-erma/x115-erma-zuxuan/x115-erma-zuxuan-fushi/x115-erma-zuxuan-fushi.component';
import { X115ErmaZuxuanDanshiComponent } from './lottery-page/lottery-x115/x115-play/x115-erma/x115-erma-zuxuan/x115-erma-zuxuan-danshi/x115-erma-zuxuan-danshi.component';
import { X115ErmaZuxuanDantuoComponent } from './lottery-page/lottery-x115/x115-play/x115-erma/x115-erma-zuxuan/x115-erma-zuxuan-dantuo/x115-erma-zuxuan-dantuo.component';
import { X115BudingweiQiansanComponent } from './lottery-page/lottery-x115/x115-play/x115-budingwei/x115-budingwei-qiansan/x115-budingwei-qiansan.component';
import { X115QuweixingDingdanshuangComponent } from './lottery-page/lottery-x115/x115-play/x115-quweixing/x115-quweixing-dingdanshuang/x115-quweixing-dingdanshuang.component';
import { X115QuweixingCaizhongweiComponent } from './lottery-page/lottery-x115/x115-play/x115-quweixing/x115-quweixing-caizhongwei/x115-quweixing-caizhongwei.component';
import { X115DingweidanDingweidanComponent } from './lottery-page/lottery-x115/x115-play/x115-dingweidan/x115-dingweidan-dingweidan/x115-dingweidan-dingweidan.component';
import { X115RenxuanFushiRx11Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx11/x115-renxuan-fushi-rx11.component';
import { X115RenxuanFushiRx22Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx22/x115-renxuan-fushi-rx22.component';
import { X115RenxuanFushiRx33Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx33/x115-renxuan-fushi-rx33.component';
import { X115RenxuanFushiRx44Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx44/x115-renxuan-fushi-rx44.component';
import { X115RenxuanFushiRx55Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx55/x115-renxuan-fushi-rx55.component';
import { X115RenxuanFushiRx65Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx65/x115-renxuan-fushi-rx65.component';
import { X115RenxuanFushiRx75Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx75/x115-renxuan-fushi-rx75.component';
import { X115RenxuanFushiRx85Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-fushi/x115-renxuan-fushi-rx85/x115-renxuan-fushi-rx85.component';
import { X115RenxuanDanshiRx11Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx11/x115-renxuan-danshi-rx11.component';
import { X115RenxuanDanshiRx22Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx22/x115-renxuan-danshi-rx22.component';
import { X115RenxuanDanshiRx33Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx33/x115-renxuan-danshi-rx33.component';
import { X115RenxuanDanshiRx44Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx44/x115-renxuan-danshi-rx44.component';
import { X115RenxuanDanshiRx55Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx55/x115-renxuan-danshi-rx55.component';
import { X115RenxuanDanshiRx65Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx65/x115-renxuan-danshi-rx65.component';
import { X115RenxuanDanshiRx75Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx75/x115-renxuan-danshi-rx75.component';
import { X115RenxuanDanshiRx85Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-danshi/x115-renxuan-danshi-rx85/x115-renxuan-danshi-rx85.component';
import { X115RenxuanDantuoRx22Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx22/x115-renxuan-dantuo-rx22.component';
import { X115RenxuanDantuoRx33Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx33/x115-renxuan-dantuo-rx33.component';
import { X115RenxuanDantuoRx44Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx44/x115-renxuan-dantuo-rx44.component';
import { X115RenxuanDantuoRx55Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx55/x115-renxuan-dantuo-rx55.component';
import { X115RenxuanDantuoRx65Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx65/x115-renxuan-dantuo-rx65.component';
import { X115RenxuanDantuoRx75Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx75/x115-renxuan-dantuo-rx75.component';
import { X115RenxuanDantuoRx85Component } from './lottery-page/lottery-x115/x115-play/x115-renxuan-dantuo/x115-renxuan-dantuo-rx85/x115-renxuan-dantuo-rx85.component';
import { DipinSanxingZhixuanFushiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zhixuan/dipin-sanxing-zhixuan-fushi/dipin-sanxing-zhixuan-fushi.component';
import { DipinSanxingZhixuanDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zhixuan/dipin-sanxing-zhixuan-danshi/dipin-sanxing-zhixuan-danshi.component';
import { DipinSanxingZhixuanHezhiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zhixuan/dipin-sanxing-zhixuan-hezhi/dipin-sanxing-zhixuan-hezhi.component';
import { DipinSanxingZuxuanZusanComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-zusan/dipin-sanxing-zuxuan-zusan.component';
import { DipinSanxingZuxuanZuliuComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-zuliu/dipin-sanxing-zuxuan-zuliu.component';
import { DipinSanxingZuxuanHezhiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-hezhi/dipin-sanxing-zuxuan-hezhi.component';
import { DipinSanxingZuxuanZusanDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-zusan-danshi/dipin-sanxing-zuxuan-zusan-danshi.component';
import { DipinSanxingZuxuanZuliuDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-zuliu-danshi/dipin-sanxing-zuxuan-zuliu-danshi.component';
import { DipinSanxingZuxuanHunheComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-sanxing/dipin-sanxing-zuxuan/dipin-sanxing-zuxuan-hunhe/dipin-sanxing-zuxuan-hunhe.component';
import { DipinErxingZhixuanQianerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zhixuan/dipin-erxing-zhixuan-qianer/dipin-erxing-zhixuan-qianer.component';
import { DipinErxingZhixuanQianerDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zhixuan/dipin-erxing-zhixuan-qianer-danshi/dipin-erxing-zhixuan-qianer-danshi.component';
import { DipinErxingZhixuanHouerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zhixuan/dipin-erxing-zhixuan-houer/dipin-erxing-zhixuan-houer.component';
import { DipinErxingZhixuanHouerDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zhixuan/dipin-erxing-zhixuan-houer-danshi/dipin-erxing-zhixuan-houer-danshi.component';
import { DipinErxingZuxuanQianerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zuxuan/dipin-erxing-zuxuan-qianer/dipin-erxing-zuxuan-qianer.component';
import { DipinErxingZuxuanQianerDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zuxuan/dipin-erxing-zuxuan-qianer-danshi/dipin-erxing-zuxuan-qianer-danshi.component';
import { DipinErxingZuxuanHouerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zuxuan/dipin-erxing-zuxuan-houer/dipin-erxing-zuxuan-houer.component';
import { DipinErxingZuxuanHouerDanshiComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-erxing/dipin-erxing-zuxuan/dipin-erxing-zuxuan-houer-danshi/dipin-erxing-zuxuan-houer-danshi.component';
import { DipinDingweidanDingweidanComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-dingweidan/dipin-dingweidan-dingweidan/dipin-dingweidan-dingweidan.component';
import { DipinBudingweiYimaComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-budingwei/dipin-budingwei-yima/dipin-budingwei-yima.component';
import { DipinBudingweiErmaComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-budingwei/dipin-budingwei-erma/dipin-budingwei-erma.component';
import { DipinDaxiaodanshuangQianerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-daxiaodanshuang/dipin-daxiaodanshuang-qianer/dipin-daxiaodanshuang-qianer.component';
import { DipinDaxiaodanshuangHouerComponent } from './lottery-page/lottery-dipin/dipin-play/dipin-daxiaodanshuang/dipin-daxiaodanshuang-houer/dipin-daxiaodanshuang-houer.component';
import { PlwPaisanZhixuanFushiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zhixuan/plw-paisan-zhixuan-fushi/plw-paisan-zhixuan-fushi.component';
import { PlwPaisanZhixuanDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zhixuan/plw-paisan-zhixuan-danshi/plw-paisan-zhixuan-danshi.component';
import { PlwPaisanZhixuanHezhiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zhixuan/plw-paisan-zhixuan-hezhi/plw-paisan-zhixuan-hezhi.component';
import { PlwPaisanZuxuanZusanComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-zusan/plw-paisan-zuxuan-zusan.component';
import { PlwPaisanZuxuanZusanDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-zusan-danshi/plw-paisan-zuxuan-zusan-danshi.component';
import { PlwPaisanZuxuanZuliuComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-zuliu/plw-paisan-zuxuan-zuliu.component';
import { PlwPaisanZuxuanZuliuDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-zuliu-danshi/plw-paisan-zuxuan-zuliu-danshi.component';
import { PlwPaisanZuxuanHunheComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-hunhe/plw-paisan-zuxuan-hunhe.component';
import { PlwPaisanZuxuanHezhiComponent } from './lottery-page/lottery-plw/plw-play/plw-paisan/plw-paisan-zuxuan/plw-paisan-zuxuan-hezhi/plw-paisan-zuxuan-hezhi.component';
import { PlwErxingZhixuanQianerFushiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zhixuan/plw-erxing-zhixuan-qianer-fushi/plw-erxing-zhixuan-qianer-fushi.component';
import { PlwErxingZhixuanQianerDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zhixuan/plw-erxing-zhixuan-qianer-danshi/plw-erxing-zhixuan-qianer-danshi.component';
import { PlwErxingZhixuanHouerFushiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zhixuan/plw-erxing-zhixuan-houer-fushi/plw-erxing-zhixuan-houer-fushi.component';
import { PlwErxingZhixuanHouerDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zhixuan/plw-erxing-zhixuan-houer-danshi/plw-erxing-zhixuan-houer-danshi.component';
import { PlwErxingZuxuanQianerFushiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zuxuan/plw-erxing-zuxuan-qianer-fushi/plw-erxing-zuxuan-qianer-fushi.component';
import { PlwErxingZuxuanQianerDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zuxuan/plw-erxing-zuxuan-qianer-danshi/plw-erxing-zuxuan-qianer-danshi.component';
import { PlwErxingZuxuanHouerFushiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zuxuan/plw-erxing-zuxuan-houer-fushi/plw-erxing-zuxuan-houer-fushi.component';
import { PlwErxingZuxuanHouerDanshiComponent } from './lottery-page/lottery-plw/plw-play/plw-erxing/plw-erxing-zuxuan/plw-erxing-zuxuan-houer-danshi/plw-erxing-zuxuan-houer-danshi.component';
import { PlwDingweidanDingweidanComponent } from './lottery-page/lottery-plw/plw-play/plw-dingweidan/plw-dingweidan-dingweidan/plw-dingweidan-dingweidan.component';
import { PlwBudingweiYimaComponent } from './lottery-page/lottery-plw/plw-play/plw-budingwei/plw-budingwei-yima/plw-budingwei-yima.component';
import { PlwBudingweiErmaComponent } from './lottery-page/lottery-plw/plw-play/plw-budingwei/plw-budingwei-erma/plw-budingwei-erma.component';
import { PlwDaxiaodanshuangQianerComponent } from './lottery-page/lottery-plw/plw-play/plw-daxiaodanshuang/plw-daxiaodanshuang-qianer/plw-daxiaodanshuang-qianer.component';
import { PlwDaxiaodanshuangHouerComponent } from './lottery-page/lottery-plw/plw-play/plw-daxiaodanshuang/plw-daxiaodanshuang-houer/plw-daxiaodanshuang-houer.component';
import { K3DaxiaodanshuangComponent } from './lottery-page/lottery-k3/k3-play/k3-daxiaodanshuang/k3-daxiaodanshuang.component';
import { K3HezhiComponent } from './lottery-page/lottery-k3/k3-play/k3-hezhi/k3-hezhi.component';
import { K3SanbutonghaoComponent } from './lottery-page/lottery-k3/k3-play/k3-sanbutonghao/k3-sanbutonghao.component';
import { K3SantonghaoComponent } from './lottery-page/lottery-k3/k3-play/k3-santonghao/k3-santonghao.component';
import { K3SanlianhaoComponent } from './lottery-page/lottery-k3/k3-play/k3-sanlianhao/k3-sanlianhao.component';
import { K3ErbutonghaoComponent } from './lottery-page/lottery-k3/k3-play/k3-erbutonghao/k3-erbutonghao.component';
import { K3ErtonghaoComponent } from './lottery-page/lottery-k3/k3-play/k3-ertonghao/k3-ertonghao.component';
import { K3DantiaoyitouComponent } from './lottery-page/lottery-k3/k3-play/k3-dantiaoyitou/k3-dantiaoyitou.component';
import { Pk10CaidiyiDiyimingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caidiyi/pk10-caidiyi-diyiming/pk10-caidiyi-diyiming-fushi/pk10-caidiyi-diyiming-fushi.component';
import { Pk10CaidiyiDiyimingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caidiyi/pk10-caidiyi-diyiming/pk10-caidiyi-diyiming-danshi/pk10-caidiyi-diyiming-danshi.component';
import { Pk10CaiqianerDiermingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianer/pk10-caiqianer-dierming/pk10-caiqianer-dierming-fushi/pk10-caiqianer-dierming-fushi.component';
import { Pk10CaiqianerDiermingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianer/pk10-caiqianer-dierming/pk10-caiqianer-dierming-danshi/pk10-caiqianer-dierming-danshi.component';
import { Pk10CaiqianerCaiqianerFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianer/pk10-caiqianer-caiqianer/pk10-caiqianer-caiqianer-fushi/pk10-caiqianer-caiqianer-fushi.component';
import { Pk10CaiqianerCaiqianerDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianer/pk10-caiqianer-caiqianer/pk10-caiqianer-caiqianer-danshi/pk10-caiqianer-caiqianer-danshi.component';
import { Pk10CaiqiansanDisanmingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansan/pk10-caiqiansan-disanming/pk10-caiqiansan-disanming-fushi/pk10-caiqiansan-disanming-fushi.component';
import { Pk10CaiqiansanDisanmingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansan/pk10-caiqiansan-disanming/pk10-caiqiansan-disanming-danshi/pk10-caiqiansan-disanming-danshi.component';
import { Pk10CaiqiansanCaiqiansanFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansan/pk10-caiqiansan-caiqiansan/pk10-caiqiansan-caiqiansan-fushi/pk10-caiqiansan-caiqiansan-fushi.component';
import { Pk10CaiqiansanCaiqiansanDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansan/pk10-caiqiansan-caiqiansan/pk10-caiqiansan-caiqiansan-danshi/pk10-caiqiansan-caiqiansan-danshi.component';
import { Pk10CaiqiansiDisimingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansi/pk10-caiqiansi-disiming/pk10-caiqiansi-disiming-fushi/pk10-caiqiansi-disiming-fushi.component';
import { Pk10CaiqiansiDisimingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansi/pk10-caiqiansi-disiming/pk10-caiqiansi-disiming-danshi/pk10-caiqiansi-disiming-danshi.component';
import { Pk10CaiqiansiCaiqiansiFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansi/pk10-caiqiansi-caiqiansi/pk10-caiqiansi-caiqiansi-fushi/pk10-caiqiansi-caiqiansi-fushi.component';
import { Pk10CaiqiansiCaiqiansiDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqiansi/pk10-caiqiansi-caiqiansi/pk10-caiqiansi-caiqiansi-danshi/pk10-caiqiansi-caiqiansi-danshi.component';
import { Pk10CaiqianwuDiwumingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianwu/pk10-caiqianwu-diwuming/pk10-caiqianwu-diwuming-fushi/pk10-caiqianwu-diwuming-fushi.component';
import { Pk10CaiqianwuDiwumingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianwu/pk10-caiqianwu-diwuming/pk10-caiqianwu-diwuming-danshi/pk10-caiqianwu-diwuming-danshi.component';
import { Pk10CaiqianwuCaiqianwuFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianwu/pk10-caiqianwu-caiqianwu/pk10-caiqianwu-caiqianwu-fushi/pk10-caiqianwu-caiqianwu-fushi.component';
import { Pk10CaiqianwuCaiqianwuDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianwu/pk10-caiqianwu-caiqianwu/pk10-caiqianwu-caiqianwu-danshi/pk10-caiqianwu-caiqianwu-danshi.component';
import { Pk10CaiqianliuDiliumingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianliu/pk10-caiqianliu-diliuming/pk10-caiqianliu-diliuming-fushi/pk10-caiqianliu-diliuming-fushi.component';
import { Pk10CaiqianliuDiliumingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianliu/pk10-caiqianliu-diliuming/pk10-caiqianliu-diliuming-danshi/pk10-caiqianliu-diliuming-danshi.component';
import { Pk10CaiqianliuCaiqianliuFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianliu/pk10-caiqianliu-caiqianliu/pk10-caiqianliu-caiqianliu-fushi/pk10-caiqianliu-caiqianliu-fushi.component';
import { Pk10CaiqianliuCaiqianliuDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianliu/pk10-caiqianliu-caiqianliu/pk10-caiqianliu-caiqianliu-danshi/pk10-caiqianliu-caiqianliu-danshi.component';
import { Pk10CaiqianqiDiqimingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianqi/pk10-caiqianqi-diqiming/pk10-caiqianqi-diqiming-fushi/pk10-caiqianqi-diqiming-fushi.component';
import { Pk10CaiqianqiDiqimingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianqi/pk10-caiqianqi-diqiming/pk10-caiqianqi-diqiming-danshi/pk10-caiqianqi-diqiming-danshi.component';
import { Pk10CaiqianbaDibamingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianba/pk10-caiqianba-dibaming/pk10-caiqianba-dibaming-fushi/pk10-caiqianba-dibaming-fushi.component';
import { Pk10CaiqianbaDibamingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianba/pk10-caiqianba-dibaming/pk10-caiqianba-dibaming-danshi/pk10-caiqianba-dibaming-danshi.component';
import { Pk10CaiqianjiuDijiumingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianjiu/pk10-caiqianjiu-dijiuming/pk10-caiqianjiu-dijiuming-fushi/pk10-caiqianjiu-dijiuming-fushi.component';
import { Pk10CaiqianjiuDijiumingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianjiu/pk10-caiqianjiu-dijiuming/pk10-caiqianjiu-dijiuming-danshi/pk10-caiqianjiu-dijiuming-danshi.component';
import { Pk10CaiqianshiDishimingFushiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianshi/pk10-caiqianshi-dishiming/pk10-caiqianshi-dishiming-fushi/pk10-caiqianshi-dishiming-fushi.component';
import { Pk10CaiqianshiDishimingDanshiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-caiqianshi/pk10-caiqianshi-dishiming/pk10-caiqianshi-dishiming-danshi/pk10-caiqianshi-dishiming-danshi.component';
import { Pk10DingweidanDingweidanComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-dingweidan/pk10-dingweidan-dingweidan/pk10-dingweidan-dingweidan.component';
import { Pk10BudingweiSanmaQiansanComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-sanma/pk10-budingwei-sanma-qiansan/pk10-budingwei-sanma-qiansan.component';
import { Pk10BudingweiSanmaHousanComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-sanma/pk10-budingwei-sanma-housan/pk10-budingwei-sanma-housan.component';
import { Pk10BudingweiSimaQiansiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-sima/pk10-budingwei-sima-qiansi/pk10-budingwei-sima-qiansi.component';
import { Pk10BudingweiSimaHousiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-sima/pk10-budingwei-sima-housi/pk10-budingwei-sima-housi.component';
import { Pk10BudingweiWumaQianwuComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-wuma/pk10-budingwei-wuma-qianwu/pk10-budingwei-wuma-qianwu.component';
import { Pk10BudingweiWumaHouwuComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-budingwei/pk10-budingwei-wuma/pk10-budingwei-wuma-houwu/pk10-budingwei-wuma-houwu.component';
import { Pk10QuweiDaxiaodanshuangGuanjunComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-danxiaodanshuang/pk10-quwei-daxiaodanshuang-guanjun/pk10-quwei-daxiaodanshuang-guanjun.component';
import { Pk10QuweiDaxiaodanshuangYajunComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-danxiaodanshuang/pk10-quwei-daxiaodanshuang-yajun/pk10-quwei-daxiaodanshuang-yajun.component';
import { Pk10QuweiDaxiaodanshuangJijunComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-danxiaodanshuang/pk10-quwei-daxiaodanshuang-jijun/pk10-quwei-daxiaodanshuang-jijun.component';
import { Pk10QuweiCaihezhiGuanyaComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-caihezhi/pk10-quwei-caihezhi-guanya/pk10-quwei-caihezhi-guanya.component';
import { Pk10QuweiCaihezhiGuanyajiComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-caihezhi/pk10-quwei-caihezhi-guanyaji/pk10-quwei-caihezhi-guanyaji.component';
import { Pk10QuweiLonghudouLonghudouComponent } from './lottery-page/lottery-pk10/pk10-play/pk10-quwei/pk10-quwei-longhudou/pk10-quwei-longhudou-longhudou/pk10-quwei-longhudou-longhudou.component';
import { SscQuweiQuweiWumaSanxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-quwei/ssc-quwei-quwei-wuma-sanxing/ssc-quwei-quwei-wuma-sanxing.component';
import { SscQuweiQuweiSimaSanxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-quwei/ssc-quwei-quwei-sima-sanxing/ssc-quwei-quwei-sima-sanxing.component';
import { SscQuweiQuweiHousanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-quwei/ssc-quwei-quwei-housan-erxing/ssc-quwei-quwei-housan-erxing.component';
import { SscQuweiQuweiQiansanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-quwei/ssc-quwei-quwei-qiansan-erxing/ssc-quwei-quwei-qiansan-erxing.component';
import { SscQuweiQuweiZhongsanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-quwei/ssc-quwei-quwei-zhongsan-erxing/ssc-quwei-quwei-zhongsan-erxing.component';
import { SscQuweiQujianWumaSanxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-qujian/ssc-quwei-qujian-wuma-sanxing/ssc-quwei-qujian-wuma-sanxing.component';
import { SscQuweiQujianSimaSanxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-qujian/ssc-quwei-qujian-sima-sanxing/ssc-quwei-qujian-sima-sanxing.component';
import { SscQuweiQujianHousanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-qujian/ssc-quwei-qujian-housan-erxing/ssc-quwei-qujian-housan-erxing.component';
import { SscQuweiQujianQiansanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-qujian/ssc-quwei-qujian-qiansan-erxing/ssc-quwei-qujian-qiansan-erxing.component';
import { SscQuweiQujianZhongsanErxingComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-quwei/ssc-quwei-qujian/ssc-quwei-qujian-zhongsan-erxing/ssc-quwei-qujian-zhongsan-erxing.component';
import { SscWuxingZuxuanPipe } from './pipe/lottery/ssc/ssc-wuxing-zuxuan.pipe';
import { SscSixingZuxuanPipe } from './pipe/lottery/ssc/ssc-sixing-zuxuan.pipe';
import { SscHezhiPipe } from './pipe/lottery/ssc/ssc-hezhi.pipe';
import { SscKuaduPipe } from './pipe/lottery/ssc/ssc-kuadu.pipe';
import { SscSanxingZuxuanPipe } from './pipe/lottery/ssc/ssc-sanxing-zuxuan.pipe';
import { SscSanxingWeishuPipe } from './pipe/lottery/ssc/ssc-sanxing-weishu.pipe';
import { SscSanxingTeshuPipe } from './pipe/lottery/ssc/ssc-sanxing-teshu.pipe';
import { SscDaxiaoPipe } from './pipe/lottery/ssc/ssc-daxiao.pipe';
import { SscDanshuangPipe } from './pipe/lottery/ssc/ssc-danshuang.pipe';
import { SscLonghuhePipe } from './pipe/lottery/ssc/ssc-longhuhe.pipe';
import { K3DaxiaodanshuangPipe } from './pipe/lottery/k3/k3-daxiaodanshuang.pipe';
import { K3HezhiPipe } from './pipe/lottery/k3/k3-hezhi.pipe';
import { K3SanbutongPipe } from './pipe/lottery/k3/k3-sanbutong.pipe';
import { K3SantonghaoPipe } from './pipe/lottery/k3/k3-santonghao.pipe';
import { K3SanlianhaoPipe } from './pipe/lottery/k3/k3-sanlianhao.pipe';
import { K3ErtonghaoPipe } from './pipe/lottery/k3/k3-ertonghao.pipe';
import { DipinDaxiaoPipe } from './pipe/lottery/dipin/dipin-daxiao.pipe';
import { DipinDanshuangPipe } from './pipe/lottery/dipin/dipin-danshuang.pipe';
import { PlayNamePipe } from './pipe/lottery/play/play-name.pipe';
import { GameNamePipe } from './pipe/lottery/game/game-name.pipe';
import { BetStatusPipe } from './pipe/lottery/bet-status/bet-status.pipe';
import { TraceStatusPipe } from './pipe/lottery/trace-status/trace-status.pipe';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HeaderPrivateComponent } from './header/header-private/header-private.component';
import { FooterPublicComponent } from './footer/footer-public/footer-public.component';
import { FooterPrivateComponent } from './footer/footer-private/footer-private.component';
import { TrendPageComponent } from './trend-page/trend-page.component';
import { AnnouncementPageComponent } from './announcement-page/announcement-page.component';
import { SscRenxuanRx2ZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zhixuan-fushi/ssc-renxuan-rx2-zhixuan-fushi.component';
import { SscRenxuanRx2ZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zhixuan-danshi/ssc-renxuan-rx2-zhixuan-danshi.component';
import { SscRenxuanRx2ZhixuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zhixuan-hezhi/ssc-renxuan-rx2-zhixuan-hezhi.component';
import { SscRenxuanRx2ZuxuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zuxuan-fushi/ssc-renxuan-rx2-zuxuan-fushi.component';
import { SscRenxuanRx2ZuxuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zuxuan-danshi/ssc-renxuan-rx2-zuxuan-danshi.component';
import { SscRenxuanRx2ZuxuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx2/ssc-renxuan-rx2-zuxuan-hezhi/ssc-renxuan-rx2-zuxuan-hezhi.component';
import { SscRenxuanRx3ZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zhixuan-fushi/ssc-renxuan-rx3-zhixuan-fushi.component';
import { SscRenxuanRx3ZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zhixuan-danshi/ssc-renxuan-rx3-zhixuan-danshi.component';
import { SscRenxuanRx3ZhixuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zhixuan-hezhi/ssc-renxuan-rx3-zhixuan-hezhi.component';
import { SscRenxuanRx3ZusanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zusan-fushi/ssc-renxuan-rx3-zusan-fushi.component';
import { SscRenxuanRx3ZusanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zusan-danshi/ssc-renxuan-rx3-zusan-danshi.component';
import { SscRenxuanRx3ZuliuFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zuliu-fushi/ssc-renxuan-rx3-zuliu-fushi.component';
import { SscRenxuanRx3ZuliuDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zuliu-danshi/ssc-renxuan-rx3-zuliu-danshi.component';
import { SscRenxuanRx3ZuxuanHunheComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zuxuan-hunhe/ssc-renxuan-rx3-zuxuan-hunhe.component';
import { SscRenxuanRx3ZuxuanHezhiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx3/ssc-renxuan-rx3-zuxuan-hezhi/ssc-renxuan-rx3-zuxuan-hezhi.component';
import { SscRenxuanRx4ZhixuanFushiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zhixuan-fushi/ssc-renxuan-rx4-zhixuan-fushi.component';
import { SscRenxuanRx4ZhixuanDanshiComponent } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zhixuan-danshi/ssc-renxuan-rx4-zhixuan-danshi.component';
import { SscRenxuanRx4ZuxuanZx24Component } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zuxuan-zx24/ssc-renxuan-rx4-zuxuan-zx24.component';
import { SscRenxuanRx4ZuxuanZx12Component } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zuxuan-zx12/ssc-renxuan-rx4-zuxuan-zx12.component';
import { SscRenxuanRx4ZuxuanZx6Component } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zuxuan-zx6/ssc-renxuan-rx4-zuxuan-zx6.component';
import { SscRenxuanRx4ZuxuanZx4Component } from './lottery-page/lottery-ssc/ssc-play/ssc-renxuan/ssc-renxuan-rx4/ssc-renxuan-rx4-zuxuan-zx4/ssc-renxuan-rx4-zuxuan-zx4.component';
import { TrendTableSscComponent } from './trend-page/trend-table-ssc/trend-table-ssc.component';
import { TrendTableX115Component } from './trend-page/trend-table-x115/trend-table-x115.component';
import { TrendTableK3Component } from './trend-page/trend-table-k3/trend-table-k3.component';
import { TrendTablePk10Component } from './trend-page/trend-table-pk10/trend-table-pk10.component';
import { TrendTableDipinComponent } from './trend-page/trend-table-dipin/trend-table-dipin.component';
import { SscWuxingComponent } from './trend-page/trend-table-ssc/ssc-wuxing/ssc-wuxing.component';
import { SscSixingComponent } from './trend-page/trend-table-ssc/ssc-sixing/ssc-sixing.component';
import { SscQiansanComponent } from './trend-page/trend-table-ssc/ssc-qiansan/ssc-qiansan.component';
import { SscZhongsanComponent } from './trend-page/trend-table-ssc/ssc-zhongsan/ssc-zhongsan.component';
import { SscHousanComponent } from './trend-page/trend-table-ssc/ssc-housan/ssc-housan.component';
import { SscQianerComponent } from './trend-page/trend-table-ssc/ssc-qianer/ssc-qianer.component';
import { SscHouerComponent } from './trend-page/trend-table-ssc/ssc-houer/ssc-houer.component';
import { TrendTablePlwComponent } from './trend-page/trend-table-plw/trend-table-plw.component';
import { Pk10GuanyaComponent } from './trend-page/trend-table-pk10/pk10-guanya/pk10-guanya.component';
import { Pk10QianwuComponent } from './trend-page/trend-table-pk10/pk10-qianwu/pk10-qianwu.component';
import { Pk10HouwuComponent } from './trend-page/trend-table-pk10/pk10-houwu/pk10-houwu.component';
import { LhcHeaderComponent } from './lottery-page/lottery-lhc/lhc-header/lhc-header.component';
import { LhcPanelComponent } from './lottery-page/lottery-lhc/lhc-panel/lhc-panel.component';
import { LhcTemaComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-tema/lhc-tema.component';
import { LhcZhengte1Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte1/lhc-zhengte1.component';
import { LhcZhengte2Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte2/lhc-zhengte2.component';
import { LhcZhengte3Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte3/lhc-zhengte3.component';
import { LhcZhengte4Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte4/lhc-zhengte4.component';
import { LhcZhengte5Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte5/lhc-zhengte5.component';
import { LhcZhengte6Component } from './lottery-page/lottery-lhc/lhc-play/lhc-zhengte/lhc-zhengte6/lhc-zhengte6.component';
import { LhcBanboComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-banbo/lhc-banbo.component';
import { LhcShengxiaoTexiaoComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-shengxiao/lhc-shengxiao-texiao/lhc-shengxiao-texiao.component';
import { LhcShengxiaoYixiaoComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-shengxiao/lhc-shengxiao-yixiao/lhc-shengxiao-yixiao.component';
import { LhcShengxiaoLiuxiaoComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-shengxiao/lhc-shengxiao-liuxiao/lhc-shengxiao-liuxiao.component';
import { LhcWeishuComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-weishu/lhc-weishu.component';
import { LhcZongfenComponent } from './lottery-page/lottery-lhc/lhc-play/lhc-zongfen/lhc-zongfen.component';
import { LhcBuzhongBz5Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz5/lhc-buzhong-bz5.component';
import { LhcBuzhongBz6Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz6/lhc-buzhong-bz6.component';
import { LhcBuzhongBz7Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz7/lhc-buzhong-bz7.component';
import { LhcBuzhongBz8Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz8/lhc-buzhong-bz8.component';
import { LhcBuzhongBz9Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz9/lhc-buzhong-bz9.component';
import { LhcBuzhongBz10Component } from './lottery-page/lottery-lhc/lhc-play/lhc-buzhong/lhc-buzhong-bz10/lhc-buzhong-bz10.component';
import { LotteryChipsComponent } from './lottery-page/lottery-chips/lottery-chips.component';
import { SscPanelCreditComponent } from './lottery-page/lottery-ssc/ssc-panel-credit/ssc-panel-credit.component';
import { SscCreditZhengheComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-zhenghe/ssc-credit-zhenghe.component';
import { SscCreditLiangmianpanComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-liangmianpan/ssc-credit-liangmianpan.component';
import { SscCreditYihaoqiuComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-yihaoqiu/ssc-credit-yihaoqiu.component';
import { SscCreditErhaoqiuComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-erhaoqiu/ssc-credit-erhaoqiu.component';
import { SscCreditSanhaoqiuComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-sanhaoqiu/ssc-credit-sanhaoqiu.component';
import { SscCreditSihaoqiuComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-sihaoqiu/ssc-credit-sihaoqiu.component';
import { SscCreditWuhaoqiuComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-wuhaoqiu/ssc-credit-wuhaoqiu.component';
import { SscCreditLonghudouComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-longhudou/ssc-credit-longhudou.component';
import { SscCreditQuanwuzhongyiComponent } from './lottery-page/lottery-ssc/ssc-play-credit/ssc-credit-quanwuzhongyi/ssc-credit-quanwuzhongyi.component';
import { K3PanelCreditComponent } from './lottery-page/lottery-k3/k3-panel-credit/k3-panel-credit.component';
import { K3CreditZhengheComponent } from './lottery-page/lottery-k3/k3-play-credit/k3-credit-zhenghe/k3-credit-zhenghe.component';
import { Pk10PanelCreditComponent } from './lottery-page/lottery-pk10/pk10-panel-credit/pk10-panel-credit.component';
import { Pk10CreditLiangmianComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-liangmian/pk10-credit-liangmian.component';
import { Pk10CreditMingciComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-mingci/pk10-credit-mingci.component';
import { Pk10CreditLonghudouComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-longhudou/pk10-credit-longhudou.component';
import { Pk10CreditHezhiGuanyaComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-hezhi-guanya/pk10-credit-hezhi-guanya.component';
import { Pk10CreditHezhiGuanyajiComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-hezhi-guanyaji/pk10-credit-hezhi-guanyaji.component';
import { Pk10CreditHezhiShouweiComponent } from './lottery-page/lottery-pk10/pk10-play-credit/pk10-credit-hezhi-shouwei/pk10-credit-hezhi-shouwei.component';
import { LotteryPcddComponent } from './lottery-page/lottery-pcdd/lottery-pcdd.component';
import { PcddHeaderComponent } from './lottery-page/lottery-pcdd/pcdd-header/pcdd-header.component';
import { PcddPanelComponent } from './lottery-page/lottery-pcdd/pcdd-panel/pcdd-panel.component';
import { PcddZhengheComponent } from './lottery-page/lottery-pcdd/pcdd-play/pcdd-zhenghe/pcdd-zhenghe.component';
import { BetContentPipe } from './pipe/lottery/bet-content/bet-content.pipe';
import { Page404Component } from './page404/page404.component';
import { TrendTableLhcComponent } from './trend-page/trend-table-lhc/trend-table-lhc.component';
import { TrendTablePcddComponent } from './trend-page/trend-table-pcdd/trend-table-pcdd.component';
import { MoneyModePipe } from './pipe/lottery/money-mode/money-mode.pipe';
import { PageCloseComponent } from './page-close/page-close.component';

import { Store } from './providers/store';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LotteryPageComponent,
    LotteryLobbyComponent,
    LotterySscComponent,
    LotteryX115Component,
    LotteryPk10Component,
    LotteryK3Component,
    LotteryLhcComponent,
    LotteryDipinComponent,
    DataCenterPageComponent,
    DataCenterContentComponent,
    PersonalPrizeComponent,
    PersonalBetRecordComponent,
    PersonalTraceRecordComponent,
    PersonalTransactionRecordComponent,
    PersonalTransferRecordComponent,
    MessageAnnouncementComponent,
    LoginPageComponent,
    SscHeaderComponent,
    SscPanelComponent,
    SscTrendComponent,
    LotteryBetInfoComponent,
    LotteryBetRecordComponent,
    X115HeaderComponent,
    X115PanelComponent,
    X115TrendComponent,
    K3HeaderComponent,
    K3PanelComponent,
    K3TrendComponent,
    Pk10HeaderComponent,
    Pk10PanelComponent,
    Pk10TrendComponent,
    DipinHeaderComponent,
    DipinPanelComponent,
    DipinTrendComponent,
    LotteryPlwComponent,
    PlwHeaderComponent,
    PlwPanelComponent,
    PlwTrendComponent,
    SscWuxingZhixuanFushiComponent,
    SscWuxingZhixuanDanshiComponent,
    SscWuxingZhixuanZuheComponent,
    SscWuxingZuxuanZx120Component,
    SscWuxingZuxuanZx60Component,
    SscWuxingZuxuanZx30Component,
    SscWuxingZuxuanZx20Component,
    SscWuxingZuxuanZx10Component,
    SscWuxingZuxuanZx5Component,
    SscSixingZhixuanFushiComponent,
    SscSixingZhixuanDanshiComponent,
    SscSixingZhixuanZuheComponent,
    SscSixingZuxuanZx24Component,
    SscSixingZuxuanZx12Component,
    SscSixingZuxuanZx6Component,
    SscSixingZuxuanZx4Component,
    SscQiansanZhixuanFushiComponent,
    SscQiansanZhixuanDanshiComponent,
    SscQiansanZhixuanHezhiComponent,
    SscQiansanZhixuanKuaduComponent,
    SscQiansanZhixuanZuheComponent,
    SscQiansanZuxuanZusanComponent,
    SscQiansanZuxuanZuliuComponent,
    SscQiansanZuxuanZusanDanshiComponent,
    SscQiansanZuxuanZuliuDanshiComponent,
    SscQiansanZuxuanHezhiComponent,
    SscQiansanZuxuanHunheComponent,
    SscQiansanZuxuanBaodanComponent,
    SscQiansanQitaHezhiWeishuComponent,
    SscQiansanQitaTeshuHaomaComponent,
    SscZhongsanZhixuanFushiComponent,
    SscZhongsanZhixuanDanshiComponent,
    SscZhongsanZhixuanHezhiComponent,
    SscZhongsanZhixuanKuaduComponent,
    SscZhongsanZhixuanZuheComponent,
    SscZhongsanZuxuanZusanComponent,
    SscZhongsanZuxuanZusanDanshiComponent,
    SscZhongsanZuxuanZuliuComponent,
    SscZhongsanZuxuanZuliuDanshiComponent,
    SscZhongsanZuxuanHezhiComponent,
    SscZhongsanZuxuanHunheComponent,
    SscZhongsanZuxuanBaodanComponent,
    SscZhongsanQitaHezhiWeishuComponent,
    SscZhongsanQitaTeshuHaomaComponent,
    SscHousanZhixuanFushiComponent,
    SscHousanZhixuanDanshiComponent,
    SscHousanZhixuanHezhiComponent,
    SscHousanZhixuanKuaduComponent,
    SscHousanZhixuanZuheComponent,
    SscHousanZuxuanZusanComponent,
    SscHousanZuxuanZusanDanshiComponent,
    SscHousanZuxuanZuliuComponent,
    SscHousanZuxuanZuliuDanshiComponent,
    SscHousanZuxuanHezhiComponent,
    SscHousanZuxuanHunheComponent,
    SscHousanZuxuanBaodanComponent,
    SscHousanQitaHezhiWeishuComponent,
    SscHousanQitaTeshuHaomaComponent,
    SscErxingZhixuanHouerFushiComponent,
    SscErxingZhixuanHouerDanshiComponent,
    SscErxingZhixuanHouerHezhiComponent,
    SscErxingZhixuanHouerKuaduComponent,
    SscErxingZhixuanQianerFushiComponent,
    SscErxingZhixuanQianerDanshiComponent,
    SscErxingZhixuanQianerHezhiComponent,
    SscErxingZhixuanQianerKuaduComponent,
    SscErxingZuxuanHouerFushiComponent,
    SscErxingZuxuanHouerDanshiComponent,
    SscErxingZuxuanHouerHezhiComponent,
    SscErxingZuxuanHouerBaodanComponent,
    SscErxingZuxuanQianerFushiComponent,
    SscErxingZuxuanQianerDanshiComponent,
    SscErxingZuxuanQianerHezhiComponent,
    SscErxingZuxuanQianerBaodanComponent,
    SscDingweidanDingweidanComponent,
    SscBudingweiSanxingHousanYimaComponent,
    SscBudingweiSanxingHousanErmaComponent,
    SscBudingweiSanxingQiansanYimaComponent,
    SscBudingweiSanxingQiansanErmaComponent,
    SscBudingweiSanxingZhongsanYimaComponent,
    SscBudingweiSanxingZhongsanErmaComponent,
    SscBudingweiSixingYimaComponent,
    SscBudingweiSixingErmaComponent,
    SscBudingweiWuxingErmaComponent,
    SscBudingweiWuxingSanmaComponent,
    SscDaxiaodanshuangHouerComponent,
    SscDaxiaodanshuangHousanComponent,
    SscDaxiaodanshuangQianerComponent,
    SscDaxiaodanshuangQiansanComponent,
    SscDaxiaodanshuangZhongsanComponent,
    SscQuweiTeshuTs1Component,
    SscQuweiTeshuTs2Component,
    SscQuweiTeshuTs3Component,
    SscQuweiTeshuTs4Component,
    SscLonghuWanqianComponent,
    SscLonghuWanbaiComponent,
    SscLonghuWanshiComponent,
    SscLonghuWangeComponent,
    SscLonghuQianbaiComponent,
    SscLonghuQianshiComponent,
    SscLonghuQiangeComponent,
    SscLonghuBaishiComponent,
    SscLonghuBaigeComponent,
    SscLonghuShigeComponent,
    X115SanmaZhixuanFushiComponent,
    X115SanmaZhixuanDanshiComponent,
    X115SanmaZuxuanFushiComponent,
    X115SanmaZuxuanDanshiComponent,
    X115SanmaZuxuanDantuoComponent,
    X115ErmaZhixuanFushiComponent,
    X115ErmaZhixuanDanshiComponent,
    X115ErmaZuxuanFushiComponent,
    X115ErmaZuxuanDanshiComponent,
    X115ErmaZuxuanDantuoComponent,
    X115BudingweiQiansanComponent,
    X115QuweixingDingdanshuangComponent,
    X115QuweixingCaizhongweiComponent,
    X115DingweidanDingweidanComponent,
    X115RenxuanFushiRx11Component,
    X115RenxuanFushiRx22Component,
    X115RenxuanFushiRx33Component,
    X115RenxuanFushiRx44Component,
    X115RenxuanFushiRx55Component,
    X115RenxuanFushiRx65Component,
    X115RenxuanFushiRx75Component,
    X115RenxuanFushiRx85Component,
    X115RenxuanDanshiRx11Component,
    X115RenxuanDanshiRx22Component,
    X115RenxuanDanshiRx33Component,
    X115RenxuanDanshiRx44Component,
    X115RenxuanDanshiRx55Component,
    X115RenxuanDanshiRx65Component,
    X115RenxuanDanshiRx75Component,
    X115RenxuanDanshiRx85Component,
    X115RenxuanDantuoRx22Component,
    X115RenxuanDantuoRx33Component,
    X115RenxuanDantuoRx44Component,
    X115RenxuanDantuoRx55Component,
    X115RenxuanDantuoRx65Component,
    X115RenxuanDantuoRx75Component,
    X115RenxuanDantuoRx85Component,
    DipinSanxingZhixuanFushiComponent,
    DipinSanxingZhixuanDanshiComponent,
    DipinSanxingZhixuanHezhiComponent,
    DipinSanxingZuxuanZusanComponent,
    DipinSanxingZuxuanZuliuComponent,
    DipinSanxingZuxuanHezhiComponent,
    DipinSanxingZuxuanZusanDanshiComponent,
    DipinSanxingZuxuanZuliuDanshiComponent,
    DipinSanxingZuxuanHunheComponent,
    DipinErxingZhixuanQianerComponent,
    DipinErxingZhixuanQianerDanshiComponent,
    DipinErxingZhixuanHouerComponent,
    DipinErxingZhixuanHouerDanshiComponent,
    DipinErxingZuxuanQianerComponent,
    DipinErxingZuxuanQianerDanshiComponent,
    DipinErxingZuxuanHouerComponent,
    DipinErxingZuxuanHouerDanshiComponent,
    DipinDingweidanDingweidanComponent,
    DipinBudingweiYimaComponent,
    DipinBudingweiErmaComponent,
    DipinDaxiaodanshuangQianerComponent,
    DipinDaxiaodanshuangHouerComponent,
    PlwPaisanZhixuanFushiComponent,
    PlwPaisanZhixuanDanshiComponent,
    PlwPaisanZhixuanHezhiComponent,
    PlwPaisanZuxuanZusanComponent,
    PlwPaisanZuxuanZusanDanshiComponent,
    PlwPaisanZuxuanZuliuComponent,
    PlwPaisanZuxuanZuliuDanshiComponent,
    PlwPaisanZuxuanHunheComponent,
    PlwPaisanZuxuanHezhiComponent,
    PlwErxingZhixuanQianerFushiComponent,
    PlwErxingZhixuanQianerDanshiComponent,
    PlwErxingZhixuanHouerFushiComponent,
    PlwErxingZhixuanHouerDanshiComponent,
    PlwErxingZuxuanQianerFushiComponent,
    PlwErxingZuxuanQianerDanshiComponent,
    PlwErxingZuxuanHouerFushiComponent,
    PlwErxingZuxuanHouerDanshiComponent,
    PlwDingweidanDingweidanComponent,
    PlwBudingweiYimaComponent,
    PlwBudingweiErmaComponent,
    PlwDaxiaodanshuangQianerComponent,
    PlwDaxiaodanshuangHouerComponent,
    K3DaxiaodanshuangComponent,
    K3HezhiComponent,
    K3SanbutonghaoComponent,
    K3SantonghaoComponent,
    K3SanlianhaoComponent,
    K3ErbutonghaoComponent,
    K3ErtonghaoComponent,
    K3DantiaoyitouComponent,
    Pk10CaidiyiDiyimingFushiComponent,
    Pk10CaidiyiDiyimingDanshiComponent,
    Pk10CaiqianerDiermingFushiComponent,
    Pk10CaiqianerDiermingDanshiComponent,
    Pk10CaiqianerCaiqianerFushiComponent,
    Pk10CaiqianerCaiqianerDanshiComponent,
    Pk10CaiqiansanDisanmingFushiComponent,
    Pk10CaiqiansanDisanmingDanshiComponent,
    Pk10CaiqiansanCaiqiansanFushiComponent,
    Pk10CaiqiansanCaiqiansanDanshiComponent,
    Pk10CaiqiansiDisimingFushiComponent,
    Pk10CaiqiansiDisimingDanshiComponent,
    Pk10CaiqiansiCaiqiansiFushiComponent,
    Pk10CaiqiansiCaiqiansiDanshiComponent,
    Pk10CaiqianwuDiwumingFushiComponent,
    Pk10CaiqianwuDiwumingDanshiComponent,
    Pk10CaiqianwuCaiqianwuFushiComponent,
    Pk10CaiqianwuCaiqianwuDanshiComponent,
    Pk10CaiqianliuDiliumingFushiComponent,
    Pk10CaiqianliuDiliumingDanshiComponent,
    Pk10CaiqianliuCaiqianliuFushiComponent,
    Pk10CaiqianliuCaiqianliuDanshiComponent,
    Pk10CaiqianqiDiqimingFushiComponent,
    Pk10CaiqianqiDiqimingDanshiComponent,
    Pk10CaiqianbaDibamingFushiComponent,
    Pk10CaiqianbaDibamingDanshiComponent,
    Pk10CaiqianjiuDijiumingFushiComponent,
    Pk10CaiqianjiuDijiumingDanshiComponent,
    Pk10CaiqianshiDishimingFushiComponent,
    Pk10CaiqianshiDishimingDanshiComponent,
    Pk10DingweidanDingweidanComponent,
    Pk10BudingweiSanmaQiansanComponent,
    Pk10BudingweiSanmaHousanComponent,
    Pk10BudingweiSimaQiansiComponent,
    Pk10BudingweiSimaHousiComponent,
    Pk10BudingweiWumaQianwuComponent,
    Pk10BudingweiWumaHouwuComponent,
    Pk10QuweiDaxiaodanshuangGuanjunComponent,
    Pk10QuweiDaxiaodanshuangYajunComponent,
    Pk10QuweiDaxiaodanshuangJijunComponent,
    Pk10QuweiCaihezhiGuanyaComponent,
    Pk10QuweiCaihezhiGuanyajiComponent,
    Pk10QuweiLonghudouLonghudouComponent,
    SscQuweiQuweiWumaSanxingComponent,
    SscQuweiQuweiSimaSanxingComponent,
    SscQuweiQuweiHousanErxingComponent,
    SscQuweiQuweiQiansanErxingComponent,
    SscQuweiQuweiZhongsanErxingComponent,
    SscQuweiQujianWumaSanxingComponent,
    SscQuweiQujianSimaSanxingComponent,
    SscQuweiQujianHousanErxingComponent,
    SscQuweiQujianQiansanErxingComponent,
    SscQuweiQujianZhongsanErxingComponent,
    SscWuxingZuxuanPipe,
    SscSixingZuxuanPipe,
    SscHezhiPipe,
    SscKuaduPipe,
    SscSanxingZuxuanPipe,
    SscSanxingWeishuPipe,
    SscSanxingTeshuPipe,
    SscDaxiaoPipe,
    SscDanshuangPipe,
    SscLonghuhePipe,
    K3DaxiaodanshuangPipe,
    K3HezhiPipe,
    K3SanbutongPipe,
    K3SantonghaoPipe,
    K3SanlianhaoPipe,
    K3ErtonghaoPipe,
    DipinDaxiaoPipe,
    DipinDanshuangPipe,
    PlayNamePipe,
    HeaderPrivateComponent,
    FooterPublicComponent,
    FooterPrivateComponent,
    TrendPageComponent,
    AnnouncementPageComponent,
    GameNamePipe,
    BetStatusPipe,
    TraceStatusPipe,
    SscRenxuanRx2ZhixuanFushiComponent,
    SscRenxuanRx2ZhixuanDanshiComponent,
    SscRenxuanRx2ZhixuanHezhiComponent,
    SscRenxuanRx2ZuxuanFushiComponent,
    SscRenxuanRx2ZuxuanDanshiComponent,
    SscRenxuanRx2ZuxuanHezhiComponent,
    SscRenxuanRx3ZhixuanFushiComponent,
    SscRenxuanRx3ZhixuanDanshiComponent,
    SscRenxuanRx3ZhixuanHezhiComponent,
    SscRenxuanRx3ZusanFushiComponent,
    SscRenxuanRx3ZusanDanshiComponent,
    SscRenxuanRx3ZuliuFushiComponent,
    SscRenxuanRx3ZuliuDanshiComponent,
    SscRenxuanRx3ZuxuanHunheComponent,
    SscRenxuanRx3ZuxuanHezhiComponent,
    SscRenxuanRx4ZhixuanFushiComponent,
    SscRenxuanRx4ZhixuanDanshiComponent,
    SscRenxuanRx4ZuxuanZx24Component,
    SscRenxuanRx4ZuxuanZx12Component,
    SscRenxuanRx4ZuxuanZx6Component,
    SscRenxuanRx4ZuxuanZx4Component,
    TrendTableSscComponent,
    TrendTableX115Component,
    TrendTableK3Component,
    TrendTablePk10Component,
    TrendTableDipinComponent,
    SscWuxingComponent,
    SscSixingComponent,
    SscQiansanComponent,
    SscZhongsanComponent,
    SscHousanComponent,
    SscQianerComponent,
    SscHouerComponent,
    TrendTablePlwComponent,
    Pk10GuanyaComponent,
    Pk10QianwuComponent,
    Pk10HouwuComponent,
    LhcHeaderComponent,
    LhcPanelComponent,
    LhcTemaComponent,
    LhcZhengte1Component,
    LhcZhengte2Component,
    LhcZhengte3Component,
    LhcZhengte4Component,
    LhcZhengte5Component,
    LhcZhengte6Component,
    LhcBanboComponent,
    LhcShengxiaoTexiaoComponent,
    LhcShengxiaoYixiaoComponent,
    LhcShengxiaoLiuxiaoComponent,
    LhcWeishuComponent,
    LhcZongfenComponent,
    LhcBuzhongBz5Component,
    LhcBuzhongBz6Component,
    LhcBuzhongBz7Component,
    LhcBuzhongBz8Component,
    LhcBuzhongBz9Component,
    LhcBuzhongBz10Component,
    LotteryChipsComponent,
    SscPanelCreditComponent,
    SscCreditZhengheComponent,
    SscCreditLiangmianpanComponent,
    SscCreditYihaoqiuComponent,
    SscCreditErhaoqiuComponent,
    SscCreditSanhaoqiuComponent,
    SscCreditSihaoqiuComponent,
    SscCreditWuhaoqiuComponent,
    SscCreditLonghudouComponent,
    SscCreditQuanwuzhongyiComponent,
    K3PanelCreditComponent,
    K3CreditZhengheComponent,
    Pk10PanelCreditComponent,
    Pk10CreditLiangmianComponent,
    Pk10CreditMingciComponent,
    Pk10CreditLonghudouComponent,
    Pk10CreditHezhiGuanyaComponent,
    Pk10CreditHezhiGuanyajiComponent,
    Pk10CreditHezhiShouweiComponent,
    LotteryPcddComponent,
    PcddHeaderComponent,
    PcddPanelComponent,
    PcddZhengheComponent,
    BetContentPipe,
    Page404Component,
    TrendTableLhcComponent,
    TrendTablePcddComponent,
    MoneyModePipe,
    PageCloseComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    AppRoutingModule,
    NgxChartsModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    Store
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
