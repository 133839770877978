import { Component, OnInit } from '@angular/core';
import { Utils } from 'app/common/util';
import { ApiService } from 'app/providers/api/api.service';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import {Store} from '../providers/store';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  // 当前路由
  public current_url: string;
  // 游戏菜单
  public ssc_game_list: Array<any> = [];
  public x115_game_list: Array<any> = [];
  public k3_game_list: Array<any> = [];
  public pk10_game_list: Array<any> = [];
  public dp_game_list: Array<any> = [];
  public lhc_game_list: Array<any> = [];
  public pcdd_game_list: Array<any> = [];

  // 路由监听流
  public router_subscription: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private message: NzMessageService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.api.get_request_game_list_info().subscribe(data => {
      if (data) {
        Utils.game_list = data['game_list'];
        this.ssc_game_list = Utils.game_list[0];
        this.x115_game_list = Utils.game_list[1];
        this.k3_game_list = Utils.game_list[2];
        this.pk10_game_list = Utils.game_list[3];
        this.dp_game_list = Utils.game_list[4];
        this.lhc_game_list = Utils.game_list[5];
        this.pcdd_game_list = Utils.game_list[6];

        Utils.game_type_array = data['game_type'];
      }
    });
  }

  public goto_game_page_handler(gameInfo: any, name: string) {
    this.store.pageCloseImgUrl = gameInfo['close_img'];
    if (gameInfo.is_stop) {
      // this.message.create('error', '该游戏休市中，请投注其他游戏！');
      this.router.navigate(['page-close']);
    } else {
      switch (name) {
        case 'ssc':
          this.current_url = `/lottery/ssc/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case '11x5':
          this.current_url = `/lottery/11x5/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'k3':
          this.current_url = `/lottery/k3/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'pk10':
          this.current_url = `/lottery/pk10/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'dp':
          this.current_url = `/lottery/dp/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'plw':
          this.current_url = `/lottery/plw/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'lhc':
          this.current_url = `/lottery/lhc/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
        case 'pcdd':
          this.current_url = `/lottery/pcdd/${gameInfo.id}`;
          this.router.navigate([this.current_url]);
          break;
          default: break;
      }
      // let url = `/lottery/ssc/${gameInfo.id}`;
    }
  }
}
