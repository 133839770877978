import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-dipin-budingwei-yima',
  templateUrl: './dipin-budingwei-yima.component.html',
  styleUrls: ['./dipin-budingwei-yima.component.css']
})
export class DipinBudingweiYimaComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];

  }

  ngOnInit() {
  }

  /**
   * 计算注单数量
   *
   * @memberof DipinBudingweiYimaComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array[0].length;

    this.bet_num = num;
  }

}
