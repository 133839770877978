import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-k3-sanlianhao',
  templateUrl: './k3-sanlianhao.component.html',
  styleUrls: ['./k3-sanlianhao.component.css']
})
export class K3SanlianhaoComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball('123'),
        new Ball('234'),
        new Ball('345'),
        new Ball('456')
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球值
   *
   * @memberof K3SanlianhaoComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 计算注单数量
   *
   * @memberof K3SanlianhaoComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array.length;

    this.bet_num = num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof K3SanlianhaoComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      // 对比lottery_data数据，确认选球位置
      for (let j = 0; j < this.lottery_data.length; j++) {
        for (let k = 0; k < this.lottery_data[j].length; k++) {
          if (bet_arr[i] === this.lottery_data[j][k].get_ball_num()) {
            this.btn_status_array[j][k] = true;
          }
        }
      }
    }

    this.update_bet_infor_handler();
  }


}
