import { Component, OnInit } from '@angular/core';
import { GameDanShiBase } from 'app/common/gameDanShiBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-pk10-caiqianba-dibaming-danshi',
  templateUrl: './pk10-caiqianba-dibaming-danshi.component.html',
  styleUrls: ['./pk10-caiqianba-dibaming-danshi.component.css']
})
export class Pk10CaiqianbaDibamingDanshiComponent extends GameDanShiBase implements OnInit {

  // 输入内容
  public input_content: string = '';

  public tips: string = `说明：
  1、支持常见的各种单式格式，间隔符如： 换行符 回车 逗号 分号等
  2、上传文件后缀必须是.txt格式,最大支持10万注，并支持拖拽文件到文本框进行上传
  3、文件较大时会导致上传时间较长，请耐心等待！
  
  格式范例：1|2|3`;

  constructor(
    public betInfoService: BetInfoService,
    public msgService: NzMessageService
  ) {
    super(betInfoService, msgService);
  }

  ngOnInit() {
  }

  /**
   * 整理注单
   *
   * @param {string} bets_str
   * @returns {Array<any>}
   * @memberof Pk10CaiqianbaDibamingDanshiComponent
   */
  public sort_out_bet_info_handler(bets_str: string): Array<any> {
    let arr;
    let bets: string = bets_str
      .replace(this.filtration, '|')
      .replace(this.regStr2, ' ')
      .trim();

    arr = bets.split('|');
    return arr;
  }

  /**
   * 清理错误 & 去重
   *
   * @returns
   * @memberof Pk10CaiqianbaDibamingDanshiComponent
   */
  public fix_error_repeat_bet_info_handler() {
    if (this.input_content.length === 0) {
      this.betInfoService.set_bet_num_handler(0);
      this.betInfoService.set_bet_info_handler(null);
      return;
    }

    let arr = this.sort_out_bet_info_handler(this.input_content).filter((item) => {
      let b = item.split(' ');
      if (b.length !== 1) {
        return false;
      }

      for (let i = 0; i < b.length; i++) {
        let num = Number(b[i]);
        if (!(num >= 1 && num <= 10)) {
          return false;
        }
      }

      let b_arr = Array.from(new Set(b));
      if (b_arr.length === 1) {
        return true;
      } else {
        return false;
      }
    });

    this.bet_arr = Array.from(new Set(arr));

    this.input_content = this.bet_arr.join('|');
    this.msgService.success('内容整理完毕！');

    // 注单信息
    this.betInfoService.set_bet_num_handler(this.bet_arr.length);
    this.betInfoService.set_bet_info_handler(this.bet_arr.join('|'));
  }

}
