import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscKuadu'
})
export class SscKuaduPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let lottery_arr = [];
    // 跨度
    let num: number;

    switch (args) {
      case 'qiansan':
        value.forEach((element, index) => {
          if (index < 3) {
            lottery_arr.push(Number(element));
          }
        });
        num = this.calculation_kuadu_handler(lottery_arr);
        break;
      case 'zhongsan':
        value.forEach((element, index) => {
          if (index > 0 && index < 4) {
            lottery_arr.push(Number(element));
          }
        });
        num = this.calculation_kuadu_handler(lottery_arr);
        break;
      case 'housan':
        value.forEach((element, index) => {
          if (index > 1) {
            lottery_arr.push(Number(element));
          }
        });
        num = this.calculation_kuadu_handler(lottery_arr);
        break;
      case 'qianer':
        value.forEach((element, index) => {
          if (index < 2) {
            lottery_arr.push(Number(element));
          }
        });
        num = this.calculation_kuadu_handler(lottery_arr);
        break;
      case 'houer':
        value.forEach((element, index) => {
          if (index > 2) {
            lottery_arr.push(Number(element));
          }
        });
        num = this.calculation_kuadu_handler(lottery_arr);
        break;
      default: break;
    }

    return num;
  }

  private calculation_kuadu_handler(lottery_arr: any): number {
    let arr = lottery_arr.sort(function (a, b) {
      return a - b;
    });

    let kuadu_num = Number(arr[arr.length - 1]) - Number(arr[0]);
    return kuadu_num;
  }

}
