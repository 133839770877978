import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'app/providers/store';

@Component({
  selector: 'app-page-close',
  templateUrl: './page-close.component.html',
  styleUrls: ['./page-close.component.css']
})
export class PageCloseComponent implements OnInit {

  public url: any;
  public pageCloseImgUrl: any;
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    this.pageCloseImgUrl = this.store.pageCloseImgUrl;
  }

  /**
   * 去投注
   *
   * @param {*} gameType
   * @param {*} id
   * @memberof PageCloseComponent
   */
  public go_to_paly_game_handler(gameType, id) {

    switch (gameType) {
      case 'k3':
        this.url = `/lottery/k3/${id}`;
        this.router.navigate([this.url]);
      break;
      case 'ssc':
        this.url = `/lottery/ssc/${id}`;
        this.router.navigate([this.url]);
      break;
      default: break;
    }
  }
}
