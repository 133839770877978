import { Component, OnInit } from '@angular/core';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-lhc-banbo',
  templateUrl: './lhc-banbo.component.html',
  styleUrls: ['./lhc-banbo.component.css']
})
export class LhcBanboComponent extends GameCreditBase implements OnInit {


  // balls
  public balls_array: Array<any> = [
    // 红
    [
      { id: 1, name: '红大', balls: ['29', '30', '34', '35', '40', '45', '46'], p: 0, is_select: false, val: null },
      { id: 3, name: '红小', balls: ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24'], p: 0, is_select: false, val: null },
      { id: 5, name: '红单', balls: ['01', '07', '13', '19', '23', '29', '35', '45'], p: 0, is_select: false, val: null },
      { id: 7, name: '红双', balls: ['02', '08', '12', '18', '24', '30', '34', '40', '46'], p: 0, is_select: false, val: null },
      { id: 9, name: '红合单', balls: ['01', '07', '12', '18', '23', '29', '30', '34', '45'], p: 0, is_select: false, val: null },
      { id: 11, name: '红合双', balls: ['02', '08', '13', '19', '24', '35', '40', '46'], p: 0, is_select: false, val: null }
    ],
    // 绿
    [
      { id: 13, name: '绿大', balls: ['27', '28', '32', '33', '38', '39', '43', '44'], p: 0, is_select: false, val: null },
      { id: 15, name: '绿小', balls: ['05', '06', '11', '16', '17', '21', '22'], p: 0, is_select: false, val: null },
      { id: 17, name: '绿单', balls: ['05', '11', '17', '21', '27', '33', '39', '43'], p: 0, is_select: false, val: null },
      { id: 2, name: '绿双', balls: ['06', '16', '22', '28', '32', '38', '44'], p: 0, is_select: false, val: null },
      { id: 4, name: '绿合单', balls: ['05', '16', '21', '27', '32', '38', '43'], p: 0, is_select: false, val: null },
      { id: 6, name: '绿合双', balls: ['06', '11', '17', '22', '28', '33', '39', '44'], p: 0, is_select: false, val: null }
    ],
    // 蓝
    [
      { id: 8, name: '蓝大', balls: ['25', '26', '31', '36', '37', '41', '42', '47', '48'], p: 0, is_select: false, val: null },
      { id: 10, name: '蓝小', balls: ['03', '04', '09', '10', '14', '15', '20'], p: 0, is_select: false, val: null },
      { id: 12, name: '蓝单', balls: ['03', '09', '15', '25', '31', '37', '41', '47'], p: 0, is_select: false, val: null },
      { id: 14, name: '蓝双', balls: ['04', '10', '14', '20', '26', '36', '42', '48'], p: 0, is_select: false, val: null },
      { id: 16, name: '蓝合单', balls: ['03', '09', '10', '14', '25', '36', '41', '47'], p: 0, is_select: false, val: null },
      { id: 18, name: '蓝合双', balls: ['04', '15', '20', '26', '31', '37', '42', '48'], p: 0, is_select: false, val: null }
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof LhcBanboComponent
   */
  public reset_balls_panel_handler() {
    this.balls_array.forEach(item => {
      item.forEach(info => {
        info.is_select = false;
        info.val = null;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof LhcBanboComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability['probability'];
    this.balls_array.forEach(item => {
      item.forEach(info => {
        let n = info['id'] - 1;
        let prize = (this.prize_group / 2000) * (1 / p_arr[n]);
        info['p'] = prize;
      });
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof LhcBanboComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} item
   * @memberof LhcBanboComponent
   */
  public selecte_ball_handler(item: any) {
    if (!item.is_select) {
      item.is_select = true;
      item.val = this.bet_value;

      // balls内容整理
      this.update_bet_balls_info();
    }
  }

  /**
   * 失去焦点
   *
   * @param {*} item
   * @memberof LhcBanboComponent
   */
  public change_val_handler(item: any) {
    if (item.is_select && !item.val) {
      item.val = this.bet_value;
    }

    let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
    item.val = item.val > max_m ? max_m : item.val;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof LhcBanboComponent
   */
  public render_bet_value_handler() {
    this.balls_array.forEach(item => {
      item.forEach(info => {
        if (info.is_select) {
          info.val = this.bet_value;
        }
      });
    });

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof LhcBanboComponent
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.balls_array.forEach(group => {
      group.forEach((item: any) => {
        if (item.is_select && item.val !== null) {
          this.is_selected_info.push(item);
        }
      });
    });

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      this.is_selected_info.forEach(item => {
        let bet_info = new BetInfo();
        bet_info.way_id = Number(this.play_info['id']);
        bet_info.ball = item.id;
        bet_info.num = 1;
        bet_info.prize_group = Number(this.prize_group);

        let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
        item.val = item.val > max_m ? max_m : item.val;
        bet_info.multiple = Number(item.val);

        ball_arr.push(bet_info);
      });

      this.betInfoService.set_bet_info_handler(ball_arr);
      this.betInfoService.set_bet_num_handler(this.is_selected_info.length);
    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }
}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
