import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-x115-renxuan-dantuo-rx22',
  templateUrl: './x115-renxuan-dantuo-rx22.component.html',
  styleUrls: ['./x115-renxuan-dantuo-rx22.component.css']
})
export class X115RenxuanDantuoRx22Component extends GameBase implements OnInit {

  // 胆码选中数组
  private dan_num_arr = [];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false, false]
    ];

    this.fast_option_array = [
      [],
      [false, false, false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball('01'),
        new Ball('02'),
        new Ball('03'),
        new Ball('04'),
        new Ball('05'),
        new Ball('06'),
        new Ball('07'),
        new Ball('08'),
        new Ball('09'),
        new Ball('10'),
        new Ball('11')
      ],
      [
        new Ball('01'),
        new Ball('02'),
        new Ball('03'),
        new Ball('04'),
        new Ball('05'),
        new Ball('06'),
        new Ball('07'),
        new Ball('08'),
        new Ball('09'),
        new Ball('10'),
        new Ball('11')
      ],
    ];
  }

  ngOnInit() {
  }

  /**
   * 快捷菜单选择球
   *
   * @param {number} r
   * @param {number} n
   * @memberof X115RenxuanDantuoRx22Component
   */
  public select_fast_option_btn_handler(r: number, n: number) {
    this.fast_option_array[r] = [false, false, false, false, false, false];
    this.fast_option_array[r][n] = true;
    switch (n) {
      case 0:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = true;

          if (this.btn_status_array[0][i]) {
            this.btn_status_array[0][i] = false;

            let index = this.dan_num_arr.indexOf(i);
            if (index !== -1) {
              this.dan_num_arr.splice(index, 1);
            }
          }
        }
        break;
      case 1:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i >= Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }

          if (this.btn_status_array[0][i] && i >= Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[0][i] = false;

            let index = this.dan_num_arr.indexOf(i);
            if (index !== -1) {
              this.dan_num_arr.splice(index, 1);
            }
          }
        }
        break;
      case 2:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i < Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }

          if (this.btn_status_array[0][i] && i < Math.floor(this.btn_status_array[r].length / 2)) {
            this.btn_status_array[0][i] = false;

            let index = this.dan_num_arr.indexOf(i);
            if (index !== -1) {
              this.dan_num_arr.splice(index, 1);
            }
          }
        }
        break;
      case 3:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 0) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }

          if (this.btn_status_array[0][i] && i % 2 === 0) {
            this.btn_status_array[0][i] = false;

            let index = this.dan_num_arr.indexOf(i);
            if (index !== -1) {
              this.dan_num_arr.splice(index, 1);
            }
          }
        }
        break;
      case 4:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          if (i % 2 === 1) {
            this.btn_status_array[r][i] = true;
          } else {
            this.btn_status_array[r][i] = false;
          }

          if (this.btn_status_array[0][i] && i % 2 === 1) {
            this.btn_status_array[0][i] = false;

            let index = this.dan_num_arr.indexOf(i);
            if (index !== -1) {
              this.dan_num_arr.splice(index, 1);
            }
          }
        }
        break;
      default:
        for (let i = 0; i < this.btn_status_array[r].length; i++) {
          this.btn_status_array[r][i] = false;
        }
        break;
    }
    this.update_bet_infor_handler();
  }

  /**
   * 选球操作
   *
   * @param {number} r
   * @param {number} c
   * @memberof X115RenxuanDantuoRx22Component
   */
  public click_btn_lottery_handler(r: number, c: number) {

    if (r === 1 && this.btn_status_array[0][c]) {
      this.btn_status_array[0][c] = false;
    }
    if (r === 0 && this.btn_status_array[1][c]) {
      this.btn_status_array[1][c] = false;
    }

    this.btn_status_array[r][c] = !this.btn_status_array[r][c];
    // 选中胆码数组 重新过滤
    for (let i = 0; i < this.dan_num_arr.length; i++) {
      if (this.dan_num_arr[i] === c && !this.btn_status_array[0][c]) {
        this.dan_num_arr.splice(i, 1);
      }
    }

    if (r === 0 && this.btn_status_array[r][c]) {
      this.dan_num_arr.push(c);
    }
    // 胆码不能超过1位
    if (this.dan_num_arr.length > 1) {
      this.btn_status_array[0][this.dan_num_arr[0]] = false;
      this.dan_num_arr.splice(0, 1);
    }

    this.update_bet_infor_handler();
  }

  /**
   * 更新选球值
   *
   * @memberof X115RenxuanDantuoRx22Component
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      if (i !== 0) {
        selected_ball_str = selected_ball_str + '|';
        no_ball = no_ball + '|';
      }
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + ' ';
        }
      }
      selected_ball_str = selected_ball_str.trim();
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str;
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof X115RenxuanDantuoRx22Component
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];
    let pos_array = [];

    if (this.balls_str !== '') {
      pos_array = this.balls_str.split('|');
    }

    for (let item of pos_array) {
      let arr = item.split(' ');
      if (arr)
        this.selected_ball_array.push(arr);
    }

    if (this.selected_ball_array.length !== 0) {
      if (this.selected_ball_array[0][0] !== '' && this.selected_ball_array[1][0] !== '') {
        // 1位 胆码
        if (this.selected_ball_array[0].length === 1) {
          if (this.selected_ball_array[1].length >= 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * 计算注单数量
   *
   * @memberof X115RenxuanDantuoRx22Component
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array[1].length;

    this.bet_num = num;
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof X115RenxuanDantuoRx22Component
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      let arr = bet_arr[i].split(' ');
      for (let j = 0; j < arr.length; j++) {
        let index = Number(arr[j]) - 1;
        this.btn_status_array[i][index] = true;
      }
    }

    this.update_bet_infor_handler();
  }

  /**
   * 投注版面更新为初始状态
   *
   * @memberof X115RenxuanDantuoRx22Component
   */
  public reset_ball_plane_handler() {
    // 恢复选球
    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          this.btn_status_array[i][j] = false;
        }
      }
    }
    // 恢复快捷菜单
    for (let i = 0; i < this.fast_option_array.length; i++) {
      for (let j = 0; j < this.fast_option_array[i].length; j++) {
        if (this.fast_option_array[i][j]) {
          this.fast_option_array[i][j] = false;
        }
      }
    }
    // 胆码数组清空
    this.dan_num_arr = [];
    // 更新操作
    this.update_bet_infor_handler();
  }

}
