import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-quwei-qujian-zhongsan-erxing',
  templateUrl: './ssc-quwei-qujian-zhongsan-erxing.component.html',
  styleUrls: ['./ssc-quwei-qujian-zhongsan-erxing.component.css']
})
export class SscQuweiQujianZhongsanErxingComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [],
      [false, false, false, false, false, false],
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ],
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

}
