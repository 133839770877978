export class GameConfig {

    // SSC游戏玩法配置文件
    static ssc_game_config: any;

    // SSC游戏玩法配置文件
    static tx_game_config: any;

    // SSC游戏玩法配置文件
    static ssc_game_credit_config: any;

    // 11选5游戏玩法配置文件
    static x115_game_config: any;

    // K3游戏玩法配置文件
    static k3_game_config: any;

    // K3游戏玩法配置文件
    static k3_game_credit_config: any;

    // PK10游戏玩法配置文件
    static pk10_game_config: any;

    // PK10游戏玩法配置文件
    static pk10_game_credit_config: any;

    // 福彩3D游戏玩法配置文件
    static dipin_game_config: any;

    // 排列三游戏玩法配置文件
    static plw_game_config: any;

    // 六合彩游戏玩法配置文件
    static lhc_game_config: any;

    // PC蛋蛋游戏玩法配置文件
    static pcdd_game_config: any;

    constructor() {

    }
}
