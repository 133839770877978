import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BetInfoService {

  // 注单内容
  private bet_info: BehaviorSubject<string>;
  // 注单位置
  private bet_position_info: BehaviorSubject<string>;
  // 注单数量
  private bet_num: BehaviorSubject<number>;
  // 玩法信息
  private play_info: BehaviorSubject<any>;
  // 奖金信息
  private prize_info: BehaviorSubject<any>;
  // 清空操作
  private clear_balls: BehaviorSubject<any>;
  // 反选玩法
  private selected_play: BehaviorSubject<any>;
  // 反选操作
  private bets: BehaviorSubject<string>;
  // 撤销购彩栏注单
  private del_seleted_bet: BehaviorSubject<any>;
  // 清空购彩篮
  private clear_basket_list: BehaviorSubject<any>;
  // 开始追号
  private start_trace: BehaviorSubject<any>;
  // 投注成功
  private bet_success: BehaviorSubject<any>;
  // 开奖信息
  private win_number_info: BehaviorSubject<any>;
  // 当前游戏信息
  private cur_game_info: BehaviorSubject<any>;
  // 机选指令
  private random_balls: BehaviorSubject<string>;
  // 游戏奖金组
  private game_prize_group_info: BehaviorSubject<string>;
  // 模式
  private money_unit_info: BehaviorSubject<number>;
  // 两/一元模式
  private money_mode_info: BehaviorSubject<number>;
  // 倒计时
  private count_down_info: BehaviorSubject<number>;

  /** 信用玩法 */
  // 投注金额
  private credit_bet_val: BehaviorSubject<number>;
  // 清空选号
  private clear_credit_balls: BehaviorSubject<any>;
  // 奖金组
  private credit_prize_group: BehaviorSubject<number>;
  // 确认投注
  private confirm_bet: BehaviorSubject<any>;
  // 刷新余额
  private credit_update_balance: BehaviorSubject<number>;

  /** 投注记录 */
  private bet_record_info: BehaviorSubject<any>;
  private open_game_record_info: BehaviorSubject<any>;


  constructor() {
    this.bet_info = new BehaviorSubject(null);
    this.bet_position_info = new BehaviorSubject(null);
    this.bet_num = new BehaviorSubject(0);
    this.play_info = new BehaviorSubject(null);
    this.prize_info = new BehaviorSubject(null);
    this.clear_balls = new BehaviorSubject(null);
    this.selected_play = new BehaviorSubject(null);
    this.bets = new BehaviorSubject(null);
    this.del_seleted_bet = new BehaviorSubject(null);
    this.clear_basket_list = new BehaviorSubject(null);
    this.cur_game_info = new BehaviorSubject(null);
    this.start_trace = new BehaviorSubject(null);
    this.bet_success = new BehaviorSubject(null);
    this.win_number_info = new BehaviorSubject(null);
    this.random_balls = new BehaviorSubject(null);
    this.game_prize_group_info = new BehaviorSubject(null);
    this.money_unit_info = new BehaviorSubject(1);
    this.money_mode_info = new BehaviorSubject(2);
    this.count_down_info = new BehaviorSubject(0);
    // 信用玩法
    this.credit_bet_val = new BehaviorSubject(1);
    this.clear_credit_balls = new BehaviorSubject(null);
    this.credit_prize_group = new BehaviorSubject(null);
    this.confirm_bet = new BehaviorSubject(null);
    this.credit_update_balance = new BehaviorSubject(null);
    // 投注记录
    this.bet_record_info = new BehaviorSubject(null);
    this.open_game_record_info = new BehaviorSubject(null);
  }

  // 注单内容
  set_bet_info_handler(val) {
    this.bet_info.next(val);
  }

  get_bet_info_handler() {
    return this.bet_info.asObservable();
  }

  // 注单位置
  set_bet_position_info_handler(val) {
    this.bet_position_info.next(val);
  }

  get_bet_position_info_handle() {
    return this.bet_position_info.asObservable();
  }

  // 注单数量
  set_bet_num_handler(val) {
    this.bet_num.next(val);
  }

  get_bet_num_handler() {
    return this.bet_num.asObservable();
  }

  // 玩法信息
  set_play_info_handler(val) {
    this.play_info.next(val);
  }

  get_play_info_handler() {
    return this.play_info.asObservable();
  }

  // 奖金信息
  set_prize_info_handler(val) {
    this.prize_info.next(val);
  }

  get_prize_info_handler() {
    return this.prize_info.asObservable();
  }

  // 清空操作
  set_clear_balls_handler(val) {
    this.clear_balls.next(val);
  }

  get_clear_balls_handler() {
    return this.clear_balls.asObservable();
  }

  // 反选玩法
  set_selected_play_handler(val) {
    this.selected_play.next(val);
  }

  get_selected_play_handler() {
    return this.selected_play.asObservable();
  }

  // 反选操作
  set_bets_handler(val) {
    this.bets.next(val);
  }

  get_bets_handler() {
    return this.bets.asObservable();
  }

  // 撤销购彩栏注单
  set_del_seleted_bet_handler(val) {
    this.del_seleted_bet.next(val);
  }

  get_del_seleted_bet_handler() {
    return this.del_seleted_bet.asObservable();
  }

  // 清空购彩蓝
  set_clear_basket_list_handler(val) {
    this.clear_basket_list.next(val);
  }

  get_clear_basket_list_handle() {
    return this.clear_basket_list.asObservable();
  }

  // 当前游戏信息
  set_cur_game_info_handler(val) {
    this.cur_game_info.next(val);
  }

  get_cur_game_info_handler() {
    return this.cur_game_info.asObservable();
  }

  // 开始追号
  set_start_trace_handler(val) {
    this.start_trace.next(val);
  }

  get_start_trace_handler() {
    return this.start_trace.asObservable();
  }

  // 投注成功
  set_bet_success_handler(val) {
    this.bet_success.next(val);
  }

  get_bet_success_handler() {
    return this.bet_success.asObservable();
  }

  // 更新开奖
  set_win_number_info_handler(val) {
    this.win_number_info.next(val);
  }

  get_win_number_info_handler() {
    return this.win_number_info.asObservable();
  }

  // 机选注单
  set_random_balls_handler(val) {
    this.random_balls.next(val);
  }

  get_random_balls_handler() {
    return this.random_balls.asObservable();
  }

  // 设置奖金组
  set_game_prize_group_info_handler(val) {
    this.game_prize_group_info.next(val);
  }

  get_game_prize_group_info_handler() {
    return this.game_prize_group_info.asObservable();
  }

  // 模式
  set_money_unit_info_handler(val) {
    this.money_unit_info.next(val);
  }

  get_money_unit_info_handler() {
    return this.money_unit_info.asObservable();
  }

  // 两/一元模式
  set_money_mode_info_handler(val) {
    this.money_mode_info.next(val);
  }

  get_money_mode_info_handler() {
    return this.money_mode_info.asObservable();
  }

  // 设置倒计时时间
  set_count_down_info_handler(val) {
    this.count_down_info.next(val);
  }

  get_count_down_info_handler() {
    return this.count_down_info.asObservable();
  }

  /** 信用玩法 */

  // 信用玩法 - 投注金额
  set_credit_bet_val_handler(val) {
    this.credit_bet_val.next(val);
  }

  get_credit_bet_val_handler() {
    return this.credit_bet_val.asObservable();
  }

  // 信用玩法 - 清空选号
  set_clear_credit_balls_handler(val) {
    this.clear_credit_balls.next(val);
  }

  get_clear_credit_balls_handler() {
    return this.clear_credit_balls.asObservable();
  }

  // 信用玩法 - 调节奖金组
  set_credit_prize_group_handler(val) {
    this.credit_prize_group.next(val);
  }

  get_credit_prize_group_handler() {
    return this.credit_prize_group.asObservable();
  }

  /** 投注记录 */

  // 投注记录
  set_bet_record_info(val) {
    this.bet_record_info.next(val);
  }

  get_bet_record_info() {
    return this.bet_record_info.asObservable();
  }

  // 打开投注记录
  set_open_game_record_info(val) {
    this.open_game_record_info.next(val);
  }

  get_open_game_record_info() {
    return this.open_game_record_info.asObservable();
  }

  // 确认投注
  set_confirm_bet_info(val) {
    this.confirm_bet.next(val);
  }

  get_confirm_bet_info() {
    return this.confirm_bet.asObservable();
  }

  // 刷新余额
  set_credit_update_balance_handler(val) {
    this.credit_update_balance.next(val);
  }

  get_credit_update_balance_handler() {
    return this.credit_update_balance.asObservable();
  }
}
