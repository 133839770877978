import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dipinDaxiao'
})
export class DipinDaxiaoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // 大小形态
    let form: string;
    let b1;
    let b2;

    switch (args) {
      case 'qianer':
        b1 = Number(value[0]);
        b2 = Number(value[1]);
        form = this.calculation_form_handler(b1, b2);
        break;
      case 'houer':
        b1 = Number(value[1]);
        b2 = Number(value[2]);
        form = this.calculation_form_handler(b1, b2);
        break;
      default: break;
    }
    return form;
  }

  private calculation_form_handler(b1, b2): string {
    let str: string;
    let s1: string;
    let s2: string;

    if (b1 < 5) {
      s1 = '小';
    } else {
      s1 = '大';
    }

    if (b2 < 5) {
      s2 = '小';
    } else {
      s2 = '大';
    }

    str = `${s1}${s2}`;
    return str;
  }

}
