import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ssc-trend',
  templateUrl: './ssc-trend.component.html',
  styleUrls: ['./ssc-trend.component.css']
})
export class SscTrendComponent implements OnInit {

  // 当前游戏ID
  @Input()
  public current_game_play_id: number;

  // Mini走势数据
  @Input()
  public mini_trend_data: Array<any>;

  constructor() { }

  ngOnInit() {
  }

}
