import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-daxiaodanshuang-qiansan',
  templateUrl: './ssc-daxiaodanshuang-qiansan.component.html',
  styleUrls: ['./ssc-daxiaodanshuang-qiansan.component.css']
})
export class SscDaxiaodanshuangQiansanComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false],
      [false, false, false, false],
      [false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball('大'),
        new Ball('小'),
        new Ball('单'),
        new Ball('双')
      ],
      [
        new Ball('大'),
        new Ball('小'),
        new Ball('单'),
        new Ball('双')
      ],
      [
        new Ball('大'),
        new Ball('小'),
        new Ball('单'),
        new Ball('双')
      ]
    ];
  }

  ngOnInit() {
  }

}
