import { Component, OnInit } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { GameCreditBase } from 'app/common/gameCreditBase';
import { Utils } from 'app/common/util';

@Component({
  selector: 'app-pcdd-zhenghe',
  templateUrl: './pcdd-zhenghe.component.html',
  styleUrls: ['./pcdd-zhenghe.component.css']
})
export class PcddZhengheComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    [
      { name: '0', p: 0, is_select: false, val: null },
      { name: '1', p: 0, is_select: false, val: null },
      { name: '2', p: 0, is_select: false, val: null },
      { name: '3', p: 0, is_select: false, val: null },
      { name: '4', p: 0, is_select: false, val: null },
      { name: '5', p: 0, is_select: false, val: null },
      { name: '6', p: 0, is_select: false, val: null },

      { name: '7', p: 0, is_select: false, val: null },
      { name: '8', p: 0, is_select: false, val: null },
      { name: '9', p: 0, is_select: false, val: null },
      { name: '10', p: 0, is_select: false, val: null },
      { name: '11', p: 0, is_select: false, val: null },
      { name: '12', p: 0, is_select: false, val: null },
      { name: '13', p: 0, is_select: false, val: null },

      { name: '14', p: 0, is_select: false, val: null },
      { name: '15', p: 0, is_select: false, val: null },
      { name: '16', p: 0, is_select: false, val: null },
      { name: '17', p: 0, is_select: false, val: null },
      { name: '18', p: 0, is_select: false, val: null },
      { name: '19', p: 0, is_select: false, val: null },
      { name: '20', p: 0, is_select: false, val: null },

      { name: '21', p: 0, is_select: false, val: null },
      { name: '22', p: 0, is_select: false, val: null },
      { name: '23', p: 0, is_select: false, val: null },
      { name: '24', p: 0, is_select: false, val: null },
      { name: '25', p: 0, is_select: false, val: null },
      { name: '26', p: 0, is_select: false, val: null },
      { name: '27', p: 0, is_select: false, val: null }
    ],
    [
      { name: '特码包三', p: 0, is_select: false, val: null }
    ],
    [
      { name: '大', p: 0, is_select: false, val: null },
      { name: '小', p: 0, is_select: false, val: null },
      { name: '单', p: 0, is_select: false, val: null },
      { name: '双', p: 0, is_select: false, val: null }
    ],
    [
      { name: '大单', p: 0, is_select: false, val: null },
      { name: '大双', p: 0, is_select: false, val: null },
      { name: '小单', p: 0, is_select: false, val: null },
      { name: '小双', p: 0, is_select: false, val: null }
    ],
    [
      { name: '极大', p: 0, is_select: false, val: null },
      { name: '极小', p: 0, is_select: false, val: null }
    ],
    [
      { name: '红波', p: 0, is_select: false, val: null },
      { name: '蓝波', p: 0, is_select: false, val: null },
      { name: '绿波', p: 0, is_select: false, val: null },
      { name: '豹子', p: 0, is_select: false, val: null }
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
   * 重制号码状态
   *
   * @memberof PcddZhengheComponent
   */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach((group) => {
      group.forEach(item => {
        item.is_select = false;
        item.val = null;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof PcddZhengheComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach((group, n) => {
      group.forEach((item, m) => {
        item.p = (this.prize_group / 2000) * (1 / p_arr[n][m]);
      });
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof PcddZhengheComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} ball
   * @memberof PcddZhengheComponent
   */
  public selecte_ball_handler(ball: any) {
    if (!ball.is_select) {
      ball.is_select = true;
      ball.val = this.bet_value;

      // balls内容整理
      this.update_bet_balls_info();
    }
  }

  /**
   * 失去焦点
   *
   * @param {*} ball
   * @memberof PcddZhengheComponent
   */
  public change_val_handler(ball: any) {
    if (ball.is_select && !ball.val) {
      ball.val = this.bet_value;
    }

    let max_m = Math.floor(Utils.dp_max_prize / Number(ball['p']));
    ball.val = ball.val > max_m ? max_m : ball.val;

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 渲染投注金额
   *
   * @memberof PcddZhengheComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach((group) => {
      group.forEach(item => {
        if (item.is_select) {
          item.val = this.bet_value;
        }
      });
    });

    // balls内容整理
    this.update_bet_balls_info();
  }

  /**
   * 更新注单balls内容
   *
   * @memberof PcddZhengheComponent
   */
  public update_bet_balls_info() {
    this.is_selected_info = [];
    this.panel_arr.forEach(group => {
      group.forEach(item => {
        if (item.is_select && item.val !== null) {
          this.is_selected_info.push(item);
        }
      });
    });

    let ball_arr = [];
    if (this.is_selected_info.length > 0) {
      this.is_selected_info.forEach(item => {
        let bet_info = new BetInfo();

        bet_info.way_id = this.set_bet_content_handler(item)['way_id'];
        bet_info.ball = this.set_bet_content_handler(item)['ball'];
        bet_info.num = 1;
        bet_info.prize_group = Number(this.prize_group);

        let max_m = Math.floor(Utils.dp_max_prize / Number(item['p']));
        item.val = item.val > max_m ? max_m : item.val;
        bet_info.multiple = Number(item.val);

        ball_arr.push(bet_info);
      });

      this.betInfoService.set_bet_info_handler(ball_arr);
      this.betInfoService.set_bet_num_handler(this.is_selected_info.length);
    } else {
      this.betInfoService.set_bet_num_handler(0);
    }
  }

  /**
   * 设置投注内容
   *
   * @param {*} contetnt
   * @returns
   * @memberof PcddZhengheComponent
   */
  public set_bet_content_handler(contetnt: any) {
    let ball: any;
    let way_id: any;
    switch (contetnt.name) {
      case '大':
        ball = '1';
        way_id = 283;
        break;
      case '小':
        ball = '0';
        way_id = 283;
        break;
      case '单':
        ball = '1';
        way_id = 284;
        break;
      case '双':
        ball = '0';
        way_id = 284;
        break;
      case '极大':
        ball = '1';
        way_id = 285;
        break;
      case '极小':
        ball = '0';
        way_id = 285;
        break;
      case '大单':
        ball = '11';
        way_id = 286;
        break;
      case '大双':
        ball = '10';
        way_id = 286;
        break;
      case '小单':
        ball = '01';
        way_id = 286;
        break;
      case '小双':
        ball = '00';
        way_id = 286;
        break;
      default:
        ball = contetnt.name;
        way_id = 282;
        break;
    }

    return { 'ball': ball, 'way_id': way_id };
  }
}

class BetInfo {
  // 玩法ID
  public way_id: number;
  // ball信息
  public ball: string;
  // 注单数量
  public num: number;
  // 奖金组
  public prize_group: number;
  // 倍数
  public multiple: number;
  // 单位
  public onePrice: number = 1;
  public moneyunit: number = 1;

  constructor() { }
}
