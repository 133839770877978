import { Component, OnInit } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { GameCreditBase } from 'app/common/gameCreditBase';

@Component({
  selector: 'app-ssc-credit-quanwuzhongyi',
  templateUrl: './ssc-credit-quanwuzhongyi.component.html',
  styleUrls: ['./ssc-credit-quanwuzhongyi.component.css']
})
export class SscCreditQuanwuzhongyiComponent extends GameCreditBase implements OnInit {

  public panel_arr: Array<any> = [
    [
      { name: '0', p: 0, val: null, is_select: false },
      { name: '1', p: 0, val: null, is_select: false },
      { name: '2', p: 0, val: null, is_select: false },
      { name: '3', p: 0, val: null, is_select: false },
      { name: '4', p: 0, val: null, is_select: false },
      { name: '5', p: 0, val: null, is_select: false },
      { name: '6', p: 0, val: null, is_select: false },
      { name: '7', p: 0, val: null, is_select: false },
      { name: '8', p: 0, val: null, is_select: false },
      { name: '9', p: 0, val: null, is_select: false }
    ]
  ];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);
  }

  ngOnInit() {
    this.reset_balls_panel_handler();
  }

  /**
 * 重制号码状态
 *
 * @memberof SscCreditQuanwuzhongyiComponent
 */
  public reset_balls_panel_handler() {
    this.panel_arr.forEach(item => {
      item.forEach(ball => {
        ball['is_select'] = false;
        ball['val'] = null;
      });
    });
  }

  /**
   * 填充奖金信息
   *
   * @memberof SscCreditQuanwuzhongyiComponent
   */
  public fill_probilities_info_handler() {
    let p_arr = this.prize_probability;

    this.panel_arr.forEach((item, index) => {
      if (index === 0) {
        item.forEach((ball) => {
          ball['p'] = (this.prize_group / 2000) * (1 / p_arr[0]);
        });
      } else {
        item.forEach((ball) => {
          ball['p'] = (this.prize_group / 2000) * (1 / p_arr[1]);
        });
      }
    });
  }

  /**
   * 切换球的状态
   *
   * @param {*} ball
   * @memberof SscCreditQuanwuzhongyiComponent
   */
  public switch_ball_status_handler(ball: any) {
    ball.is_select = !ball.is_select;
    if (!ball.is_select) {
      ball.val = null;
    } else {
      ball.val = this.bet_value;
    }
  }

  /**
   * 设置输入框，选中球
   *
   * @param {*} ball
   * @memberof SscCreditQuanwuzhongyiComponent
   */
  public selecte_ball_handler(ball: any) {
    ball.is_select = true;
    ball.val = this.bet_value;
  }

  /**
   * 渲染投注金额
   *
   * @memberof SscCreditQuanwuzhongyiComponent
   */
  public render_bet_value_handler() {
    this.panel_arr.forEach((item) => {
      item.forEach(ball => {
        if (ball.is_select) {
          ball.val = this.bet_value;
        }
      });
    });
  }

}
