import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';

@Component({
  selector: 'app-lhc-panel',
  templateUrl: './lhc-panel.component.html',
  styleUrls: ['./lhc-panel.component.css']
})
export class LhcPanelComponent implements OnInit {

  // 默认类型下标
  public default_play_type_index: number;
  // 默认玩法类型ID
  public play_type_id: number;
  // 当前玩法信息
  public current_play_info: any;
  @Input()
  public current_zodiac: number;
  // 玩法菜单数据
  @Input()
  public play_menu: Array<any>;
  // 当前玩法ID
  @Input()
  public current_game_play_id: number;
  // 玩法集合
  @Input()
  public game_play_array: Array<any>;
  // 发送当前玩法
  @Output()
  public emit_game_play_id: EventEmitter<any> = new EventEmitter<any>();
  // 样式
  public bar_style: any = { color: '#fff' };

  constructor(
    public betInfoService: BetInfoService
  ) { }

  ngOnInit() {
    this.init_menu_handler();
  }

  /**
   * 指向默认菜单
   *
   * @memberof LhcPanelComponent
   */
  public init_menu_handler() {
    for (let i = 0; i < this.game_play_array.length; i++) {
      let play = this.game_play_array[i];
      if (play.id === this.current_game_play_id) {
        this.play_type_id = play['menu_id'];
        this.current_play_info = play;
      }
    }

    for (let i = 0; i < this.play_menu.length; i++) {
      if (this.play_menu[i]['id'] === this.play_type_id) {
        this.default_play_type_index = i;
      }
    }
  }

  /**
   * 切换玩法类型
   *
   * @param {*} e
   * @memberof LhcPanelComponent
   */
  public switch_play_menu_handler(e: any) {
    this.default_play_type_index = e;
    this.current_game_play_id = Number(this.play_menu[e]['children'][0]['children'][0]['id']);
    this.switch_game_play_handler(this.play_menu[e]['children'][0]['children'][0]);
  }

  /**
   * 切换玩法操作
   *
   * @param {*} play
   * @memberof LhcPanelComponent
   */
  public switch_game_play_handler(play: any) {
    this.betInfoService.set_play_info_handler(play);
    this.current_play_info = play;
    this.current_game_play_id = play.id;
    this.emit_game_play_id.emit(play);
  }

}
