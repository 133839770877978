import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-k3-ertonghao',
  templateUrl: './k3-ertonghao.component.html',
  styleUrls: ['./k3-ertonghao.component.css']
})
export class K3ErtonghaoComponent extends GameBase implements OnInit {

  public key_arr = [false, false, false, false, false, false];

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    this.btn_status_array = [
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false]
    ];

    this.lottery_data = [
      [
        new Ball('112'),
        new Ball('113'),
        new Ball('114'),
        new Ball('115'),
        new Ball('116')
      ],
      [
        new Ball('122'),
        new Ball('223'),
        new Ball('224'),
        new Ball('225'),
        new Ball('226')
      ],
      [
        new Ball('133'),
        new Ball('233'),
        new Ball('334'),
        new Ball('335'),
        new Ball('336')
      ],
      [
        new Ball('144'),
        new Ball('244'),
        new Ball('344'),
        new Ball('445'),
        new Ball('446')
      ],
      [
        new Ball('155'),
        new Ball('255'),
        new Ball('355'),
        new Ball('455'),
        new Ball('556')
      ],
      [
        new Ball('166'),
        new Ball('266'),
        new Ball('366'),
        new Ball('466'),
        new Ball('566')
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 更新选球值
   *
   * @memberof K3ErtonghaoComponent
   */
  public update_bet_infor_handler() {
    let selected_ball_str: string = '';
    let no_ball: string = '';

    for (let i = 0; i < this.btn_status_array.length; i++) {
      for (let j = 0; j < this.btn_status_array[i].length; j++) {
        if (this.btn_status_array[i][j]) {
          selected_ball_str = selected_ball_str + this.lottery_data[i][j].get_ball_num() + '|';
        }
      }
    }

    selected_ball_str = (selected_ball_str === no_ball) ? '' : selected_ball_str.substring(0, selected_ball_str.length - 1);
    this.balls_str = selected_ball_str;
    this.betInfoService.set_bet_info_handler(this.balls_str);

    // 判断是否选球完成，构成注单
    if (this.is_selected_ball_completed()) {
      this.calculate_bet_number();
    } else {
      this.bet_num = 0;
    }

    this.betInfoService.set_bet_num_handler(this.bet_num);
  }

  /**
   * 计算注单数量
   *
   * @memberof K3ErtonghaoComponent
   */
  public calculate_bet_number() {
    let num: number = 0;
    num = this.selected_ball_array.length;

    this.bet_num = num;
  }

  /**
   * 行列内容全选操作
   *
   * @param {string} key
   * @memberof K3ErtonghaoComponent
   */
  public selected_cancel_row_all_handler(key: string) {
    switch (key) {
      case '11':
        this.key_arr[0] = !this.key_arr[0];
        if (this.key_arr[0]) {
          for (let i = 0; i < this.btn_status_array[0].length; i++) {
            this.btn_status_array[0][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[0].length; i++) {
            this.btn_status_array[0][i] = false;
          }
        }
        break;
      case '22':
        this.key_arr[1] = !this.key_arr[1];
        if (this.key_arr[1]) {
          for (let i = 0; i < this.btn_status_array[1].length; i++) {
            this.btn_status_array[1][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[1].length; i++) {
            this.btn_status_array[1][i] = false;
          }
        }
        break;
      case '33':
        this.key_arr[2] = !this.key_arr[2];
        if (this.key_arr[2]) {
          for (let i = 0; i < this.btn_status_array[2].length; i++) {
            this.btn_status_array[2][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[2].length; i++) {
            this.btn_status_array[2][i] = false;
          }
        }
        break;
      case '44':
        this.key_arr[3] = !this.key_arr[3];
        if (this.key_arr[3]) {
          for (let i = 0; i < this.btn_status_array[3].length; i++) {
            this.btn_status_array[3][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[3].length; i++) {
            this.btn_status_array[3][i] = false;
          }
        }
        break;
      case '55':
        this.key_arr[4] = !this.key_arr[4];
        if (this.key_arr[4]) {
          for (let i = 0; i < this.btn_status_array[4].length; i++) {
            this.btn_status_array[4][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[4].length; i++) {
            this.btn_status_array[4][i] = false;
          }
        }
        break;
      case '66':
        this.key_arr[5] = !this.key_arr[5];
        if (this.key_arr[5]) {
          for (let i = 0; i < this.btn_status_array[5].length; i++) {
            this.btn_status_array[5][i] = true;
          }
        } else {
          for (let i = 0; i < this.btn_status_array[5].length; i++) {
            this.btn_status_array[5][i] = false;
          }
        }
        break;
      default: break;
    }

    this.update_bet_infor_handler();
  }

  /**
   * 反选效果
   *
   * @param {string} bets
   * @memberof K3ErtonghaoComponent
   */
  public update_seleted_bets_handler(bets: string) {
    let bet_arr = bets.split('|');

    for (let i = 0; i < bet_arr.length; i++) {
      // 对比lottery_data数据，确认选球位置
      for (let j = 0; j < this.lottery_data.length; j++) {
        for (let k = 0; k < this.lottery_data[j].length; k++) {
          if (bet_arr[i] === this.lottery_data[j][k].get_ball_num()) {
            this.btn_status_array[j][k] = true;
          }
        }
      }
    }

    this.update_bet_infor_handler();
  }

}
