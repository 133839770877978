import { Component, OnInit } from '@angular/core';
import { GameBase } from 'app/common/gameBase';
import { BetInfoService } from 'app/providers/bet-info/bet-info.service';
import { Ball } from 'app/common/betBall';

@Component({
  selector: 'app-ssc-budingwei-sanxing-zhongsan-erma',
  templateUrl: './ssc-budingwei-sanxing-zhongsan-erma.component.html',
  styleUrls: ['./ssc-budingwei-sanxing-zhongsan-erma.component.css']
})
export class SscBudingweiSanxingZhongsanErmaComponent extends GameBase implements OnInit {

  constructor(
    public betInfoService: BetInfoService
  ) {
    super(betInfoService);

    /** 玩法面板选球状态 */
    this.btn_status_array = [
      [false, false, false, false, false, false, false, false, false, false]
    ];

    /** 快捷选号数组 */
    this.fast_option_array = [
      [false, false, false, false, false, false]
    ];

    /** 玩法面板数据 */
    this.lottery_data = [
      [
        new Ball(0),
        new Ball(1),
        new Ball(2),
        new Ball(3),
        new Ball(4),
        new Ball(5),
        new Ball(6),
        new Ball(7),
        new Ball(8),
        new Ball(9)
      ]
    ];
  }

  ngOnInit() {
  }

  /**
   * 判断是否完成选球，构成注单
   *
   * @returns {boolean}
   * @memberof SscBudingweiSanxingZhongsanErmaComponent
   */
  public is_selected_ball_completed(): boolean {
    this.selected_ball_array = [];

    let pos_array = this.balls_str.split('|');
    for (let item of pos_array) {
      let arr = item.split('');
      this.selected_ball_array.push(arr);
    }

    for (let item of this.selected_ball_array) {
      if (item.length < 2) {
        return false;
      }
    }

    return true;
  }

  /**
   * 计算注单数量
   *
   * @memberof SscBudingweiSanxingZhongsanErmaComponent
   */
  public calculate_bet_number() {
    let num: number = 1;
    for (let item of this.selected_ball_array) {
      let len = item.length;
      num = this.fac(len) / (this.fac(2) * this.fac((len - 2)));
    }

    this.bet_num = num;
  }

}
